import { Deserializable } from '../protocols/deserializable';

export class MemberSubmissionType implements Deserializable {
  public id!: number;
  public name!: string;
  public idSuffix!: string;
  public effort!: number;
  public daysBeforeOverdue!: number;

  onDeserialize() {}
}
