import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable, Type } from '@angular/core';
import { Deserializable } from '../models/protocols/deserializable';
import { StringifyUtils } from '../utils/stringify-utils';
import { catchError, map } from 'rxjs/operators';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { ODataResponse } from '../models/protocols/odata-response';
import { environment } from '../../environments/environment';
import * as crypto from 'crypto';
import { ErrorResponse } from '../models/shared/responses/error-response';

@Injectable({
  providedIn: 'root'
})
export class ApiClient {
  constructor(private http: HttpClient) {}

  /* ************************** GET ************************** */

  public getObj<T extends Deserializable>(
    ObjectType: Type<T>,
    url: string,
    additionalHeaders: any = null
  ): Observable<T> {
    let responseType = 'text';
    if (environment.encryptionBypass) {
      responseType = 'json';
    }
    const urlObj = new URL(url);
    const baseURL = urlObj.origin + urlObj.pathname;
    const params = urlObj.searchParams;
    const completeUrl = `${baseURL}?${this.encrypt(params.toString(), false)}`;

    return this.http
      .get<T | undefined>(completeUrl, { headers: additionalHeaders, responseType: responseType as 'json' })
      .pipe(
        map(r => this.decrypt<T>(r)),
        map(r => window?.injector?.Deserialize?.instanceOf(ObjectType, r))
      );
  }

  public simpleGet(url: string, additionalHeaders: any = null): Observable<HttpResponse<any>> {
    return this.http.get(url, { headers: additionalHeaders, observe: 'response' });
  }

  public getArr<T extends Deserializable>(
    ObjectType: Type<T>,
    url: string,
    additionalHeaders: any = null
  ): Observable<T[]> {
    let responseType = 'text';
    if (environment.encryptionBypass) {
      responseType = 'json';
    }
    return this.http.get<T[]>(url, { headers: additionalHeaders, responseType: responseType as 'json' }).pipe(
      map(r => {
        return !r ? [] : this.decrypt<T[]>(r);
      }),
      map(r => r.map(rr => window?.injector?.Deserialize?.instanceOf(ObjectType, rr)) as T[])
    );
  }

  public getBlob<Blob>(url: string): Observable<Blob> {
    return this.http.get<Blob>(url, {
      responseType: 'blob' as 'json'
    });
  }

  public getOdata<T extends Deserializable>(
    url: string,
    objectType: Type<T>,
    odataQueryOptions?: ODataQueryOptions,
    additionalHeaders: any = null,
    additionalParams: string = '',
    skipQuestionMarkAppending: boolean = false
  ): Observable<ODataResponse<T>> {
    let completeUrl = url;
    let responseType = 'text';
    if (environment.encryptionBypass) {
      responseType = 'json';
    }
    if ((odataQueryOptions || additionalParams) && !skipQuestionMarkAppending) {
      completeUrl += '?';
    }
    if (odataQueryOptions) {
      completeUrl += `${this.encrypt(odataQueryOptions?.toQueryString(), false)}`;
    }
    if (additionalParams) {
      completeUrl += `${odataQueryOptions ? '&' : ''}${this.encrypt(additionalParams, false)}`;
    }
    return this.http
      .get<ODataResponse<T>>(completeUrl, {
        headers: additionalHeaders,
        responseType: responseType as 'json'
      })
      .pipe(
        map(r => this.decrypt<ODataResponse<T>>(r)),
        map(response => {
          const data = Array.isArray(response.value) ? response.value : [response.value];
          return { ...response, value: data };
        }),
        map((response: ODataResponse<T>) => {
          const mappedData = response.value.map(item => window?.injector?.Deserialize?.instanceOf(objectType, item));
          return { ...response, value: mappedData };
        })
      );
  }

  public getOdataObj<T extends Deserializable>(
    url: string,
    objectType: Type<T>,
    odataQueryOptions?: ODataQueryOptions,
    additionalHeaders: any = null
  ): Observable<T> {
    let completeUrl = url;
    let responseType = 'text';
    if (environment.encryptionBypass) {
      responseType = 'json';
    }
    if (odataQueryOptions) {
      completeUrl += `?${this.encrypt(odataQueryOptions?.toQueryString(), false)}`;
    }
    return this.http
      .get<T | undefined>(completeUrl, {
        headers: additionalHeaders,
        responseType: responseType as 'json'
      })
      .pipe(
        catchError((e: HttpErrorResponse) => {
          const customErr = new ErrorResponse(e.status, $localize`No content returned.`);
          throw new HttpErrorResponse({ error: customErr });
        }),
        map(r => this.decrypt<T>(r)),
        map(r => window?.injector?.Deserialize?.instanceOf(objectType, r))
      );
  }

  /* ************************* POST ************************** */

  public simplePost(
    url: string,
    payload: Deserializable,
    additionalHeaders: any = null
  ): Observable<HttpResponse<any>> {
    let responseType = 'text';
    let convertedPayload = '';
    if (environment.encryptionBypass) {
      responseType = 'json';
      convertedPayload = this.getStringifiedObject(payload);
    } else {
      convertedPayload = this.encrypt(payload);
    }
    return this.http
      .post(url, convertedPayload, {
        headers: additionalHeaders,
        responseType: responseType as 'json',
        observe: 'response'
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          const decryptedBody = this.decrypt(response.body);
          return new HttpResponse({
            body: decryptedBody,
            headers: response.headers,
            status: response.status,
            statusText: response.statusText,
            url: response.url ?? undefined
          });
        })
      );
  }

  public simpleBodylessPost(url: string, additionalHeaders: any = null): Observable<HttpResponse<any>> {
    let responseType = 'text';
    if (environment.encryptionBypass) {
      responseType = 'json';
    }
    return this.http
      .post(url, undefined, {
        headers: additionalHeaders,
        responseType: responseType as 'json',
        observe: 'response'
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          const decryptedBody = this.decrypt(response.body);
          return new HttpResponse({
            body: decryptedBody,
            headers: response.headers,
            status: response.status,
            statusText: response.statusText,
            url: response.url ?? undefined
          });
        })
      );
  }

  public postObj<T extends Deserializable, U extends Deserializable | Deserializable[]>(
    ReturnType: Type<T>,
    url: string,
    payload: U,
    additionalHeaders: any = null
  ): Observable<T> {
    let responseType = 'text';
    let convertedPayload = '';
    if (environment.encryptionBypass) {
      responseType = 'json';
      convertedPayload = this.getStringifiedObject(payload);
    } else {
      convertedPayload = this.encrypt(this.getStringifiedObject(payload));
    }
    return this.http
      .post<T>(url, convertedPayload, {
        headers: additionalHeaders,
        responseType: responseType as 'json'
      })
      .pipe(
        map(r => this.decrypt<T>(r)),
        map(r => window?.injector?.Deserialize?.instanceOf(ReturnType, r))
      );
  }

  /* ************************* PUT *************************** */

  public putWithUntypedRes(url: string, payload: any, additionalHeaders?: HttpHeaders): Observable<HttpResponse<any>> {
    let responseType = 'text';
    let convertedPayload = '';
    if (environment.encryptionBypass) {
      responseType = 'json';
      convertedPayload = this.getStringifiedObject(payload);
    } else {
      convertedPayload = this.encrypt(payload);
    }
    return this.http
      .put<any>(url, convertedPayload, {
        headers: additionalHeaders,
        responseType: responseType as 'json'
      })
      .pipe(map(r => this.decrypt<HttpResponse<any>>(r)));
  }

  public putAssetToS3(url: string, payload: any, additionalHeaders?: HttpHeaders): Observable<HttpResponse<any>> {
    return this.http.put<any>(url, payload, {
      headers: additionalHeaders,
      responseType: 'json'
    });
  }

  public putObj<T extends Deserializable, U extends Deserializable>(
    ReturnType: Type<T>,
    url: string,
    payload: U | null,
    additionalHeaders: any = null
  ): Observable<T> {
    let responseType = 'text';
    let convertedPayload: string | null;
    if (environment.encryptionBypass) {
      responseType = 'json';
      convertedPayload = !!payload ? this.getStringifiedObject(payload) : null;
    } else {
      convertedPayload = !!payload ? this.encrypt(payload) : null;
    }
    return this.http
      .put<T>(url, convertedPayload, {
        headers: additionalHeaders,
        responseType: responseType as 'json'
      })
      .pipe(
        map(r => this.decrypt<T>(r)),
        map(r => {
          // The following exists for PUT requests that return no payload
          // In that case, the endpoint will just return a new object of the expected return type
          // It is up the developer to handle that empty object
          if (!!r) {
            return window?.injector?.Deserialize?.instanceOf(ReturnType, r);
          }
          return new ReturnType();
        })
      );
  }

  /* ************************ DELETE ************************* */

  public deleteWithUntypedRes(url: string, additionalHeaders?: HttpHeaders): Observable<HttpResponse<any>> {
    let responseType = 'text';
    if (environment.encryptionBypass) {
      responseType = 'json';
    }
    return this.http
      .delete<any>(url, {
        headers: additionalHeaders,
        responseType: responseType as 'json'
      })
      .pipe(
        map(r => {
          return !r ? r : this.decrypt<HttpResponse<any>>(r);
        })
      );
  }

  /* ************************ ENCRYPTION ************************* */

  private decrypt<T>(base64Cipher: any): T {
    if (environment.encryptionBypass) return base64Cipher as T;
    const decipher = crypto.createDecipheriv('aes-256-cbc', environment.encryptionKey, environment.encryptionIV);
    let decryptedString = decipher.update(base64Cipher, 'base64', 'utf8');
    decryptedString += decipher.final('utf8');
    if (decryptedString === '') return '' as T;
    return JSON.parse(decryptedString) as T;
  }

  private encrypt(message: any, messageIsJson: boolean = true): string {
    if (environment.encryptionBypass) return message;
    const stringifyMessage = messageIsJson ? JSON.stringify(message) : message;
    const cipher = crypto.createCipheriv('aes-256-cbc', environment.encryptionKey, environment.encryptionIV);
    let encrypted = cipher.update(stringifyMessage, 'utf8', 'base64');
    encrypted += cipher.final('base64');
    return encrypted;
  }

  /* ************************ Helpers ************************* */
  private getStringifiedObject(payload: Deserializable | Deserializable[]): string {
    if (Array.isArray(payload)) {
      return JSON.stringify(
        payload.map(item => item?.onSerialize?.() ?? item),
        StringifyUtils.replacer
      );
    } else {
      return JSON.stringify(payload?.onSerialize?.() ?? payload, StringifyUtils.replacer);
    }
  }
}
