import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalUtils } from '../utils/modal-utils';
import { Injector } from '@angular/core';
import { ConfirmationModalComponent } from '../modules/confirmation/confirmation-modal/confirmation-modal.component';
import { from, Observable } from 'rxjs';
import { ConfirmationOptions } from '../models/shared/stylesheet/confirmation-options';

export class ModalConfirmation {
  static open(
    ngbModal: NgbModal,
    injector: Injector,
    confirmationOpts: ConfirmationOptions,
    onConfirmation?: (confirmed: boolean) => void,
    returnObservable?: boolean
  ): void | Observable<boolean> {
    const modalRef = ngbModal.open(ConfirmationModalComponent, ModalUtils.confirmationModalOptions(injector));
    const compInstance = modalRef.componentInstance as ConfirmationModalComponent;
    compInstance.setConfirmationOptions(confirmationOpts);
    if (returnObservable) {
      return from(modalRef.result);
    }
    modalRef.result
      .then(cont => {
        onConfirmation?.(cont);
      })
      .catch();
  }
}
