import { MemberUser } from '../../dto/member-user';
import { SubmissionRequest } from './submission-request';

export class ChangeNameRequest extends SubmissionRequest {
  public firstName: string;
  public middleInitial: string;
  public preferredName: string;
  public lastName: string;

  constructor(user: MemberUser) {
    super();
    this.firstName = user.firstName;
    this.middleInitial = user.middleInitial;
    this.lastName = user.lastName;
    this.preferredName = user.preferredName;
    this.memberId = user.id;
  }
}
