import { Deserializable } from '../../protocols/deserializable';

export class State implements Deserializable {
  stateCode!: string;
  countryId!: number;
  countryCode!: string;
  countryName!: string;
  id!: number;
  name!: string;

  onDeserialize() {}
}
