import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-file-uploader-text',
  templateUrl: './file-uploader-text.component.html',
  styleUrls: ['./file-uploader-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploaderTextComponent extends BaseComponent {
  @Input() public disabled = false;
  @Input() public error = false;
  @Input() public descriptionText = '';

  setupViews(): void {}
  setupBindings(): void {}
}
