import { CachePolicy } from '../models/enum/shared/cachable-image-policy.enum';

export class StringifyUtils {
  static mapDataType = 'Map';

  static replacer(key: string, value: unknown) {
    const ignoredKeys: string[] = ['inheritedDisplayAttribute', 'srcUrl', 'loading', 'sizePriorityUrl'];
    const originalObject = this[key];
    if (ignoredKeys.contains(key)) {
      return undefined;
    } else if (originalObject instanceof Map) {
      const obj = Object.create(null);
      for (const [k, v] of originalObject) {
        obj[k] = v;
      }
      return obj;
    } else {
      return value;
    }
  }

  static [key: string]: any;

  static cachedBlobReplacer(key: string, value: CachePolicy) {
    const originalObject = this[key];
    if (key === 'url' || key === 'safeUrl' || key === 'blob') {
      return undefined;
    } else if (originalObject instanceof Map) {
      const obj = Object.create(null);
      for (const [k, v] of originalObject) {
        obj[k] = v;
      }
      return obj;
    } else {
      return value;
    }
  }
}
