import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponse } from './responses/error-response';

export class CustomError {
  public code!: number;
  public title!: string;
  public message!: string;

  constructor(err?: HttpErrorResponse, serviceName?: string) {
    if (err?.error) {
      const thrownError = err?.error as ErrorResponse;
      this.code = thrownError.StatusCode;
      this.title = serviceName + ' ' + this.code.toString(10);
      this.message = thrownError.Error;
    }
  }
}
