<div class="d-flex flex-column">
  <form class="search-bar-container">
    <label for="searchBar" [hidden]="!label">{{ label }}</label>
    <input
      #searchBar
      (input)="searchChanged($event)"
      class="text-field search-text-field"
      [class.error]="hasErrors"
      [class.disabled]="disabled"
      id="searchBar"
      name="searchBar"
      [disabled]="disabled"
      placeholder="{{ placeHolder }}"
      autocomplete="off"
      type="text" />
  </form>
  <div
    popper
    [popper]="popperContent"
    [popperShowOnStart]="false"
    [popperTrigger]="NgxPopperjsTriggers.none"
    [popperModifiers]="popperModifier"
    [popperHideOnClickOutside]="false"
    [popperHideOnScroll]="false"
    [popperPreventOverflow]="false"
    [popperTarget]="searchBar"
    [popperPositionFixed]="false"
    [popperApplyClass]="popperClass"
    [popperStyles]="popperStyles"
    [popperPlacement]="NgxPopperjsPlacements.BOTTOM"></div>
  <popper-content #popperContent [style.z-index]="1500" clickOutside (onClickOutside)="handleClickOutside()">
    <div class="loading-container" [hidden]="!(isLoading$ | async)">
      <app-loading [hidden]="!(isLoading$ | async)" [options]="(loadingOptions$ | async) ?? undefined"> </app-loading>
    </div>
    <app-look-ahead-list
      [customItemTemplate]="customItemTemplate"
      [lookAheadItems]="lookAheadItems"
      [lookAheadProperties]="searchedProperties"
      [useProfileItems]="useProfileListItems"
      (lookAheadItemClicked)="lookAheadSelected.emit($event); searchBar.value = ''; clearSearch()">
    </app-look-ahead-list>
  </popper-content>
</div>
