import { BaseViewModel } from '../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { NavItem } from '../nav-items/nav-item/models/nav-item';
import { DefaultNavItem } from '../nav-items/nav-item/models/default-nav-item';
import { NavItemId } from '../../../../models/enum/shared/nav-item-id.enum';
import { Router } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { ScreenService } from '../../../../services/screen-service.service';
import { UserDomainModel } from '../../../../domainModels/user-domain-model';
import { PortalService } from '../../../../services/portal/portal.service';
import { PortalType } from '../../../../models/enum/shared/portal-type';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { ProfileNavItem } from '../nav-items/nav-item/models/profile-nav-item';
import { DropDownNavItem } from '../nav-items/nav-item/models/drop-down-nav-item';
import { NavSubItem } from '../nav-items/nav-sub-item/models/nav-sub-item';
import { PermissionService } from '../../../../services/permission-service';

@Injectable({ providedIn: 'root' })
export class NavbarViewModel extends BaseViewModel {
  constructor(private router: Router) {
    super();
    this.init();
  }

  private screenService = inject(ScreenService);
  private userDomainModel = inject(UserDomainModel);
  private portalService = inject(PortalService);
  private permissionService = inject(PermissionService);

  public _loadingOpts = new BehaviorSubject(LoadingOptions.default(false, true));
  public loadingOpts$ = this._loadingOpts as Observable<LoadingOptions>;
  public isLoading$ = this.loadingOpts$.pipe(map(opts => opts.isLoading));

  public navItems$ = combineLatest([this.portalService.portalType$, this.permissionService.permissionMapReady$]).pipe(
    switchMap(([pt, mapReady]) => {
      if (mapReady) {
        switch (pt) {
          case PortalType.Employer:
            return this.getEmployerNavItems();
          case PortalType.Member:
            return this.getMemberNavItems();
          case PortalType.Internal:
            return this.getInternalUserNavItems();
          default:
            return of([]);
        }
      } else {
        return of([]);
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _isNavbarCollapsed = new BehaviorSubject(true);
  public readonly isNavbarCollapsed$ = this._isNavbarCollapsed as Observable<boolean>;

  public isMobile$ = this.screenService.isMobile$;

  public init() {
    super.init();
    const lm = $localize`Loading...`;
    this._loadingOpts.addRequest(lm);
    this.permissionService.permissionMapReady$.subscribeWhileAlive({
      owner: this,
      next: ready => {
        if (ready) {
          this._loadingOpts.removeRequest(lm);
        }
      }
    });
  }

  private getEmployerNavItems(): Observable<NavItem[]> {
    return combineLatest([
      this.userDomainModel.user$,
      this.permissionService.permissionGranted$([2001]),
      this.permissionService.permissionGranted$([2006]),
      this.permissionService.permissionGranted$([2009]),
      this.permissionService.permissionGranted$([2013])
    ]).pipe(
      map(([u, canViewEmployeeTable, canViewRemittances, canViewEmployerUsers, canViewEmployerPlan]) => {
        return [
          this.getHomeNavItem(),
          canViewEmployeeTable ? this.getEmployeesNavItem() : null,
          canViewRemittances ? this.getEmployerRemittanceNavItem() : null,
          this.getEmployerUserSettingsNavItem(canViewEmployerUsers, canViewEmployerPlan),
          this.getResourcesNavItem(),
          this.getProfileNavItem(u.fullName)
        ].filter((item): item is NavItem => !!item);
      })
    );
  }

  private getMemberNavItems(): Observable<NavItem[]> {
    return this.userDomainModel.user$.pipe(
      map(u => {
        const navItems = [];
        navItems.push(
          this.getHomeNavItem(),
          this.getAccountsNavItem(),
          this.getNotificationsNavItem(),
          this.getMemberActionsNavItem(),
          this.getResourcesNavItem(),
          this.getProfileNavItem(u.fullName)
        );
        return navItems;
      })
    );
  }

  private getInternalUserNavItems(): Observable<NavItem[]> {
    return combineLatest([
      this.userDomainModel.user$,
      this.permissionService.permissionGranted$([1001]),
      this.permissionService.permissionGranted$([1028]),
      this.permissionService.permissionGranted$([1020]),
      this.permissionService.permissionGranted$([1026]),
      this.permissionService.permissionGranted$([1002]),
      this.permissionService.permissionGranted$([1032])
    ]).pipe(
      map(
        ([
          u,
          canViewSubmissions,
          canViewAlerts,
          canViewInternalUsers,
          canViewRoles,
          canViewRemittances,
          canViewReleaseNotes
        ]) => {
          const navItems = [];
          navItems.push(this.getDashboardNavItem());
          navItems.push(this.getInternalWorkDropdownNavItem(canViewSubmissions, canViewRemittances));
          navItems.push(this.getRecordsDropdownNavItem(), this.getReportsNavItem());
          if (canViewAlerts || canViewInternalUsers || canViewRoles || canViewReleaseNotes) {
            navItems.push(
              this.getInternalUserSettingsDropdownNavItems(
                canViewAlerts,
                canViewInternalUsers,
                canViewRoles,
                canViewReleaseNotes
              )
            );
          }
          navItems.push(this.getProfileNavItem(u.fullName));
          return navItems;
        }
      )
    );
  }

  private getHomeNavItem(): NavItem {
    return new DefaultNavItem({
      id: NavItemId.Home,
      name: $localize`Home`,
      subItems: [],
      routerPath: '/dashboard',
      iconSrc: '',
      iconFilledSrc: ''
    });
  }

  private getDashboardNavItem(): NavItem {
    return new DefaultNavItem({
      id: NavItemId.Dashboard,
      name: $localize`Dashboard`,
      subItems: [],
      routerPath: '/dashboard',
      iconSrc: '',
      iconFilledSrc: ''
    });
  }

  private getAccountsNavItem(): NavItem {
    return new DefaultNavItem({
      id: NavItemId.Accounts,
      name: $localize`Accounts`,
      subItems: [],
      routerPath: '/accounts',
      iconSrc: '',
      iconFilledSrc: ''
    });
  }

  private getNotificationsNavItem(): NavItem {
    return new DefaultNavItem({
      id: NavItemId.Notifications,
      name: $localize`Notifications`,
      subItems: [],
      routerPath: '/notifications',
      iconSrc: '',
      iconFilledSrc: ''
    });
  }

  private getEmployeesNavItem(): NavItem {
    return new DefaultNavItem({
      id: NavItemId.Members,
      name: $localize`Employees`,
      subItems: [],
      routerPath: '/employees',
      iconSrc: '',
      iconFilledSrc: ''
    });
  }

  private getEmployersNavItem(): NavItem {
    return new DefaultNavItem({
      id: NavItemId.Employers,
      name: $localize`Employers`,
      subItems: [],
      routerPath: '/employers',
      iconSrc: '',
      iconFilledSrc: ''
    });
  }

  private getEmployerRemittanceNavItem(): NavItem {
    return new DefaultNavItem({
      id: NavItemId.Remittance,
      name: $localize`Remittance`,
      subItems: [],
      routerPath: '/remittances/employers/remittances',
      iconSrc: '',
      iconFilledSrc: ''
    });
  }

  private getEmployerUserSettingsNavItem(canViewEmployerUsers: boolean, canViewEmployerPlan: boolean): NavItem | null {
    const subItems = [
      canViewEmployerUsers ? new NavSubItem($localize`Employer Users`, 'employer-users') : null,
      canViewEmployerPlan ? new NavSubItem($localize`Plan Configuration`, 'employer-plan') : null
    ].filter((item): item is NavSubItem => !!item);
    return subItems.length
      ? new DropDownNavItem({
          id: NavItemId.Settings,
          name: $localize`Settings`,
          subItems,
          routerPath: '/settings',
          iconSrc: 'assets/icons/light/DownChevron.svg',
          iconFilledSrc: 'assets/icons/white/Chevron Down.svg'
        })
      : null;
  }

  private getReportsNavItem(): NavItem {
    return new DefaultNavItem({
      id: NavItemId.Reports,
      name: $localize`Reports`,
      subItems: [],
      routerPath: '/reports',
      iconSrc: '',
      iconFilledSrc: ''
    });
  }

  private getInternalUserSettingsDropdownNavItems(
    canViewAlerts: boolean,
    canViewInternalUsers: boolean,
    canViewRoles: boolean,
    canViewReleaseNotes: boolean
  ): NavItem {
    const subItems: NavSubItem[] = [];
    if (canViewRoles) {
      const rolesSubItem = new NavSubItem($localize`Roles`, '');
      rolesSubItem.subItemFragment = 'roles';
      subItems.push(rolesSubItem);
    }

    if (canViewAlerts) subItems.push(new NavSubItem($localize`Alerts`, 'alerts'));
    if (canViewInternalUsers) subItems.push(new NavSubItem($localize`Internal Users`, 'internal-users'));
    if (canViewReleaseNotes) subItems.push(new NavSubItem($localize`Release Notes`, 'release-notes'));

    return new DropDownNavItem({
      id: NavItemId.Settings,
      name: $localize`Settings`,
      subItems,
      routerPath: '/settings',
      iconSrc: 'assets/icons/light/DownChevron.svg',
      iconFilledSrc: 'assets/icons/white/Chevron Down.svg',
      baseFragmentRoute: '/settings/menu'
    });
  }

  private getInternalWorkDropdownNavItem(canViewSubmissions: boolean, canViewRemittances: boolean): NavItem {
    const subItems: NavSubItem[] = [];
    if (canViewSubmissions) {
      const rolesSubItem = new NavSubItem($localize`Submissions`, 'submissions');
      subItems.push(rolesSubItem);
    }

    if (canViewRemittances) {
      subItems.push(new NavSubItem($localize`Remittances`, 'remittances/queue'));
    }

    return new DropDownNavItem({
      id: NavItemId.Work,
      name: $localize`Work`,
      subItems,
      routerPath: '',
      iconSrc: 'assets/icons/light/DownChevron.svg',
      iconFilledSrc: 'assets/icons/white/Chevron Down.svg',
      itemMapsToRoute: false
    });
  }

  private getRecordsDropdownNavItem(): NavItem {
    return new DropDownNavItem({
      id: NavItemId.Records,
      name: $localize`Records`,
      subItems: [new NavSubItem($localize`Members`, 'members'), new NavSubItem($localize`Employers`, 'employers')],
      routerPath: '/records',
      iconSrc: 'assets/icons/light/DownChevron.svg',
      iconFilledSrc: 'assets/icons/white/Chevron Down.svg'
    });
  }

  private getMemberActionsNavItem(): NavItem {
    return new DefaultNavItem({
      id: NavItemId.MemberActions,
      name: $localize`Member Actions`,
      subItems: [],
      routerPath: '/member-actions',
      iconSrc: '',
      iconFilledSrc: ''
    });
  }

  private getSubmissionsNavItem(): NavItem {
    return new DefaultNavItem({
      id: NavItemId.Submissions,
      name: $localize`Submissions`,
      subItems: [],
      routerPath: '/submissions',
      iconSrc: '',
      iconFilledSrc: ''
    });
  }

  private getResourcesNavItem(): NavItem {
    const icon = 'assets/icons/light/Link3.svg';
    return new DefaultNavItem({
      id: NavItemId.Resources,
      name: $localize`Resources`,
      subItems: [],
      routerPath: '/resources',
      iconSrc: icon,
      iconFilledSrc: icon
    });
  }

  private getProfileNavItem(userName: string): NavItem {
    return new ProfileNavItem({
      id: NavItemId.Profile,
      name: userName,
      subItems: [],
      routerPath: '/profile',
      iconSrc: '',
      iconFilledSrc: ''
    });
  }

  public navigateToRoot() {
    this.router.navigate(['']).then(() => {});
  }

  public toggleNavbarCollapse() {
    this._isNavbarCollapsed.once(collapse => {
      this._isNavbarCollapsed.next(!collapse);
    });
  }
}
