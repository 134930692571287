import { OdataFilterGenerator } from '../../../interfaces/odata-filter-generator';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { Deserializable } from '../../protocols/deserializable';
import * as moment from 'moment/moment';

export class ClosedRemittanceTableFilterRequest implements OdataFilterGenerator, UniquelyIdentifiable, Deserializable {
  public lastStatusStartDate: string = '';
  public lastStatusEndDate: string = '';
  public payPeriodStartDate: string = '';
  public payPeriodEndDate: string = '';
  public taxationYear: string = '';
  public status: string[] = [];
  public totalRemittanceStartAmount: string | null = null;
  public totalRemittanceEndAmount: string | null = null;

  public searchId: string = '';
  public searchLastStatusUpdateDate: string = '';
  public searchPayPeriodEndDate: string = '';
  public searchTaxationYear: string = '';
  public searchTotalRemittanceAmount: string = '';
  public searchStatusId: number = 0;

  private filterCount: number = 0;
  private searchCount: number = 0;

  [key: string]: any; // Index signature to allow dynamic property querying

  getFilterString(): string | null {
    const filterArray = [];

    if (this.status?.length > 0) {
      const statusIds = this.status.map(statusId => `statusId eq ${statusId}`).join(' or ');
      this.filterCount = this.filterCount + this.status.length;
      filterArray.push(`(${statusIds})`);
    }

    if (this.taxationYear !== '') {
      filterArray.push(`taxationYear eq ${this.taxationYear}`);
      this.filterCount++;
    }

    if (this.lastStatusStartDate !== '') {
      const startDate = moment(this.lastStatusStartDate).startOf('day');
      let endDate = startDate.clone().endOf('day');
      if (this.lastStatusEndDate !== '') {
        endDate = moment(this.lastStatusEndDate).endOf('day');
      }
      filterArray.push(
        `lastStatusUpdateDate ge cast(${startDate.toISOString(
          true
        )}, Edm.DateTimeOffset) and lastStatusUpdateDate le cast(${endDate.toISOString(true)}, Edm.DateTimeOffset)`
      );

      this.filterCount++;
    }

    if (this.payPeriodStartDate !== '') {
      const startDate = moment(this.payPeriodStartDate).startOf('day');
      let endDate = startDate.clone().endOf('day');
      if (this.payPeriodEndDate !== '') {
        endDate = moment(this.payPeriodEndDate).endOf('day');
      }
      filterArray.push(
        `payPeriodEndDate ge cast(${startDate.toISOString(
          true
        )}, Edm.DateTimeOffset) and payPeriodEndDate le cast(${endDate.toISOString(true)}, Edm.DateTimeOffset)`
      );

      this.filterCount++;
    }

    if (this.totalRemittanceStartAmount !== null) {
      const startAmount = this.totalRemittanceStartAmount;
      let endAmount = this.totalRemittanceStartAmount;
      if (this.totalRemittanceEndAmount !== null) {
        endAmount = this.totalRemittanceEndAmount;
      }
      filterArray.push(`totalRemittanceAmount ge ${startAmount} and totalRemittanceAmount le ${endAmount}`);

      this.filterCount++;
    }

    if (this.searchId !== '') {
      filterArray.push(`id eq ${this.searchId}`);
      this.searchCount++;
    }
    if (this.searchLastStatusUpdateDate !== '') {
      const startDate = moment(this.searchLastStatusUpdateDate).startOf('day');
      const endDate = startDate.clone().endOf('day');
      filterArray.push(
        `lastStatusUpdateDate ge cast(${startDate.toISOString(
          true
        )}, Edm.DateTimeOffset) and lastStatusUpdateDate le cast(${endDate.toISOString(true)}, Edm.DateTimeOffset)`
      );
      this.searchCount++;
    }
    if (this.searchPayPeriodEndDate !== '') {
      const startDate = moment(this.searchPayPeriodEndDate).startOf('day');
      const endDate = startDate.clone().endOf('day');
      filterArray.push(
        `payPeriodEndDate ge cast(${startDate.toISOString(
          true
        )}, Edm.DateTimeOffset) and payPeriodEndDate le cast(${endDate.toISOString(true)}, Edm.DateTimeOffset)`
      );
      this.searchCount++;
    }
    if (this.searchTaxationYear !== '') {
      const year = parseInt(this.searchTaxationYear, 10);
      const startDate = new Date(year, 0, 1, 0, 0, 0).toISOString(); // January 1, 00:00:00
      const endDate = new Date(year, 11, 31, 23, 59, 59).toISOString(); // December 31, 23:59:59

      filterArray.push(`taxationYear ge ${startDate} and taxationYear le ${endDate}`);
      this.searchCount++;
    }
    if (this.searchTotalRemittanceAmount !== '') {
      filterArray.push(`totalRemittanceAmount eq ${this.searchTotalRemittanceAmount}`);
      this.searchCount++;
    }
    if (this.searchStatusId !== 0) {
      filterArray.push(`statusId eq ${this.searchStatusId}`);
      this.searchCount++;
    }

    if (filterArray.length > 1) {
      return filterArray.join(' and ');
    } else if (filterArray.length === 1) {
      return filterArray[0];
    } else {
      return null;
    }
  }

  public getFilterCount(): number {
    this.filterCount = 0;
    this.getFilterString();
    return this.filterCount;
  }

  public getSearchCount(): number {
    this.searchCount = 0;
    this.getFilterString();
    return this.searchCount;
  }

  public clearFilters(): void {
    this.lastStatusStartDate = '';
    this.lastStatusEndDate = '';
    this.payPeriodStartDate = '';
    this.payPeriodEndDate = '';
    this.taxationYear = '';
    this.status = [];
    this.totalRemittanceStartAmount = '';
    this.totalRemittanceEndAmount = '';
    this.filterCount = 0;
  }

  getUniqueIdentifier(): string {
    return `${this.lastStatusStartDate} +
      ${this.lastStatusEndDate} +
      ${this.payPeriodStartDate} +
      ${this.payPeriodEndDate} +
      ${this.taxationYear} +
      ${this.status.join(',')} +
      ${this.totalRemittanceStartAmount} +
      ${this.totalRemittanceEndAmount} +
      ${this.searchId} +
      ${this.searchLastStatusUpdateDate} +
      ${this.searchPayPeriodEndDate} +
      ${this.searchTaxationYear} +
      ${this.searchTotalRemittanceAmount} +
      ${this.searchStatusId} +
      ${this.filterCount} +
      ${this.searchCount}`;
  }

  onDeserialize(): void {}
}
