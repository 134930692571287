import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { BannerAlertComponent } from '../banner-alert/banner-alert.component';

@Component({
  selector: 'app-system-banner-alert',
  templateUrl: './system-banner-alert.component.html',
  styleUrls: ['./system-banner-alert.component.scss', '../banner-alert/banner-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemBannerAlertComponent extends BannerAlertComponent {
  @Output() buttonClicked = new EventEmitter<void>();

  public showBannerButton$ = this.alert$.pipe(map(a => a?.showSystemBannerButton));
  public buttonText$ = this.alert$.pipe(map(a => a?.systemButtonText));
}
