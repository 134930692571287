<div
  class="menu-position"
  dropdown
  [placement]="openLeft ? 'bottom left' : 'bottom'"
  [style.height]="useDivInsteadOfButton ? '1rem' : ''">
  <button
    *ngIf="!useDivInsteadOfButton"
    dropdownToggle
    id="{{ bId }}"
    [attr.aria-controls]="ddId"
    type="button"
    class="custom-button {{ buttonClass }}">
    <div class="d-flex gap-2 align-items-center" [class.icon-right]="true">
      <img *ngIf="iconSrc" [src]="iconSrc" alt="" />
      <div>{{ buttonText }}</div>
    </div>
  </button>
  <div
    *ngIf="useDivInsteadOfButton"
    id="{{ bId }}"
    [attr.aria-controls]="ddId"
    class="mt-2 custom-button-div"
    dropdownToggle>
    <div class="d-flex gap-2 align-items-center" [class.icon-right]="true">
      <img *ngIf="iconSrc" [src]="iconSrc" alt="" />
      <div>{{ buttonText }}</div>
    </div>
  </div>
  <div class="btn-group droplet">
    <ul
      *dropdownMenu
      [class.open-left]="openLeft"
      [class.dropdown-menu-left]="openLeft"
      [style.margin-left]="!!dropdownLeftMargin ? dropdownLeftMargin : ''"
      [style.margin-top]="!!dropdownTopMargin ? dropdownTopMargin : ''"
      [style.box-shadow]="disableBoxShadow ? '' : '0px 0px 8px 0 rgba(34, 34, 34, 0.26)'"
      class="dropdown-menu custom-dropdown-menu"
      role="menu"
      [attr.aria-labelledby]="bId"
      id="{{ ddId }}">
      <ng-container *ngFor="let section of sections">
        <li *ngIf="section.header">
          <div class="custom-dropdown-menu-header">
            {{ section.header.title }}
          </div>
        </li>
        <li *ngFor="let item of section.items" role="menuitem" (click)="clicked(item.value)">
          <button
            [ngClass]="item.styling.add('custom-dropdown-menu-item')"
            [disabled]="!!disabledSectionNavOptionIds && disabledSectionNavOptionIds.includes(item?.id || '')"
            class="option-title d-flex align-items-center"
            [style.gap.rem]="0.75">
            <img
              *ngIf="!!item.titleIconSrc"
              [src]="item.titleIconSrc"
              [style.opacity]="
                !!disabledSectionNavOptionIds && disabledSectionNavOptionIds.includes(item?.id || '') ? '50%' : '100%'
              "
              alt=""
              class="title-icon" />
            {{ item.title }}
          </button>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
