import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, iif, Observable, of, throwError } from 'rxjs';
import { SessionService } from '../services/session-service';
import { SignInRequest } from '../models/users/requests/sign-in-request';
import { UserAPI } from '../api/user-api';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { CacheService } from '../services/cache-service';
import { DefaultCacheKey } from '../models/enum/shared/default-cache-key.enum';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { CachePolicy } from '../models/enum/shared/cachable-image-policy.enum';
import { InternalUser } from '../models/users/dto/internal-user';
import { ChoosePasswordRequest } from '../models/users/requests/choose-password-request';
import { SessionContainer } from '../models/shared/session-container';
import { BaseUser } from '../models/base/base-user';
import { UserSession } from '../models/users/dto/user-session';
import { EmployerUser } from '../models/users/dto/employer-user';
import { AuthCodeRequest } from '../models/users/requests/auth-code-request';
import { ForgotPasswordRequest } from '../models/users/requests/forgot-password-request';
import { HttpResponse } from '@angular/common/http';
import { ResetPasswordRequest } from '../models/users/requests/reset-password-request';
import { PortalService } from '../services/portal/portal.service';
import { PortalType } from '../models/enum/shared/portal-type';
import { ChangeEmailRequest } from '../models/users/requests/change-email-request';
import { StringResponse } from '../models/shared/responses/string-response';
import { RefreshSessionRequest } from '../models/users/requests/refresh-session-request';
import { VerifyEmailRequest } from '../models/users/requests/verify-email-request';
import { ChangePasswordRequest } from '../models/users/requests/change-password-request';
import { ChangeMfaPhoneRequest } from '../models/users/requests/change-mfa-phone-request';
import { ConfirmCodeRequest } from '../models/users/requests/confirm-code-request';
import { MemberUser } from '../models/users/dto/member-user';
import { VerifiedPhoneNumber } from '../models/users/dto/verified-phone-number';
import { MfaSecretCode } from '../models/users/dto/mfa-secret-code';
import { TypeService } from '../services/type/type-service';
import { CustomError } from '../models/shared/custom-error';
import { ToastService } from '../services/toast-service';
import { Router } from '@angular/router';
import { MemberSIN } from '../models/users/dto/member-sin';

@Injectable({ providedIn: 'root' })
export class UserDomainModel extends BaseDomainModel {
  private sessionService = inject(SessionService);
  private cacheService = inject(CacheService);
  private portalService = inject(PortalService);
  private typeService = inject(TypeService);
  private toastService = inject(ToastService);
  private router = inject(Router);

  constructor(private userAPI: UserAPI) {
    super();
    this.init();
  }

  public refreshSessionResult = new BehaviorSubject<UserSession | null>(null);

  public user$ = this.sessionService.sessionContainer$.pipe(map(sess => sess?.user as BaseUser));

  public userId$ = this.user$.pipe(map(user => user?.id));
  public userEmail$ = this.user$.pipe(map(user => user?.email));
  public authToken$ = iif(
    () => !!this.user$,
    this.user$.pipe(map(user => user?.userSession?.accessToken)),
    of(undefined)
  );
  public refreshToken$ = iif(
    () => !!this.user$,
    this.user$.pipe(map(user => user?.userSession?.refreshToken)),
    of(undefined)
  );

  public portalType$ = this.portalService.portalType$;

  public init() {
    this.setupBindings();
  }

  private setupBindings() {
    // Bind to destroy session
    const destroySess = this.sessionService.destroySession.notNull().subscribe(_ => {
      this.sessionService.setRefreshingSession(false);
      this.refreshSessionResult.next(null);
    });
    this.pushSub(destroySess);
  }

  // Auth Functions

  public isAuthenticated(forceRefresh: boolean = false): Observable<UserSession | null> {
    return combineLatest([
      this.sessionService.sessionContainer$,
      this.sessionService.refreshingSession$,
      this.portalType$
    ]).pipe(
      filter(([, s]) => !s),
      take(1),
      switchMap(([container, refreshingSession, portalType]) => {
        if (!container && !!this.sessionService.getCachedSession(portalType)) {
          container = this.sessionService.getCachedSession(portalType) as SessionContainer;
        }
        if (container?.validSession() && !refreshingSession && !forceRefresh && !!container?.user) {
          return of(container.user.userSession);
        }
        return this.refreshSession().pipe(
          map(r => {
            return r?.userSession ?? null;
          })
        );
      })
    );
  }

  public signIn(req: SignInRequest, portalType: PortalType): Observable<BaseUser> {
    return this.userAPI.signIn(req, portalType).pipe(
      map(user => {
        // Clear existing session from cache
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer);
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);
        // Set the new user in the session
        this.sessionService.setUser(user, true);
        return user;
      })
    );
  }

  public memberEmployerVerifyMFA(req: AuthCodeRequest, portalType: PortalType): Observable<BaseUser> {
    return this.userAPI.signIn(req, portalType).pipe(
      map(user => {
        // Clear existing session from cache
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer);
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);
        // Set the new user in the session
        this.sessionService.setUser(user, true);
        return user;
      })
    );
  }

  public signOut(): Observable<any> {
    return combineLatest([this.userEmail$, this.portalType$]).pipe(
      take(1),
      switchMap(([email, portalType]) => {
        return this.userAPI.signOut(email, portalType).pipe(
          map(r => {
            // Clear the user session from the persistent cache
            this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);
            this.sessionService.destroySession.next(true);
            return r;
          }),
          catchError(err => {
            // If sign out request fails, we want to kill all caches any destroy session
            this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);
            this.sessionService.destroySession.next(true);
            return err;
          })
        );
      })
    );
  }

  public setNewInternalUserPassword(req: ChoosePasswordRequest): Observable<InternalUser> {
    return this.userAPI.setInternalUserPassword(req).pipe(
      map(user => {
        //Clear existing session from cache
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer);
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);

        //  Set the new user in the session
        this.sessionService.setUser(user, true);
        return user;
      })
    );
  }

  public setNewEmployerPassword(req: ChoosePasswordRequest): Observable<EmployerUser> {
    return this.userAPI.setEmployerPassword(req).pipe(
      map(user => {
        //Clear existing session from cache
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer);
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);

        //  Set the new user in the session
        this.sessionService.setUser(user, true);
        return user;
      })
    );
  }

  public verifyMfaCode(req: AuthCodeRequest): Observable<InternalUser> {
    return this.userAPI.setupInternalUserMfa(req).pipe(
      map(user => {
        //Clear existing session from cache
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer);
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);

        //  Set the new user in the session
        this.sessionService.setUser(user, true);
        return user;
      })
    );
  }

  public disableMfa(): Observable<MemberUser | EmployerUser> {
    return combineLatest([this.userEmail$, this.portalService.portalType$]).pipe(
      take(1),
      switchMap(([email, portalType]) => {
        return this.userAPI.disableMfa(email, portalType);
      })
    );
  }

  public getVerifiedPhoneNumber(): Observable<VerifiedPhoneNumber> {
    return combineLatest([this.userEmail$, this.portalService.portalType$]).pipe(
      take(1),
      switchMap(([email, portalType]) => {
        return this.userAPI.getVerifiedPhoneNumber(email, portalType);
      })
    );
  }

  public associateMfaToken(): Observable<MfaSecretCode> {
    return combineLatest([this.userEmail$, this.portalService.portalType$]).pipe(
      take(1),
      switchMap(([email, portalType]) => {
        return this.userAPI.associateMfaToken(email, portalType);
      })
    );
  }

  public forgotPassword(req: ForgotPasswordRequest, portalType: PortalType): Observable<HttpResponse<any>> {
    return this.userAPI.forgotPassword(req, portalType).pipe(
      map(response => {
        return response;
      })
    );
  }

  public resetPassword(req: ResetPasswordRequest, portalType: PortalType): Observable<HttpResponse<any>> {
    return this.userAPI.resetPassword(req, portalType).pipe(
      map(response => {
        return response;
      })
    );
  }

  public refreshSession(): Observable<BaseUser | null> {
    return combineLatest([this.refreshToken$, this.portalService.portalType$, this.userEmail$]).pipe(
      take(1),
      switchMap(([refreshToken, portalType, userEmail]) => {
        if (!!refreshToken) {
          this.sessionService.setRefreshingSession(true);
          const req = new RefreshSessionRequest(refreshToken);
          return this.userAPI.refreshSession(req, userEmail, portalType).pipe(
            tap(user => {
              this.sessionService.setUser(user, true);
              this.sessionService.setRefreshingSession(false);
            })
          );
        } else {
          this.sessionService.setRefreshingSession(false);
          this.refreshSessionResult.next(null);
          return of(null);
        }
      }),
      catchError(error => {
        if (error instanceof CustomError && error.code === 400) {
          this.toastService.publishInfoMessage($localize`Your session has expired. Please sign in again.`);
          this.sessionService.destroySession.next(true);
          this.router.navigate([`/auth/sign-in`]).then(() => {});
        }
        // Pass other errors along
        return throwError(error);
      })
    );
  }

  public changeEmail(req: ChangeEmailRequest): Observable<StringResponse> {
    return combineLatest([this.portalService.portalType$, this.userEmail$]).pipe(
      take(1),
      switchMap(([portalType, userEmail]) => {
        return this.userAPI.changeEmail(req, userEmail, portalType);
      })
    );
  }

  public changePassword(req: ChangePasswordRequest): Observable<StringResponse> {
    return combineLatest([this.portalService.portalType$, this.userEmail$]).pipe(
      take(1),
      switchMap(([portalType, userEmail]) => {
        return this.userAPI.changePassword(req, userEmail, portalType);
      })
    );
  }

  public verifyEmail(req: VerifyEmailRequest): Observable<BaseUser> {
    return this.portalType$.pipe(
      take(1),
      switchMap(portalType => {
        return this.userAPI.verifyEmail(req, portalType);
      })
    );
  }

  public changeMfaPhoneNumber(req: ChangeMfaPhoneRequest): Observable<MemberUser | EmployerUser> {
    return combineLatest([this.typeService.countryCodes$, this.portalService.portalType$, this.userEmail$]).pipe(
      take(1),
      switchMap(([countryCodes, portalType, userEmail]) => {
        if (!req.newPhoneNumber.startsWith('+')) {
          const prefix = countryCodes.filter(c => c.countryCode === req.countryCode)[0]?.prefix;
          req.newPhoneNumber = prefix + req.newPhoneNumber;
        }
        return this.userAPI.changeMfaPhoneNumber(req, userEmail, portalType);
      })
    );
  }

  public verifySmsCode(req: ConfirmCodeRequest): Observable<BaseUser> {
    return combineLatest([this.portalService.portalType$, this.userEmail$]).pipe(
      take(1),
      switchMap(([portalType, userEmail]) => {
        return this.userAPI.verifySmsCode(req, userEmail, portalType);
      })
    );
  }

  public verifyMfaToken(req: ConfirmCodeRequest): Observable<BaseUser> {
    return combineLatest([this.portalService.portalType$, this.userEmail$]).pipe(
      take(1),
      switchMap(([portalType, userEmail]) => {
        return this.userAPI.verifyMfaToken(req, userEmail, portalType);
      })
    );
  }

  public getDecryptedMemberSIN(): Observable<MemberSIN> {
    return this.user$.pipe(
      switchMap(user => {
        return this.userAPI.getMemberSIN(user.id.toString());
      })
    );
  }
}
