import { Component, inject, ViewChild } from '@angular/core';
import { SessionService } from '../../../../../../services/session-service';
import { ChoosePasswordRequest } from '../../../../../../models/users/requests/choose-password-request';
import { EmployerAuthViewModel } from '../../../../viewModels/employer-auth-view-model';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { ReactiveFormGroupComponent } from '@csspension/reactive-form';
import { PasswordInputUtils } from '../../../../../../utils/password-input-utils';

@Component({
  selector: 'app-employer-choose-password',
  templateUrl: './employer-choose-password.component.html',
  styleUrls: ['./employer-choose-password.component.scss', '../../auth.component.scss']
})
export class EmployerChoosePasswordComponent extends BaseComponent {
  viewModel = inject(EmployerAuthViewModel);

  public eulaChecked: boolean = false;

  public readonly passwordHintText = PasswordInputUtils.passwordHintText;

  @ViewChild('form') form!: ReactiveFormGroupComponent;

  constructor(private session: SessionService) {
    super();
  }

  setupViews(): void {}

  req = new ChoosePasswordRequest('', '', '', '', '', false);

  setupBindings() {
    this.session.sessionContainer$.once(session => {
      if (!session) session = this.viewModel.getCachedSession();
      const user = session?.user;
      this.req.email = user?.email ?? '';
      this.req.sessionId = user?.sessionId ?? '';
      this.req.challengeName = user?.challengeName ?? '';
    });
  }

  setEulaChecked(checked: boolean): void {
    this.eulaChecked = checked;
    const control = this.form.reactiveFormGroup.controls.eulaAccepted;
    control.patchValue(checked);
    control.updateValueAndValidity();
  }

  formSubmitted(req: ChoosePasswordRequest) {
    this.viewModel.setNewEmployerPassword(req);
  }
}
