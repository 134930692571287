import { Deserializable } from '../../protocols/deserializable';
import { LookAheadItem } from '../../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { BaseUser } from '../../base/base-user';
import { UserSession } from './user-session';
import { Address } from './address';
import { UserChallengeName } from '../enum/user-challenge.name';
import * as moment from 'moment/moment';
import { PronounTypeEnum } from '../enum/pronoun-type.enum';
import { MemberRecordComment } from '../../../views/records/components/members/view-member-record/member-record-comments/member-record-comment';
import { MemberAccount } from '../../transactions/member-account';
import { GenderTypeEnum } from '../enum/gender-type';
import { Gender } from './gender';
import { MfaType } from '../../types/dto/mfa-type';
import { EmploymentHistory } from './employment-history';
import { MostRecentInvite } from './most-recent-invite';

export class MemberUser extends BaseUser implements Deserializable, LookAheadItem {
  public birthDate: Date;
  public accounts: MemberAccount[] = [];
  public genderId: GenderTypeEnum;
  public gender: Gender;
  public middleName: string;
  public primaryPhoneNumber: string;
  public primaryPhoneNumberCountryCode: string;
  public secondaryPhoneNumber: string;
  public secondaryPhoneNumberCountryCode: string;
  public socialInsuranceNumber: string;
  public preferredName: string;
  public deathDate: Date;
  public contactInformationConfirmed: boolean;
  public employmentHistory!: EmploymentHistory[];
  public pronoun: Pronoun | null;
  public pronounId: PronounTypeEnum | null;
  public comments!: MemberRecordComment[];
  public mfaTypeId!: number;
  public mfaType!: MfaType;
  public mostRecentInvite!: MostRecentInvite;

  // Not From API
  public formattedBirthDate!: string;
  public mfaActive!: boolean;

  constructor(
    id: number,
    firstName: string,
    lastName: string,
    email: string,
    birthDate: Date,
    genderId: number,
    gender: Gender,
    addressId: string,
    address: Address,
    passwordChangedDate: Date,
    eulaConfirmation: boolean,
    eulaConfirmationDate: Date,
    lastLoginDate: Date,
    userSession: UserSession | null,
    challengeName: UserChallengeName | null,
    sessionId: string | null,
    mfaActive: boolean,
    middleName: string,
    primaryPhoneNumber: string,
    primaryPhoneNumberCountryCode: string,
    secondaryPhoneNumber: string,
    secondaryPhoneNumberCountryCode: string,
    socialInsuranceNumber: string,
    preferredName: string,
    deathDate: Date,
    contactInformationConfirmed: boolean,
    pronoun: Pronoun | null,
    pronounId: PronounTypeEnum | null
  ) {
    super(
      id,
      firstName,
      lastName,
      email,
      passwordChangedDate,
      eulaConfirmation,
      eulaConfirmationDate,
      lastLoginDate,
      userSession,
      challengeName,
      sessionId,
      addressId,
      address
    );
    this.birthDate = birthDate;
    this.genderId = genderId;
    this.gender = gender;
    this.mfaActive = mfaActive;
    this.middleName = middleName;
    this.primaryPhoneNumber = primaryPhoneNumber;
    this.primaryPhoneNumberCountryCode = primaryPhoneNumberCountryCode;
    this.socialInsuranceNumber = socialInsuranceNumber;
    this.preferredName = preferredName;
    this.deathDate = deathDate;
    this.contactInformationConfirmed = contactInformationConfirmed;
    this.secondaryPhoneNumber = secondaryPhoneNumber;
    this.secondaryPhoneNumberCountryCode = secondaryPhoneNumberCountryCode;
    this.pronoun = pronoun;
    this.pronounId = pronounId;
  }

  public onDeserialize() {
    super.onDeserialize();
    if (!!this.address) {
      this.address = window?.injector?.Deserialize?.instanceOf(Address, this.address);
    }
    if (!!this.employmentHistory) {
      this.employmentHistory = window?.injector?.Deserialize?.arrayOf(EmploymentHistory, this.employmentHistory);
    }
    if (!!this.birthDate) {
      const date = moment(this.birthDate.toString().split('T')[0]);
      this.birthDate = new Date(this.birthDate);
      this.formattedBirthDate = date.format('DD / MM / YYYY');
    }
    if (!!this.accounts) {
      this.accounts = window?.injector?.Deserialize?.arrayOf(MemberAccount, this.accounts);
    }
    if (this.mfaTypeId !== 3) {
      this.mfaActive = true;
    }
    if (!!this.mostRecentInvite) {
      this.mostRecentInvite = window?.injector?.Deserialize?.instanceOf(MostRecentInvite, this.mostRecentInvite);
    }
  }

  // Look ahead item interface

  lookAheadDisabled(): boolean {
    return false;
  }

  lookAheadDisabledMessage(): string {
    return 'disabled';
  }
}

export class Pronoun implements Deserializable {
  public id!: PronounTypeEnum;
  public name!: string;

  onDeserialize(): void {}
}
