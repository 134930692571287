<div class="sliding-window" *ngIf="showWindow$ | async" [@slideInOut]="true">
  <div class="sliding-window-header">
    <ng-content select="[header]"></ng-content>
  </div>
  <div class="sliding-window-content" [style.padding.rem]="contentPaddingRem">
    <ng-content select="[content]"></ng-content>
  </div>
  <div class="sliding-window-footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
