import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Checkbox } from 'src/app/models/shared/stylesheet/checkbox';

/**
 * @param checkbox: can be used to initialize data, else use parameters below
 * @param label: what is displayed beside the checkmark
 * @param disabled: self explanatory
 */
@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  public cid = Math.random().toString(24);

  @Input() checkbox!: Checkbox;
  @Input() label!: string;
  @Input() checked = false;
  @Input() disabled = false;
  @Input() selectAll: boolean = false;
  @Input() partiallySelected: boolean = false;
  @Input() refresh = new EventEmitter<any>();
  @Input() inline: boolean = false;
  @Input() customCheckboxMargin!: string;

  @Output() clickEvent = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    this.refresh?.subscribe(next => this.refreshObj());
    this.refreshObj();
  }

  refreshObj() {
    if (this.checkbox) {
      this.label = this.checkbox.label;
      this.checked = this.checkbox.checked;
      this.disabled = this.checkbox.disabled;
    }
  }

  onClick() {
    this.checked = !this.checked;
    if (this.checkbox) this.checkbox.checked = this.checked;
    this.clickEvent.emit(this.checked);
  }
}
