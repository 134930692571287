<div class="legend-row" *ngFor="let dataItem of data">
  <div class="legend-color-indicator" [style.background-color]="dataItem.color"></div>
  <div class="legend-row-title">{{ dataItem.label }}</div>
  <div class="legend-row-percentage">{{ (dataItem.value ?? 0) / totalValue ?? 0 | percent : '.2' }}</div>
  <div class="legend-row-slash">/</div>
  <div class="legend-row-balance">{{ dataItem.value | currency }}</div>
</div>
<div *ngIf="showTotalRow" class="legend-row">
  <div class="legend-row-title" i18n>Total:</div>
  <div class="legend-row-balance">{{ totalValue | currency }}</div>
</div>
