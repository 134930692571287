import { AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';
import { Directive } from '@angular/core';

export function versionValidator(): ValidatorFn {
  return (control: AbstractControl): { [p: string]: any } | null => {
    const { value } = control;
    const regex = new RegExp('^[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}$');
    if (!!value && !regex.test(value)) {
      return { [VersionNumberFormatValidatorDirective.key]: true };
    } else {
      return null;
    }
  };
}

@Directive({
  selector: '[appVersionNumberFormatValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: VersionNumberFormatValidatorDirective, multi: true }]
})
export class VersionNumberFormatValidatorDirective {
  static key: string = 'versionNumberFormat';

  validate(control: AbstractControl): { [key: string]: any } {
    // @ts-ignore
    return versionValidator()(control);
  }
}
