import { Observable, ReplaySubject, Subscription } from 'rxjs';

declare module 'rxjs/internal/ReplaySubject' {
  interface ReplaySubject<T> {
    bind(obvs$: Observable<any> | undefined | null): Subscription;
  }
}

ReplaySubject.prototype.bind = function (obvs$: Observable<any> | null = null): Subscription {
  return (
    obvs$?.subscribe(n => {
      this.next(n);
    }) ?? Subscription.EMPTY
  );
};
