import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';
import { ToastService } from '../../../../../services/toast-service';
import { BehaviorSubject } from 'rxjs';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CustomError } from '../../../../../models/shared/custom-error';

@Injectable()
export class SignOutViewModel extends BaseViewModel {
  constructor(
    protected router: Router,
    protected userDomainModel: UserDomainModel,
    protected toastService: ToastService
  ) {
    super();
    this.init();
  }

  public loadingOpts$ = new BehaviorSubject<LoadingOptions>(LoadingOptions.default());
  public isLoading$ = this.loadingOpts$.pipe(map(it => it?.isLoading));

  signOut() {
    const loadingMess = 'Signing Out';
    this.loadingOpts$.addRequest(loadingMess);
    return this.userDomainModel.signOut().subscribe({
      complete: () => {
        this.loadingOpts$.removeRequest(loadingMess);
        this.router.navigate(['/auth/sign-in']).then();
      },
      error: (error: CustomError) => {
        this.loadingOpts$.removeRequest(loadingMess);
        this.toastService.publishError(error);
        this.router.navigate(['/auth/sign-in']).then();
      }
    });
  }
}
