import { BannerEventsService } from '../service/banner-events.service';
import { EventType } from './event-type.enum';

export class Event {
  public type: EventType;
  public message: string;
  public dismissed: boolean;
  private _service: BannerEventsService | undefined;
  private readonly secondsVisible: number;
  private timer: ReturnType<typeof setInterval> | null = null;

  constructor(bannerEventsService: BannerEventsService, secondsVisible: number, type: EventType, message: string) {
    this.type = type;
    this.message = message;
    this.dismissed = false;
    this._service = bannerEventsService;
    this.secondsVisible = secondsVisible;
  }

  public startTimer() {
    const kill = () => {
      if (this._service) {
        this._service.dismiss(this);
      }
    };
    this.timer = setInterval(kill, this.secondsVisible * 1000);
  }

  public killTimerAndDereference(): Event {
    this.dismissed = true;
    if (!!this.timer) clearInterval(this.timer);
    this._service = undefined;
    return this;
  }
}
