import { Deserializable } from '../protocols/deserializable';
import { PermissionEnforcementType } from '../permissions/base/permission-enforcement-type';
import { Selectable } from '../protocols/selectable';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';

export class Role implements Deserializable, Selectable, UniquelyIdentifiable {
  public id!: number;
  public name!: string;
  public description!: string;
  public users: RoleUser[] = [];
  public permissions: RolePermission[] = [];

  // Not from API, but used to prevent duplicate roles being assigned to a user
  public isAssignedToUser = false;

  onDeserialize(): void {
    const Deserialize = window?.injector?.Deserialize;
    this.users = Deserialize?.arrayOf(RoleUser, this.users);
  }

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  getSelectionValue(): any {
    return this.id;
  }

  getSelectionIsDisabled(): boolean {
    return this.isAssignedToUser;
  }

  getUniqueIdentifier(): string {
    return `${this.id} +
    ${this.name} +
    ${this.description} +
    ${this.users} +
    ${this.permissions}`;
  }
}

export class RoleUser implements Deserializable {
  public id!: number;
  public firstName!: string;
  public lastName!: string;
  public email!: string;

  onDeserialize(): void {}
}

export class RolePermission implements Deserializable {
  public id!: number;
  public permissionId!: number;
  public accessGranted!: boolean;
  public enforcementTypeId!: PermissionEnforcementType;

  onDeserialize(): void {}
}
