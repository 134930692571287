import { Pipe, PipeTransform } from '@angular/core';
import { Alert } from '../../../../../models/alerts/dto/alert';
import { AlertTypeEnum } from '../../../../../models/alerts/enum/alert-type.enum';

@Pipe({
  name: 'isBannerTypeAlert'
})
export class IsBannerTypeAlertPipe implements PipeTransform {
  transform(alert: Alert | null): boolean {
    return alert?.typeId === AlertTypeEnum.Banner;
  }
}
