<app-base-card *ngIf="showSteps" [maxWidth]="stepperMaxWidth">
  <div class="base-card-title" i18n>Progress</div>
  <div class="d-flex gap-4 mt-4">
    <div
      *ngFor="let step of steps; let i = index"
      class="step-button"
      [class.step-disabled]="linear"
      [class.step-active]="selected === step"
      [class.step-completed]="step.completed"
      (click)="setSelectedStep(i)">
      <div class="step-number"><span i18n>Step</span> {{ i + 1 }}</div>
      <div class="step-title">{{ step.label }}</div>
    </div>
  </div>
</app-base-card>

<div [style.margin-top.rem]="2">
  <ng-container *ngTemplateOutlet="selected?.content"></ng-container>
</div>
