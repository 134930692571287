import { SubmissionPermission } from '../permissions/dto/submission-permission';
import { Deserializable } from '../protocols/deserializable';

export class SubmissionRoleTemplate implements Deserializable {
  public id!: number;
  public name!: string;
  public permissions!: SubmissionPermission[];

  onDeserialize() {}
}
