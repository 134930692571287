<lib-reactive-form-group #form [bindTo]="req" (formSubmitted$)="submitAddressChange($event)">
  <lib-reactive-form-column-layout [nColumns]="(isMobile$ | async) ? 1 : 2" [columnGap]="'2rem'">
    <lib-reactive-form-text
      [inputName]="'address'"
      [label]="'Address' | localization"
      [bindingProperty]="'addressLine1'"
      [required]="true">
    </lib-reactive-form-text>
    <lib-reactive-form-text
      [inputName]="'address2'"
      [label]="'Address 2' | localization"
      [hintText]="'Optional' | localization"
      [bindingProperty]="'addressLine2'">
    </lib-reactive-form-text>
    <lib-reactive-form-text
      [inputName]="'city'"
      [label]="'City / Town' | localization"
      [required]="true"
      [bindingProperty]="'city'">
    </lib-reactive-form-text>
    <lib-reactive-form-drop-down
      [inputName]="'province'"
      [required]="true"
      [label]="'Province' | localization"
      [dropdowns]="(stateDropdowns$ | async) ?? undefined"
      [bindingProperty]="'stateId'">
    </lib-reactive-form-drop-down>
    <lib-reactive-form-drop-down
      [inputName]="'country'"
      [label]="'Country' | localization"
      [required]="true"
      [dropdowns]="(countryDropdowns$ | async) ?? undefined"
      [bindingProperty]="'countryId'"
      [programmaticallyChangeValue]="selectedCountryId$ | async"
      (valueChanged)="countryChanged($event)">
    </lib-reactive-form-drop-down>
    <lib-reactive-form-text
      [inputName]="'postalCode'"
      [label]="'Postal Code' | localization"
      [required]="true"
      [bindingProperty]="'postalCode'">
    </lib-reactive-form-text>
  </lib-reactive-form-column-layout>
  <lib-reactive-form-file-uploader
    *ngIf="filesRequired"
    #fileUploader
    (previewClicked)="openFileInNewTab($event)"
    (duplicateFileNamesAdded)="duplicateFilesAdded()"
    [inputName]="'file'"
    [id]="'file'"
    [accept]="acceptedFileTypes"
    [label]="'Document' | localization"
    [uploadTitle]="'Upload a file' | localization"
    [showPreview]="true"
    [multiple]="allowMultipleFiles"
    [maxFileSizeInMb]="maxFileSizeInMb"
    [maxTotalFileSizeInMb]="allowMultipleFiles ? maxTotalFileSizeInMb : 0"
    [maxFiles]="5"
    [uploadBody]="'or drag and drop .png or .jpeg up to 3MB' | localization"
    [chooseAnother]="true"
    [required]="true"
    [chooseAnotherLabel]="chooseAnotherLabel"
    [previewLabel]="'Preview' | localization"
    [removeLabel]="'Remove' | localization">
  </lib-reactive-form-file-uploader>
</lib-reactive-form-group>
