import { Deserializable } from '../protocols/deserializable';
import { ColorUtils } from '../../utils/color-utils';

export class Mentionable implements Deserializable {
  public id: number = 0;
  public firstName: string = '';
  public lastName: string = '';
  public profileColour: string = '';

  // Not from API
  public initials: string = '';
  public textColor: string = '';
  public fullName: string = '';

  onDeserialize() {
    if (this.id === -1) {
      // This indicates that the author is the CSS Bot
      this.initials = '';
    } else {
      this.initials = `${this.firstName?.charAt(0)}${this.lastName?.charAt(0)}`;
    }
    this.textColor = ColorUtils.isDarkColor(this.profileColour) ? '#FFFFFF' : '#000000';
    this.fullName = `${this.firstName} ${this.lastName}`;
  }
}
