import { defer, EMPTY, iif, NEVER, Observable, PartialObserver, Subscription } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  finalize,
  map,
  pairwise,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { Subscribable } from '@csspension/base-angular';
import { LoadingOptions } from '../models/shared/loading-options';
import { DistinctUtils } from './distinct-utils';
import { UniquelyIdentifiable } from '../models/protocols/uniquely-identifiable';

interface LivingObserver<T> {
  owner: Subscribable;
  closed?: boolean;
  next?: (value: T) => void;
  error?: (err: any) => void;
  complete?: () => void;
}

declare module 'rxjs/internal/Observable' {
  interface Observable<T> {
    notNull(): Observable<T>;
    firstNotNull(): Observable<T>;
    deepCopy(): Observable<T>;
    deepCopyArray(): Observable<T>;
    log(): Observable<T>;
    subscribeWhileAlive(observer: LivingObserver<T>): Subscription;
    stopWhenInactive(): Observable<T>;
    preventConsecutiveNulls(): Observable<T>;
    once(run: (data: T) => void): Subscription;
    distinctUniquelyIdentifiable(): Observable<T>;
    distinctUniquelyIdentifiableArray(): Observable<T>;
    notEmpty(): Observable<T>;
  }
}

// noinspection SpellCheckingInspection
/**
 * Custom operator. Extends rxjs's iif operator.
 * Allows you to pass in observable pipes for all parameters.
 *
 * @param condition$ - The observable to emit true or false, which determines which observable to emit.
 * @param trueResult$ - The observable to emit if the condition$ observable emits true.
 * @param falseResult$ - The observable to emit if the condition$ observable emits false.
 *
 * Example
 * const condition$ = of(true);
 * const trueResult$ = of('positive');
 * const falseResult$ = of('negative');
 * const result$ = iiif(condition$, trueResult$, falseResult$);
 * result$.subscribe(); // 'positive'
 */
export function iiif<T = never, F = never>(
  condition$: Observable<boolean> = EMPTY,
  trueResult$: Observable<T> = EMPTY,
  falseResult$: Observable<F> = EMPTY
): Observable<T | F> {
  return defer(() => (condition$ || EMPTY).pipe(switchMap(condition => (condition ? trueResult$ : falseResult$))));
}

Observable.prototype.notNull = function (): Observable<any> {
  return this.pipe(filter(x => x !== null && x !== undefined));
};

Observable.prototype.notEmpty = function (): Observable<any> {
  return this.pipe(
    filter(x => x !== null && x !== undefined && x.length > 0),
    take(1)
  );
};

Observable.prototype.firstNotNull = function (): Observable<any> {
  return this.pipe(
    filter(x => x !== null && x !== undefined),
    take(1)
  );
};

Observable.prototype.deepCopy = function <T>(): Observable<T> {
  return this.pipe(
    map(x => {
      if (!x) {
        return x;
      } else if (typeof (x as any)?.onDeserialize === 'function') {
        return window?.injector?.Deserialize?.instanceOf((x as any).constructor, x);
      } else {
        return Object.assign(Object.create((x as any).prototype), x);
      }
    })
  );
};

Observable.prototype.deepCopyArray = function <T>(): Observable<T[]> {
  return this.pipe(
    map(x => {
      if (!x) return x;
      const castedX = x as T[];
      return castedX?.length > 0 ? (castedX?.deepCopy() as T[]) : ([] as T[]);
    })
  );
};

Observable.prototype.log = function (): Observable<any> {
  // eslint-disable-next-line no-console
  return this.pipe(tap(console.log));
};

Observable.prototype.subscribeWhileAlive = function <T>(observer: LivingObserver<T>): Subscription {
  const emptyObserver = !observer?.next && !observer?.error && !observer?.complete;
  const typedObserver = emptyObserver ? undefined : (observer as PartialObserver<T>);
  return this.pipe(takeUntil(observer.owner.onDestroy)).subscribe(typedObserver);
};

Observable.prototype.stopWhenInactive = function (): Observable<any> {
  return window.injector.appIsActiveService.appIsActive$.pipe(
    switchMap(appIsActive => iif(() => appIsActive, this, NEVER))
  );
};

Observable.prototype.preventConsecutiveNulls = function (): Observable<any> {
  return this.pipe(
    startWith<any, any>(null),
    pairwise(),
    filter(([prev, curr]) => {
      const prevIsNull = prev === null || prev === undefined;
      const currIsNull = curr === null || curr === undefined;
      return !(prevIsNull && currIsNull);
    }),
    map(([_, curr]) => curr)
  );
};

Observable.prototype.once = function <T>(run: (data: T) => void): Subscription {
  return this.pipe(take(1)).subscribe(run);
};

Observable.prototype.distinctUniquelyIdentifiable = function <T extends UniquelyIdentifiable>(): Observable<T> {
  return this.pipe(distinctUntilChanged((prev: T, curr: T) => DistinctUtils.distinctUniquelyIdentifiable(prev, curr)));
};

Observable.prototype.distinctUniquelyIdentifiableArray = function <T extends UniquelyIdentifiable>(): Observable<T[]> {
  return this.pipe(
    distinctUntilChanged((prev: T[], curr: T[]) => DistinctUtils.distinctUniquelyIdentifiableArray(prev, curr))
  );
};

export function indicate<T>(loadingOpts: LoadingOptions, message: string): (source: Observable<T>) => Observable<T> {
  return (source$: Observable<T>): Observable<T> =>
    source$.pipe(
      prepare(() => loadingOpts.addRequest(message)),
      finalize(() => loadingOpts.removeRequest(message))
    );
}

export function indicateOnNext<T>(
  loadingOpts: LoadingOptions,
  message: string
): (source$: Observable<T>) => Observable<T> {
  return (source$: Observable<T>): Observable<T> =>
    source$.pipe(
      prepare(() => loadingOpts.addRequest(message)),
      tap(() => loadingOpts.removeRequest(message)),
      finalize(() => loadingOpts.removeRequest(message))
    );
}

export function prepare<T>(callback: () => void): (source: Observable<T>) => Observable<T> {
  return (source$: Observable<T>): Observable<T> =>
    defer(() => {
      callback();
      return source$;
    });
}
