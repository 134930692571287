<div class="asset-preview-container" [fxLayout]="(screenLessThan1200px$ | async) ? 'column' : 'row'">
  <div class="asset-preview" [fxFlex]="(screenLessThan1200px$ | async) ? 'auto' : '0'">
    <app-asset
      #appAsset
      [asset]="asset"
      [scaleFit]="true"
      [showControls]="true"
      [style.width.px]="assetWidth$ | async"
      [size]="options?.previewSize">
    </app-asset>
  </div>

  <div class="asset-info">
    <div class="asset-details">
      <div class="asset-name ellipsis-nowrap-text">
        {{ asset?.getFileNameWithoutExtension() }}
      </div>
      <div class="asset-detail">
        <span>Asset Type:</span>
        {{ asset?.mediaType }}
      </div>
    </div>
    <div class="asset-preview-buttons" fxLayout="row wrap" [style.gap.rem]="1">
      <!--      Preview Buttons/Options here-->
    </div>
  </div>
</div>
