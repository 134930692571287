import { Deserializable } from '../protocols/deserializable';
import { Selectable } from '../protocols/selectable';

export class SubmissionStatus implements Deserializable {
  public id: number = 0;
  public name: string = '';
  public subStatuses: SubmissionSubStatus[] = [];

  onDeserialize(): void {
    this.subStatuses = window?.injector?.Deserialize?.arrayOf(SubmissionSubStatus, this.subStatuses);
  }
}

export class SubmissionSubStatus implements Deserializable, Selectable {
  public id: number = 0;
  public name: string = '';
  public parentStatusId: number = 0;
  public parentStatus: string = '';

  onDeserialize(): void {}

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier(): any {
    return this.name + this.id;
  }

  getSelectionValue(): any {
    return this.id;
  }
}
