<lib-reactive-form-group
  #form
  [bindTo]="req$ | async"
  (formSubmitted$)="submitContactInfo($event)"
  [atLeastNItemsFilledIn]="1">
  <lib-reactive-form-column-layout [nColumns]="(isMobile$ | async) ? 1 : 2" [columnGap]="'2rem'">
    <lib-reactive-form-phone-country-code
      [bindingProperty]="'homePhoneNumber'"
      [countryCodeBindingProperty]="'homePhoneNumberCountryCode'"
      [countryCodes]="(countryCodes$ | async) ?? []"
      [addToAtLeastNItemsFilledInPool]="true"
      [label]="'Home Phone Number' | localization"
      [hintText]="'Optional' | localization"
      [inputName]="'homePhoneNumber'"></lib-reactive-form-phone-country-code>
    <lib-reactive-form-phone-country-code
      [bindingProperty]="'mobilePhoneNumber'"
      [countryCodeBindingProperty]="'mobilePhoneNumberCountryCode'"
      [addToAtLeastNItemsFilledInPool]="true"
      [countryCodes]="(countryCodes$ | async) ?? []"
      [label]="'Mobile Phone Number' | localization"
      [hintText]="'Optional' | localization"
      [inputName]="'mobilePhoneNumber'">
    </lib-reactive-form-phone-country-code>
  </lib-reactive-form-column-layout>
  <lib-reactive-form-column-layout [nColumns]="(isMobile$ | async) ? 1 : 2" [columnGap]="'2rem'">
    <lib-reactive-form-phone-country-code
      [bindingProperty]="'workPhoneNumber'"
      [countryCodeBindingProperty]="'workPhoneNumberCountryCode'"
      [addToAtLeastNItemsFilledInPool]="true"
      [countryCodes]="(countryCodes$ | async) ?? []"
      [label]="'Work Phone Number' | localization"
      [hintText]="'Optional' | localization"
      [inputName]="'workPhoneNumber'">
    </lib-reactive-form-phone-country-code>
  </lib-reactive-form-column-layout>
  <div class="phone-error" *ngIf="(submitAttempted$ | async) && !(form.atLeastNItemsHaveBeenFilledIn$ | async)">
    {{ 'Must enter at least one phone number' | localization }}
  </div>
  <lib-reactive-form-file-uploader
    *ngIf="filesRequired"
    #fileUploader
    (previewClicked)="openFileInNewTab($event)"
    (duplicateFileNamesAdded)="duplicateFilesAdded()"
    [inputName]="'file'"
    [id]="'file'"
    [accept]="acceptedFileTypes"
    [label]="'Document' | localization"
    [uploadTitle]="'Upload a file' | localization"
    [showPreview]="true"
    [multiple]="allowMultipleFiles"
    [maxFileSizeInMb]="maxFileSizeInMb"
    [maxTotalFileSizeInMb]="allowMultipleFiles ? maxTotalFileSizeInMb : 0"
    [maxFiles]="5"
    [uploadBody]="'or drag and drop .png or .jpeg up to 3MB' | localization"
    [chooseAnother]="true"
    [required]="true"
    [chooseAnotherLabel]="chooseAnotherLabel"
    [previewLabel]="'Preview' | localization"
    [removeLabel]="'Remove' | localization">
  </lib-reactive-form-file-uploader>
</lib-reactive-form-group>
