<div *ngIf="isMobile$ | async" class="mobile-navbar">
  <div class="wrapper">
    <img (click)="navigateToRoot()" [src]="'assets/logo/light/css-logo.svg'" alt="logo" class="mobile-nav-logo" />
    <button
      *ngIf="(navItems$ | async)?.length > 0"
      class="toggle-button"
      (click)="toggleNavbarCollapse()"
      [attr.aria-expanded]="!(isNavbarCollapsed$ | async)"
      aria-controls="mobile-collapse">
      <div id="hamburger-icon" [class.open]="!(isNavbarCollapsed$ | async)">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
  </div>
  <div id="mobile-collapse" [collapse]="(isNavbarCollapsed$ | async) ?? false" [isAnimated]="true">
    <ul class="mobile-nav-list">
      <li *ngFor="let navItem of navItems$ | async">
        <app-nav-item *ngIf="navItem | isDefaultNavItem" [navItem]="navItem"></app-nav-item>
        <app-sign-out-nav-item *ngIf="navItem | isSignOutNavItem" [navItem]="navItem"></app-sign-out-nav-item>
        <app-drop-down-nav-item *ngIf="navItem | isDropDownNavItem" [navItem]="navItem"></app-drop-down-nav-item>
      </li>
    </ul>
  </div>
</div>
