<div
  class="alert-container"
  [style.background-color]="backgroundColour$ | async"
  [style.border-color]="borderColor$ | async">
  <div class="alert-body align-items-center">
    <div class="overflow-auto">
      <div class="alert-title">{{ title$ | async }}</div>
    </div>
    <div *ngIf="showBannerButton$ | async" class="system-button" (click)="buttonClicked.emit()">
      {{ buttonText$ | async }}
    </div>
    <div *ngIf="isDismissable$ | async" class="dismiss-button" (click)="dismissClicked.emit()">
      <img class="d-block" [src]="dismissButtonSrc$ | async" alt="Dismiss" />
    </div>
  </div>
</div>
