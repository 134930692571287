import { inject, Injectable } from '@angular/core';
import { ToastService } from './toast-service';

@Injectable({ providedIn: 'root' })
export class ClipboardService {
  toastService = inject(ToastService);

  private successToastMessage = $localize`Copied to clipboard`;
  private failedToastMessage = $localize`Failed to copy to clipboard`;

  constructor() {}

  public copyToClipboard(text: string) {
    // Clipboard API is supported
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.toastService.publishSuccessMessage('', this.successToastMessage);
        })
        .catch(err => {
          this.handleCopyError();
        });
    } else {
      // Fallback for browsers that do not support Clipboard API (e.g., older iOS browsers)
      const tempInput = document.createElement('input');
      tempInput.value = text;
      document.body.appendChild(tempInput);
      tempInput.select();
      // Deprecated, but including for compatibility
      document.execCommand('copy');
      document.body.removeChild(tempInput);
      this.toastService.publishSuccessMessage('', this.successToastMessage);
    }
  }

  private handleCopyError(): void {
    try {
      // Fallback for handling copy errors in some iOS versions
      const range = document.createRange();
      range.selectNodeContents(document.body);
      const selection = window.getSelection();
      if (!!selection) {
        selection.removeAllRanges();
        selection.addRange(range);
        // Deprecated, but including for compatibility
        document.execCommand('copy');
        this.toastService.publishSuccessMessage('', this.successToastMessage);
      }
    } catch (err) {
      this.toastService.publishErrorMessage('', this.failedToastMessage);
    }
  }
}
