import { PermissionTypeMap } from '../base/permission-type-map';
import { PermissionType } from '../base/permission-type';
import { PermissionMap } from '../base/permission-map';

export class InternalUserPermissionMap extends PermissionMap {
  public map: Map<number, PermissionTypeMap> = new Map<number, PermissionTypeMap>();

  constructor() {
    super();
    this.map.set(1001, new PermissionTypeMap(1001, PermissionType.Action, 'viewMemberSubmissions'));
    this.map.set(1002, new PermissionTypeMap(1002, PermissionType.Action, 'viewRemittances'));
    this.map.set(1003, new PermissionTypeMap(1003, PermissionType.Action, 'editCreateRemittances'));
    this.map.set(1004, new PermissionTypeMap(1004, PermissionType.Action, 'viewRemittanceDetails'));
    this.map.set(1005, new PermissionTypeMap(1005, PermissionType.Action, 'viewMembersTable'));
    this.map.set(1006, new PermissionTypeMap(1006, PermissionType.Action, 'createMembers'));
    this.map.set(1007, new PermissionTypeMap(1007, PermissionType.Action, 'viewMemberDetails'));
    this.map.set(1008, new PermissionTypeMap(1008, PermissionType.Action, 'viewFullMemberSin'));
    this.map.set(1009, new PermissionTypeMap(1009, PermissionType.Action, 'viewMemberAccounts'));
    this.map.set(1010, new PermissionTypeMap(1010, PermissionType.Action, 'viewMemberEmploymentHistory'));
    this.map.set(1011, new PermissionTypeMap(1011, PermissionType.Action, 'createMemberEmploymentHistory'));
    this.map.set(1012, new PermissionTypeMap(1012, PermissionType.Action, 'editMemberEmploymentHistory'));
    this.map.set(1013, new PermissionTypeMap(1013, PermissionType.Action, 'viewEmployersTable'));
    this.map.set(1014, new PermissionTypeMap(1014, PermissionType.Action, 'createEmployers'));
    this.map.set(1015, new PermissionTypeMap(1015, PermissionType.Action, 'viewEmployerDetails'));
    this.map.set(1016, new PermissionTypeMap(1016, PermissionType.Action, 'viewEmployerMembers'));
    this.map.set(1017, new PermissionTypeMap(1017, PermissionType.Action, 'viewEmployerAccounts'));
    this.map.set(1018, new PermissionTypeMap(1018, PermissionType.Action, 'viewEmployerPlan'));
    this.map.set(1019, new PermissionTypeMap(1019, PermissionType.Action, 'editEmployerPlan'));
    this.map.set(1020, new PermissionTypeMap(1020, PermissionType.Action, 'viewInternalUsersTable'));
    this.map.set(1021, new PermissionTypeMap(1021, PermissionType.Action, 'createInternalUsers'));
    this.map.set(1022, new PermissionTypeMap(1022, PermissionType.Action, 'viewInternalUserDetails'));
    this.map.set(1023, new PermissionTypeMap(1023, PermissionType.Action, 'editInternalUserDetails'));
    this.map.set(1024, new PermissionTypeMap(1024, PermissionType.Action, 'viewInternalUserSubmissionPermissions'));
    this.map.set(1025, new PermissionTypeMap(1025, PermissionType.Action, 'editInternalUserSubmissionPermissions'));
    this.map.set(1026, new PermissionTypeMap(1026, PermissionType.Action, 'viewInternalRoles'));
    this.map.set(1027, new PermissionTypeMap(1027, PermissionType.Action, 'viewInternalRoleDetails'));
    this.map.set(1028, new PermissionTypeMap(1028, PermissionType.Action, 'viewAlertsTable'));
    this.map.set(1029, new PermissionTypeMap(1029, PermissionType.Action, 'createAlerts'));
    this.map.set(1030, new PermissionTypeMap(1030, PermissionType.Action, 'viewAlertDetails'));
    this.map.set(1031, new PermissionTypeMap(1031, PermissionType.Action, 'editAlertDetails'));
    this.map.set(1032, new PermissionTypeMap(1032, PermissionType.Action, 'viewReleaseNotesTable'));
    this.map.set(1033, new PermissionTypeMap(1033, PermissionType.Action, 'createReleaseNotes'));
    this.map.set(1034, new PermissionTypeMap(1034, PermissionType.Action, 'viewReleaseNoteDetails'));
    this.map.set(1035, new PermissionTypeMap(1035, PermissionType.Action, 'editReleaseNoteDetails'));
    this.map.set(1036, new PermissionTypeMap(1036, PermissionType.Action, 'editAcceptingSubmissions'));
    this.map.set(1037, new PermissionTypeMap(1037, PermissionType.Action, 'viewAnnuityRates'));
    this.map.set(1038, new PermissionTypeMap(1038, PermissionType.Action, 'createAnnuityRates'));
    this.map.set(1039, new PermissionTypeMap(1039, PermissionType.Action, 'editAnnuityRates'));
  }
}
