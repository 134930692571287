<app-sliding-window [toggleWindow]="showCommentWindow$ | async" [contentPaddingRem]="0">
  <div header>
    <app-sliding-window-header
      [slidingWindowOptions]="commentWindowOptions$ | async"
      (closeButtonClicked)="toggleCommentWindow()">
    </app-sliding-window-header>
    <div class="header-content" *ngIf="headerText">
      {{ headerText }}
    </div>
  </div>
  <lib-reactive-form-group
    content
    #form
    [bindTo]="commentReq$ | async"
    (formSubmitted$)="addComment($event)"
    [isLoading]="isLoading"
    [clearOnSubmit]="true">
    <div>
      <div
        class="comment-container"
        [ngClass]="{ 'header-height': headerText, 'no-header-height': !headerText }"
        [style.justify-content]="!(comments$ | async)?.length || isLoading ? 'flex-end' : 'normal'">
        <ng-container *ngIf="(comments$ | async)?.length && !isLoading">
          <div
            class="comments"
            *ngFor="let comment of comments$ | async; let first = first"
            [ngClass]="{
              'mt-auto': first && (comments$ | async)?.length < 5
            }">
            <div class="comment" [innerHTML]="comment.comment"></div>
            <div class="comment-info-container">
              <app-user-profile-badge
                [initials]="comment?.author?.initials"
                [profileColor]="comment?.author?.profileColour"
                [textColor]="comment?.author?.textColor">
              </app-user-profile-badge>
              <span class="comment-info comment-name">
                {{ comment?.author?.firstName }} {{ comment?.author?.lastName }}
              </span>
              <span class="comment-info">{{ comment?.formattedDate }}</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!(comments$ | async)?.length && !isLoading">
          <span class="no-comments" i18n>No comments yet.</span>
        </ng-container>
        <ng-container *ngIf="isLoading">
          <div class="d-flex flex-column gap-4 mb-3">
            <lib-loading-shimmer
              *ngFor="let c of comments$ | async"
              [borderRadius]="'1rem'"
              [height]="'1.5rem'"
              [width]="'3rem'"></lib-loading-shimmer>
            <lib-loading-shimmer [borderRadius]="'1rem'" [height]="'1.5rem'" [width]="'3rem'"></lib-loading-shimmer>
          </div>
        </ng-container>
      </div>
      <div class="input-container">
        <div class="tag-input" [style.padding-bottom]="isLoading ? '1.5rem' : '0.5rem'">
          <lib-reactive-form-tagging-input
            #tag
            (valueChanged)="onCommentValueChange($event)"
            [inputName]="'comment'"
            [required]="true"
            [bindingProperty]="'comment'"
            label="Comments"
            (dropdownSearchText)="filterDropdown($event)"
            [users]="dropdownItems"
            [customResultTemplate]="customTemplate"
            [showCharacterCount]="true"
            [maxLength]="1000"
            placeholder="Add a Comment..."
            i18n-label
            i18n-placeholder>
          </lib-reactive-form-tagging-input>
        </div>
      </div>
    </div>
  </lib-reactive-form-group>

  <app-sliding-window-footer
    footer
    [hideActionButton]="hideActionButton$ | async"
    [slidingWindowOptions]="commentWindowOptions$ | async"
    (primaryButtonClicked)="form.submitForm()"
    (closeButtonClicked)="toggleCommentWindow()">
  </app-sliding-window-footer>
</app-sliding-window>

<button
  type="button"
  [style.margin-bottom.rem]="buttonMarginBottomRem"
  class="comment-button"
  (click)="toggleCommentWindow()"></button>

<ng-template #customTemplate let-user>
  <div class="dropdown-item" [attr.data-user-id]="user.id">
    <app-user-profile-badge [initials]="user.initials" [profileColor]="user.profileColor" [textColor]="user.textColor">
    </app-user-profile-badge>
    {{ user.value }}
  </div>
</ng-template>
