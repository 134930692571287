import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input() progress = 0;
  @Input() errored: boolean = false;
  @Input() barColor: string | null = null;

  constructor() {}

  ngOnInit() {}

  getOverrideBarColor(): string | null {
    if (this.barColor && this.barColor !== '') {
      return this.barColor;
    } else {
      return null;
    }
  }
}
