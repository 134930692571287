import { Deserializable } from '../protocols/deserializable';

export class RemittanceOwner implements Deserializable {
  public id!: number;
  public firstName!: string;
  public lastName!: string;
  public profileColour!: string;

  onDeserialize(): void {}
}
