import { Deserializable } from '../protocols/deserializable';
import { Account } from './account';
import { TransactionStatus } from './transaction-status';
import { TransactionStatusPill } from '../shared/transaction-status-pill';
import { TransactionEntryLegacyCodeType } from './transaction-entry-legacy-code-type';
import { FundType } from './fund-type';

export class TransactionEntry implements Deserializable {
  public id!: string;
  public transactionId!: number;
  public accountId!: number;
  public account!: Account;
  public codeId!: number;
  public codeName!: string;
  public fundId!: number;
  public fund!: FundType;
  public legacyCodeId!: number;
  public legacyCode!: TransactionEntryLegacyCodeType;
  public debitedUnitCount!: number | null;
  public creditedUnitCount!: number | null;
  public unitSettlementCount!: number | null;
  public settledDebitValue!: number | null;
  public settledCreditValue!: number | null;
  public createdDate: Date | undefined;
  public postedDate: Date | undefined;
  public status: TransactionStatus | undefined;

  // not from API
  public statusPill: TransactionStatusPill | undefined;

  onDeserialize() {
    if (this.createdDate) {
      this.createdDate = new Date(this.createdDate);
    }
    if (this.postedDate) {
      this.postedDate = new Date(this.postedDate);
    }
    if (this.account) {
      this.account = window?.injector?.Deserialize.instanceOf(Account, this.account);
    }
    if (this.legacyCode) {
      this.legacyCode = window?.injector?.Deserialize.instanceOf(TransactionEntryLegacyCodeType, this.legacyCode);
    }
    if (this.fund) {
      this.fund = window?.injector?.Deserialize.instanceOf(FundType, this.fund);
    }
    if (this.status) {
      this.status = window?.injector?.Deserialize.instanceOf(TransactionStatus, this.status);
      this.statusPill = new TransactionStatusPill(this.status.id, this.status.name);
    }
  }
}
