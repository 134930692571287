import { Deserializable } from '../../protocols/deserializable';

export class RefreshSessionRequest implements Deserializable {
  public refreshToken: string = '';

  constructor(refreshToken: string) {
    this.refreshToken = refreshToken;
  }

  onDeserialize = () => {};
}
