<div class="auth-flow-logo-container">
  <img src="assets/logo/dark/css-logo.svg" alt="CSS Logo" i18n-alt />
</div>

<div class="auth-flow-title">
  {{ authFlowTitle$ | async }}
</div>

<div class="auth-flow-subtext">
  <div>
    <span i18n>You’re almost there! We sent an email with a verification code to </span>
    <span class="auth-highlighted-text">{{ newMemberEmail$ | async }}</span
    >. <span i18n>Please allow 5-10 minutes for this message to arrive.</span>
  </div>
  <div class="mt-3">
    <span i18n>Click on the link in the email to complete your account setup. If you don’t see an email from us,</span>
    <span class="auth-highlighted-text" i18n> you may need to check your spam folder.</span>
  </div>
  <div class="mt-3" i18n>Still cant find the email?</div>
</div>

<div class="sign-in-button-container">
  <lib-button-primary [style.width.%]="100" i18n>Resend Email</lib-button-primary>
</div>
<div class="support-button-container">
  <span i18n class="support-button">Having trouble?</span>
  <button class="paddingless-text-button mt-2" (click)="openContactPage()" i18n>Click here for support</button>
</div>
