import { Component } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { NavbarViewModel } from '../../navbar-view-model';

@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss']
})
export class MobileNavComponent extends BaseComponent {
  constructor(public viewModel: NavbarViewModel) {
    super();
  }
  setupBindings(): void {}

  setupViews(): void {}
}
