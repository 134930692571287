import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SessionContainer } from '../models/shared/session-container';
import { CacheService } from './cache-service';
import { DefaultCacheKey } from '../models/enum/shared/default-cache-key.enum';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { DateUtils } from '../utils/date-utils';
import { BaseUser } from '../models/base/base-user';
import { PortalType } from '../models/enum/shared/portal-type';
import { UserSession } from '../models/account/dto/user-session';
import { Role } from '../models/roles/role';

@Injectable({ providedIn: 'root' })
export class SessionService {
  private cacheService = inject(CacheService);

  constructor() {
    this.setupBindings();
  }

  // Behaviour Subjects

  private _refreshingSession: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public refreshingSession$ = this._refreshingSession.asObservable() as Observable<boolean>;
  setRefreshingSession = (refreshing: boolean) => this._refreshingSession.next(refreshing);

  private _sessionContainer: BehaviorSubject<SessionContainer | null> = new BehaviorSubject<SessionContainer | null>(
    null
  );
  public sessionContainer$ = this._sessionContainer.asObservable() as Observable<SessionContainer>;
  public validSession$ = this.sessionContainer$.pipe(
    map(s => s?.validSession()),
    distinctUntilChanged()
  );

  public destroySession: Subject<boolean> = new Subject<boolean>();

  private deadSession: boolean = true;

  public setupBindings() {
    this.sessionContainer$.notNull().subscribe(sess => {
      if (!!sess && sess?.rememberSession) {
        // save session to persistent cache
        this.cacheService.cacheObject(DefaultCacheKey.SessionContainer, sess, true);
      }
      // save session to session cache
      this.cacheService.cacheObject(DefaultCacheKey.SessionContainer, sess);
    });

    this.destroySession.notNull().subscribe(shouldDestroy => {
      if (shouldDestroy) {
        this.deadSession = true;
        this.cacheService.clearSessionCache();
        this.cacheService.clearPersistentCache();
        this._sessionContainer.next(null);
      }
    });
  }

  // Getters

  public getCachedSession(portalType: PortalType): SessionContainer | null {
    // Get user session from cache, checking session cache first
    let sess: SessionContainer | null;
    sess = this.cacheService.getCachedObject<SessionContainer>(
      SessionContainer,
      DefaultCacheKey.SessionContainer,
      undefined,
      portalType
    );
    if (!sess) {
      // Check the persistent cache for a session
      sess = this.cacheService.getCachedObject(SessionContainer, DefaultCacheKey.SessionContainer, true, portalType);
    }
    this.deadSession = false;
    if (!!sess) {
      this._sessionContainer.next(sess);
    }
    return sess;
  }

  // Setters

  public setUser(u: BaseUser, newSession: boolean) {
    this._sessionContainer
      .pipe(
        take(1),
        map(sessCopy => {
          const newSessCopy = Object.assign(new SessionContainer(), sessCopy);
          newSessCopy.user = u;
          if (newSession) {
            newSessCopy.sessionStartTime = DateUtils.currentTimestamp();
          }
          this.deadSession = false;
          return newSessCopy;
        })
      )
      .subscribe(sessCopy => {
        this._sessionContainer.next(sessCopy);
      });
  }

  // Update user info while persisting session
  public updateUser(u: BaseUser) {
    this._sessionContainer
      .pipe(
        take(1),
        map(sessCopy => {
          if (sessCopy) {
            const persistentSession = sessCopy?.user?.userSession;
            const newSessCopy = Object.assign(new SessionContainer(), sessCopy);
            newSessCopy.user = u as BaseUser;
            newSessCopy.user.userSession = persistentSession as UserSession;
            this.deadSession = false;
            return newSessCopy;
          }
        })
      )
      .subscribe(sessCopy => {
        if (!!sessCopy) this._sessionContainer.next(sessCopy);
      });
  }

  public setUserRoles(r: Role[], newSession: boolean) {
    this._sessionContainer
      .pipe(
        take(1),
        map(sessCopy => {
          const newSessCopy = Object.assign(new SessionContainer(), sessCopy);
          newSessCopy.roles = r;
          if (newSession) {
            newSessCopy.sessionStartTime = DateUtils.currentTimestamp();
          }
          this.deadSession = false;
          return newSessCopy;
        })
      )
      .subscribe(sessCopy => {
        this._sessionContainer.next(sessCopy);
      });
  }
}
