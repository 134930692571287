import { Deserializable } from '../../protocols/deserializable';
import { EmployerPlanDesignatedUnit } from './employer-plan-designated-unit';
import { Employer } from '../../account/dto/employer';
import * as moment from 'moment/moment';
import { RemittanceFrequencyType } from '../../shared/remittance-frequency-type';
import { EmployerPlanType } from './employer-plan-type';

export class EmployerPlan implements Deserializable {
  public id!: number;
  public employerPlanTypeId!: number;
  public employerPlanType!: EmployerPlanType;
  public employerId!: number;
  public employer!: Employer;
  public remittanceFrequencyTypeId!: number;
  public remittanceFrequencyType!: RemittanceFrequencyType;
  public planEstablishmentDate!: Date;
  public designatedUnits!: EmployerPlanDesignatedUnit[];

  // Not From API
  public formattedPlanEstablishmentDate!: string;

  onDeserialize(): void {
    this.designatedUnits = window?.injector?.Deserialize.arrayOf(EmployerPlanDesignatedUnit, this.designatedUnits);
    if (!!this.employer) {
      this.employer = window?.injector?.Deserialize.instanceOf(Employer, this.employer);
    }
    if (!!this.employerPlanType) {
      this.employerPlanType = window?.injector?.Deserialize.instanceOf(EmployerPlanType, this.employerPlanType);
    }
    if (!!this.planEstablishmentDate) {
      this.planEstablishmentDate = new Date(this.planEstablishmentDate);
      this.formattedPlanEstablishmentDate = moment(this.planEstablishmentDate).format('MMMM, D, YYYY');
    }
  }
}
