import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Orderable } from '../../../../models/protocols/orderable';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResizeObserver } from '@juggle/resize-observer';
import { PermissionService } from '../../../../services/permission-service';

@Component({
  selector: 'app-reorder-list-item',
  templateUrl: './reorder-list-item.component.html',
  styleUrls: ['./reorder-list-item.component.scss']
})
export class ReorderListItemComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() item!: Orderable;
  @Input() showIcons!: boolean;
  @Input() showVisibility!: boolean;
  @ViewChild('reorderItem') reorderItem!: ElementRef;

  private permissionService = inject(PermissionService);

  private ro!: ResizeObserver;
  private _reorderItemHeight = new BehaviorSubject<string>('');
  public reorderItemHeight$ = this._reorderItemHeight as Observable<string>;

  private _item = new BehaviorSubject({} as Orderable);
  public item$ = this._item as Observable<Orderable>;

  ngAfterViewInit(): void {
    this.ro = new ResizeObserver(_ => {
      const reorderItemHeight = this.reorderItem.nativeElement?.getBoundingClientRect()?.height?.toString();
      this._reorderItemHeight.next(reorderItemHeight);
    });
    this.ro.observe(this.reorderItem.nativeElement);
  }

  ngOnDestroy(): void {
    this.ro?.disconnect();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.item) this._item.next(this.item);
  }
}
