import { FoundationBaseModal } from '@csspension/base-angular';
import { Component } from '@angular/core';

@Component({ template: '' })
export abstract class BaseModalComponent extends FoundationBaseModal {
  public abstract ignoreEscape: boolean;

  public onKeyDownHandler(event: KeyboardEvent): void {
    if (!this.ignoreEscape) super.onKeyDownHandler(event);
  }
}
