<button
  class="css-pill"
  type="button"
  [ngClass]="{ active: selected || activeCount > 0, hoverable: clickable && !disabled }"
  [ngStyle]="{ 'font-size': fontSize }"
  [style.padding]="padding"
  [style.gap]="gap"
  [disabled]="disabled"
  (click)="pillClicked()">
  <ng-content *ngIf="!imageSrc"></ng-content>
  <ng-container *ngIf="imageSrc"
    ><img
      [ngClass]="{ 'active-img-filter': selected || activeCount > 0 }"
      class="pill-image"
      [src]="imageSrc"
      alt="pill-icon"
  /></ng-container>
  <ng-content *ngIf="!text"></ng-content>
  <ng-container *ngIf="text">{{ text }}</ng-container>
  <ng-content *ngIf="!activeCount"></ng-content>
  <ng-container *ngIf="activeCount">
    <div class="active-count-indicator">
      {{ activeCount }}
    </div>
  </ng-container>
  <ng-container *ngIf="showXButton">
    <img
      [ngClass]="{ 'active-img-filter': selected || activeCount > 0 }"
      class="pill-image"
      [src]="'assets/icons/dark/X.svg'"
      alt="pill-icon" />
  </ng-container>
</button>
