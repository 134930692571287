import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseCommentRequest } from '../../../../models/base/base-comment-request';
import { BaseComment } from '../../../../models/base/base-comment';
import { BaseComponent } from '../../../../models/base/base-component';
import { UserTaggingDropdownItem } from '../../../../models/shared/user-tagging-dropdown-item';
import { ScreenService } from '../../../../services/screen-service.service';
import { ReactiveFormGroupComponent } from '@csspension/reactive-form';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-comments-form',
  templateUrl: './comments-form.component.html',
  styleUrls: ['./comments-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentsFormComponent extends BaseComponent implements OnChanges, OnDestroy {
  private screenService = inject(ScreenService);
  public sanitizer = inject(DomSanitizer);

  @Input() isLoading: boolean = false;
  @Input() comments: BaseComment[] = [];
  @Input() commentReq: BaseCommentRequest = new BaseCommentRequest();
  @Input() dropdownItems: UserTaggingDropdownItem[] = [];
  @Input() headerHeight: string = '0rem';

  @Output() newComment = new EventEmitter<BaseCommentRequest>();
  @Output() filterText = new EventEmitter<string>();
  @Output() commentValueChanged = new EventEmitter<string>();

  @ViewChild('form') form!: ReactiveFormGroupComponent;

  private _showCommentWindow = new BehaviorSubject<boolean>(false);
  public readonly showCommentWindow$ = this._showCommentWindow as Observable<boolean>;

  private _commentReq = new BehaviorSubject<BaseCommentRequest>(new BaseCommentRequest());
  public commentReq$ = this._commentReq as Observable<BaseCommentRequest>;

  private _comments = new BehaviorSubject<BaseComment[]>([]);
  public comments$ = this._comments as Observable<BaseComment[]>;

  public filterDropdown(text: string): void {
    this.filterText.emit(text);
  }

  public onCommentValueChange(comment: string): void {
    this.commentValueChanged.emit(comment);
  }

  public addComment(comment: BaseCommentRequest): void {
    this.newComment.emit(comment);
  }

  public submitForm() {
    this.form.submitForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.comments) {
      this._comments.next(this.comments);
    }
    if (!!changes.commentReq) {
      this._commentReq.next(this.commentReq);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.screenService.enableScroll();
  }

  setupBindings(): void {}

  setupViews(): void {}
}
