import { Deserializable } from '../../protocols/deserializable';

export class ConfirmMemberRequest implements Deserializable {
  public email: string = '';
  public password: string = '';

  constructor(email: string, password: string) {
    this.email = email;
    this.password = password;
  }

  onDeserialize = () => {};
}
