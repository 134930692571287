import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leadingZero'
})
export class LeadingZeroPipe implements PipeTransform {
  transform(value: number | undefined, padWidth = 2): string {
    const str = value?.toString() ?? '';
    return str.padWithLeadingZeros(padWidth);
  }
}
