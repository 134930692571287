import { Deserializable } from './deserializable';
import { Type } from '@angular/core';
import { HydratedUser } from '../users/dto/hydrated-user';

export class DeserializeLabelHelper {
  // This code is partial from BudSense, just meant as a reference for polymorphic deserialization.

  static getPolymorphicLabelObject<T extends Deserializable>(label: any): T {
    const createPolymorphicObject = (ObjectType: Type<any>): T => {
      return Object.assign(Object.create(ObjectType.prototype), label);
    };
    switch (label?.id) {
      default:
        return createPolymorphicObject(HydratedUser);
    }
  }
}
