<div class="auth-flow-subtext">
  <div class="header">{{ 'Authenticator App' | localization }}</div>
  <div class="subtext">
    {{ 'Scan the QR code below using the authenticator app of your choice such as' | localization }}
    <span class="auth-highlighted-text">{{ 'Google Authenticator' | localization }}</span
    >, <span class="auth-highlighted-text">{{ 'Authy' | localization }}</span
    >,
    {{ 'or' | localization }}
    <span class="auth-highlighted-text">{{ 'Windows Phone Authenticator' | localization }}</span
    >.
  </div>
  <ng-container *ngIf="viewModel.isMobile$ | async">
    <div class="subtext pt-3">
      {{ 'On Mobile? Click' | localization }}
      <a class="paddingless-text-button copy-text" (click)="clipboardService.copyToClipboard(mfaKey)">
        {{ 'here' | localization }}
      </a>
      {{ 'to copy the code to your clipboard.' | localization }}
    </div>
    <hr />
  </ng-container>
</div>
<div>
  <canvas id="qr-code"></canvas>
  <button class="paddingless-text-button qr-support" (click)="openSupportModal()">
    {{ 'QR Code Support' | localization }}
  </button>
</div>

<div class="subtext pb-3">
  {{
    'After setting up your authenticator, you will be given a six-digit verification code. Please enter it below.'
      | localization
  }}
</div>

<lib-reactive-form-group #form [bindTo]="req" (formSubmitted$)="formSubmitted(req)">
  <lib-reactive-form-string-number
    [inputName]="'timeBasedOneTimePassword'"
    [label]="'Verification Code' | localization"
    [inlineLabel]="false"
    [placeholder]="''"
    [integersOnly]="true"
    [required]="true"
    [minCharacterCount]="6"
    [maxCharacterCount]="6"
    [showRequiredAstrix]="false"
    [bindingProperty]="'timeBasedOneTimePassword'">
  </lib-reactive-form-string-number>
</lib-reactive-form-group>
<lib-button-primary class="w-100 mt-3" (buttonClicked)="form.submitForm()">
  {{ 'Submit' | localization }}
</lib-button-primary>
<div class="support-button-container">
  <span>{{ 'Having trouble?' | localization }}</span>
  <button class="paddingless-text-button mt-2" (click)="viewModel.openContactPage()">
    {{ 'Click here for support' | localization }}
  </button>
</div>
