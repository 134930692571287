import { Deserializable } from '../../protocols/deserializable';
import { CodeDeliveryDetails } from './code-delivery-details';
import { Asset } from '../../asset/dto/asset';
import { BaseUser } from '../../base/base-user';

export class HydratedUser extends BaseUser implements Deserializable {
  public profilePicture!: Asset;
  public codeDeliveryDetails!: CodeDeliveryDetails[];

  public onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.profilePicture = Deserialize?.instanceOf(Asset, this.profilePicture);
    this.codeDeliveryDetails = Deserialize?.arrayOf(CodeDeliveryDetails, this.codeDeliveryDetails);
  }
}
