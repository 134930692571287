import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, iif, Observable, of } from 'rxjs';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { StringExtensions } from '../../../../../utils/string.extensions';
import { AlertTypeEnum } from '../../../../../models/alerts/enum/alert-type.enum';
import { debounceTime, filter, finalize, map, switchMap, takeUntil } from 'rxjs/operators';
import { Alert } from '../../../../../models/alerts/dto/alert';
import { AlertsDomainModel } from '../../../../../domainModels/alerts-domain-model';
import { inject } from '@angular/core';
import { RadioButton } from '../../../../../models/shared/radio-button';
import { AlertBackgroundColorEnum } from '../../../../../models/alerts/enum/alert-background-color.enum';
import { CreateAlertRequest } from '../../../../../models/alerts/requests/create-alert-request';
import { ToastService } from '../../../../../services/toast-service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DateUtils } from '../../../../../utils/date-utils';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { PermissionService } from 'src/app/services/permission-service';
import { AlertStatusStringEnum } from '../../../../../models/alerts/enum/alert-status.enum';

export class AlertDetailsViewModel extends BaseViewModel {
  private alertDomainModel = inject(AlertsDomainModel);
  private toastService = inject(ToastService);
  private router = inject(Router);
  private permissionService = inject(PermissionService);

  public _loadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.default(false, true));
  public loadingOpts$ = this._loadingOpts as Observable<LoadingOptions>;

  public readonly canEditAlertDetails$ = this.permissionService.permissionGranted$([1031]);

  public cardTypes$ = this.alertDomainModel.alertTypes$;

  public cardAlertColors$ = this.cardTypes$.pipe(
    map(cts => {
      return cts.find(ct => ct.id === AlertTypeEnum.Card)?.colours ?? [];
    })
  );

  public bannerAlertColors$ = this.cardTypes$.pipe(
    map(cts => {
      return cts.find(ct => ct.id === AlertTypeEnum.Banner)?.colours ?? [];
    })
  );

  public cardRadioButtons$ = this.cardAlertColors$.pipe(
    map(colors => {
      return colors.map(c => {
        return new RadioButton(c.name, c.id);
      });
    })
  );

  public bannerRadioButtons$ = this.bannerAlertColors$.pipe(
    map(colors => {
      return colors.map(c => {
        return new RadioButton(c.name, c.id);
      });
    })
  );

  public dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

  private _selectedEndDate = new BehaviorSubject<string>('');
  public selectedEndDate$ = this._selectedEndDate as Observable<string>;

  private _selectedStartDate = new BehaviorSubject<string>('');
  public selectedStartDate$ = this._selectedStartDate as Observable<string>;

  private _datePickerStartDate = new BehaviorSubject<NgbDate[]>([]);
  public readonly datePickerStartDate$ = this._datePickerStartDate as Observable<NgbDate[]>;

  private _datePickerEndDate = new BehaviorSubject<NgbDate[]>([]);
  public readonly datePickerEndDate$ = this._datePickerEndDate as Observable<NgbDate[]>;

  private _selectedAlertType = new BehaviorSubject<AlertTypeEnum>(AlertTypeEnum.Card);
  public selectedAlertType$ = this._selectedAlertType as Observable<AlertTypeEnum>;

  private _alert = new BehaviorSubject<Alert>(new Alert());
  public alert$ = this._alert as Observable<Alert>;

  private _alertFormDirty = new BehaviorSubject<boolean>(false);
  public alertFormDirty$ = this._alertFormDirty as Observable<boolean>;
  public setAlertFormDirty = (value: boolean) => this._alertFormDirty.next(value);

  public isPastAlert$ = this.alert$.pipe(map(alert => alert.status === AlertStatusStringEnum.Past));

  private _alertPreviewPristine = new BehaviorSubject<boolean>(true);
  public alertPreviewPristine$ = this._alertPreviewPristine as Observable<boolean>;
  public setAlertPreviewPristine = (value: boolean) => this._alertPreviewPristine.next(value);

  private _alertPreview = new BehaviorSubject<Alert>(new Alert());
  public alertPreview$ = combineLatest([this._alertPreview, this.alertPreviewPristine$]).pipe(
    map(([alert, pristine]) => {
      if (pristine) {
        alert.title = $localize`Title`;
        alert.description = $localize`Description`;
        alert.buttonText = $localize`Button Text`;
      }
      return alert;
    })
  );

  private _showAlertPreview = new BehaviorSubject<boolean>(true);
  public showAlertPreview$ = this._showAlertPreview as Observable<boolean>;

  private _previewLoading = new BehaviorSubject<boolean>(false);
  public previewLoading$ = this._previewLoading as Observable<boolean>;
  public setPreviewLoading = (value: boolean) => this._previewLoading.next(value);

  public isCardTypeAlert$ = this.selectedAlertType$.pipe(map(alertType => alertType === AlertTypeEnum.Card));

  private _alertId = new BehaviorSubject<string | null>(null);
  public readonly alertId$ = this._alertId as Observable<string | null>;

  private loadingMessage = $localize`Loading Alert`;

  protected listenToAlertId = this.alertId$
    .pipe(
      filter(alertId => !!alertId),
      switchMap(alertUserId => {
        if (alertUserId !== null) {
          this._loadingOpts.addRequest(this.loadingMessage);
          return this.alertDomainModel.getAlertById(alertUserId);
        } else {
          this.toastService.publishErrorMessage($localize`Error!`, $localize`Invalid Alert ID!`);
          this.router.navigate(['/settings/alerts']).then();
          return of(null);
        }
      }),
      takeUntil(this.onDestroy),
      finalize(() => {
        this._loadingOpts.removeRequest(this.loadingMessage);
      })
    )
    .subscribe({
      next: alert => {
        this._loadingOpts.removeRequest(this.loadingMessage);
        if (!!alert) {
          this._alert.next(alert);
          this.setDates(alert);
          this.setAlertType(alert.typeId);
          this.updatePreview(alert);
        } else {
          this.toastService.publishErrorMessage($localize`Error!`, $localize`Invalid Alert ID!`);
          this.router.navigate(['/settings/alerts']).then();
        }
      },
      error: err => {
        if (err.code === 404) {
          this.toastService.publishErrorMessage($localize`Error!`, $localize`Invalid Alert ID!`);
        } else {
          this.toastService.publishError(err);
        }
        this._loadingOpts.removeRequest(this.loadingMessage);
        this.router.navigate(['/settings/alerts']).then();
      }
    });

  private listenForInvalidColorSelection = combineLatest([this.alert$, this.selectedAlertType$, this.cardTypes$])
    .pipe(debounceTime(100))
    .subscribeWhileAlive({
      owner: this,
      next: ([alert, selectedAlertType, cardTypes]) => {
        const possibleColorIds = cardTypes?.find(ct => ct.id === selectedAlertType)?.colours?.map(c => c?.id);
        if (!possibleColorIds?.contains(alert.colourId) && possibleColorIds) {
          alert.colourId = possibleColorIds[0];
          alert.typeId = selectedAlertType;
          const copy = Object.assign(new Alert(), alert);
          this._alert.next(copy);
        }
      }
    });

  public setAlertType(alertType: AlertTypeEnum): void {
    this._selectedAlertType.next(alertType);
  }

  private setDates(alert: Alert): void {
    const formattedStartDate = moment(alert.startDate).format('YYYY/MM/DD');
    const formattedEndDate = moment(alert.endDate).format('YYYY/MM/DD');
    const startDate = StringExtensions.convertDateStringToNgbDate(formattedStartDate);
    const endDate = StringExtensions.convertDateStringToNgbDate(formattedEndDate);
    this.setStartDate([startDate]);
    this.setEndDate([endDate]);
  }

  public setStartDate(selDate: NgbDate[]): void {
    const [date] = selDate;
    this._datePickerStartDate.next(selDate);
    this._selectedStartDate.next(DateUtils.convertNgbDateObjectToString(date));
  }

  public setEndDate(selDate: NgbDate[]): void {
    const [date] = selDate;
    this._datePickerEndDate.next(selDate);
    this._selectedEndDate.next(DateUtils.convertNgbDateObjectToString(date));
  }

  public setAlertId(alertId: string): void {
    this._alertId.next(alertId);
  }

  public toggleShowAlertPreview(): void {
    this.showAlertPreview$.once(showPreview => this._showAlertPreview.next(!showPreview));
  }

  public updatePreview(alert: Alert): void {
    this.checkForColorAndUpdatePreview(alert);
  }

  public createAlert(alert: Alert): void {
    const req = CreateAlertRequest.hydrateFromAlert(alert);
    const lm = $localize`Creating Alert`;
    this._loadingOpts.addRequest(lm);
    alert.clearInvalidProperties();
    this.alertDomainModel.createAlert(req).subscribe({
      next: a => {
        this._alertFormDirty.next(false);
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishSuccessMessage($localize`Success!`, $localize`Alert Created`);
        this.router.navigate(['/settings/alerts']).then();
      },
      error: err => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishError(err);
      }
    });
  }

  public updateAlert(alert: Alert): void {
    const lm = $localize`Updating Alert`;
    alert.clearInvalidProperties();
    alert.setDates();
    this._loadingOpts.addRequest(lm);
    this.alertDomainModel.updateAlert(alert).subscribe({
      next: a => {
        this._alert.next(a);
        this._selectedStartDate.next(a.startDateString);
        this._selectedEndDate.next(a.endDateString);
        this.toastService.publishSuccessMessage($localize`Success!`, $localize`Alert Updated`);
        this._loadingOpts.removeRequest(lm);
      },
      error: err => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishError(err);
      }
    });
  }

  public deleteAlert(id: string): void {
    const lm = $localize`Deleting Alert`;
    this._loadingOpts.addRequest(lm);
    this.alertDomainModel.deleteAlert(id).subscribe({
      next: () => {
        this._alertFormDirty.next(false);
        this.toastService.publishSuccessMessage($localize`The alert has been deleted`);
        this.router.navigate(['/settings/alerts']).then();
        this._loadingOpts.removeRequest(lm);
      },
      error: err => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishError(err);
      }
    });
  }

  private checkForColorAndUpdatePreview(alert: Alert): void {
    // Ensuring a color is set on the alert preview, if not set default of green
    if (!alert.colourId) {
      alert.colourId =
        alert.typeId === AlertTypeEnum.Card ? AlertBackgroundColorEnum.CardGreen : AlertBackgroundColorEnum.BannerGreen;
    }
    this.isCardTypeAlert$
      .pipe(switchMap(isCardTypeAlert => iif(() => isCardTypeAlert, this.cardAlertColors$, this.bannerAlertColors$)))
      .once(colors => {
        const color = colors?.find(c => c?.id === alert?.colourId);
        if (color) {
          alert.colour.backgroundColour = color?.backgroundColour;
          alert.colour.borderColour = color?.borderColour;
        }
        this._alertPreview.next(alert);
      });
  }
}
