<div
  class="sub-nav-item"
  (click)="navItemSelected(); $event.stopPropagation()"
  [class.sub-active]="viewModel.isNavItemActive$ | async">
  <div class="check-container">
    <img
      *ngIf="viewModel.isNavItemActive$ | async"
      [src]="'assets/icons/dark/Check.svg'"
      alt="Checkmark"
      class="checkmark" />
  </div>
  <div>
    {{ subItem?.name }}
  </div>
</div>
