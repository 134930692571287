<div [ngbCollapse]="!(showSearchBar$ | async) ?? false" [animation]="true">
  <lib-reactive-form-group #searchForm [bindTo]="searchForm$ | async" (formSubmitted$)="formSubmitted($event)">
    <ng-container *ngIf="!(useDatePicker$ | async)">
      <lib-reactive-form-text
        [inputName]="(bindingProperty$ | async) ?? ''"
        [bindingProperty]="(bindingProperty$ | async) ?? ''"
        [placeholder]="(placeholder$ | async) ?? ''"
        (valueChanged)="searchForm.submitForm()">
      </lib-reactive-form-text>
    </ng-container>

    <ng-container *ngIf="useDatePicker$ | async">
      <lib-reactive-form-masked-input
        #dateInput
        [inputName]="(bindingProperty$ | async) ?? ''"
        [mask]="dateMask"
        [placeholder]="(placeholder$ | async) ?? ''"
        [bindingProperty]="(bindingProperty$ | async) ?? ''"
        [showCalButton]="true"
        [calButtonIconHeightRem]="0.75"
        [calButtonIconTopPercent]="78"
        [showingCalendar]="shouldShowDatePicker"
        [customValidators]="[validDateDirective]"
        (valueChanged)="searchForm.submitForm(); checkDateValue($event)"
        (calButtonClicked)="toggleDatePicker()">
      </lib-reactive-form-masked-input>
      <div
        #datePicker
        class="date-picker-wrapper"
        [style.display]="'none'"
        clickOutside
        (onClickOutside)="onClickOutside()">
        <app-date-picker [initialSelectedDates]="selectedDates$ | async" (dateSelected)="handleDateSelection($event)">
        </app-date-picker>
      </div>
    </ng-container>
  </lib-reactive-form-group>
</div>
