import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { BaseCommentRequest } from '../../../../models/base/base-comment-request';
import { UserTaggingDropdownItem } from '../../../../models/shared/user-tagging-dropdown-item';
import { SubmissionComment } from '../../../../models/submissions/submission-comment';
import { SubmissionCommentRequest } from '../../../../models/account/requests/submission-comment-request';

@Component({
  selector: 'app-submission-comments-form',
  templateUrl: './submission-comments-form.component.html',
  styleUrls: [
    './submission-comments-form.component.scss',
    '../sliding-window-comments/sliding-window-comments.component.scss',
    '../../../submissions/components/submission-details/internal-user-view-member-submission/internal-user-view-member-submission.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubmissionCommentsFormComponent extends BaseComponent {
  @Input() dropdownItems: UserTaggingDropdownItem[] = [];
  @Input() comments: SubmissionComment[] = [];
  @Input() commentReq: SubmissionCommentRequest = new SubmissionCommentRequest();

  @Output() newComment = new EventEmitter<BaseCommentRequest>();
  @Output() filterText = new EventEmitter<string>();

  setupViews(): void {}

  setupBindings(): void {}
}
