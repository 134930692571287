import { BaseDomainModel } from '../models/base/base-domain-model';
import { inject, Injectable } from '@angular/core';
import { SubmissionsAPI } from '../api/submissions-api';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { ODataResponse } from '../models/protocols/odata-response';
import { ChangeNameRequest } from '../models/users/requests/submissionRequests/change-name-request';
import { CreateAttachmentRequest } from '../models/users/requests/create-attachment-request';
import { Asset } from '../models/asset/dto/asset';
import { SubmissionAttachment } from '../models/submissions/submission-attachment';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { MemberSubmission, SubmissionStakeholder } from '../models/submissions/member-submission';
import { TypeService } from '../services/type/type-service';
import { SubmissionStatus } from '../models/shared/submission-status';
import { SubmissionCreated } from '../models/submissions/submission-created';
import { AttachmentFile } from '../models/users/dto/attachment-file';
import { AttachmentApi } from '../api/attachment-api';
import { SubmissionCommentRequest } from '../models/users/requests/submission-comment-request';
import { SubmissionComment } from '../models/submissions/submission-comment';
import { SubmissionStatusCommentRequest } from '../models/submissions/requests/submission-comment-request';
import { SubmissionSubStatusEnum } from '../models/enum/shared/submission-sub-status.enum';
import { UpdateSubmissionOwnerRequest } from '../models/users/requests/update-submission-owner-request';
import { UpdateSubmissionApproverRequest } from '../models/users/requests/update-submission-approver-request';
import { SubmissionRoleTemplate } from '../models/submissions/submission-role-template';
import { MemberSubmissionType } from '../models/submissions/member-submission-type';
import { SubmissionPermissionType } from '../models/permissions/dto/submission-permission-type';
import { SubmissionPermissionWithType } from '../models/permissions/dto/submission-permission-with-type';
import { ChangeAddressRequest } from '../models/users/requests/submissionRequests/change-address-request';
import { SubmissionReminderRequest } from '../models/users/requests/submission-reminder-request';
import { HttpResponse } from '@angular/common/http';
import { PortalService } from '../services/portal/portal.service';
import { UpdateContactInfoRequest } from '../models/users/requests/submissionRequests/update-contact-info-request';
import { SubmissionStatusPill } from '../models/shared/submission-status-pill';
import { ChangePersonalInfoRequest } from '../models/users/requests/submissionRequests/change-personal-info-request';

// Provided by Logged In Scope
@Injectable()
export class SubmissionsDomainModel extends BaseDomainModel {
  constructor(private submissionsApi: SubmissionsAPI, private attachmentApi: AttachmentApi) {
    super();
    this.init();
  }

  private typeService = inject(TypeService);
  private portalService = inject(PortalService);

  private _allMemberSubmissions = new BehaviorSubject<MemberSubmission[] | null>(null);
  public allMemberSubmissions$ = this._allMemberSubmissions as Observable<MemberSubmission[]>;

  private _submissionStatuses = new BehaviorSubject<SubmissionStatus[]>([]);
  public submissionStatuses$ = this._submissionStatuses as Observable<SubmissionStatus[]>;

  private _submissionRoleTemplates = new BehaviorSubject<SubmissionRoleTemplate[]>([]);
  public submissionRoleTemplates$ = this._submissionRoleTemplates as Observable<SubmissionRoleTemplate[]>;

  private _submissionTypes = new BehaviorSubject<MemberSubmissionType[]>([]);
  public submissionTypes$ = this._submissionTypes as Observable<MemberSubmissionType[]>;

  private _submissionPermissionTypes = new BehaviorSubject<SubmissionPermissionType[]>([]);
  public submissionPermissionTypes$ = this._submissionPermissionTypes as Observable<SubmissionPermissionType[]>;

  public submissionStatusPills$: Observable<SubmissionStatusPill[]> = this.submissionStatuses$.pipe(
    map(ss => {
      return ss.flatMap(s => {
        if (s?.subStatuses?.length > 0) {
          return s?.subStatuses.map(st => {
            return new SubmissionStatusPill(st.id, st.name, st.parentStatusId, st.parentStatus);
          });
        }
        return []; // Return an empty array if there are no sub statuses
      });
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _allMemberSubmissionsTotalCount = new BehaviorSubject<number | undefined>(undefined);
  public allMemberSubmissionsTotalCount$ = this._allMemberSubmissionsTotalCount as Observable<number | undefined>;

  public init(): void {
    this.fetchSubmissionStatuses();
    this.portalService.isInternal$.once(isInternal => {
      if (isInternal) {
        this.getSubmissionRoleTemplates();
        this.getMemberSubmissionTypes();
        this.getSubmissionPermissionTypes();
      }
    });
  }

  public getMemberSubmissions(oDataQueryOptions: ODataQueryOptions): Observable<ODataResponse<MemberSubmission>> {
    oDataQueryOptions.setExpand('Owner,Approver,Reminder');
    return this.submissionsApi.getMemberSubmissions(oDataQueryOptions).pipe(
      tap(odataRes => this._allMemberSubmissionsTotalCount.next(odataRes['@odata.count'])),
      tap(odataRes => this._allMemberSubmissions.next(odataRes.value)),
      catchError(err => {
        this._allMemberSubmissions.next([]);
        this._allMemberSubmissionsTotalCount.next(0);
        return throwError(() => err);
      })
    );
  }

  public getMemberSubmissionById(id: string): Observable<MemberSubmission> {
    return this.submissionsApi.getMemberSubmissionById(id);
  }

  public getSubmissionViewersForSubmissionTypeId(subTypeId: string): Observable<SubmissionStakeholder[]> {
    return this.submissionsApi.getSubmissionViewersForSubmissionType(subTypeId).pipe(map(res => res.value));
  }

  public createMemberNameChangeSubmission(req: ChangeNameRequest): Observable<SubmissionCreated> {
    return this.submissionsApi.createNameChangeSubmission(req);
  }

  public createMemberPersonalInfoChangeSubmission(req: ChangePersonalInfoRequest): Observable<SubmissionCreated> {
    return this.submissionsApi.createPersonalInfoChangeSubmission(req);
  }

  public createMemberAddressChangeSubmission(req: ChangeAddressRequest): Observable<SubmissionCreated> {
    return this.submissionsApi.createAddressChangeSubmission(req);
  }

  public createUpdateContactInfoSubmission(req: UpdateContactInfoRequest): Observable<SubmissionCreated> {
    return this.submissionsApi.createUpdateContactInformationSubmission(req);
  }

  public createSubmissionAttachment(
    req: CreateAttachmentRequest,
    submissionId: string
  ): Observable<SubmissionAttachment> {
    return this.submissionsApi.createSubmissionAttachment(req, submissionId);
  }

  public addSubmissionComment(req: SubmissionCommentRequest): Observable<SubmissionComment[]> {
    return this.submissionsApi.addSubmissionComment(req);
  }

  public getSubmissionAttachments(submissionId: string): Observable<SubmissionAttachment[]> {
    return this.attachmentApi.getSubmissionAttachments(submissionId);
  }

  public uploadAttachmentToS3(file: AttachmentFile): Observable<Asset> {
    return this.attachmentApi.uploadAttachmentFile(file);
  }

  public deleteSubmissionAttachment(attachmentId: string, submissionId: string): Observable<any> {
    return this.attachmentApi.deleteSubmissionAttachment(attachmentId, submissionId);
  }

  public updateSubmissionStatusWithComment(req: SubmissionStatusCommentRequest, url: string): Observable<any> {
    return this.submissionsApi.updateSubmissionStatusWithComment(req, url);
  }

  public updateSubmissionStatus(status: SubmissionSubStatusEnum, submissionId: string): Observable<any> {
    return this.submissionsApi.updateSubmissionStatus(status, submissionId);
  }

  private fetchSubmissionStatuses(): void {
    this.typeService.submissionStatuses$.once(ss => this._submissionStatuses.next(ss));
  }

  public getFilteredMemberSubmissionOwners(filterString: string): Observable<SubmissionStakeholder[]> {
    const queryOptions = new ODataQueryOptions();
    queryOptions.setFilter(`contains(firstName, '${filterString}')` + `or contains(lastName, '${filterString}')`);
    return this.submissionsApi.getMemberSubmissionOwners(queryOptions).pipe(map(res => res.value));
  }

  public getMemberSubmissionOwnersForSubmissionTypeId(subTypeId: string): Observable<SubmissionStakeholder[]> {
    return this.submissionsApi.getMemberSubmissionOwnersForSubmissionType(subTypeId).pipe(map(res => res.value));
  }

  public getMemberSubmissionApproversForSubmissionTypeId(subTypeId: string): Observable<SubmissionStakeholder[]> {
    return this.submissionsApi.getSubmissionApproversForSubmissionType(subTypeId).pipe(map(res => res.value));
  }

  public getFilteredMemberSubmissionApprovers(filterString: string): Observable<SubmissionStakeholder[]> {
    const queryOptions = new ODataQueryOptions();
    queryOptions.setFilter(`contains(firstName, '${filterString}')` + `or contains(lastName, '${filterString}')`);
    return this.submissionsApi.getMemberSubmissionApprovers(queryOptions).pipe(map(res => res.value));
  }

  public getSubmissionRoleTemplates(): void {
    this.submissionsApi
      .getSubmissionRoleTemplates()
      .pipe(map(res => res.value))
      .once(templates => this._submissionRoleTemplates.next(templates));
  }

  public getSubmissionPermissionTypes(): void {
    this.submissionsApi.getSubmissionPermissionTypes().once(res => this._submissionPermissionTypes.next(res));
  }

  public getAllSubmissionPermissions(): Observable<SubmissionPermissionWithType[]> {
    return this.submissionsApi.getAllSubmissionPermissions().pipe(
      map(res => res.value),
      shareReplay({
        bufferSize: 1,
        refCount: true
      })
    );
  }

  public getMemberSubmissionTypes(): void {
    this.submissionsApi
      .getMemberSubmissionTypes()
      .pipe(map(res => res.value))
      .once(types => this._submissionTypes.next(types));
  }

  public updateMemberSubmissionOwner(req: UpdateSubmissionOwnerRequest): Observable<MemberSubmission> {
    return this.submissionsApi.updateMemberSubmissionOwner(req);
  }

  public updateMemberSubmissionApprover(req: UpdateSubmissionApproverRequest): Observable<MemberSubmission> {
    return this.submissionsApi.updateMemberSubmissionApprover(req);
  }

  public setSubmissionToReadyForApproval(req: UpdateSubmissionApproverRequest): Observable<MemberSubmission> {
    return this.submissionsApi.setSubmissionToReadyForApproval(req);
  }

  public createMemberSubmissionReminder(req: SubmissionReminderRequest): Observable<MemberSubmission> {
    return this.submissionsApi.createMemberSubmissionReminder(req);
  }

  public deleteMemberSubmissionReminder(submissionId: string, reminderId: string): Observable<HttpResponse<any>> {
    return this.submissionsApi.deleteMemberSubmissionReminder(submissionId, reminderId);
  }
}
