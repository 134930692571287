import { BaseViewModel } from '../../../models/base/base-view-model';
import { inject, Injectable } from '@angular/core';
import { UserDomainModel } from '../../../domainModels/user-domain-model';
import { PortalService } from '../../../services/portal/portal.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { EmployerUser } from '../../../models/account/dto/employer-user';
import { MemberUser } from '../../../models/account/dto/member-user';
import { DateUtils } from '../../../utils/date-utils';
import { TypeService } from '../../../services/type/type-service';
import { DropDownItem } from '../../../models/shared/stylesheet/drop-down-item';
import { Router } from '@angular/router';
import { SessionService } from '../../../services/session-service';
import { ToastService } from '../../../services/toast-service';
import { SubmissionsDomainModel } from '../../../domainModels/submissions-domain-model';
import { ODataQueryOptions } from '../../../models/shared/odata-query-options';
import { LoadingOptions } from '../../../models/shared/loading-options';
import { MemberSIN } from '../../../models/account/dto/member-sin';
import { MembersDomainModel } from '../../../domainModels/members-domain-model';
import { MemberSubmission } from '../../../models/submissions/member-submission';

// Provided by Logged In Scope
@Injectable()
export class ProfileViewModel extends BaseViewModel {
  private userDomainModel = inject(UserDomainModel);
  private membersDomainModel = inject(MembersDomainModel);
  private submissionDomainModel = inject(SubmissionsDomainModel);

  private portalService = inject(PortalService);
  private typeService = inject(TypeService);
  private router = inject(Router);
  private sessionService = inject(SessionService);
  private toastService = inject(ToastService);

  constructor() {
    super();
    this.init();
    this.states$ = this.typeService.states$.pipe(
      map(states => {
        return states.map(state => new DropDownItem(state.name, state.stateCode));
      })
    );
  }

  public states$: Observable<DropDownItem[]>;

  private _memberSubmissions = new BehaviorSubject<MemberSubmission[] | null>(null);
  public readonly memberSubmissions$ = this._memberSubmissions as Observable<MemberSubmission[]>;

  private _memberSubmissionsCount = new BehaviorSubject<number>(0);
  public readonly memberSubmissionsCount$ = this._memberSubmissionsCount as Observable<number>;

  public readonly user$ = this.userDomainModel.user$;
  public decryptedMemberSIN$: Observable<MemberSIN | null> = this.portalService.isMember$.pipe(
    switchMap(isMember => {
      return isMember ? this.userDomainModel.getDecryptedMemberSIN() : of(null);
    })
  );

  private message = $localize`Loading...`;

  private _submissionLoadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.default(false));
  public submissionLoadingOpts$ = this._submissionLoadingOpts as Observable<LoadingOptions>;

  public lastAuthString$ = this.userDomainModel.user$.notNull().pipe(
    map(user => {
      if (!user.lastLoginDate) return '';
      return DateUtils.formatUnixToCurrentDateTime((user.lastLoginDate.getTime() ?? 0) / 1000);
    })
  );

  public userActiveMFA$ = this.user$.pipe(
    map(u => {
      if (u instanceof EmployerUser || u instanceof MemberUser) {
        return u?.mfaActive;
      } else {
        return true;
      }
    })
  );

  public getMemberSubmissions(): void {
    // TODO: Filter out non-profile submissions when available
    const opts = new ODataQueryOptions();
    opts.setTop(5);
    opts.setCount(true);
    opts.setOrderBy('lastStatusUpdateDate desc');
    this.submissionDomainModel
      .getMemberSubmissions(opts)
      .pipe(
        tap(_ => this._submissionLoadingOpts.addRequest(this.message)),
        finalize(() => this._submissionLoadingOpts.removeRequest(this.message)),
        take(1)
      )
      .subscribe({
        error: () => {
          this.toastService.publishErrorMessage($localize`Error`, $localize`Unable to get submissions for user.`);
        },
        next: res => {
          this._memberSubmissions.next(res.value);
          this._memberSubmissionsCount.next(res['@odata.count'] ?? 0);
        }
      });
  }

  public updateMemberUser(): void {
    this.user$.once(user => {
      const opts = new ODataQueryOptions();
      opts.setExpand('Address($expand=State, Country), EmploymentHistory, Accounts($expand=Type), Gender, Pronoun');
      this.membersDomainModel.getMemberById(user.id.toString(), opts).subscribe(u => {
        this.sessionService.updateUser(u);
      });
    });
  }

  public disableMfa(): void {
    const loadingMessage = $localize`Verifying...`;
    this._loadingOpts.addRequest(loadingMessage);
    this.userDomainModel.disableMfa().subscribe({
      next: user => {
        this.sessionService.updateUser(user);
        this._loadingOpts.removeRequest(loadingMessage);
        this.toastService.publishSuccessMessage(
          $localize`Multi-Factor Authentication Disabled`,
          $localize`You will no longer need to authenticate your account when signing in.`
        );
      },
      error: err => {
        this._loadingOpts.removeRequest(loadingMessage);
        this.toastService.publishErrorMessage($localize`Error`, $localize`${err.title}`);
      }
    });
  }

  public signOut() {
    this.router.navigate([`/auth/sign-out`]).then();
  }

  public init(): void {
    super.init();
    this.portalService.isMember$.once(isMember => {
      if (isMember) {
        this.getMemberSubmissions();
      }
    });
  }
}
