import { Component, Input } from '@angular/core';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-eula-modal',
  templateUrl: './eula-modal.component.html',
  styleUrls: ['./eula-modal.component.scss', '../base-action-modal/base-action-modal.component.scss']
})
export class EulaModalComponent extends BaseModalComponent {
  @Input() eulaText: string = '';
  public ignoreEscape: boolean = false;

  constructor(protected activeModal: NgbActiveModal) {
    super(activeModal);
  }

  setupBindings(): void {}

  setupViews(): void {}
}
