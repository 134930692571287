export class FormPill {
  public id: number = 0;
  public title: string = '';
  public value: any = null;
  public selected: boolean = false;

  constructor(id: number, title: string, value: any, selected: boolean) {
    this.id = id;
    this.title = title;
    this.value = value;
    this.selected = selected;
  }
}
