import { Deserializable } from '../../protocols/deserializable';

export class VerifyEmailRequest implements Deserializable {
  public email: string;
  public code: string;

  constructor(email: string, code: string) {
    this.email = email;
    this.code = code;
  }

  onDeserialize(): void {}
}
