import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BaseModalComponent } from '../../../models/base/base-modal.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationOptions } from '../../../models/shared/stylesheet/confirmation-options';
import { NavigationEnd, Router } from '@angular/router';
import { ConfirmationModalViewModel } from './confirmation-modal-view-model';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  providers: [ConfirmationModalViewModel]
})
export class ConfirmationModalComponent extends BaseModalComponent implements AfterViewInit {
  public confirmationOptions: ConfirmationOptions;
  public ignoreEscape: boolean = false;

  @ViewChild('modalBody') modalBody: HTMLDivElement | null = null;

  constructor(
    protected activeModal: NgbActiveModal,
    protected router: Router,
    protected modalService: NgbModal,
    public viewModel: ConfirmationModalViewModel
  ) {
    super(activeModal);
    this.confirmationOptions = new ConfirmationOptions();
  }

  setConfirmationOptions(opts: ConfirmationOptions) {
    this.confirmationOptions = opts;
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.modalBody?.scrollTop && this.modalBody.scrollHeight) {
      this.modalBody.scrollTop = this.modalBody.scrollHeight;
    }
  }

  cancel() {
    this.activeModal.close(false);
  }

  continue() {
    this.activeModal.close(true);
  }

  setupBindings() {
    this.bindToBackNav();
  }

  bindToBackNav() {
    const backNavSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.modalService.dismissAll();
      }
    });
    this.pushSub(backNavSub);
  }

  setupViews() {}
}
