import { LoggableAPI } from '../models/protocols/loggable-api';
import { inject, Injectable } from '@angular/core';
import { ApiClient } from './api-client';
import { Endpoints } from './endpoints';
import { Transaction } from '../models/transactions/transaction';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CustomError } from '../models/shared/custom-error';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { RemittanceTransaction } from '../models/transactions/remittance-transaction';
import { TransactionEntry } from '../models/transactions/transaction-entry';

@Injectable({
  providedIn: 'root'
})
export class TransactionsAPI implements LoggableAPI {
  private apiClient = inject(ApiClient);

  public serviceName = 'TransactionsAPI';

  public fetchTransactionById(id: number): Observable<Transaction> {
    const url = Endpoints.getTransactionById(id);
    const odataQueryOptions = new ODataQueryOptions();
    odataQueryOptions.setExpand('Status, Source, Currency, Comment, Entries($expand=Account($expand=Type))');
    return this.apiClient.getOdataObj(url, Transaction, odataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getTransactionsForRemittance(remittanceId: number): Observable<RemittanceTransaction[]> {
    const url = Endpoints.getTransactionsForRemittance(remittanceId);
    const odataQueryOptions = new ODataQueryOptions();
    return this.apiClient.getOdata(url, RemittanceTransaction, odataQueryOptions).pipe(
      map(res => res.value),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getTransactionEntriesForMemberAccount(
    memberAccountId: number,
    odataQueryOptions = new ODataQueryOptions()
  ): Observable<TransactionEntry[]> {
    const url = Endpoints.getTransactionEntriesForMemberAccount(memberAccountId);
    return this.apiClient.getOdata(url, TransactionEntry, odataQueryOptions).pipe(
      map(res => res.value),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }
}
