import { Deserializable } from '../../protocols/deserializable';

export class MostRecentInvite implements Deserializable {
  public id!: string;
  public email!: string;
  public memberId!: number;
  public employerId!: number;
  public verificationDate!: Date;
  public lastInviteSentDate!: Date;

  onDeserialize(): void {
    this.verificationDate = new Date(this.verificationDate);
    this.lastInviteSentDate = new Date(this.lastInviteSentDate);
  }
}
