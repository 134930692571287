export class RouteUtils {
  static cleanedUrlRoute(url: string): string {
    if (url.indexOf('?') > -1) {
      return url.substring(0, url.indexOf('?'));
    } else {
      return url;
    }
  }

  static cleanFragments(url: string): string {
    const fragment = RouteUtils.getFragmentFromUrl(url);
    if (fragment) {
      return url.substring(0, url.indexOf(fragment));
    }
    return url;
  }

  /** returns # in front of fragment */
  static getFragmentFromUrl(url: string): string {
    const regEx = /(?=.*)(#([^\/])+)$/g.exec(url);
    if (regEx && regEx.length > 0) {
      // Add a null check before accessing the length property.
      return regEx[0];
    }
    return '';
  }
}
