import { SubmissionStakeholder } from './member-submission';
import { CommentMention } from '../base/comment-mention';
import { BaseComment } from '../base/base-comment';

export class SubmissionComment extends BaseComment {
  public memberSubmissionId!: number;
  public visibleToMember!: boolean;
  public mentions: CommentMention[] = [];

  onDeserialize() {
    super.onDeserialize();
    if (!!this.author) {
      this.author = window?.injector?.Deserialize?.instanceOf(SubmissionStakeholder, this.author);
    }
  }
}
