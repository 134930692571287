import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { PortalService } from '../../../../services/portal/portal.service';
import { BaseComponent } from '../../../../models/base/base-component';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ScreenService } from '../../../../services/screen-service.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthComponent extends BaseComponent {
  public screenService = inject(ScreenService);

  private _loadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.default());
  public loadingOpts$ = this._loadingOpts as Observable<LoadingOptions>;

  constructor(public portalService: PortalService) {
    super();
  }

  public isMobile$ = this.screenService.isMobile$;
  public readonly versionString$ = of(require('package.json')?.version);

  setLoadingOpts(opts: LoadingOptions): void {
    this._loadingOpts.next(opts);
  }

  setupBindings(): void {}

  setupViews(): void {}
}
