import { Deserializable } from '../protocols/deserializable';
import { UserSession } from '../users/dto/user-session';
import { Address } from '../users/dto/address';
import { UserChallengeName } from '../users/enum/user-challenge.name';
import { UserChallengeParamName } from '../users/enum/user-challenge-param-name';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';
import { RoleUser } from '../roles/role';

export abstract class BaseUser implements Deserializable, UniquelyIdentifiable {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  passwordChangedDate: Date | null;
  eulaAccepted: boolean;
  eulaAcceptedDate: Date | null;
  lastLoginDate: Date | null;
  userSession: UserSession | null;
  challengeName: UserChallengeName | null;
  challengeParameters!: Map<UserChallengeParamName, string> | null;
  sessionId: string | null = null;
  address: Address;
  addressId: string;
  roles: UserRole[] = [];

  // Not returned from api, calculated on deserialization
  fullName!: string;

  constructor(
    id: number,
    firstName: string,
    lastName: string,
    email: string,
    passwordChangedDate: Date | null,
    eulaAccepted: boolean,
    eulaAcceptedDate: Date,
    lastLoginDate: Date,
    userSession: UserSession | null,
    challengeName: UserChallengeName | null,
    sessionId: string | null,
    addressId: string,
    address: Address
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.passwordChangedDate = passwordChangedDate;
    this.eulaAccepted = eulaAccepted;
    this.eulaAcceptedDate = eulaAcceptedDate;
    this.lastLoginDate = lastLoginDate;
    this.userSession = userSession;
    this.challengeName = challengeName;
    this.sessionId = sessionId;
    this.addressId = addressId;
    this.address = address;
  }

  getFullName(): string {
    return `${this?.firstName} ${this?.lastName}`;
  }

  onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.fullName = `${this?.firstName} ${this?.lastName}`.trim();
    this.challengeName = UserChallengeName[this.challengeName as keyof typeof UserChallengeName];
    this.roles = Deserialize?.arrayOf(UserRole, this?.roles);
    if (!!this.lastLoginDate) {
      this.lastLoginDate = new Date(this.lastLoginDate);
    }
    if (!!this.passwordChangedDate) {
      this.passwordChangedDate = new Date(this.passwordChangedDate);
    } else {
      this.passwordChangedDate = new Date(0);
    }
    if (!!this.eulaAcceptedDate) {
      this.eulaAcceptedDate = new Date(this.eulaAcceptedDate);
    } else {
      this.eulaAcceptedDate = new Date(0);
    }
    if (!!this.userSession) {
      this.userSession = Deserialize?.instanceOf(UserSession, this.userSession);
    }
    if (!!this.challengeParameters) {
      this.challengeParameters = new Map<UserChallengeParamName, string>(
        Object.entries(this.challengeParameters).map(([key, value]) => [
          UserChallengeParamName[key as keyof typeof UserChallengeParamName],
          value
        ])
      );
    }
  }

  getUniqueIdentifier(): string {
    const rolesIdentifier = this?.roles?.map(r => r?.getUniqueIdentifier())?.join(',');
    return `${this.id} +
    ${this.firstName} +
    ${this.lastName} +
    ${this.email} +
    ${this.passwordChangedDate} +
    ${this.eulaAccepted} +
    ${this.eulaAcceptedDate} +
    ${this.lastLoginDate} +
    ${this.userSession} +
    ${this.challengeName} +
    ${this.sessionId} +
    ${this.addressId} +
    ${this.address} +
    ${rolesIdentifier}`;
  }
}

export class UserRole implements Deserializable, UniquelyIdentifiable {
  roleId: number;
  users: RoleUser[] = [];

  constructor(roleId: number) {
    this.roleId = roleId;
  }

  onDeserialize(): void {
    this.users = window?.injector?.Deserialize?.arrayOf(RoleUser, this.users);
  }

  getUniqueIdentifier(): string {
    return `${this?.roleId} +
    ${this?.users?.join(',')}`;
  }
}
