import { Pipe, PipeTransform } from '@angular/core';
import { NavItem } from '../models/nav-item';
import { DropDownNavItem } from '../models/drop-down-nav-item';

@Pipe({
  name: 'isDropDownNavItem'
})
export class IsDropDownNavItemPipe implements PipeTransform {
  transform(value: NavItem): boolean {
    return value instanceof DropDownNavItem;
  }
}
