import { ChangeDetectionStrategy, Component, ElementRef, inject, Renderer2, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { ResizeObserver } from '@juggle/resize-observer';

@Component({
  selector: 'app-sticky-footer',
  templateUrl: './sticky-footer.component.html',
  styleUrls: ['./sticky-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StickyFooterComponent extends BaseComponent {
  @ViewChild('buffer') buffer: ElementRef | undefined;
  @ViewChild('footer') footer: ElementRef | undefined;

  private renderer = inject(Renderer2);
  private observer = new ResizeObserver(() => {
    const mainContainerPaddingInPx = 64;
    const footerHeightInPx = this.footer?.nativeElement?.offsetHeight;
    const footerHeightWithoutPadding = footerHeightInPx - mainContainerPaddingInPx + 'px';
    this.renderer.setStyle(this.buffer?.nativeElement, 'height', footerHeightWithoutPadding);
  });

  public setupBindings(): void {
    this.observer.observe(this.footer?.nativeElement);
  }

  public setupViews(): void {}

  public destroy(): void {
    super.destroy();
    this.observer.disconnect();
  }
}
