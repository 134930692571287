import { Deserializable } from '../protocols/deserializable';
import { MemberAccount } from './member-account';
import { TransactionEntryLegacyCodeType } from './transaction-entry-legacy-code-type';
import { FundType } from './fund-type';

export class TransactionEntry implements Deserializable {
  public id!: string;
  public transactionId!: number;
  public accountId!: number;
  public account!: MemberAccount;
  public fundId!: number;
  public fund!: FundType;
  public codeId!: number;
  public codeName!: string;
  public legacyCodeId!: number | null;
  public legacyCode!: TransactionEntryLegacyCodeType | null;
  public debitedUnitCount!: number | null;
  public creditedUnitCount!: number | null;
  public unitSettlementPrice!: number;
  public settledDebitValue!: number | null;
  public settledCreditValue!: number | null;

  // TODO: Not from api yet, but will be added in CSS-1478
  public postedDate: Date = new Date();

  onDeserialize() {
    if (this.account) {
      this.account = window?.injector?.Deserialize.instanceOf(MemberAccount, this.account);
    }
    if (this.legacyCode) {
      this.legacyCode = window?.injector?.Deserialize.instanceOf(TransactionEntryLegacyCodeType, this.legacyCode);
    }
    if (this.fund) {
      this.fund = window?.injector?.Deserialize.instanceOf(FundType, this.fund);
    }
  }
}
