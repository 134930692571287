import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SlidingWindowCommentsAndHistoryViewModel } from '../sliding-window-comments-and-history-view-model';
import { BaseComponent } from '../../../../../models/base/base-component';
import { Tabbable } from '../../../../../models/protocols/tabbable';

@Component({
  selector: 'app-sliding-window-history-tab',
  templateUrl: './sliding-window-history-tab.component.html',
  styleUrls: ['./sliding-window-history-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlidingWindowHistoryTabComponent extends BaseComponent implements Tabbable {
  public tabActivated$ = new BehaviorSubject<boolean>(false);

  private viewModel = inject(SlidingWindowCommentsAndHistoryViewModel);

  public history$ = this.viewModel.history$;

  setupBindings(): void {}

  setupViews(): void {}
}
