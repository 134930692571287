<div class="d-flex">
  <div class="notification-button">
    <img class="notification-icon" [src]="'assets/icons/primary/Bell-On.svg'" alt="" />
  </div>
  <div
    appThrottleClick
    (throttleClick)="navItemSelected()"
    [ngClass]="{
      active: isNavItemActive$ | async,
      animating: animating$ | async
    }"
    class="nav-item custom-nav-item">
    <div class="nav-item-label" [ngClass]="{ 'multi-line-label': multiLineNavItem$ | async }">
      {{ navItem.name }}
    </div>
    <div *ngIf="isInternalUser$ | async" class="nav-item-icon">
      <app-user-profile-badge
        [initials]="(user$ | async)?.initials"
        [profileColor]="(user$ | async)?.profileColour"
        [textColor]="(user$ | async)?.textColor">
      </app-user-profile-badge>
    </div>
    <div *ngIf="hasIcons$ | async" class="nav-item-icon">
      <img [src]="navItem.iconSrc" alt="icon" class="nav-bar-icon" />
      <img [src]="navItem.iconFilledSrc" alt="icon-filled" class="nav-bar-icon-filled" />
    </div>
  </div>
</div>
