import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { inject } from '@angular/core';
import { SubmissionsDomainModel } from '../../../../../domainModels/submissions-domain-model';
import { ToastService } from '../../../../../services/toast-service';
import { ScreenService } from '../../../../../services/screen-service.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, switchMap, take } from 'rxjs/operators';
import { SubmissionCreated } from '../../../../../models/submissions/submission-created';
import { AttachmentFile } from '../../../../../models/account/dto/attachment-file';
import { CustomError } from '../../../../../models/shared/custom-error';
import { UserFile } from '../../../../../models/account/dto/user-file';
import { SubmissionRequest } from '../../../../../models/account/requests/submissionRequests/submission-request';
import { Router } from '@angular/router';
import { AttachmentType } from '../../../../../models/enum/dto/attachment-type';
import { CreateAttachmentRequest } from '../../../../../models/account/requests/create-attachment-request';

export abstract class AbstractSubmissionFormViewModel<T extends SubmissionRequest> extends BaseModalViewModel {
  protected constructor(router: Router, ngbModal: NgbModal) {
    super(router, ngbModal);
  }

  protected submissionsDomainModel = inject(SubmissionsDomainModel);
  protected toastService = inject(ToastService);
  protected screenService = inject(ScreenService);
  protected activeModal = inject(NgbActiveModal);

  protected abstract createSubmissionMethod: (req: T) => Observable<SubmissionCreated>;
  protected abstract successMessage: string;

  protected loadingMessage = $localize`Submitting...`;

  protected submissionObserver = {
    next: () => {
      this.toastService.publishSuccessMessage($localize`Success!`, this.successMessage);
      this.activeModal.close(true);
    },
    error: (err: any) => {
      this.toastService.publishErrorMessage($localize`Error`, err.message);
    }
  };

  public submitRequest(req: T, files?: UserFile[]): void {
    this._loadingOpts.addRequest(this.loadingMessage);
    if (files?.length) {
      this.submitRequestWithAttachments(req, files);
    } else {
      this.submitRequestWithoutAttachments(req);
    }
  }

  protected submitRequestWithAttachments(req: T, files: UserFile[]): void {
    req.attachments = files.map(uf => {
      const attachmentType = uf.file.type === 'application/pdf' ? AttachmentType.PDF : AttachmentType.Image;
      return new CreateAttachmentRequest(attachmentType, uf.file);
    });

    this.createSubmissionMethod(req)
      .pipe(
        switchMap((res: SubmissionCreated) => {
          if (!res.attachments.length) return of(null);

          const attachmentUploads = files.map(uf => {
            const attachment = res.attachments.find(a => a.attachment.fileName === uf.file.name);
            if (!attachment) return of(null);

            const attachmentFile = new AttachmentFile(
              uf.file,
              attachment.attachmentId,
              uf.fileUrl,
              attachment.attachment.links[0].presignedUrl
            );
            return this.submissionsDomainModel.uploadAttachmentToS3(attachmentFile);
          });

          return forkJoin(attachmentUploads);
        }),
        catchError((error: CustomError) => throwError(() => error)),
        finalize(() => {
          this._loadingOpts.removeRequest(this.loadingMessage);
        }),
        take(1)
      )
      .subscribe(this.submissionObserver);
  }

  protected submitRequestWithoutAttachments(req: T): void {
    this.createSubmissionMethod(req)
      .pipe(finalize(() => this._loadingOpts.removeRequest(this.loadingMessage)))
      .subscribe(this.submissionObserver);
  }

  public duplicateFilesAdded(): void {
    this.toastService.publishErrorMessage(
      $localize`File Name Already Exists`,
      $localize`Duplicate files are not allowed.`
    );
  }
}
