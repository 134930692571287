import { OdataOrderbyGenerator } from '../../../interfaces/odata-orderby-generator';

export class ReleaseNotesTableOrderbyRequest implements OdataOrderbyGenerator {
  public version: string | null = null;
  public releaseDate: string | null = null;
  public portalTypeId: string | null = null;
  public status: string | null = null;

  [key: string]: any; // Index signature to allow dynamic property querying

  constructor(orderByString: string | null = null) {
    if (orderByString) {
      this.setOrderByFromString(orderByString);
    }
  }

  // Allows us to populate the req object from a string such as "firstName asc"
  setOrderByFromString(orderByString: string): void {
    // Split the input string into property and order
    const [property, order] = orderByString.split(' ');

    if (property === 'status') return;

    // Check if the property exists in the class
    if (this.hasOwnProperty(property)) {
      // Set the property value
      this[property] = order;
    } else {
      console.error(`Property ${property} does not exist in ReleaseNotesTableOrderbyRequest.`);
    }
  }

  getOrderByString(): string | null {
    const orderByClauses = [];

    for (const property in this) {
      if (this[property] !== null && typeof this[property] === 'string') {
        orderByClauses.push(`${property} ${this[property]}`);
      }
    }

    if (orderByClauses.length > 1) {
      return orderByClauses.join(',');
    } else if (orderByClauses.length === 1) {
      return orderByClauses[0];
    } else {
      return null;
    }
  }
}
