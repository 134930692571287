import { TypeCheckUtils } from './type-check-utils';

export {};
declare global {
  interface Map<K, V> {
    deepCopy(): Map<K, V>;
    shallowCopy(): Map<K, V>;
  }
}

Map.prototype.deepCopy = function <K, V>(): Map<K, V> {
  const copy = new Map<any, any>();
  for (const [key, val] of this) {
    const isPrimitive = TypeCheckUtils.isPrimitive(val);
    if (isPrimitive) {
      copy.set(key, JSON.parse(JSON.stringify(val)));
    } else if (val instanceof Array) {
      copy.set(key, val.deepCopy());
    } else if (val instanceof Map) {
      copy.set(key, val.deepCopy());
    } else if (typeof (val as any)?.onDeserialize === 'function') {
      copy.set(key, window?.injector?.Deserialize?.instanceOf((val as any).constructor, val));
    } else {
      copy.set(key, Object.assign(Object.create((val as any).prototype), val));
    }
  }
  return copy;
};

Map.prototype.shallowCopy = function <K, V>(): Map<K, V> {
  return new Map(this);
};
