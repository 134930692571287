import { AfterContentInit, ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: CdkStepper, useExisting: forwardRef(() => StepperComponent) }]
})
export class StepperComponent extends CdkStepper implements AfterContentInit {
  @Input() public showSteps = true;
  @Input() public stepperMaxWidth = '100%';

  public setSelectedStep(index: number): void {
    if (!this.linear) {
      this.selectedIndex = index;
    }
  }

  public ngAfterContentInit(): void {
    super.ngAfterContentInit();
    if (this.linear) {
      this.steps.forEach((step, index) => {
        if (index < this.selectedIndex) {
          step.completed = true;
        }
      });
    }
  }
}
