import { Deserializable } from '../protocols/deserializable';
import { SubmissionAttachment } from './submission-attachment';

export class SubmissionCreated implements Deserializable {
  public id: number = -1;
  public memberId: number = -1;
  public submissionTypeId: number = -1;
  public submissionTypeName: string = '';
  public statusId: number = -1;
  public status: string = '';
  public idPrefix: string = '';
  public idSuffix: string = '';
  public createdDate: Date = new Date();
  public completedDate: Date = new Date();
  public expiringSoon: boolean = false;
  public comment: string = '';
  public attachments: SubmissionAttachment[] = [];

  onDeserialize() {
    this.createdDate = new Date(this.createdDate);
    this.completedDate = new Date(this.completedDate);
  }
}
