import { RemittanceFileRecord } from './remittance-file-record';
import { DateUtils } from '../../utils/date-utils';
import { Deserializable } from '../protocols/deserializable';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';

export class ContributionEntry implements Deserializable, UniquelyIdentifiable {
  id!: string;
  contributionId!: string;
  codeId!: number;
  payPeriodEndDate!: string;
  taxationYear!: string;
  amount!: number;
  salary!: number;
  designatedUnitId!: number;

  constructor(record?: RemittanceFileRecord) {
    if (record) {
      this.codeId = record['ENTRY CODE'];
      this.payPeriodEndDate = DateUtils.simpleDateStringtoUTCDateString(record['PAY PERIOD END DATE']);
      this.taxationYear = DateUtils.yearToUTCDateString(record['TAXATION YEAR']);
      this.amount = record.AMOUNT;
      this.salary = record.SALARY;
      this.designatedUnitId = record['DESIGNATED UNIT ID'];
    }
  }

  onDeserialize() {}

  getUniqueIdentifier(): string {
    return `${this.id}-
    ${this.contributionId}-
    ${this.codeId}-
    ${this.payPeriodEndDate}-
    ${this.taxationYear}-
    ${this.amount}-
    ${this.salary}-
    ${this.designatedUnitId}`;
  }
}
