import { ChangeDetectorRef, Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ApplicationProgressItem } from '../../../../models/shared/application-progress-item';
import { BaseComponent } from '../../../../models/base/base-component';
import { Router } from '@angular/router';

export enum ApplicationProcessItemStatus {
  Active = 0,
  Complete = 1,
  Pending = 2
}

@Component({
  selector: 'app-application-progress-item',
  templateUrl: './application-progress-item.component.html',
  styleUrls: ['./application-progress-item.component.scss']
})
export class ApplicationProgressItemComponent extends BaseComponent implements OnChanges {
  private router = inject(Router);
  @Input() item!: ApplicationProgressItem;

  public iconSrc!: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  setIconSrc(item: ApplicationProgressItem): void {
    if (item.status === ApplicationProcessItemStatus.Pending) {
      this.iconSrc = 'assets/icons/grey/Clock.svg';
    } else if (item.status === ApplicationProcessItemStatus.Complete) {
      this.iconSrc = 'assets/icons/primary/Circle Check.svg';
    } else {
      this.iconSrc = 'assets/icons/yellow/Circle Arrow Right.svg';
    }
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setIconSrc(this.item);
  }

  setupBindings(): void {}

  setupViews(): void {}

  routeTo(): void {
    if (!this.item.routeTo) return;
    this.router.navigate([this.item.routeTo]).then();
  }

  protected readonly ApplicationProcessItemStatus = ApplicationProcessItemStatus;
}
