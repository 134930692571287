// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environmentName: 'dev',
  mainServiceUrl: 'https://dev.api.csspension.com',
  mainSocketUrl: 'wss://866yqjcu3d.execute-api.ca-central-1.amazonaws.com/dev',
  disableEncryptionKey: '1352620D-9CBE-4E15-AD2F-BB8ABC3FA802',
  encryptionKey: '4030F2362AEA41F1ACA46788604D69B5',
  encryptionIV: 'MstZX0hgs5@e8rI8',
  encryptionBypass: true
};
