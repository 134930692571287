import { PhoneDropdownItem } from '../models/shared/phone-dropdown-item';

export class PhoneNumberUtils {
  /*
   * This function will take a phone number and format it, and if specified,
   * replace a specified number of digits with x's.
   * @param phoneNumber The phone number to be formatted. in the format +15199959595
   * @param showDigits The number of digits to show.
   */
  static formatPhoneNumber(phoneNumber: string, showDigits?: number): string {
    const countryCode = phoneNumber.slice(0, 2);
    const mainNumber = phoneNumber.slice(2);
    if (!showDigits) showDigits = mainNumber.length;
    let formattedNumber = `${countryCode} `;

    for (let i = 0; i < mainNumber.length; i++) {
      if (i < mainNumber.length - showDigits) {
        formattedNumber += 'x';
      } else {
        formattedNumber += mainNumber[i];
      }
    }
    formattedNumber = formattedNumber.replace(/^(.{6})(.{3})(.*)$/, '$1-$2-$3');

    return formattedNumber;
  }

  /*
   * This function will take a phone number and return it in the format
   * +1 (519) 995-9595
   * @param phoneNumber The phone number to be formatted. in the format +15199959595
   * @param prefix The country code prefix to be ignored and then added back after replacement.
   */
  static formatPhoneNumberWithParentheses(phoneNumber: string, prefix: string): string {
    const mainNumber = phoneNumber?.slice(prefix?.length);
    const formattedNumber = mainNumber?.replace(/^(.{3})(.{3})(.*)$/, '($1) $2-$3');
    return `${prefix} ${formattedNumber}`;
  }

  static removeCodePrefix(phoneNumber: string, countryCode: string, countryCodes: PhoneDropdownItem[]): string {
    const prefix = countryCodes?.find(c => c.countryCode === countryCode)?.prefix;
    return phoneNumber.replace(prefix ?? '', '');
  }
}
