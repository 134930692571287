import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { AsyncValidatorFn, ValidatorFn, Validators } from '@angular/forms';
import { MemberUserSearchResult } from '../../../../models/account/dto/member-user-search-result';
import { Observable } from 'rxjs';
import { DispersedFormGroupService, ReactiveFormItemComponent } from '@csspension/reactive-form';
import { map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-member-search-input',
  templateUrl: './member-search-input.component.html',
  providers: [
    {
      provide: ReactiveFormItemComponent,
      useExisting: forwardRef(() => MemberSearchInputComponent)
    }
  ]
})
export class MemberSearchInputComponent extends ReactiveFormItemComponent implements OnChanges {
  constructor(elementRef: ElementRef, cdr: ChangeDetectorRef, dispersedFormGroupService: DispersedFormGroupService) {
    super(elementRef, dispersedFormGroupService, cdr);
  }

  @Input() odataFunction!: (searchString: string) => Observable<MemberUserSearchResult[]>;
  @Input() selectedMember: MemberUserSearchResult | null | undefined;
  @Input() required: boolean = true;
  @Output() memberSelected = new EventEmitter<MemberUserSearchResult | null>();

  public listenForFormReset$ = this.reactiveFormGroupComponent$
    .pipe(
      switchMap(form =>
        form.formWasReset$.pipe(
          map(() => {
            this.handleSelectedMemberChange(null);
            this.memberSelected.next(null);
          })
        )
      ),
      takeUntil(this.onDestroy)
    )
    .subscribe();

  public getValidators(): ValidatorFn[] {
    if (this.required) {
      return [Validators.required];
    } else {
      return [];
    }
  }

  public getAsyncValidators(): AsyncValidatorFn[] {
    return [];
  }

  public setBindingProperty(): void {
    this.setDataInBindingProperty(this.getMyValue());
  }

  private markAsDirty(): void {
    this?.getSelfAsFormItem()?.markAsDirty();
  }

  public ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.selectedMember) {
      this.handleSelectedMemberChange(this.selectedMember);
    }
  }

  private handleSelectedMemberChange(selectedMember: MemberUserSearchResult | null | undefined): void {
    this.handleInputChange(selectedMember);
    this.getSelfAsFormItem()?.patchValue(selectedMember);
    this.markAsDirty();
  }

  protected checkParentElement(): void {}
}
