<div class="auth-flow-logo-container">
  <img src="assets/logo/dark/css-logo.svg" alt="CSS Logo" i18n-alt />
</div>

<div class="auth-flow-title">
  {{ authFlowTitle$ | async }}
</div>

<div class="auth-flow-subtext">
  <div class="header" i18n>Enter Verification Code</div>
  <div>
    <span class="subtext" i18n>Please enter the verification code that was sent to </span>
    <span class="auth-highlighted-text">{{ userEmail$ | async }}</span> <span class="subtext" i18n> below.</span>
  </div>
</div>

<lib-reactive-form-group #form [bindTo]="req$ | async" (formSubmitted$)="formSubmitted($event)">
  <lib-reactive-form-text
    [inputName]="'verificationCode'"
    label="Verification Code"
    i18n-label
    [bindingProperty]="'code'"
    [required]="true"
    [showRequiredAstrix]="false"
    [placeholder]="''">
  </lib-reactive-form-text>
</lib-reactive-form-group>

<div class="sign-in-button-container">
  <lib-button-primary [style.width.%]="100" (buttonClicked)="form.submitForm()" i18n> Verify Email</lib-button-primary>
</div>
<div class="support-button-container">
  <span class="support-button" i18n>Having trouble ?</span>
  <button class="paddingless-text-button mt-2" (click)="openContactPage()" i18n>Click here for support</button>
</div>
