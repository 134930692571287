<div
  #container
  matRipple
  appThrottleClick
  class="look-ahead-item"
  [matRippleDisabled]="disabled$ | async"
  [ngClass]="{ selected: selected && !(disabled$ | async), disabled: (disabled$ | async) }"
  [ngbTooltip]="(disabled$ | async) ? (disabledMessage$ | async) : null"
  (throttleClick)="handleClick()">
  <ng-container *ngTemplateOutlet="customItemTemplate || defaultItemTemplate; context: { $implicit: item }">
  </ng-container>
  <ng-template #defaultItemTemplate>
    {{ lookAheadString$ | async }}
  </ng-template>
</div>
