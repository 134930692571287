import { Injectable } from '@angular/core';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { BehaviorSubject, Subject } from 'rxjs';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Asset } from '../../../../../models/asset/dto/asset';
import { CustomFile } from '../../../../../models/shared/custom-file';

@Injectable()
export class UploadProfilePictureViewModel extends BaseModalViewModel {
  existingProfilePicture!: Asset;
  file!: CustomFile;
  protected _loadingOpts = new BehaviorSubject(this.getLoadingSpinner());

  public dismissModalSubject: Subject<boolean> = new Subject<boolean>();

  constructor(public router: Router, public modalService: NgbModal) {
    super(router, modalService);
  }

  private getLoadingSpinner(): LoadingOptions {
    const opts = LoadingOptions.default();
    opts.loadingText = 'Uploading';
    opts.isLoading = false;
    opts.fullscreen = false;
    return opts;
  }

  uploadProfilePicture() {}

  deleteProfilePicture() {}
}
