import { inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { ToastService } from '../toast-service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class SessionExpiryInterceptor implements HttpInterceptor {
  private toastService = inject(ToastService);
  private router = inject(Router);

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(err => {
        // If a 403 error is encountered, it indicates that the session has expired
        // and the user should be redirected to the login page to restart the signup process
        if (err instanceof HttpErrorResponse && err.status === 403) {
          this.toastService.publishErrorMessage($localize`Your session has expired. Please log in again to continue.`);
          this.router.navigate(['auth/sign-in']).then(() => {});
          return of();
        }
        // Pass other errors along
        return throwError(() => err);
      })
    );
  }
}
