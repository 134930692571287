import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-base-card',
  templateUrl: './base-card.component.html',
  styleUrls: ['./base-card.component.scss']
})
export class BaseCardComponent {
  @Input() public height: string = '100%';
  @Input() public maxWidth: string = '100%';
  @Input() public maxHeight: string = '100%';
  @Input() public display: string = 'block';
  @Input() public width: string = '100%';
  @Input() public padding: string = '2rem';
  @Input() public backgroundColor: string = '#ffffff';
  @Input() public borderColor: string = '#cacaca';
}
