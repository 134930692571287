<app-header-container>
  <div class="d-flex flex-column gap-3" [style.background-color]="'#fff'" *ngIf="!isLoading; else loading">
    <app-breadcrumbs *ngIf="!isMobile" [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    <div class="d-flex justify-content-between">
      <div class="header-text">{{ title }}</div>
      <ng-content></ng-content>
    </div>
  </div>
  <div *ngIf="tabs.length > 0" class="header-row tab-bar">
    <app-tab-bar [tabs]="tabs" (selectedTab)="selectedTab.emit($event)"></app-tab-bar>
  </div>
</app-header-container>

<ng-template #loading>
  <div class="d-flex flex-column gap-3">
    <lib-loading-shimmer [height]="'1rem'"></lib-loading-shimmer>
    <lib-loading-shimmer [height]="'2rem'"></lib-loading-shimmer>
  </div>
</ng-template>
