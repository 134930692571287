import { Deserializable } from '../../protocols/deserializable';
import { EmploymentStatusType } from '../../employers/employer-history/types/employment-status-type';
import { LookAheadItem } from '../../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';

export class EmployeeTableUser implements Deserializable, LookAheadItem {
  public id!: number;
  public firstName!: string;
  public lastName!: string;
  public lastContributionDate!: Date;
  public employmentStatusId!: number;
  public employmentStatus!: EmploymentStatusType;

  // Not returned from API
  public fullName!: string;

  onDeserialize(): void {
    this.fullName = `${this?.firstName} ${this?.lastName}`.trim();

    if (!!this.employmentStatus) {
      this.employmentStatus = window?.injector?.Deserialize?.instanceOf(EmploymentStatusType, this.employmentStatus);
    }

    if (!!this.lastContributionDate) {
      this.lastContributionDate = new Date(this.lastContributionDate);
    }
  }

  // Look ahead item interface
  lookAheadDisabled(): boolean {
    return false;
  }

  lookAheadDisabledMessage(): string {
    return 'disabled';
  }
}
