import { Component, inject } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { NavbarViewModel } from '../../navbar-view-model';

@Component({
  selector: 'app-desktop-nav',
  templateUrl: './desktop-nav.component.html',
  styleUrls: ['./desktop-nav.component.scss']
})
export class DesktopNavComponent extends BaseComponent {
  private viewModel = inject(NavbarViewModel);

  public navItems$ = this.viewModel.navItems$;

  public navigateToRoot(): void {
    this.viewModel.navigateToRoot();
  }

  setupBindings(): void {}

  setupViews(): void {}
}
