<div
  class="alert-container"
  [style.background-color]="backgroundColour$ | async"
  [style.border-color]="borderColor$ | async">
  <div class="alert-body">
    <div class="alert-title">{{ title$ | async }}</div>
    <div class="alert-description">
      {{ description$ | async }}
    </div>
    <div class="button-container">
      <lib-button-neutral (buttonClicked)="handleClick()" *ngIf="!!(buttonText$ | async)">{{
        buttonText$ | async
      }}</lib-button-neutral>
    </div>
  </div>
</div>
