import { Component, EventEmitter, inject, Output } from '@angular/core';
import { BaseAuthComponent } from '../../../../../models/base/base-auth-component';
import { ActivatedRoute, Router } from '@angular/router';
import { InternalUserAuthViewModel } from '../../../viewModels/internal-user-auth-view-model.service';
import { InternalUserAuthFlow } from '../../../../../models/users/enum/internal-user-auth-flow.enum';
import { SessionService } from '../../../../../services/session-service';
import { LoadingOptions } from '../../../../../models/shared/loading-options';

@Component({
  selector: 'app-internal-user-auth',
  templateUrl: './internal-user-auth.component.html',
  styleUrls: ['./internal-user-auth.component.scss']
})
export class InternalUserAuthComponent extends BaseAuthComponent {
  private route = inject(ActivatedRoute);
  @Output() loadingOpts = new EventEmitter<LoadingOptions>();

  constructor(public viewModel: InternalUserAuthViewModel, private router: Router, private session: SessionService) {
    super();
  }

  protected readonly AuthFlow = InternalUserAuthFlow;

  parseAuthFlow() {
    const urlSub = this.route.url.subscribeWhileAlive({
      owner: this,
      next: url => {
        switch (url[url.length - 1].path) {
          case 'sign-in': {
            this.viewModel.setAuthFlow(this.AuthFlow.SignIn);
            break;
          }
          case 'forgot-password': {
            this.viewModel.setAuthFlow(this.AuthFlow.ForgotPassword);
            break;
          }
          case 'reset-password': {
            this.viewModel.setAuthFlow(this.AuthFlow.ResetPassword);
            break;
          }
          case 'new-password': {
            this.viewModel.setAuthFlow(this.AuthFlow.SetNewPassword);
            break;
          }
          case 'internal-user-auth-setup': {
            this.viewModel.setAuthFlow(this.AuthFlow.InternalUserSetupMfa);
            break;
          }
          case 'internal-user-choose-password': {
            this.viewModel.setAuthFlow(this.AuthFlow.InternalUserChoosePassword);
            break;
          }
          case 'internal-user-verify-code': {
            this.viewModel.setAuthFlow(this.AuthFlow.InternalVerifyCode);
            break;
          }
        }
      }
    });

    this.pushSub(urlSub);
  }

  setupBindings(): void {
    this.parseAuthFlow();
    this.viewModel.loadingOpts$.subscribeWhileAlive({
      owner: this,
      next: opts => this.loadingOpts.emit(opts)
    });
  }

  setupViews(): void {}
}
