import { Cachable } from '../protocols/cachable';
import { Deserializable } from '../protocols/deserializable';
import { DateUtils } from '../../utils/date-utils';
import { CachePolicy } from '../enum/shared/cachable-image-policy.enum';

export class GenericCacheItem implements Deserializable, Cachable {
  public key!: string;
  public item!: string | number;
  public cachedTime!: number;

  constructor() {}

  cacheExpirySeconds(): number {
    // Default items should expire in 30 days
    return DateUtils.unixOneMonth();
  }

  cacheKey(): string {
    return this.key;
  }

  onDeserialize() {}

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

  imageCachePolicy(): CachePolicy {
    return CachePolicy.Session;
  }
}
