import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskSinNumber'
})
export class MaskSinNumberPipe implements PipeTransform {
  transform(value: string, showFullSin: boolean, isSinNumber: boolean): string {
    if (!value) return '-';
    if (isSinNumber && !showFullSin) {
      // Mask the SIN number
      return '\u23FA'.repeat(3) + ' ' + '\u23FA'.repeat(3) + ' ' + value.slice(-3);
    } else {
      return value.slice(0, 3) + ' ' + value.slice(3, 6) + ' ' + value.slice(-3);
    }
  }
}
