import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { LookAheadItem } from './protocol/look-ahead-item';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-look-ahead-item',
  templateUrl: './look-ahead-item.component.html',
  styleUrls: ['./look-ahead-item.component.scss']
})
export class LookAheadItemComponent implements OnChanges {
  @Input() item!: LookAheadItem;
  @Input() selected!: boolean;
  @Input() lookAheadProperties!: string[];
  @Input() lookAheadSeparator: string = ' - ';
  @Input() customItemTemplate: TemplateRef<any> | undefined;
  @Output() lookAheadItemClicked = new EventEmitter<any>(true);

  @ViewChild('container') container!: ElementRef;

  private _item = new BehaviorSubject<LookAheadItem>(this.item);
  public item$ = this._item as Observable<LookAheadItem>;

  private _lookAheadProperties = new BehaviorSubject<string[]>([]);
  public lookAheadProperties$ = this._lookAheadProperties as Observable<string[]>;

  public lookAheadString$ = combineLatest([this.lookAheadProperties$, this.item$]).pipe(
    map(([lookAheadProperties, item]) => {
      return lookAheadProperties
        .map(property => item[property])
        .filter(value => value !== null && value !== undefined)
        .join(this.lookAheadSeparator);
    })
  );

  public disabled$ = this.item$.pipe(map(i => i?.lookAheadDisabled()));
  public disabledMessage$ = this.item$.pipe(map(i => i?.lookAheadDisabledMessage()));

  constructor() {}

  handleClick() {
    this.disabled$.once(disabled => {
      this.lookAheadItemClicked.emit(this.item);
    });
  }

  click() {
    this.container.nativeElement.click();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item) {
      this._item.next(this.item);
    }
    if (changes.lookAheadProperties) {
      this._lookAheadProperties.next(this.lookAheadProperties);
    }
  }
}
