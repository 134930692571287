import { inject, Injectable } from '@angular/core';
import { AbstractSubmissionFormViewModel } from '../submission-forms/abstract-submission-form/abstract-submission-form-view-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangePersonalInfoRequest } from '../../../../models/users/requests/submissionRequests/change-personal-info-request';
import { map } from 'rxjs/operators';
import { DropDownItem } from '../../../../models/shared/stylesheet/drop-down-item';
import { TypeService } from '../../../../services/type/type-service';

@Injectable()
export class PersonalInfoSubmissionFormViewModel extends AbstractSubmissionFormViewModel<ChangePersonalInfoRequest> {
  private typeService = inject(TypeService);

  constructor(public router: Router, public modalService: NgbModal) {
    super(router, modalService);
  }

  public genderDropdowns$ = this.typeService.genders$.pipe(
    map(genders => {
      return genders.map(g => new DropDownItem(g?.name, g?.id));
    })
  );

  public pronounDropdowns$ = this.typeService.pronouns$.pipe(
    map(pronouns => {
      return pronouns.map(p => new DropDownItem(p?.name, p?.id));
    })
  );

  protected createSubmissionMethod = (req: ChangePersonalInfoRequest) =>
    this.submissionsDomainModel.createMemberPersonalInfoChangeSubmission(req);

  protected successMessage = $localize`Personal Info Change Request Submitted`;
}
