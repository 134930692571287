<div class="modal-body sticky eula-modal">
  <div class="sticky-header-container">
    <div class="modal-header d-flex flex-row">
      <div class="modal-title">{{ 'Terms and Conditions' | localization }}</div>
      <app-close-button (closeClicked)="close(null)"></app-close-button>
    </div>
  </div>
  <div class="body-container">
    <span class="subtext" [innerHTML]="eulaText | trustAsHtml"> </span>
  </div>
</div>
