import { AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';
import { Directive } from '@angular/core';

export function eulaValidator(): ValidatorFn {
  return (control: AbstractControl): { [p: string]: any } | null => {
    const { value } = control;
    if (!value) {
      return { [EulaRequiredValidatorDirective.key]: true };
    } else {
      return null;
    }
  };
}

@Directive({
  selector: '[appEulaRequiredValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EulaRequiredValidatorDirective, multi: true }]
})
export class EulaRequiredValidatorDirective {
  static key: string = 'eulaRequired';

  validate(control: AbstractControl): { [key: string]: any } {
    // @ts-ignore
    return eulaValidator()(control);
  }
}
