import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, skip, Subject } from 'rxjs';
import { debounceTime, map, shareReplay } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { ToastService } from '../../../../services/toast-service';
import { PermissionDomainModel } from '../../../../domainModels/permission-domain-model';
import { SlidingWindowOptions } from '../../../../models/shared/sliding-window-options';
import { OdataFilterGenerator } from '../../../../interfaces/odata-filter-generator';
import { ODataQueryOptions } from '../../../../models/shared/odata-query-options';
import { CustomError } from '../../../../models/shared/custom-error';
import { SubmissionsDomainModel } from '../../../../domainModels/submissions-domain-model';
import { MemberSubmissionsFilterRequest } from '../../../../models/shared/odata-filter-requests/member-submission-filter-request';
import { MemberSubmissionsOrderbyRequest } from '../../../../models/shared/odata-sorting-requests/member-submissions-orderby-request';
import { MemberSubmission, SubmissionStakeholder } from '../../../../models/submissions/member-submission';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateUtils } from '../../../../utils/date-utils';
import { FormPill } from '../../../../models/shared/form-pill';
import { SubmissionsAPI } from '../../../../api/submissions-api';
import { PillItem } from '../../../../models/shared/pill-item';
import { SubmissionTypeEnum } from '../../../../models/account/enum/submission-type-enum';
import { StringExtensions } from '../../../../utils/string.extensions';
import { DropDownItem } from '../../../../models/shared/stylesheet/drop-down-item';

@Injectable()
export class MemberSubmissionsDataTableViewModel extends BaseViewModel {
  constructor(
    private toastService: ToastService,
    private router: Router,
    // Required to inject the SubmissionsAPI for arrow function binding
    private submissionsApi: SubmissionsAPI
  ) {
    super();
  }

  private submissionsDomainModel = inject(SubmissionsDomainModel);
  private permissionDomainModel = inject(PermissionDomainModel);
  private activatedRoute = inject(ActivatedRoute);

  public memberSubmissions$ = this.submissionsDomainModel.allMemberSubmissions$.pipe(skip(1));
  public memberSubmissionsTotalCount$ = this.submissionsDomainModel.allMemberSubmissionsTotalCount$;

  private _showFilterWindow = new BehaviorSubject<boolean>(false);
  public readonly showFilterWindow$ = this._showFilterWindow as Observable<boolean>;

  private _showSearchBars = new BehaviorSubject<boolean>(false);
  public readonly showSearchBars$ = this._showSearchBars as Observable<boolean>;

  private _activeFilterCount = new BehaviorSubject<number>(0);
  public readonly activeFilterCount$ = this._activeFilterCount as Observable<number>;

  private _activeSearchCriteria = new BehaviorSubject<number>(0);
  public readonly activeSearchCriteria$ = this._activeSearchCriteria as Observable<number>;

  public filterWindowOptions$ = of(
    new SlidingWindowOptions($localize`Filters`, $localize`Clear All`, $localize`Apply Filters`)
  );

  public roles$ = this.permissionDomainModel.roles$;

  private _loadingSubmissions = new BehaviorSubject(false);
  public loadingSubmissions$ = this._loadingSubmissions as Observable<boolean>;

  private _memberSubmissionsFilterRequest = new BehaviorSubject<MemberSubmissionsFilterRequest>(
    new MemberSubmissionsFilterRequest()
  );
  public readonly memberSubmissionsFilterRequest$ = this._memberSubmissionsFilterRequest
    .distinctUniquelyIdentifiable()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  private _memberSubmissionsOrderbyRequest = new BehaviorSubject<MemberSubmissionsOrderbyRequest>(
    new MemberSubmissionsOrderbyRequest()
  );
  public readonly memberSubmissionsOrderbyRequest$ = this
    ._memberSubmissionsOrderbyRequest as Observable<MemberSubmissionsOrderbyRequest>;

  private _currPageNumber = new BehaviorSubject<number>(0);
  public readonly currPageNumber$ = this._currPageNumber as Observable<number>;

  private _pageSize = new BehaviorSubject<number>(10);
  public readonly pageSize$ = this._pageSize as Observable<number>;

  private _searchCriteriaChangedSubject = new Subject<MemberSubmissionsFilterRequest>();
  public readonly searchCriteriaChanged$ = this
    ._searchCriteriaChangedSubject as Observable<MemberSubmissionsFilterRequest>;

  private _selectedStartDate = new BehaviorSubject<string>('');
  public readonly selectedStartDate$ = this._selectedStartDate as Observable<string>;

  private _selectedEndDate = new BehaviorSubject<string>('');
  public readonly selectedEndDate$ = this._selectedEndDate as Observable<string>;

  private _selectedDates = new BehaviorSubject<NgbDate[]>([]);
  public readonly selectedDates$ = this._selectedDates as Observable<NgbDate[]>;

  public clearDates$ = new Subject<void>();

  public submissionTypeDropdowns$ = this.submissionsDomainModel.submissionTypes$.pipe(
    map(types => types.map(t => new DropDownItem(t.name, t.id)))
  );

  public statusFilterPills$ = this.submissionsDomainModel.submissionStatuses$.pipe(
    map(statuses => {
      return statuses.flatMap(s => {
        if (s?.subStatuses?.length > 0) {
          return s?.subStatuses.map(st => {
            return new FormPill(st.id, st.name, st.id, false);
          });
        }
        return [];
      });
    })
  );

  public substatusDropdowns$ = this.submissionsDomainModel.submissionStatuses$.pipe(
    map(statuses => {
      return statuses.flatMap(s => {
        if (s?.subStatuses?.length > 0) {
          return s?.subStatuses;
        }
        return [];
      });
    })
  );

  private _selectedSubmissionOwners = new BehaviorSubject<SubmissionStakeholder[]>([]);
  public readonly selectedSubmissionOwners$ = this._selectedSubmissionOwners as Observable<SubmissionStakeholder[]>;

  public submissionOwnerPills$ = this.selectedSubmissionOwners$.pipe(
    map(owners => {
      return owners.map(o => {
        return new PillItem(o.fullName, true, true, false, undefined, o);
      });
    })
  );

  private _selectedSubmissionApprovers = new BehaviorSubject<SubmissionStakeholder[]>([]);
  public readonly selectedSubmissionApprovers$ = this._selectedSubmissionApprovers as Observable<
    SubmissionStakeholder[]
  >;

  public submissionApproverPills$ = this.selectedSubmissionApprovers$.pipe(
    map(owners => {
      return owners.map(o => {
        return new PillItem(o.fullName, true, true, false, undefined, o);
      });
    })
  );

  public submissionOwnerSearchFunc = this.submissionsDomainModel.getFilteredMemberSubmissionOwners.bind(this);
  public submissionApproverSearchFunc = this.submissionsDomainModel.getFilteredMemberSubmissionApprovers.bind(this);

  private searchCriteriaChangedSubscription = this.searchCriteriaChanged$
    .distinctUniquelyIdentifiable()
    .pipe(debounceTime(500))
    .subscribeWhileAlive({
      owner: this,
      next: req => {
        this._memberSubmissionsFilterRequest.next(req);
        this._activeSearchCriteria.next(req.getSearchCount());
      }
    });

  private listenForQueryParams = this.activatedRoute.queryParams.subscribeWhileAlive({
    owner: this,
    next: params => {}
    // TODO: Fill this out when we need to filter via query params
  });

  private listenToFetchMemberSubmissions = combineLatest([
    this.memberSubmissionsFilterRequest$,
    this.memberSubmissionsOrderbyRequest$,
    this.currPageNumber$,
    this.pageSize$
  ])
    .pipe(debounceTime(100))
    .subscribe(([filterRequest, orderbyRequest, currPage, pageSize]) => {
      this.fetchMemberSubmissions(filterRequest, orderbyRequest, currPage, pageSize);
    });

  public toggleFilterWindow(): void {
    this.showFilterWindow$.once(show => {
      this._showFilterWindow.next(!show);
    });
  }

  public toggleSearchBars(): void {
    this.showSearchBars$.once(show => {
      this._showSearchBars.next(!show);
    });
  }

  public memberSubmissionClicked(s: MemberSubmission) {
    switch (s.submissionTypeId) {
      case SubmissionTypeEnum.NameChange:
        this.router.navigate([`/submissions/members/name/${s.id}`]).then();
        break;
      case SubmissionTypeEnum.AddressChange:
        this.router.navigate([`/submissions/members/address/${s.id}`]).then();
        break;
      case SubmissionTypeEnum.UpdateContactInfo:
        this.router.navigate([`/submissions/members/contact/${s.id}`]).then();
        break;
      case SubmissionTypeEnum.PersonalInfoChange:
        this.router.navigate([`/submissions/members/personal/${s.id}`]).then();
        break;
    }
  }

  public filterFormSubmitted(req: MemberSubmissionsFilterRequest): void {
    combineLatest([this.selectedSubmissionOwners$, this.selectedSubmissionApprovers$]).once(([owners, approvers]) => {
      if (owners.length > 0) {
        req.ownerIds = owners.map(o => o.id.toString(10));
      } else {
        req.ownerIds = [];
      }
      if (approvers.length > 0) {
        req.approverIds = approvers.map(a => a.id.toString(10));
      } else {
        req.approverIds = [];
      }
      this._activeFilterCount.next(req.getFilterCount());
      const copy = Object.assign(new MemberSubmissionsFilterRequest(), req);
      this.generateDatesFromFilterRequest(copy);
      this._memberSubmissionsFilterRequest.next(copy);
    });
  }

  public searchCriteriaChanged(req: OdataFilterGenerator): void {
    const copy = Object.assign(new MemberSubmissionsFilterRequest(), req);
    this._searchCriteriaChangedSubject.next(copy as MemberSubmissionsFilterRequest);
  }

  public resetFilterForm(): void {
    this.memberSubmissionsFilterRequest$.once(req => {
      const copy = Object.assign(new MemberSubmissionsFilterRequest(), req);
      copy.clearFilters();
      this._memberSubmissionsFilterRequest.next(copy as MemberSubmissionsFilterRequest);
      this._activeFilterCount.next(0);
      this._selectedDates.next([]);
      this._selectedStartDate.next('');
      this._selectedEndDate.next('');
      this.clearDates$.next();
      this._selectedSubmissionApprovers.next([]);
      this._selectedSubmissionOwners.next([]);
    });
  }

  public resetFilterField(fieldKeys: string[]): void {
    this.memberSubmissionsFilterRequest$.once(req => {
      const reqCopy = Object.assign(new MemberSubmissionsFilterRequest(), req);
      fieldKeys.forEach(k => {
        if (k.includes('Date')) {
          reqCopy[k] = '';
          this._selectedDates.next([]);
          this._selectedEndDate.next('');
          this._selectedStartDate.next('');
        } else if (k === 'submissionType') {
          reqCopy[k] = '';
        } else {
          reqCopy[k] = [];
        }
      });
      this._memberSubmissionsFilterRequest.next(reqCopy as MemberSubmissionsFilterRequest);
      this._activeFilterCount.next(reqCopy.getFilterCount());
    });
  }

  public resetSubmissionOwnersOrApprovers(owners: boolean): void {
    this.memberSubmissionsFilterRequest$.once(req => {
      const reqCopy = Object.assign(new MemberSubmissionsFilterRequest(), req);
      if (owners) {
        reqCopy.ownerIds = [];
        this._selectedSubmissionOwners.next([]);
      }
      if (!owners) {
        reqCopy.approverIds = [];
        this._selectedSubmissionApprovers.next([]);
      }
      this._memberSubmissionsFilterRequest.next(reqCopy as MemberSubmissionsFilterRequest);
      this._activeFilterCount.next(reqCopy.getFilterCount());
    });
  }

  public updateCurrPageNumber(currPage: number): void {
    this._currPageNumber.next(currPage);
  }

  public handleSortReq(sortReq: string): void {
    this._memberSubmissionsOrderbyRequest.next(new MemberSubmissionsOrderbyRequest(sortReq));
  }

  public clearAllFilters(): void {
    this._memberSubmissionsFilterRequest.next(new MemberSubmissionsFilterRequest());
    this._activeSearchCriteria.next(0);
    this._activeFilterCount.next(0);
    this._selectedDates.next([]);
    this._selectedStartDate.next('');
    this._selectedEndDate.next('');
    this.clearDates$.next();
    this._selectedSubmissionOwners.next([]);
    this._selectedSubmissionApprovers.next([]);
  }

  private generateDatesFromFilterRequest(req: MemberSubmissionsFilterRequest): void {
    const dates = [];
    if (!!req.startDate) {
      dates.push(StringExtensions.convertDateStringToNgbDate(req.startDate));
    }
    if (!!req.endDate) {
      dates.push(StringExtensions.convertDateStringToNgbDate(req.endDate));
    }
    this.setDateFilters(dates);
  }

  public setDateFilters(dates: NgbDate[]): void {
    if (dates.length === 1) {
      this._selectedStartDate.next(DateUtils.convertNgbDateObjectToString(dates[0]));
    } else if (dates.length === 2) {
      this._selectedStartDate.next(DateUtils.convertNgbDateObjectToString(dates[0]));
      this._selectedEndDate.next(DateUtils.convertNgbDateObjectToString(dates[1]));
    }
    this._selectedDates.next(dates);
  }

  public addSubmissionOwner(owner: SubmissionStakeholder): void {
    this.selectedSubmissionOwners$.once(owners => {
      const ownerExists = owners.some(o => o.id === owner.id);
      if (!ownerExists) {
        this._selectedSubmissionOwners.next([...owners, owner]);
      }
    });
  }

  public removeSubmissionOwner(owner: SubmissionStakeholder): void {
    this.selectedSubmissionOwners$.once(owners => {
      const ownerExists = owners.some(o => o.id === owner.id);
      if (ownerExists) {
        this._selectedSubmissionOwners.next(owners.filter(o => o.id !== owner.id));
      }
    });
  }

  public addSubmissionApprover(approver: SubmissionStakeholder): void {
    this.selectedSubmissionApprovers$.once(approvers => {
      const approverExists = approvers.some(a => a.id === approver.id);
      if (!approverExists) {
        this._selectedSubmissionApprovers.next([...approvers, approver]);
      }
    });
  }

  public removeSubmissionApprover(approver: SubmissionStakeholder): void {
    this.selectedSubmissionApprovers$.once(approvers => {
      const approverExists = approvers.some(a => a.id === approver.id);
      if (approverExists) {
        this._selectedSubmissionApprovers.next(approvers.filter(a => a.id !== approver.id));
      }
    });
  }

  private fetchMemberSubmissions(
    filterReq: MemberSubmissionsFilterRequest,
    orderbyReq: MemberSubmissionsOrderbyRequest,
    currPage: number,
    pageSize: number
  ): void {
    this._loadingSubmissions.next(true);
    const lm = $localize`Loading Member Submissions`;
    this._loadingOpts.addRequest(lm);
    const oDataParams = new ODataQueryOptions();
    const filterString = filterReq.getFilterString();
    const orderbyString = orderbyReq.getOrderByString();
    if (!!filterString) {
      oDataParams.setFilter(filterString);
    }
    if (!!orderbyString) {
      oDataParams.setOrderBy(orderbyString);
    }
    oDataParams.setCount(true);
    oDataParams.setTop(pageSize);
    oDataParams.setSkip(currPage * pageSize);
    this.submissionsDomainModel.getMemberSubmissions(oDataParams).subscribe({
      complete: () => {
        this._loadingOpts.removeRequest(lm);
        this._loadingSubmissions.next(false);
      },
      error: (error: CustomError) => {
        this._loadingOpts.removeRequest(lm);
        this._loadingSubmissions.next(false);
        this.toastService.publishError(error);
      }
    });
  }

  public refreshMemberSubmissions(): void {
    combineLatest([
      this.memberSubmissionsFilterRequest$,
      this.memberSubmissionsOrderbyRequest$,
      this.currPageNumber$,
      this.pageSize$
    ]).once(([filterRequest, orderbyRequest, currPage, pageSize]) => {
      this.fetchMemberSubmissions(filterRequest, orderbyRequest, currPage, pageSize);
    });
  }
}
