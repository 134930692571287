import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export const MAX_MOBILE_WIDTH = 768;

@Injectable({ providedIn: 'root' })
export class ScreenService {
  static MOBILE_WIDTH = MAX_MOBILE_WIDTH;
  private _isMobile = new BehaviorSubject<boolean>(false);
  public isMobile$ = this._isMobile.pipe(distinctUntilChanged());
  private _width = new BehaviorSubject<number>(0);
  public width$ = this._width.asObservable();

  private _disableScroll = new BehaviorSubject<boolean>(false);
  public disableScroll$ = this._disableScroll as Observable<boolean>;

  constructor() {}

  setWidth(w: number) {
    this._width.next(w);
  }

  setIsMobile(x: boolean) {
    this._isMobile.next(x);
  }

  disableScroll(): void {
    this._disableScroll.next(true);
  }

  enableScroll(): void {
    this._disableScroll.next(false);
  }
}
