import { Selectable } from '../../protocols/selectable';

export class DropDownItem implements Selectable {
  public title: string;
  public value: any;
  public disabled: boolean = false;

  constructor(title: string, value: any, disabled: boolean = false) {
    this.title = title;
    this.value = value;
    this.disabled = disabled;
  }

  getSelectionTitle(): string {
    return this.title;
  }

  getSelectionValue(): any {
    return this.value;
  }

  getSelectionUniqueIdentifier(): any {
    return this.value;
  }

  getSelectionIsDisabled(): boolean {
    return this.disabled;
  }
}
