import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FoundationBaseModalViewModel } from '@csspension/base-angular';
import { BehaviorSubject, defer } from 'rxjs';
import { LoadingOptions } from '../shared/loading-options';
import { map, startWith } from 'rxjs/operators';

export class BaseModalViewModel extends FoundationBaseModalViewModel {
  protected _loadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.defaultInModal());
  loadingOpts$ = defer(() => this._loadingOpts);
  isLoading$ = this.loadingOpts$.pipe(
    map(it => it?.isLoading),
    startWith(false)
  );

  constructor(public router: Router, public ngbModal: NgbModal) {
    super(router, ngbModal);
  }
}
