import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerEventsComponent } from './components/banner-events/banner-events.component';
import { ErrorBannerComponent } from './components/banners/error-banner/error-banner.component';
import { InfoBannerComponent } from './components/banners/info-banner/info-banner.component';
import { MessageBannerComponent } from './components/banners/message-banner/message-banner.component';
import { SuccessBannerComponent } from './components/banners/success-banner/success-banner.component';
import { WarningBannerComponent } from './components/banners/warning-banner/warning-banner.component';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    BannerEventsComponent,
    InfoBannerComponent,
    MessageBannerComponent,
    SuccessBannerComponent,
    WarningBannerComponent,
    ErrorBannerComponent
  ],
  exports: [
    BannerEventsComponent,
    InfoBannerComponent,
    MessageBannerComponent,
    SuccessBannerComponent,
    WarningBannerComponent,
    ErrorBannerComponent
  ],
  imports: [CommonModule, FlexModule]
})
export class BannerEventsModule {}
