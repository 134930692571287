import { BaseModalViewModel } from '../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Searchable } from '../../../../models/protocols/searchable';
import { Injectable } from '@angular/core';
import { debounceTime, map } from 'rxjs/operators';
// @ts-ignore
import FuzzySearch from 'fuzzy-search';

@Injectable()
export class SearchableModalViewModel extends BaseModalViewModel {
  constructor(public router: Router, public ngbModal: NgbModal) {
    super(router, ngbModal);
  }

  private _searchedProperties = new BehaviorSubject<string[]>([]);
  public readonly searchedProperties$ = this._searchedProperties as Observable<string[]>;

  private _searchableItems = new BehaviorSubject<Searchable[]>([]);
  public readonly searchableItems$ = this._searchableItems as Observable<Searchable[]>;

  private _showAllItemsWhenSearchIsEmpty = new BehaviorSubject<boolean>(false);
  public readonly showAllItemsWhenSearchIsEmpty$ = this._showAllItemsWhenSearchIsEmpty as Observable<boolean>;

  private _searchText = new BehaviorSubject<string | null>(null);
  public readonly searchText$ = this._searchText as Observable<string | null>;

  public fuzzyHits$ = combineLatest([
    this.searchText$,
    this.searchedProperties$,
    this.searchableItems$,
    this.showAllItemsWhenSearchIsEmpty$
  ]).pipe(
    debounceTime(50),
    map(([searchText, searchProperties, items, showAllWhenSearchIsEmpty]) => {
      const hasSearchProperties = !!searchProperties && searchProperties?.length > 0;
      const hasSearchableItems = items?.length > 0;
      let matches = showAllWhenSearchIsEmpty ? items : [];
      if (!!searchText && hasSearchProperties && hasSearchableItems) {
        const searcher = new FuzzySearch(items, searchProperties, { caseSensitive: false });
        matches = searcher.search(searchText);
        return matches;
      }
      return matches;
    })
  );

  public setSearchedProperties(properties: string[]) {
    this._searchedProperties.next(properties);
  }

  public setSearchableItems(items: Searchable[]) {
    this._searchableItems.next(items);
  }

  public setSearchText(search: string) {
    this._searchText.next(search);
  }

  public setShowAllItemsWhenSearchIsEmpty(show: boolean) {
    this._showAllItemsWhenSearchIsEmpty.next(show);
  }
}
