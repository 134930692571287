import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Selectable } from '../../../../models/protocols/selectable';

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent implements OnInit {
  @Input() labelText!: string;
  @Input() disabled: boolean = false;
  @Input() hint!: Selectable;
  @Input() dropdowns: Selectable[] = [];
  @Input() currentSelection!: Selectable;

  @Output() selection = new EventEmitter<Selectable>();

  constructor() {}

  ngOnInit(): void {
    if (!this.currentSelection && this.hint) {
      this.currentSelection = this.hint;
    }
  }

  changed() {
    this.selection.emit(this.currentSelection);
  }
}
