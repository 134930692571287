import { AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';
import { Directive } from '@angular/core';
import { DateUtils } from '../../../utils/date-utils';
import * as moment from 'moment';

let startTime = '';
let endTime = '';

export function startEndValidator(): ValidatorFn {
  const parseTimeString = function (timeString: string): { hours: number; minutes: number } {
    // Implement parsing logic to extract hours and minutes
    const [hoursString, minutesString] = timeString.split(':').map(s => parseInt(s, 10));
    return { hours: hoursString, minutes: minutesString };
  };
  return (control: AbstractControl): { [p: string]: any } | null => {
    const errMsg = $localize`Start Date must be before End Date.`;
    const startDateControl = control?.parent?.get('startDateString');
    const endDateControl = control?.parent?.get('endDateString');
    const startTimeControl = control?.parent?.get('startTime');
    const endTimeControl = control?.parent?.get('endTime');

    const startTimeValue = startTimeControl?.value;
    const endTimeValue = endTimeControl?.value;

    if (!!startTimeValue && startTimeValue !== '') {
      if (startTime === '') startTime = startTimeValue;
      const { hours: newHours, minutes: newMinutes } = parseTimeString(startTimeValue);
      const { hours: oldHours, minutes: oldMinutes } = parseTimeString(startTime);
      if (newMinutes > 59) {
        startTime = `${newHours}:${newMinutes.toString().slice(0, 2)}`;
      } else if (oldHours > 12 && oldHours - 12 === newHours) {
        startTime = `${oldHours}:${newMinutes}`;
      } else {
        startTime = `${newHours}:${newMinutes}`;
      }
    }

    if (!!endTimeValue && endTimeValue !== '') {
      if (endTime === '') endTime = endTimeValue;
      const { hours: newHours, minutes: newMinutes } = parseTimeString(endTimeValue);
      const { hours: oldHours, minutes: oldMinutes } = parseTimeString(endTime);
      if (newMinutes > 59) {
        endTime = `${newHours}:${newMinutes.toString().slice(0, 2)}`;
      } else if (oldHours > 12 && oldHours - 12 === newHours) {
        endTime = `${oldHours}:${newMinutes}`;
      } else {
        endTime = `${newHours}:${newMinutes}`;
      }
    }

    const startDate = DateUtils.formatRawAlertTimePickerDateToDateString(startDateControl?.value, startTime);
    const endDate = DateUtils.formatRawAlertTimePickerDateToDateString(endDateControl?.value, endTime);

    const momentStartDate = moment(startDate, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ');
    const momentEndDate = moment(endDate, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ');

    if (momentStartDate.isAfter(momentEndDate)) {
      setValidityStates(startDateControl, endDateControl);
      return { [StartEndDateValidatorDirective.key]: errMsg };
    }

    if (momentEndDate.isBefore(momentStartDate)) {
      setValidityStates(endDateControl, startDateControl);
      return { [StartEndDateValidatorDirective.key]: errMsg };
    }

    // Clear errors if conditions are met
    clearValidityStates(startDateControl, endDateControl);
    clearValidityStates(endDateControl, startDateControl);

    function setValidityStates(
      controlExists: AbstractControl | null | undefined,
      relatedControl: AbstractControl | null | undefined
    ): void {
      if (controlExists === null || relatedControl === null) return;
      relatedControl?.setErrors({ [StartEndDateValidatorDirective.key]: errMsg });
    }

    function clearValidityStates(
      controlExists: AbstractControl | null | undefined,
      relatedControl: AbstractControl | null | undefined
    ): void {
      if (controlExists === null || relatedControl === null) return;
      if (relatedControl?.errors && relatedControl?.errors[StartEndDateValidatorDirective.key]) {
        relatedControl.setErrors(null);
      }
    }

    return null;
  };
}

@Directive({
  selector: '[appStartEndDateValidatorDirective]',
  providers: [{ provide: NG_VALIDATORS, useExisting: StartEndDateValidatorDirective, multi: true }]
})
export class StartEndDateValidatorDirective {
  static key: string = 'validStartEndDate';

  validate(control: AbstractControl): { [key: string]: any } {
    // @ts-ignore
    return startEndValidator()(control);
  }
}
