import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UploadProfilePictureViewModel } from './upload-profile-picture-view-model';
import { UploadImageInterface } from '../../../../shared/components/upload-asset/upload-image-interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadAssetComponent } from '../../../../shared/components/upload-asset/upload-asset.component';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { CustomFile } from '../../../../../models/shared/custom-file';

@Component({
  selector: 'app-upload-profile-picture',
  templateUrl: './upload-profile-picture.component.html',
  styleUrls: ['./upload-profile-picture.component.scss'],
  providers: [UploadProfilePictureViewModel]
})
export class UploadProfilePictureComponent extends BaseModalComponent implements UploadImageInterface {
  @ViewChild(UploadAssetComponent) dnd!: UploadAssetComponent;
  public ignoreEscape: boolean = false;

  constructor(
    public viewModel: UploadProfilePictureViewModel,
    private cdr: ChangeDetectorRef,
    protected activeModal: NgbActiveModal
  ) {
    super(activeModal);
  }

  setupBindings() {
    const s = this.viewModel.dismissModalSubject.subscribe(close => {
      if (close) this.cancel();
    });
    this.pushSub(s);
  }

  setupViews() {}

  uploadImage(): void {
    this.viewModel.uploadProfilePicture();
  }

  deleteProfilePicture(): void {
    this.viewModel.deleteProfilePicture();
  }

  cancel() {
    this.dnd.clear();
    this.activeModal.close(null);
  }

  setFile(f: CustomFile) {
    this.viewModel.file = f;
    this.cdr.detectChanges();
  }

  fileList(f: CustomFile[]): void {
    if (f.length > 0) {
      this.setFile(f[0]);
    }
  }
}
