import { PermissionTypeMap } from '../base/permission-type-map';
import { PermissionType } from '../base/permission-type';
import { PermissionMap } from '../base/permission-map';

export class InternalUserSubmissionPermissionMap extends PermissionMap {
  public map: Map<number, PermissionTypeMap> = new Map<number, PermissionTypeMap>();

  constructor() {
    super();
    this.map.set(1, new PermissionTypeMap(1, PermissionType.Route, 'submissions/name/:submissionId'));
    this.map.set(2, new PermissionTypeMap(2, PermissionType.Action, 'processMemberNameChange'));
    this.map.set(3, new PermissionTypeMap(3, PermissionType.Action, 'approveMemberNameChange'));
    this.map.set(4, new PermissionTypeMap(4, PermissionType.Action, 'approveOwnMemberNameChange'));
    this.map.set(5, new PermissionTypeMap(5, PermissionType.Action, 'overrideApproverOwnerForNameChange'));
    this.map.set(6, new PermissionTypeMap(6, PermissionType.Action, 'createMemberNameChange'));
    this.map.set(7, new PermissionTypeMap(7, PermissionType.Route, 'submissions/address/:submissionId'));
    this.map.set(8, new PermissionTypeMap(8, PermissionType.Action, 'processMemberAddressChange'));
    this.map.set(9, new PermissionTypeMap(9, PermissionType.Action, 'approveMemberAddressChange'));
    this.map.set(10, new PermissionTypeMap(10, PermissionType.Action, 'approveOwnMemberAddressChange'));
    this.map.set(11, new PermissionTypeMap(11, PermissionType.Action, 'overrideApproverOwnerForAddressChange'));
    this.map.set(12, new PermissionTypeMap(12, PermissionType.Action, 'createMemberAddressChange'));
    this.map.set(13, new PermissionTypeMap(13, PermissionType.Route, 'submissions/contact/:submissionId'));
    this.map.set(14, new PermissionTypeMap(14, PermissionType.Action, 'processMemberContactChange'));
    this.map.set(15, new PermissionTypeMap(15, PermissionType.Action, 'approveMemberContactChange'));
    this.map.set(16, new PermissionTypeMap(16, PermissionType.Action, 'approveOwnMemberContactChange'));
    this.map.set(17, new PermissionTypeMap(17, PermissionType.Action, 'overrideApproverOwnerForContactChange'));
    this.map.set(18, new PermissionTypeMap(18, PermissionType.Action, 'createMemberContactChange'));
    this.map.set(19, new PermissionTypeMap(19, PermissionType.Route, 'submissions/personal/:submissionId'));
    this.map.set(20, new PermissionTypeMap(20, PermissionType.Action, 'processMemberPersonalInfoChange'));
    this.map.set(21, new PermissionTypeMap(21, PermissionType.Action, 'approveMemberPersonalInfoChange'));
    this.map.set(22, new PermissionTypeMap(22, PermissionType.Action, 'approveOwnMemberPersonalInfoChange'));
    this.map.set(23, new PermissionTypeMap(23, PermissionType.Action, 'overrideApproverOwnerForPersonalInfoChange'));
    this.map.set(24, new PermissionTypeMap(24, PermissionType.Action, 'createMemberPersonalInfoChange'));
  }
}
