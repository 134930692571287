import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notNull',
  pure: false
})
export class NotNullPipe implements PipeTransform {
  transform<T>(value: T | null): T | undefined {
    return value !== null ? value : undefined;
  }
}
