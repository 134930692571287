import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DonutChartData } from '../donut-chart-data';

@Component({
  selector: 'app-donut-chart-legend',
  templateUrl: './donut-chart-legend.component.html',
  styleUrls: ['./donut-chart-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DonutChartLegendComponent implements OnChanges {
  @Input() data!: DonutChartData[];
  @Input() showTotalRow: boolean = true;

  public totalValue = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.data) {
      this.totalValue = this.data?.reduce((a, b) => a + b.value, 0) ?? 0;
    }
  }
}
