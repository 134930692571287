<div class="auth-flow-title">{{ 'Sign In' | localization }}</div>

<lib-reactive-form-group #form [bindTo]="req$ | async" (formSubmitted$)="formSubmitted($event)">
  <lib-reactive-form-email
    [inputName]="'email'"
    [label]="'Email' | localization"
    [bindingProperty]="'email'"
    [required]="true"
    [placeholder]="''">
  </lib-reactive-form-email>

  <lib-reactive-form-password
    [inputName]="'password'"
    [required]="true"
    [label]="'Password' | localization"
    [placeholder]="''"
    (keydown.enter)="form.submitForm()"
    [bindingProperty]="'password'">
  </lib-reactive-form-password>
</lib-reactive-form-group>

<div class="sign-in-button-container">
  <lib-button-primary [style.width.%]="100" (buttonClicked)="form.submitForm()">
    {{ 'Sign In' | localization }}
  </lib-button-primary>
</div>
<button class="paddingless-text-button mt-2" (click)="secondaryButtonPressed()">
  {{ 'Forgot Password ?' | localization }}
</button>
