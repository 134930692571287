import { DateUtils } from './date-utils';
import * as moment from 'moment';

export class OdataUtils {
  static constructDateSearchFilterQuery(date: string, dateFieldName: string): string {
    const dateAdjustedForBackend = DateUtils.swapDayAndMonth(date);
    const startDate = moment.utc(dateAdjustedForBackend, 'MM/DD/YYYY').startOf('day');
    const endDate = startDate.clone().endOf('day');
    return `${dateFieldName} ge cast(${startDate.toISOString(
      true
    )}, Edm.DateTimeOffset) and ${dateFieldName} le cast(${endDate.toISOString(true)}, Edm.DateTimeOffset)`;
  }
}
