import { DateUtils } from '../../utils/date-utils';
import { Cachable } from '../protocols/cachable';
import { DefaultCacheKey } from '../enum/shared/default-cache-key.enum';
import { CachePolicy } from '../enum/shared/cachable-image-policy.enum';
import { BaseUser } from '../base/base-user';
import { MemberUser } from '../account/dto/member-user';
import { PortalType } from '../enum/shared/portal-type';
import { InternalUser } from '../account/dto/internal-user';
import { EmployerUser } from '../account/dto/employer-user';
import { Role } from '../roles/role';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';

export class SessionContainer implements Cachable, UniquelyIdentifiable {
  // Shared properties
  public user: BaseUser | undefined;
  public roles: Role[] = [];
  // Session Settings
  public sessionStartTime!: number;
  public rememberSession: boolean = true;
  public cachedTime!: number;

  constructor(user?: BaseUser) {
    this.user = user;
  }

  public validSession(): boolean {
    let valid = false;
    if (this.user && this.user.userSession && this.user?.userSession?.validSession(this.cachedTime)) {
      valid = true;
    }
    return valid;
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneWeek();
  }

  cacheKey(): string {
    return DefaultCacheKey.SessionContainer;
  }

  imageCachePolicy(): CachePolicy {
    return CachePolicy.Service;
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

  onDeserialize(portalType: PortalType): void {
    if (!!portalType) {
      switch (portalType) {
        case PortalType.Internal:
          this.user = window?.injector?.Deserialize?.instanceOf(InternalUser, this.user);
          break;
        case PortalType.Member:
          this.user = window?.injector?.Deserialize?.instanceOf(MemberUser, this.user);
          break;
        case PortalType.Employer:
          this.user = window?.injector?.Deserialize?.instanceOf(EmployerUser, this.user);
          break;
      }
    }
  }

  getUniqueIdentifier(): string {
    return `
    ${this.user?.getUniqueIdentifier()}
    ${this.roles.map(r => r?.getUniqueIdentifier())}
    ${this.sessionStartTime}
    ${this.rememberSession}
    ${this.cachedTime}`;
  }
}
