import { AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Directive, Inject } from '@angular/core';

@Directive({
  selector: '[appVersionNumberExistsValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: VersionNumberExistsValidatorDirective, multi: true }]
})
export class VersionNumberExistsValidatorDirective {
  static key: string = 'versionNumberExists';

  constructor(@Inject('versions') public versions: string[]) {
    this.versions = versions;
  }

  getErrorMsg(versions: string[], value: string): string | null {
    if (versions.length && versions.includes(value)) return $localize`Version already exists.`;
    return null;
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    const errorMsg = this.getErrorMsg(this.versions, control.value);
    if (errorMsg) return { [VersionNumberExistsValidatorDirective.key]: errorMsg };
    return null;
  }
}
