export enum SubmissionStatusPrimaryColorEnum {
  Pending = '#666666',
  Submitted = '#024256',
  InReview = '#CC7212',
  Complete = '#0D5D4B',
  Cancelled = '#666666'
}

export enum SubmissionStatusSecondaryColorEnum {
  Pending = '#F7F7F7',
  Submitted = '#E6EEF1',
  InReview = '#FCF6EB',
  Complete = '#E8F3F1',
  Cancelled = '#F7F7F7'
}

/* eslint-disable @typescript-eslint/naming-convention */
export const SubmissionStatusPrimaryColorMap: Record<number, string> = {
  1: SubmissionStatusPrimaryColorEnum.Pending,
  2: SubmissionStatusPrimaryColorEnum.Submitted,
  3: SubmissionStatusPrimaryColorEnum.InReview,
  4: SubmissionStatusPrimaryColorEnum.Complete,
  5: SubmissionStatusPrimaryColorEnum.Cancelled
};

export const SubmissionStatusSecondaryColorMap: Record<number, string> = {
  1: SubmissionStatusSecondaryColorEnum.Pending,
  2: SubmissionStatusSecondaryColorEnum.Submitted,
  3: SubmissionStatusSecondaryColorEnum.InReview,
  4: SubmissionStatusSecondaryColorEnum.Complete,
  5: SubmissionStatusSecondaryColorEnum.Cancelled
};
/* eslint-enable @typescript-eslint/naming-convention */
