import { Injectable } from '@angular/core';
import { UserDomainModel } from '../../domainModels/user-domain-model';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

type Activated = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

@Injectable({ providedIn: 'root' })
export class RefreshSessionGuard implements CanActivate {
  constructor(public userDomainModel: UserDomainModel, public router: Router) {}

  // Component can only be activated if there is no session (ie. Shouldn't be able to view the log in page if logged in)
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Activated {
    return this.userDomainModel.isAuthenticated().pipe(
      map(r => r === null),
      tap(sess => {
        if (!sess) {
          this.router.navigate(['dashboard']).then(() => {});
        }
      })
    );
  }
}
