// noinspection JSUnusedLocalSymbols

import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../models/base/base-view-model';
import { LoadingOptions } from '../../../models/shared/loading-options';

@Injectable({ providedIn: 'root' })
export class AuthWrapperViewModel extends BaseViewModel {
  // Misc
  public versionString = require('../../../../../package.json').version;

  setLoadingOpts(opts: LoadingOptions) {
    this._loadingOpts.next(opts);
  }
}
