import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, iif, Observable } from 'rxjs';
import { RolePermission } from '../models/roles/role';
import { map } from 'rxjs/operators';
import { BaseService } from '@csspension/base-angular';
import { EmployerPermissionMap } from '../models/permissions/employers/employer-permission-map';
import { PortalType } from '../models/enum/shared/portal-type';
import { InternalUserPermissionMap } from '../models/permissions/internal-users/internal-user-permission-map';
import { PermissionMap } from '../models/permissions/base/permission-map';
import { InternalUserSubmissionPermissionMap } from '../models/permissions/internal-users/internal-user-submission-permission-map';
import { InternalUser } from '../models/account/dto/internal-user';
import { PermissionMapTypeEnum } from '../models/enum/shared/permission-map-type.enum';
import { SessionService } from './session-service';
import { PortalService } from './portal/portal.service';

// Provided by Logged In Scope
@Injectable({ providedIn: 'root' })
export class PermissionService extends BaseService {
  constructor() {
    super();
  }

  private sessionService = inject(SessionService);
  private portalService = inject(PortalService);

  public userRoles$ = this.sessionService.sessionContainer$.pipe(map(sess => sess?.roles));
  private readonly user$ = this.sessionService.sessionContainer$.pipe(map(sess => sess?.user as InternalUser));

  private _permissionMap = new BehaviorSubject<PermissionMap>({} as PermissionMap);
  public permissionMap$ = this._permissionMap as Observable<PermissionMap>;

  private _submissionPermissionMap = new BehaviorSubject<PermissionMap>({} as PermissionMap);
  public submissionPermissionMap$ = this._submissionPermissionMap as Observable<PermissionMap>;

  // If an API call is made on app init that requires permissions, the call must wait until the permission map is ready
  private _permissionMapReady = new BehaviorSubject<boolean>(false);
  public readonly permissionMapReady$ = this._permissionMapReady as Observable<boolean>;

  public listenToPortalType = this.portalService.portalType$.subscribeWhileAlive({
    owner: this,
    next: pt => {
      switch (pt) {
        case PortalType.Internal:
          this._permissionMap.next(new InternalUserPermissionMap());
          this._submissionPermissionMap.next(new InternalUserSubmissionPermissionMap());
          break;
        case PortalType.Employer:
          this._permissionMap.next(new EmployerPermissionMap());
          break;
        default:
          this._permissionMapReady.next(true);
          return new EmployerPermissionMap();
      }
    }
  });

  private listenToUserRoles = this.userRoles$.subscribeWhileAlive({
    owner: this,
    next: roles => {
      if (roles?.length) {
        this.generatePermissionMap(roles.flatMap(r => r.permissions));
      }
    }
  });

  private listenToGenerateSubmissionPermissionMap = this.user$.subscribeWhileAlive({
    owner: this,
    next: user => {
      if (!!user?.submissionPermissions) {
        this.generateSubmissionPermissionMap();
      }
    }
  });

  public permissionGranted$(
    permissionIds: number[],
    permissionType: PermissionMapTypeEnum = PermissionMapTypeEnum.Role
  ): Observable<boolean> {
    return iif(
      () => permissionType === PermissionMapTypeEnum.Role,
      this.permissionMap$,
      this.submissionPermissionMap$
    ).pipe(
      map(permissionMap => {
        // No Permission has an id of 0, so if the permissionIds array contains 0,
        // then the permission is granted for dev purposes.
        if (permissionIds.equals([0])) return true;
        return permissionIds.some(permissionId => {
          return permissionMap?.map?.get(permissionId)?.accessGranted ?? false;
        });
      })
    );
  }

  private generatePermissionMap(permissions: RolePermission[]) {
    const filteredPermissions = this.filterRolePermissions(permissions);
    this.permissionMap$.once(permissionMap => {
      if (!!permissionMap) {
        filteredPermissions.forEach(p => {
          const mapValue = permissionMap.map.get(p?.permissionId);
          if (!!mapValue) {
            mapValue.setAccessGranted(p?.accessGranted);
            mapValue.setPermissionEnforcement(p?.enforcementTypeId);
          }
        });
      }
      this._permissionMapReady.next(true);
    });
  }

  private generateSubmissionPermissionMap(): void {
    combineLatest([this.user$, this.submissionPermissionMap$]).once(([user, permissionMap]) => {
      if (!!permissionMap) {
        user?.submissionPermissions?.forEach(p => {
          const mapValue = permissionMap?.map?.get(p?.submissionPermissionId);
          if (!!mapValue) {
            mapValue.setAccessGranted(p.submissionPermissionId === mapValue.id);
          }
        });
      }
    });
  }

  /**
   * Filter an array of RolePermissions from multiple roles to ensure that only one RolePermission is retained
   * for each unique permissionId, based on the following criteria:
   * 1. If there is a RolePermission with accessGranted as true, it will be selected.
   * 2. If there are multiple RolePermissions with accessGranted as false, an arbitrary one is selected.
   *
   * @param rolePermissions An array of RolePermission objects to be filtered.
   * @returns An array of filtered RolePermission objects with unique permissionIds based on the specified criteria.
   */
  private filterRolePermissions(rolePermissions: RolePermission[]): RolePermission[] {
    const permissionIdMap = new Map<number, RolePermission>();
    for (const rolePermission of rolePermissions) {
      const existing = permissionIdMap.get(rolePermission.permissionId);
      if (!existing) {
        permissionIdMap.set(rolePermission.permissionId, rolePermission);
      } else {
        if (rolePermission.accessGranted) {
          // Replace if the current one has accessGranted as true
          permissionIdMap.set(rolePermission.permissionId, rolePermission);
        }
        // Otherwise, keep the existing one with accessGranted as true
      }
    }
    return Array.from(permissionIdMap.values());
  }
}
