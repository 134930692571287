<div class="d-flex flex-column gap-2">
  <div class="label">{{ label }}</div>
  <ng-container *ngIf="!!url; else stringValue">
    <div>
      <a class="value link" [routerLink]="url">{{ value }}</a>
    </div>
  </ng-container>
  <ng-template #stringValue>
    <div class="value">{{ value }}</div>
  </ng-template>
</div>
