import { Deserializable } from '../protocols/deserializable';
import { MediaType } from '../enum/dto/media-type.enum';
import { SafeResourceUrl } from '@angular/platform-browser';
import { BlobUtils } from '../../utils/blob-utils';

export class CachedBlob implements Deserializable {
  // Given
  cacheTime!: number;
  data!: string;
  mediaType!: MediaType;

  // Generated
  url!: string;
  safeUrl!: SafeResourceUrl;
  blob!: Blob | null;

  constructor(cacheTime: number, data: string, blob: Blob, mediaType: MediaType) {
    this.cacheTime = cacheTime;
    this.data = data;
    this.mediaType = mediaType;
    const blobObject = !!blob ? blob : BlobUtils.b64toBlob(data, mediaType);
    if (!!blob) {
      this.url = URL.createObjectURL(blob);
    }
    this.safeUrl = window?.injector?.sanitizer?.bypassSecurityTrustResourceUrl(this.url);
    this.blob = blobObject;
  }

  onDeserialize() {
    const blob = BlobUtils.b64toBlob(this.data, this.mediaType);
    if (!!blob) {
      this.url = URL.createObjectURL(blob);
    }
    this.safeUrl = window?.injector?.sanitizer?.bypassSecurityTrustResourceUrl(this.url);
  }

  destroy() {
    URL.revokeObjectURL(this.url);
  }
}
