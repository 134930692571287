import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Breadcrumb } from '../../../../models/shared/stylesheet/breadcrumb';
import { TabBarItem } from '../tab-bar/tab-bar-item';

@Component({
  selector: 'app-breadcrumb-header',
  templateUrl: './breadcrumb-header.component.html',
  styleUrls: ['./breadcrumb-header.component.scss', '../page-header/page-header.component.scss']
})
export class BreadcrumbHeaderComponent {
  @Input() breadcrumbs: Breadcrumb[] = [];
  @Input() title: string = '';
  @Input() tabs: TabBarItem[] = [];
  @Input() isLoading: boolean = false;
  @Input() isMobile: boolean = false;
  @Output() selectedTab = new EventEmitter<TabBarItem>(true);
}
