import { Deserializable } from '../../protocols/deserializable';

export class AlertTypes implements Deserializable {
  public alertTypes: AlertType[] = [];
  onDeserialize(): void {}
}

export class AlertType implements Deserializable {
  public id!: number;
  public name!: string;
  public colours: AlertColor[] = [];

  onDeserialize(): void {}
}

export class AlertColor implements Deserializable {
  public alertTypeId!: number;
  public backgroundColour!: string;
  public borderColour!: string;
  public id!: number;
  public name!: string;

  onDeserialize(): void {}
}
