import { Pipe, PipeTransform } from '@angular/core';
import { PortalTypeIdEnum } from '../../../models/enum/shared/portal-type-id.enum';

@Pipe({
  name: 'portalType'
})
export class PortalTypePipe implements PipeTransform {
  transform(value: PortalTypeIdEnum): string {
    switch (value) {
      case 1:
        return $localize`Internal Portal`;
      case 2:
        return $localize`Employer Portal`;
      case 3:
        return $localize`Member Portal`;
      default:
        return '';
    }
  }
}
