import { Component } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { NavbarViewModel } from '../../navbar-view-model';

@Component({
  selector: 'app-desktop-nav',
  templateUrl: './desktop-nav.component.html',
  styleUrls: ['./desktop-nav.component.scss']
})
export class DesktopNavComponent extends BaseComponent {
  constructor(public viewModel: NavbarViewModel) {
    super();
  }
  setupBindings(): void {}

  setupViews(): void {}
}
