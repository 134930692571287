import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leadingZero'
})
export class LeadingZeroPipe implements PipeTransform {
  transform(value: number | undefined, padWidth = 2): string {
    let str = value?.toString() ?? '';
    while (str.length < padWidth) {
      str = '0' + str;
    }
    return str;
  }
}
