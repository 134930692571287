import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localization'
})
export class LocalizationPipe implements PipeTransform {
  transform(value: string | null = ''): string {
    return !!value ? $localize`${value}` : '';
  }
}
