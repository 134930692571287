import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-fake-check-box',
  templateUrl: './fake-check-box.component.html',
  styleUrls: ['./fake-check-box.component.scss']
})
export class FakeCheckBoxComponent extends BaseComponent implements OnInit {
  @Input() checked: boolean = false;
  @Input() label: string = '';
  @Input() disabled: boolean = false;
  @Output() clicked = new EventEmitter(true);

  constructor() {
    super();
  }

  ngOnInit(): void {}

  setupBindings(): void {}

  setupViews(): void {}
}
