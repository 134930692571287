export enum TransactionStatusPrimaryColorEnum {
  Pending = '#CC7212',
  Posted = '#0D5D4B',
  Reversed = '#0D5D4B',
  Adjusted = '#0D5D4B'
}

export enum TransactionStatusSecondaryColorEnum {
  Pending = '#FCF6EB',
  Posted = '#E8F3F1',
  Reversed = '#E8F3F1',
  Adjusted = '#E8F3F1'
}
/* eslint-disable @typescript-eslint/naming-convention */
export const TransactionStatusPrimaryColorMap: Record<number, string> = {
  1: TransactionStatusPrimaryColorEnum.Pending,
  2: TransactionStatusPrimaryColorEnum.Posted,
  3: TransactionStatusPrimaryColorEnum.Reversed,
  4: TransactionStatusPrimaryColorEnum.Adjusted
};

export const TransactionStatusSecondaryColorMap: Record<number, string> = {
  1: TransactionStatusSecondaryColorEnum.Pending,
  2: TransactionStatusSecondaryColorEnum.Posted,
  3: TransactionStatusSecondaryColorEnum.Reversed,
  4: TransactionStatusSecondaryColorEnum.Adjusted
};
/* eslint-enable @typescript-eslint/naming-convention */
