import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-history-item',
  templateUrl: './history-item.component.html',
  styleUrls: ['./history-item.component.scss']
})
export class HistoryItemComponent {
  @Input() public description!: string;
  @Input() public date!: Date;
}
