import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateStringWithAt'
})
export class DateStringWithAtPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(date: Date | null): string {
    if (!date) return '';
    const formattedDate = new Intl.DateTimeFormat(this.locale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }).format(date);

    const formattedTime = new Intl.DateTimeFormat(this.locale, {
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);

    const localizedAt = $localize`:separator between date and time:at`;
    return `${formattedDate} ${localizedAt} ${formattedTime}`;
  }
}
