import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DonutChartData } from '../donut-chart-data';

@Component({
  selector: 'app-donut-chart-with-legend',
  templateUrl: './donut-chart-with-legend.component.html',
  styleUrls: ['./donut-chart-with-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DonutChartWithLegendComponent {
  @Input() data!: DonutChartData[];
  @Input() showTotalRow: boolean = true;
}
