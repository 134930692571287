import { AssetSize } from '../../enum/dto/asset-size.enum';

export class AssetPreviewOptions {
  public previewSize: AssetSize = AssetSize.Medium;
  public primaryButtonText!: string;
  public primaryButtonClass: string = 'preferred-button';
  public secondaryButtonText!: string;
  public secondaryButtonClass: string = 'neutral-button';
  public tertiaryButtonText!: string;
  public tertiaryButtonClass: string = 'neutral-button';
}
