import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BaseCommentRequest } from '../../../../models/base/base-comment-request';
import { BaseComment } from '../../../../models/base/base-comment';
import { SlidingWindowOptions } from '../../../../models/shared/sliding-window-options';
import { BaseComponent } from '../../../../models/base/base-component';
import { UserTaggingDropdownItem } from '../../../../models/shared/user-tagging-dropdown-item';
import { ScreenService } from '../../../../services/screen-service.service';

@Component({
  selector: 'app-sliding-window-comments',
  templateUrl: './sliding-window-comments.component.html',
  styleUrls: ['./sliding-window-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlidingWindowCommentsComponent extends BaseComponent implements OnChanges, OnDestroy {
  public commentWindowOptions$ = of(new SlidingWindowOptions($localize`Updates`, $localize`Cancel`, $localize`Post`));
  private screenService = inject(ScreenService);

  @Input() isLoading: boolean = false;
  @Input() comments: BaseComment[] = [];
  @Input() commentReq: BaseCommentRequest = new BaseCommentRequest();
  @Input() dropdownItems: UserTaggingDropdownItem[] = [];
  @Input() buttonMarginBottomRem: number = 1.5;
  @Input() headerText: string = '';

  @Output() newComment = new EventEmitter<BaseCommentRequest>();
  @Output() filterText = new EventEmitter<string>();

  private _showCommentWindow = new BehaviorSubject<boolean>(false);
  public readonly showCommentWindow$ = this._showCommentWindow as Observable<boolean>;

  private _hideActionButton = new BehaviorSubject<boolean>(true);
  public hideActionButton$ = this._hideActionButton as Observable<boolean>;

  private _commentReq = new BehaviorSubject<BaseCommentRequest>(new BaseCommentRequest());
  public commentReq$ = this._commentReq as Observable<BaseCommentRequest>;

  private _comments = new BehaviorSubject<BaseComment[]>([]);
  public comments$ = this._comments as Observable<BaseComment[]>;

  public filterDropdown(text: string): void {
    this.filterText.emit(text);
  }

  public onCommentValueChange(comment: string): void {
    this._hideActionButton.next(comment === '' || comment === 'Add a Comment...');
  }

  public addComment(comment: BaseCommentRequest): void {
    this.newComment.emit(comment);
  }

  public toggleCommentWindow(): void {
    this.showCommentWindow$.once(value => {
      value ? this.screenService.enableScroll() : this.screenService.disableScroll();
      this._showCommentWindow.next(!value);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.comments) {
      this._comments.next(this.comments);
    }
    if (!!changes.commentReq) {
      this._commentReq.next(this.commentReq);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.screenService.enableScroll();
  }

  setupBindings(): void {}

  setupViews(): void {}
}
