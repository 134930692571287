import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { PermissionService } from '../permission-service';

@Injectable()
export class PermissionsInterceptor implements HttpInterceptor {
  constructor(private permissionService: PermissionService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const permissions = req.headers.get('permissions');

    if (!permissions) {
      // If 'permissions' header is not present, proceed with the request
      return next.handle(req);
    }
    // Convert the comma-separated string of permissions to an array of numbers
    const permissionArray = permissions.split(',').map(Number);
    // Check if permissions are granted using permissionService
    return this.permissionService.permissionGranted$(permissionArray).pipe(
      switchMap(permissionGranted => {
        if (permissionGranted) {
          // If permissions are granted, proceed with the request
          return next.handle(req);
        } else {
          // If permissions are not granted, return an empty observable (no request is made)
          return of();
        }
      }),
      catchError(error => {
        console.error('Permission check error:', error);
        return of();
      })
    );
  }
}
