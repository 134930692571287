import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencySpace'
})
export class CurrencySpacePipe implements PipeTransform {
  transform(value: string | null): string {
    if (value === undefined || value === null) return '';

    // Regular expression to match the currency format with different separators
    const regex = /^(\D*)(\d{1,3}(?:[,.\s]\d{3})*(?:[.,]\d{2})?)(\D*)$/;

    const match = value.match(regex);
    if (!match) return value;

    const [, symbolBefore, number, , symbolAfter] = match;

    // Determine the separator used in the number part
    const decimalSeparator = number.includes('.') ? '.' : ',';

    // Construct the final result
    let result = '';
    if (symbolBefore) {
      result += symbolBefore.trim() + ' ';
    }
    result += number.replace('.', decimalSeparator);
    if (symbolAfter) {
      result += ' ' + symbolAfter.trim();
    }

    return result.trim();
  }
}
