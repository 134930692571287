import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-user-profile-badge',
  templateUrl: './user-profile-badge.component.html',
  styleUrls: ['./user-profile-badge.component.scss']
})
export class UserProfileBadgeComponent extends BaseComponent {
  @Input() public initials!: string;
  @Input() public textColor!: string;
  @Input() public profileColor!: string;

  constructor() {
    super();
  }

  setupBindings(): void {}

  setupViews(): void {}
}
