import { Component, inject } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { MemberAuthViewModel } from '../../../../viewModels/member-auth-view-model';
import { VerifyMemberRequest } from '../../../../../../models/account/requests/verify-member-request';
import { StringExtensions } from '../../../../../../utils/string.extensions';
import { AssetSize } from '../../../../../../models/enum/dto/asset-size.enum';

@Component({
  selector: 'app-member-welcome',
  templateUrl: './member-welcome.component.html',
  styleUrls: ['./member-welcome.component.scss', '../../auth.component.scss']
})
export class MemberWelcomeComponent extends BaseComponent {
  private viewModel = inject(MemberAuthViewModel);

  public authFlowTitle$ = this.viewModel.authFlowTitle$;
  public authFlowSubtext$ = this.viewModel.authFlowSubtext$;

  public req = new VerifyMemberRequest('', '');
  public mask = ['-', '-', '-', ' ', '-', '-', '-', ' ', /\d/, /\d/, /\d/];

  setupBindings(): void {}

  setupViews(): void {}

  public formSubmitted(req: VerifyMemberRequest) {
    // API requires birthday to be in format YYYY-MM-DD
    // Birthday form item outputs in format DD/MM/YYYY
    req.birthdate = StringExtensions.convertDateFormatForBackend(req.birthdate);
    req.sin = StringExtensions.removeDashesFromSin(req.sin);
    this.viewModel.verifyMember(req);
  }

  public openContactPage(): void {
    this.viewModel.openContactPage();
  }

  protected readonly AssetSize = AssetSize;
}
