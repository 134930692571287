import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ChangeAddressRequest } from '../../../../models/users/requests/submissionRequests/change-address-request';
import { TypeService } from '../../../../services/type/type-service';
import { DropDownItem } from '../../../../models/shared/stylesheet/drop-down-item';
import { AbstractSubmissionFormViewModel } from '../submission-forms/abstract-submission-form/abstract-submission-form-view-model';

@Injectable()
export class AddressChangeSubmissionFormViewModel extends AbstractSubmissionFormViewModel<ChangeAddressRequest> {
  constructor(public router: Router, public ngbModal: NgbModal) {
    super(router, ngbModal);
  }

  private typeService = inject(TypeService);

  protected createSubmissionMethod = (req: ChangeAddressRequest) =>
    this.submissionsDomainModel.createMemberAddressChangeSubmission(req);
  protected successMessage = $localize`Address Change Request Submitted`;

  private _selectedCountryId = new BehaviorSubject<number>(0);
  public selectedCountryId$ = this._selectedCountryId as Observable<number>;

  public countryChanged(countryId: number): void {
    this._selectedCountryId.next(countryId);
  }

  public countryDropdowns$ = this.typeService.countries$.pipe(
    map(countries => {
      return countries.map(c => new DropDownItem(c?.name, c?.id));
    })
  );

  public stateDropdowns$ = combineLatest([this.typeService.countries$, this.selectedCountryId$]).pipe(
    map(([countries, selectedCountryId]) => {
      return countries?.find(c => c.id === selectedCountryId)?.states?.map(s => new DropDownItem(s?.name, s?.id));
    })
  );
}
