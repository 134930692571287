import { AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';
import { Directive } from '@angular/core';
import * as moment from 'moment';

export function dateValidator(): ValidatorFn {
  return (control: AbstractControl): { [p: string]: any } | null => {
    const { value } = control;
    if (!value) return null;
    const parsedDate = moment(value, 'DD/MM/YYYY', true);

    if (parsedDate.isValid() && parsedDate.format('DD/MM/YYYY') === value && parsedDate.isAfter(moment(), 'day')) {
      return null;
    } else {
      return { [DateInFutureValidatorDirective.key]: true };
    }
  };
}

@Directive({
  selector: '[appDateInFutureValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DateInFutureValidatorDirective, multi: true }]
})
export class DateInFutureValidatorDirective {
  static key: string = 'dateInFuture';

  validate(control: AbstractControl): { [key: string]: any } {
    // @ts-ignore
    return dateValidator()(control);
  }
}
