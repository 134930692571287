import { Directive, Inject } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appUniqueSinValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: UniqueSinValidatorDirective, multi: true }]
})
export class UniqueSinValidatorDirective {
  static key: string = 'sinNumberExists';

  constructor(@Inject('sinNumbers') public sinNumbers: string[]) {
    this.sinNumbers = sinNumbers;
  }

  getErrorMsg(sinNumbers: string[], value: string): string | null {
    const sanitizedValue = value?.replace(/\s+/g, '');
    if (sinNumbers?.length && sinNumbers?.includes(sanitizedValue)) return $localize`SIN already exists.`;
    return null;
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    const errorMsg = this.getErrorMsg(this.sinNumbers, control.value);
    if (errorMsg) return { [UniqueSinValidatorDirective.key]: errorMsg };
    return null;
  }
}
