import { Deserializable } from '../protocols/deserializable';
import { PortalTypeIdEnum } from '../enum/shared/portal-type-id.enum';
import { ReleaseNote } from './release-note';
import * as moment from 'moment';

export class ReleaseNoteDocument implements Deserializable {
  public id!: number;
  public releaseDate!: Date;
  public version!: string;
  public portalTypeId!: PortalTypeIdEnum;
  public isLatestReleasedVersion!: boolean;
  public releaseNotes: ReleaseNote[] = [];

  // Not from API
  public isReleased!: boolean;

  onDeserialize(): void {
    this.isReleased = moment(this.releaseDate).isBefore(moment());
  }
}
