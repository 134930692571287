import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { SlidingWindowOptions } from '../../../../../models/shared/sliding-window-options';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-sliding-window-header',
  templateUrl: './sliding-window-header.component.html',
  styleUrls: ['./sliding-window-header.component.scss']
})
export class SlidingWindowHeaderComponent extends BaseComponent implements OnChanges {
  @Input() slidingWindowOptions!: SlidingWindowOptions | null;
  @Output() closeButtonClicked = new EventEmitter<void>();

  private _slidingWindowOptions = new BehaviorSubject<SlidingWindowOptions | null>(new SlidingWindowOptions());
  public readonly slidingWindowOptions$ = this._slidingWindowOptions as Observable<SlidingWindowOptions>;

  setupBindings(): void {}

  setupViews(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.slidingWindowOptions) {
      this._slidingWindowOptions.next(this.slidingWindowOptions);
    }
  }
}
