import { Component, EventEmitter, inject, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseAuthComponent } from '../../../../../models/base/base-auth-component';
import { EmployerAuthFlow } from '../../../../../models/account/enum/employer-auth-flow.enum';
import { EmployerAuthViewModel } from '../../../viewModels/employer-auth-view-model';
import { LoadingOptions } from '../../../../../models/shared/loading-options';

@Component({
  selector: 'app-employer-auth',
  templateUrl: './employer-auth.component.html',
  styleUrls: ['./employer-auth.component.scss']
})
export class EmployerAuthComponent extends BaseAuthComponent {
  private route = inject(ActivatedRoute);

  @Output() loadingOpts = new EventEmitter<LoadingOptions>();

  constructor(public viewModel: EmployerAuthViewModel, private router: Router) {
    super();
  }

  protected readonly AuthFlow = EmployerAuthFlow;

  parseAuthFlow() {
    const urlSub = this.route.url.subscribeWhileAlive({
      owner: this,
      next: url => {
        switch (url[url.length - 1].path) {
          case 'sign-in': {
            this.viewModel.setAuthFlow(this.AuthFlow.SignIn);
            break;
          }
          case 'forgot-password': {
            this.viewModel.setAuthFlow(this.AuthFlow.ForgotPassword);
            break;
          }
          case 'reset-password': {
            this.viewModel.setAuthFlow(this.AuthFlow.ResetPassword);
            break;
          }
          case 'employer-choose-password': {
            this.viewModel.setAuthFlow(this.AuthFlow.EmployerChoosePassword);
            break;
          }
          case 'verify-mfa': {
            this.viewModel.setAuthFlow(this.AuthFlow.EmployerMFAVerification);
            break;
          }
        }
      }
    });
    this.pushSub(urlSub);
  }

  setupBindings(): void {
    this.parseAuthFlow();
    this.viewModel.loadingOpts$.subscribeWhileAlive({
      owner: this,
      next: opts => this.loadingOpts.emit(opts)
    });
  }

  setupViews(): void {}
}
