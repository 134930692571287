<app-base-action-modal
  title="Are you sure you want to delete?"
  primaryLabel="Delete Alert"
  secondaryLabel="Cancel"
  i18n-title
  i18n-primaryLabel
  i18n-secondaryLabel
  (closeClicked)="close(false)"
  (primaryClicked)="close(true)"
  (secondaryClicked)="close(false)">
  <p i18n>The alert cannot be recovered once deleted.</p>
</app-base-action-modal>
