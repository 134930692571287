import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { BehaviorSubject, Observable } from 'rxjs';
import { Alert } from '../../../../../models/alerts/dto/alert';
import { map } from 'rxjs/operators';
import {
  MAX_ALERT_DESCRIPTION_LENGTH,
  MAX_ALERT_TITLE_LENGTH
} from '../../../../settings/components/alerts/alert-details/alert-details.component';

@Component({
  template: ''
})
export abstract class BaseAlertComponent extends BaseComponent implements OnChanges {
  @Input() alert!: Alert;

  private _alert = new BehaviorSubject<Alert>(new Alert());
  public alert$ = this._alert as Observable<Alert>;

  public maxTitleLength = MAX_ALERT_TITLE_LENGTH;
  public maxDescriptionLength = MAX_ALERT_DESCRIPTION_LENGTH;

  public title$ = this.alert$.pipe(
    map(alert => {
      if (!alert || !alert.title) return '';
      if (alert.skipTitleTruncation) return alert.title;
      return alert.title.length <= this.maxTitleLength
        ? alert.title
        : '...' + alert.title.substring(alert.title.length - this.maxTitleLength);
    })
  );

  public description$ = this.alert$.pipe(
    map(alert => {
      if (!alert || !alert.description) return '';
      return alert.description.length <= this.maxDescriptionLength
        ? alert.description
        : '...' + alert.description.substring(alert.description.length - this.maxDescriptionLength);
    })
  );
  public backgroundColour$ = this.alert$.pipe(map(alert => alert?.colour?.backgroundColour));
  public borderColor$ = this.alert$.pipe(map(alert => alert?.colour?.borderColour));

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.alert) this._alert.next(this.alert);
  }
}
