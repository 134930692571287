import { Deserializable } from '../../protocols/deserializable';
import { GoverningJurisdictionType } from '../../employers/employer-history/types/governing-jurisdiction-type';
import { Account } from '../../transactions/account';
import { MemberUser } from './member-user';

export class Employer implements Deserializable {
  public id!: number;
  public name!: string;
  public planId!: number;
  public governingJurisdictionId!: number;
  public governingJurisdiction!: GoverningJurisdictionType;
  public accounts!: Account[];
  public users!: MemberUser[];

  public onDeserialize() {
    if (!!this.governingJurisdiction) {
      this.governingJurisdiction = window?.injector?.Deserialize.instanceOf(
        GoverningJurisdictionType,
        this.governingJurisdiction
      );
    }
    if (!!this.accounts) {
      this.accounts = window?.injector?.Deserialize.arrayOf(Account, this.accounts);
    }
    if (!!this.users) {
      this.users = window?.injector?.Deserialize.arrayOf(MemberUser, this.users);
    }
  }
}
