import { inject, Injectable } from '@angular/core';
import { Cachable } from '../models/protocols/cachable';
import { Asset } from '../models/asset/dto/asset';
import { DateUtils } from '../utils/date-utils';
import { StringifyUtils } from '../utils/stringify-utils';
import { GenericCacheItem } from '../models/shared/generic-cache-item';
import { SessionContainer } from '../models/shared/session-container';
import { DefaultCacheKey } from '../models/enum/shared/default-cache-key.enum';
import { CachePolicy } from '../models/enum/shared/cachable-image-policy.enum';
import { CachedBlob } from '../models/cache/cached-blob';
import * as LZUTF8 from 'lzutf8';
import { MediaType } from '../models/enum/dto/media-type.enum';
import { SafeResourceUrl } from '@angular/platform-browser';
import { CacheVersion } from '../models/cache/cache-version';
import { AssetSize } from '../models/enum/dto/asset-size.enum';
import { PortalService } from './portal/portal.service';

const MAX_CACHE_ITEM_SIZE = 2500000;
const CHUNKED_CACHE_KEY = 'CHUNKED_CACHE_KEY';

@Injectable({ providedIn: 'root' })
export class CacheService {
  constructor() {
    const currentVersion = this.getCacheVersion()?.version;
    if (currentVersion !== this.cacheVersion) {
      this.clearAllCaches();
    }
  }

  private portalService = inject(PortalService);
  private readonly cacheVersion = require('../../../package.json').version;
  private persistent = localStorage;
  private session = sessionStorage;
  private service = new Map<string, any>();

  static chunkCacheValue(key: string, val: string): [string[], Map<string, string>] | null {
    // Return chunkKeys, chunkValueMap
    const chunkedValueMap = new Map<string, string>();
    if (val.length > MAX_CACHE_ITEM_SIZE) {
      const chunks = CacheService.chunkSubstr(val, MAX_CACHE_ITEM_SIZE);
      const chunkKeys: string[] = [];
      for (let i = 0; i < chunks.length; ++i) {
        const ck = CacheService.buildChunkedValKey(key, i);
        chunkKeys.push(ck);
        chunkedValueMap.set(ck, chunks[i]);
      }
      return [chunkKeys, chunkedValueMap];
    } else {
      return null;
    }
  }

  static chunkSubstr(str: string, size: number): string[] {
    const numChunks = Math.ceil(str.length / size);
    const chunks = new Array(numChunks);
    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      chunks[i] = str.substr(o, size);
    }
    return chunks;
  }

  static buildChunkedValKey(key: string, i: number): string {
    return `${CHUNKED_CACHE_KEY}-${key}-${i}`;
  }

  static getChunkedValKeyIndex(key: string, chunkKey: string): number {
    const i = chunkKey.replace(`${CHUNKED_CACHE_KEY}-${key}-`, '');
    // eslint-disable-next-line radix
    return parseInt(i, 10);
  }

  static localStorageSpace(cache: any): string {
    let allStrings = '';
    for (const key in cache) {
      if (cache.hasOwnProperty(key)) {
        allStrings += cache[key];
      }
    }
    return allStrings ? 3 + (allStrings.length * 16) / (8 * 1024) + ' KB' : 'Empty (0 KB)';
  }

  isPersistentEnabled(): boolean {
    return this.persistent === localStorage;
  }

  clearAllCaches() {
    this.clearSessionCache();
    this.clearPersistentCache();
    this.clearServiceCache();
  }

  clearSessionCache() {
    this.session.clear();
  }

  clearPersistentCache() {
    this.persistent.clear();
    this.setCacheVersion();
  }

  clearServiceCache() {
    this.service.clear();
  }

  // Getters

  public getCachedGeneric<T extends string | number>(
    key: string,
    persistentCache: boolean = false
  ): T | null | undefined {
    const cachePolicy = persistentCache ? CachePolicy.Persistent : CachePolicy.Session;
    const result = this.getCacheItemString(key, cachePolicy);
    if (!!result) {
      let resp;
      try {
        resp = window?.injector?.Deserialize?.instanceOf(GenericCacheItem, JSON.parse(result));
      } catch (_) {
        this.clearAllCaches();
        return null;
      }
      if (!!resp && resp.isExpired()) {
        // Expired, clear cache
        const imagePolicy = persistentCache ? CachePolicy.Persistent : CachePolicy.Session;
        this.removeCachedObject(key, imagePolicy);
        return null;
      } else if (!!resp) {
        return resp.item as T;
      }
    } else {
      return null;
    }
    return null;
  }

  public getCachedObject<T extends Cachable>(
    respObjectType: new () => T,
    key: string,
    persistentCache: boolean = false,
    optionalDeserializeParam?: any
  ): T | null {
    const cachePolicy = persistentCache ? CachePolicy.Persistent : CachePolicy.Session;
    const result = this.getCacheItemString(key, cachePolicy);
    if (!!result) {
      let resp: T;
      try {
        resp = window?.injector?.Deserialize?.instanceOf(respObjectType, JSON.parse(result), optionalDeserializeParam);
      } catch (_) {
        return null;
      }
      // Pull any images from image cache
      if (resp.isExpired()) {
        // Expired, clear cache
        this.removeCachedObject(key);
        return null;
      } else {
        return resp;
      }
    } else {
      return null;
    }
  }

  public getCachedArray<T extends Cachable>(
    respObjectType: new () => T,
    key: string,
    persistentCache: boolean = false
  ): T[] | null {
    const cachePolicy = persistentCache ? CachePolicy.Persistent : CachePolicy.Session;
    const result = this.getCacheItemString(key, cachePolicy);
    if (!!result) {
      let resp;
      try {
        resp = window?.injector?.Deserialize?.arrayOf(respObjectType, JSON.parse(result));
      } catch (_) {
        this.clearAllCaches();
        return null;
      }
      if (!!resp) {
        const containsExpiredObject = resp?.filter(o => o.isExpired()).length > 0;
        if (containsExpiredObject) {
          // Expired, clear cache
          this.removeCachedObject(key, cachePolicy);
          return null;
        } else {
          return resp;
        }
      }
    } else {
      return null;
    }
    return null;
  }

  public cacheGeneric<T extends string | number>(key: string, obj: T, persistentCache: boolean = false) {
    const gc = new GenericCacheItem();
    gc.key = key;
    gc.item = obj;
    this.setCacheItem(key, gc, persistentCache);
  }

  public cacheObject<T extends Cachable>(key: string, obj: T, persistentCache: boolean = false) {
    this.setCacheItem(key, obj, persistentCache);
  }

  public cacheArray<T extends Cachable>(key: string, objects: T[], persistentCache: boolean = false) {
    this.setCacheItems(key, objects, persistentCache);
  }

  public removeCachedObject(key: string, cachePolicy: CachePolicy = CachePolicy.Session) {
    switch (cachePolicy) {
      case CachePolicy.Persistent:
        this.persistent.removeItem(key);
        break;
      case CachePolicy.Session:
        this.session.removeItem(key);
        break;
      case CachePolicy.Service:
        this.service.delete(key);
    }
  }

  // Methods to read / write to cache

  public removeCachedAsset(asset: Asset, cachePolicy: CachePolicy = CachePolicy.Service) {
    if (!!asset && !!asset?.links) {
      asset.links.forEach(u => {
        const key = u.buildCacheKey();
        this.removeCachedObject(key, cachePolicy);
        u.srcUrl.next([AssetSize.Null, '']);
      });
    }
  }

  public cacheBlob(
    key: string,
    base64data: string | ArrayBuffer | null,
    blob: Blob,
    mediaType: MediaType,
    cachePolicy: CachePolicy = CachePolicy.Service
  ): CachedBlob | null {
    let data: string | null;
    if (base64data instanceof ArrayBuffer) {
      const enc = new TextDecoder('utf-8');
      data = enc.decode(base64data);
    } else {
      data = base64data;
    }
    if (data) {
      const cachedBlob = new CachedBlob(DateUtils.currentTimestamp(), data, blob, mediaType);
      if (cachePolicy !== CachePolicy.Service) {
        this.cacheObjectTo(key, JSON.stringify(cachedBlob, StringifyUtils.cachedBlobReplacer), cachePolicy);
      } else {
        this.cacheObjectTo(key, cachedBlob, cachePolicy);
      }
      return cachedBlob;
    }
    return null;
  }

  public cacheObjectTo(key: string, data: string | CachedBlob, cachePolicy: CachePolicy) {
    const isString = typeof data === 'string';

    try {
      if (isString) {
        switch (cachePolicy) {
          case CachePolicy.Persistent:
            this.setToPersistentCache(key, data as string);
            break;
          case CachePolicy.Session:
            this.setToSessionCache(key, data as string);
            break;
          case CachePolicy.Service:
            this.service.set(key, data as string);
            break;
        }
      } else {
        this.service.set(key, data);
      }
    } catch (e) {
      if (cachePolicy === CachePolicy.Session || cachePolicy === CachePolicy.Persistent) {
        this.clearFullCache(cachePolicy === CachePolicy.Persistent);
      }
    }
  }

  private setCacheItem<T extends Cachable>(key: string, obj: T, persistentCache: boolean = false) {
    obj.cachedTime = DateUtils.currentTimestamp();
    const objString = JSON.stringify(obj, StringifyUtils.replacer);
    try {
      if (persistentCache) {
        this.setToPersistentCache(key, objString);
      } else {
        this.setToSessionCache(key, objString);
      }
    } catch (e) {
      this.clearFullCache(persistentCache);
    }
  }

  private setCacheItems<T extends Cachable>(key: string, obj: T[], persistentCache: boolean = false) {
    const currTime = DateUtils.currentTimestamp();
    obj.map(o => (o.cachedTime = currTime));
    try {
      if (persistentCache) {
        this.setToPersistentCache(key, JSON.stringify(obj, StringifyUtils.replacer));
      } else {
        this.setToSessionCache(key, JSON.stringify(obj, StringifyUtils.replacer));
      }
    } catch (e) {
      this.clearFullCache(persistentCache);
    }
  }

  public getCacheItemString(key: string, cachePolicy: CachePolicy = CachePolicy.Session): string | null {
    switch (cachePolicy) {
      case CachePolicy.Persistent:
        return this.getFromPersistentCache(key);
      case CachePolicy.Session:
        return this.getFromSessionCache(key);
      case CachePolicy.Service:
        return this.service.get(key);
      default:
        return null;
    }
  }

  public getCachedBlob(
    key: string,
    cachePolicy: CachePolicy = CachePolicy.Service,
    cacheForNSeconds: number
  ): SafeResourceUrl | undefined {
    let result: string | CachedBlob = '';
    switch (cachePolicy) {
      case CachePolicy.Persistent:
        result = this.getFromPersistentCache(key);
        break;
      case CachePolicy.Session:
        result = this.getFromSessionCache(key);
        break;
      case CachePolicy.Service:
        result = this.service.get(key);
        break;
    }
    const isString = typeof result === 'string';
    let cachedBlob: CachedBlob | undefined;
    if (isString) {
      cachedBlob = window?.injector?.Deserialize?.instanceOf(CachedBlob, JSON.parse(result as string));
    } else {
      cachedBlob = result as CachedBlob;
    }
    const cached = cachedBlob?.cacheTime ?? 0;
    const expires = cached + cacheForNSeconds;
    const now = DateUtils.currentTimestamp();
    if (now < expires) {
      return cachedBlob?.safeUrl;
    } else {
      this.removeCachedObject(key, cachePolicy);
      return undefined;
    }
  }

  //  Helpers

  private clearFullCache(persistentCache: boolean) {
    this.portalService.portalType$.once(portalType => {
      // TODO: Decide if these console.logs are useful/necessary before app launch
      // eslint-disable-next-line no-console
      console.log(`${persistentCache ? 'Persistent cache' : 'Session cache'} is full. Performing clear cache.`);
      // Get cached session contain, as it should always be cached
      const sessKey = DefaultCacheKey.SessionContainer;
      const cachedSessContainer = this.getCachedObject<SessionContainer>(
        SessionContainer,
        sessKey,
        persistentCache,
        portalType
      );
      if (persistentCache) {
        // eslint-disable-next-line no-console
        console.log(CacheService.localStorageSpace(this.persistent));
        this.clearPersistentCache();
      } else {
        // eslint-disable-next-line no-console
        console.log(CacheService.localStorageSpace(this.session));
        this.clearSessionCache();
      }
      if (!!cachedSessContainer) {
        this.setCacheItem(sessKey, cachedSessContainer, persistentCache);
      }
    });
  }

  // Read/Write chunked & compressed data to caches

  private setToPersistentCache(key: string, val: string) {
    this.setToCache(this.persistent, key, val);
  }

  private setToSessionCache(key: string, val: string) {
    this.setToCache(this.session, key, val);
  }

  private getFromPersistentCache(key: string): string {
    return this.getFromCache(this.persistent, key);
  }

  private getFromSessionCache(key: string): string {
    return this.getFromCache(this.session, key);
  }

  private setToCache(cache: any, key: string, val: string) {
    try {
      val = LZUTF8.compress(val, { outputEncoding: 'Base64' });
    } catch (_) {
      this.clearAllCaches();
      return;
    }
    const chunkTuple = CacheService.chunkCacheValue(key, val);
    if (!!chunkTuple) {
      // Save val in chunks
      const chunkKeys = chunkTuple[0];
      const chunkValueMap = chunkTuple[1];
      cache.setItem(key, JSON.stringify(chunkKeys));
      chunkValueMap.forEach((value, k: string) => {
        cache.setItem(k, value);
      });
    } else {
      cache.setItem(key, val);
    }
  }

  private getFromCache(cache: any, key: string): string {
    let cachedVal: string = cache.getItem(key);
    try {
      // Try and parse the cached value into chunked strings
      const chunkedKeys = JSON.parse(cachedVal) as Array<string>;
      if (!!chunkedKeys && chunkedKeys.every(k => k.includes(CHUNKED_CACHE_KEY))) {
        // Get chunked cache vals and build full obj
        const resultsMap = new Map<number, string>();
        chunkedKeys.forEach(ck => {
          const chunkIndex = CacheService.getChunkedValKeyIndex(key, ck);
          resultsMap.set(chunkIndex, cache.getItem(ck));
        });
        let finalResult: string = '';
        for (let i = 0; i < resultsMap.size; ++i) {
          finalResult += resultsMap.get(i);
        }
        cachedVal = finalResult;
      }
    } catch (_) {
      // Do not do anything here. The code will continue on.
      // Failed to parse into chunked strings, so this means it is a small enough cached value to handle
    }
    if (!!cachedVal) {
      try {
        return LZUTF8.decompress(cachedVal, { inputEncoding: 'Base64' });
      } catch (_) {
        this.clearAllCaches();
        throw new Error(`Unable to get from cache ${key}.`);
      }
    } else {
      return cachedVal;
    }
  }

  setCacheVersion(): void {
    const v = new CacheVersion(this.cacheVersion);
    this.cacheObject<CacheVersion>(v.cacheKey(), v, true);
  }

  getCacheVersion(): CacheVersion | null | undefined {
    return this.getCachedObject<CacheVersion>(CacheVersion, CacheVersion.key, true);
  }
}
