<div #reorderItem class="reorder-item" cdkDrag>
  <div class="reorder-header">
    <div *ngIf="showVisibility">
      <app-fake-check-box [checked]="!item?.getDisabledValue()" (clicked)="item.setDisabledValue(!$event)">
      </app-fake-check-box>
    </div>
    <div *ngIf="showIcons">
      <img [src]="item?.orderableIconSrc" [style.height.rem]="1" alt="" />
    </div>
    <div class="reorder-value ellipsis-nowrap-text">
      {{ item.getOrderableTitle() }}
    </div>
    <div class="reorder-icon">
      <img [src]="'assets/icons/dark/Menu.svg'" alt="" />
    </div>
  </div>
  <div class="reorder-placeholder" [style.height]="(reorderItemHeight$ | async) + 'px'" *cdkDragPlaceholder></div>
</div>
