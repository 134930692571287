import { Component, EventEmitter, inject, Output, ViewChild } from '@angular/core';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../../../services/toast-service';
import { ForgotPasswordRequest } from '../../../../../models/users/requests/forgot-password-request';
import { BaseComponent } from '../../../../../models/base/base-component';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReactiveFormEmailComponent } from '@csspension/reactive-form';
import { ForgotPasswordViewModel } from '../../../viewModels/forgot-password-view-model';
import { PortalService } from '../../../../../services/portal/portal.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss', '../auth.component.scss']
})
export class ForgotPasswordComponent extends BaseComponent {
  @ViewChild('resetCodeEmail') resetCodeEmail!: ReactiveFormEmailComponent;
  viewModel = inject(ForgotPasswordViewModel);
  route = inject(ActivatedRoute);
  portalService = inject(PortalService);

  @Output() loadingOpts = new EventEmitter<LoadingOptions>();

  // View
  public _loadingOpts = new BehaviorSubject(LoadingOptions.default());
  public loadingOpts$ = this._loadingOpts as Observable<LoadingOptions>;
  // Variables
  public readonly req$ = this.viewModel.preFillEmail$.pipe(map(email => new ForgotPasswordRequest(email)));
  public readonly resetPasswordText = $localize`Reset Password`;
  public readonly emailSentText = $localize`Reset Password Link Sent`;

  constructor(private router: Router, private toastService: ToastService) {
    super();
  }

  setupViews() {}

  setupBindings() {
    this.viewModel.loadingOpts$.subscribeWhileAlive({
      owner: this,
      next: opts => this.loadingOpts.emit(opts)
    });
    this.viewModel.getUrlParams(this.route);
  }

  formSubmitted(req: ForgotPasswordRequest) {
    this.portalService.portalType$.once(type => {
      this.viewModel.forgotPassword(req, type);
    });
  }
}
