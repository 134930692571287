import { BaseDomainModel } from '../models/base/base-domain-model';
import { inject, Injectable } from '@angular/core';
import { CreateRemittanceRequest } from '../models/remittances/create-remittance-request';
import { RemittancesAPI } from '../api/remittances-api';
import { Observable } from 'rxjs';
import { Remittance } from '../models/remittances/remittance';
import { map } from 'rxjs/operators';
import { ContributionEntry } from '../models/remittances/contribution-entry';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { Contribution } from '../models/remittances/contribution';
import { AddContributionRequest } from '../models/account/requests/add-contribution-request';
import { RemittanceStatusUpdateRequest } from '../models/account/requests/remittance-status-update-request';
import { RemittanceMember } from '../models/remittances/remittance-member';
import { RemittanceHistory } from '../models/remittances/remittance-history';
import { ODataResponse } from '../models/protocols/odata-response';
import { RemittanceAddDatesRequest } from '../models/remittances/remittance-add-dates-request';

@Injectable()
export class RemittanceDomainModel extends BaseDomainModel {
  private remittancesAPI = inject(RemittancesAPI);

  public getOpenRemittancesForEmployer(employerId: number): Observable<Remittance[]> {
    return this.remittancesAPI.getOpenRemittancesForEmployer(employerId).pipe(map(response => response.value));
  }

  public getClosedRemittancesForEmployer(
    employerId: number,
    odataQueryOptions: ODataQueryOptions
  ): Observable<ODataResponse<Remittance>> {
    return this.remittancesAPI.getClosedRemittancesForEmployer(employerId, odataQueryOptions);
  }

  public getRemittanceById(remittanceId: number, queryOptions: ODataQueryOptions): Observable<Remittance> {
    return this.remittancesAPI.getRemittanceById(remittanceId, queryOptions);
  }

  public getRemittanceHistory(remittanceId: number): Observable<RemittanceHistory[]> {
    return this.remittancesAPI.getRemittanceHistory(remittanceId);
  }

  public createRemittance(req: CreateRemittanceRequest): Observable<Remittance> {
    return this.remittancesAPI.createRemittance(req);
  }

  public updateRemittanceStatus(
    employerId: number,
    remittanceId: number,
    req: RemittanceStatusUpdateRequest
  ): Observable<Remittance> {
    return this.remittancesAPI.updateRemittanceStatus(employerId, remittanceId, req);
  }

  public addDatesToRemittance(remittanceId: number, req: RemittanceAddDatesRequest): Observable<Remittance> {
    return this.remittancesAPI.addDatesToRemittance(remittanceId, req);
  }

  public submitRemittance(employerId: number, remittanceId: number): Observable<Remittance> {
    return this.remittancesAPI.submitRemittance(employerId, remittanceId);
  }

  public deleteRemittance(employerId: number, remittanceId: number): Observable<any> {
    return this.remittancesAPI.deleteRemittance(employerId, remittanceId);
  }

  public getMembersOnRemittance(
    employerId: number,
    remittanceId: number,
    searchString?: string
  ): Observable<RemittanceMember[]> {
    return this.remittancesAPI
      .getMembersOnRemittance(employerId, remittanceId, searchString)
      .pipe(map(response => response.value));
  }

  public addContribution(remittanceId: number, req: AddContributionRequest): Observable<Contribution> {
    return this.remittancesAPI.addContribution(remittanceId, req);
  }

  public deleteContribution(employerId: number, remittanceId: number, contributionId: string): Observable<any> {
    return this.remittancesAPI.deleteContribution(employerId, remittanceId, contributionId);
  }

  public getContributionsForRemittance(
    remittanceId: number,
    queryOptions: ODataQueryOptions = new ODataQueryOptions()
  ): Observable<Contribution[]> {
    return this.remittancesAPI
      .getContributionsForRemittance(remittanceId, queryOptions)
      .pipe(map(response => response.value));
  }

  public createContributionEntry(
    remittanceId: number,
    contributionId: string,
    entries: ContributionEntry[]
  ): Observable<Contribution> {
    return this.remittancesAPI.createContributionEntry(remittanceId, contributionId, entries);
  }

  public updateContributionEntry(
    remittanceId: number,
    contributionId: string,
    entry: any
  ): Observable<ContributionEntry> {
    return this.remittancesAPI.updateContributionEntry(remittanceId, contributionId, entry);
  }

  public deleteContributionEntry(remittanceId: number, contributionId: string, entryId: string): Observable<any> {
    return this.remittancesAPI.deleteContributionEntry(remittanceId, contributionId, entryId);
  }

  public getContributionHistory(remittanceId: string, contributionId: string): Observable<RemittanceHistory[]> {
    return this.remittancesAPI.getContributionHistory(remittanceId, contributionId);
  }
}
