import { OdataFilterGenerator } from '../../../interfaces/odata-filter-generator';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { Deserializable } from '../../protocols/deserializable';
import * as moment from 'moment/moment';
import { ReleaseNoteStatusEnum } from '../../enum/shared/release-note-status.enum';

export class ReleaseNotesTableFilterRequest implements OdataFilterGenerator, UniquelyIdentifiable, Deserializable {
  public version: string = '';
  public startDate: string = '';
  public endDate: string = '';
  public portalType: string[] = [];
  public statuses: string[] = [];

  public searchVersion: string = '';
  public searchReleaseDate: string = '';
  public searchPortalTypeId: string = '';
  public searchStatus: string | null = '';

  private filterCount: number = 0;
  private searchCount: number = 0;

  [key: string]: any; // Index signature to allow dynamic property querying

  getFilterString(): string | null {
    const filterArray = [];

    if (this.startDate !== '') {
      const startDate = moment(this.startDate).startOf('day');
      let endDate = startDate.clone().endOf('day');
      if (this.endDate !== '') {
        endDate = moment(this.endDate).endOf('day');
      }
      filterArray.push(
        `releaseDate ge cast(${startDate.toISOString(
          true
        )}, Edm.DateTimeOffset) and releaseDate le cast(${endDate.toISOString(true)}, Edm.DateTimeOffset)`
      );

      this.filterCount++;
    }

    if (this.portalType?.length > 0) {
      const portalTypes = this.portalType.map(portalTypeId => `portalTypeId eq ${portalTypeId}`).join(' or ');
      this.filterCount = this.filterCount + this.portalType.length;
      filterArray.push(`(${portalTypes})`);
    }

    if (this.statuses?.length > 0) {
      if (this.statuses.length === 1) {
        this.statuses[0] === ReleaseNoteStatusEnum.Released
          ? filterArray.push(`releaseDate lt cast(${moment().toISOString(true)}, Edm.DateTimeOffset)`)
          : filterArray.push(`releaseDate gt cast(${moment().toISOString(true)}, Edm.DateTimeOffset)`);
      }
      this.filterCount++;
    }

    if (this.searchVersion !== '') {
      filterArray.push(`contains(version, '${this.searchVersion}')`);
      this.searchCount++;
    }

    if (this.searchReleaseDate !== '' && this.searchReleaseDate !== null) {
      const startDate = moment(this.searchReleaseDate).startOf('day');
      let endDate = startDate.clone().endOf('day');
      if (this.endDate !== '') {
        endDate = moment(this.endDate).endOf('day');
      }
      filterArray.push(
        `releaseDate ge cast(${startDate.toISOString(
          true
        )}, Edm.DateTimeOffset) and releaseDate le cast(${endDate.toISOString(true)}, Edm.DateTimeOffset)`
      );

      this.searchCount++;
    }

    if (this.searchPortalTypeId !== '' && this.searchPortalTypeId !== null) {
      filterArray.push(`portalTypeId eq ${this.searchPortalTypeId}`);
      this.searchCount++;
    }

    if (this.searchStatus !== '' && this.searchStatus !== null) {
      switch (this.searchStatus) {
        case 'Released':
          filterArray.push(`releaseDate lt cast(${moment().toISOString(true)}, Edm.DateTimeOffset)`);
          break;
        case 'Unreleased':
          filterArray.push(`releaseDate gt cast(${moment().toISOString(true)}, Edm.DateTimeOffset)`);
          break;
      }
      this.searchCount++;
    }

    if (filterArray.length > 1) {
      return filterArray.join(' and ');
    } else if (filterArray.length === 1) {
      return filterArray[0];
    } else {
      return null;
    }
  }

  public getFilterCount(): number {
    this.filterCount = 0;
    this.getFilterString();
    return this.filterCount;
  }

  public getSearchCount(): number {
    this.searchCount = 0;
    this.getFilterString();
    return this.searchCount;
  }

  public clearFilters(): void {
    this.version = '';
    this.startDate = '';
    this.endDate = '';
    this.portalType = [];
    this.statuses = [];
  }

  getUniqueIdentifier(): string {
    return `
    ${this.statuses.join(',')} +
    ${this.portalType.join(',')} +
    ${this.startDate} +
    ${this.endDate} +
    ${this.version} +
    ${this.searchVersion} +
    ${this.searchReleaseDate} +
    ${this.searchStatus} +
    ${this.searchPortalTypeId} +
    ${this.filterCount} +
    ${this.searchCount}`;
  }

  onDeserialize(): void {}
}
