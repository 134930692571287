import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-base-action-modal',
  templateUrl: './base-action-modal.component.html',
  styleUrls: ['./base-action-modal.component.scss']
})
export class BaseActionModalComponent extends BaseModalComponent implements OnChanges {
  // Ensure title is localized before being passed in
  @Input() title: string = '';
  // Ensure primaryLabel is localized before being passed in
  @Input() primaryLabel: string = '';
  // Ensure secondaryLabel is localized before being passed in
  @Input() secondaryLabel: string = '';

  @Input() hidePrimary: boolean = false;
  @Input() hideSecondary: boolean = false;
  @Input() hideClose: boolean = false;
  // Use in conjunction with ignoreEscapeModalOptions to ensure the modal
  // cannot be closed on escape
  @Input() ignoreEscape: boolean = false;

  @Output() closeClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() primaryClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() secondaryClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(private activeModal: NgbActiveModal) {
    super(activeModal);
  }

  setupBindings(): void {}

  setupViews(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
}
