import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoadingOptions } from '../../../../models/shared/loading-options';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  @Input() options: LoadingOptions = LoadingOptions.default();

  constructor(private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    if (!this.options.backgroundColor || this.options.backgroundColor === '') {
      this.options.backgroundColor = '#f7f7f7';
    }
    if (!this.options.color || this.options.color === '') {
      this.options.color = '#13856b';
    }
    if (!this.options.spinnerColor || this.options.spinnerColor === '') {
      this.options.spinnerColor = this.options.color;
    }
    this.spinner.show();
  }
}
