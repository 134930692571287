import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './components/auth/auth.component';
import { AuthGuard } from '../../services/guards/auth.guard';
import { SignOutComponent } from './components/auth/sign-out/sign-out.component';
import { RefreshSessionGuard } from '../../services/guards/refresh-session.guard';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    data: {
      title: 'Account'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'sign-in',
    component: AuthComponent,
    data: {
      title: 'Sign In'
    },
    canActivate: [RefreshSessionGuard]
  },
  {
    path: 'sign-out',
    component: SignOutComponent,
    data: {
      title: 'Signing Out'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'forgot-password',
    component: AuthComponent,
    data: {
      title: 'Forgot Password'
    },
    canActivate: [RefreshSessionGuard]
  },
  {
    path: 'reset-password',
    component: AuthComponent,
    data: {
      title: 'Reset Password'
    },
    canActivate: [RefreshSessionGuard]
  },
  {
    path: 'new-password',
    component: AuthComponent,
    data: {
      title: 'Set New Password'
    },
    canActivate: [RefreshSessionGuard]
  },
  {
    path: 'internal-user-choose-password',
    component: AuthComponent,
    data: {
      title: 'Choose Password'
    },
    canActivate: [RefreshSessionGuard]
  },
  {
    path: 'internal-user-auth-setup',
    component: AuthComponent,
    data: {
      title: 'Welcome'
    },
    canActivate: [RefreshSessionGuard]
  },
  {
    path: 'internal-user-verify-code',
    component: AuthComponent,
    data: {
      title: 'Welcome'
    },
    canActivate: [RefreshSessionGuard]
  },
  {
    path: 'member-welcome',
    component: AuthComponent,
    data: {
      title: 'Welcome'
    },
    canActivate: [RefreshSessionGuard]
  },
  {
    path: 'member-setup',
    component: AuthComponent,
    data: {
      title: 'Setup'
    },
    canActivate: [RefreshSessionGuard]
  },
  {
    path: 'member-invalid-details',
    component: AuthComponent,
    data: {
      title: 'Invalid Details'
    },
    canActivate: [RefreshSessionGuard]
  },
  {
    path: 'member-enter-email-verification',
    component: AuthComponent,
    data: {
      title: 'Enter Email Verification'
    },
    canActivate: [RefreshSessionGuard]
  },
  {
    path: 'member-send-email-verification',
    component: AuthComponent,
    data: {
      title: 'Send Email Verification'
    },
    canActivate: [RefreshSessionGuard]
  },
  {
    path: 'employer-choose-password',
    component: AuthComponent,
    data: {
      title: 'Choose Password'
    },
    canActivate: [RefreshSessionGuard]
  },
  {
    path: 'verify-mfa',
    component: AuthComponent,
    data: {
      title: 'Enter MFA Code'
    },
    canActivate: [RefreshSessionGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
