import { Deserializable } from '../../protocols/deserializable';
import { AttachmentType } from '../../enum/dto/attachment-type';

export class CreateAttachmentRequest implements Deserializable {
  public attachmentTypeId: number;
  public fileName: string;
  public mediaType: string;

  constructor(type: AttachmentType, file: File) {
    this.attachmentTypeId = type;
    this.fileName = file.name;
    this.mediaType = file.type;
  }

  onDeserialize = () => {};
}
