import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { TabBarItem } from './tab-bar-item';
import { BehaviorSubject, combineLatest, defer, Observable, Subject } from 'rxjs';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { StringExtensions } from '../../../../utils/string.extensions';

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss']
})
export class TabBarComponent extends BaseComponent implements OnChanges {
  @Input() public tabs: TabBarItem[] = [];
  @Input() public hideTabBarHeader: boolean = false;
  @Input() public skipFragmentAppending: boolean = false;
  @Input() public showLoadingSpinnerOnTabChange: boolean = false;
  @Input() public loadingMessage: string = '';
  @Input() public loadingTimeMs: number = 500;
  @Input() public canChangeTabs: boolean = true;
  @Input() public canChangeTabsErrorMsg: string | null = null;
  @Input() public useDefaultHeight: boolean = true;
  @Output() public selectedTabIndex = new EventEmitter<number>(true);
  @Output() public previousTabIndex = new EventEmitter<number>(true);
  @Output() public selectedTab = new EventEmitter<TabBarItem>(true);

  constructor(private cdr: ChangeDetectorRef, private router: Router) {
    super();
  }
  private activatedRoute = inject(ActivatedRoute);

  public currentSelectedTab: number = 0;
  private _appendFragmentToUrl = new Subject<TabBarItem>();

  private _tabs = new BehaviorSubject<TabBarItem[]>([]);
  public readonly tabs$ = this._tabs as Observable<TabBarItem[]>;

  private listenToRoute = combineLatest([
    this.activatedRoute.fragment.pipe(distinctUntilChanged()),
    this.tabs$.notEmpty().distinctUniquelyIdentifiableArray()
  ]).subscribeWhileAlive({
    owner: this,
    next: ([fragment, tabs]) => {
      let index = 0;
      if (!!fragment) {
        index = tabs.findIndex(tab => StringExtensions.camelize(tab.title) === fragment);
      }
      this.tabSelected(index);
    }
  });

  private listenToAppendToFrag = this._appendFragmentToUrl
    .pipe(distinctUntilChanged(), takeUntil(this.onDestroy))
    .subscribe(tab => {
      this.appendFragmentToUrl(tab);
    });

  // Loading Options
  protected _loadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.defaultWhiteBackground());
  loadingOpts$ = defer(() => this._loadingOpts);
  isLoading$ = this.loadingOpts$.pipe(
    map(it => it?.isLoading),
    startWith(false)
  );

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tabs) {
      this.setupViews();
      this._tabs.next(this.tabs);
    }
  }

  setupBindings() {}

  setupViews() {
    const selected = this.tabs?.findIndex(tab => tab.active);
    if (selected > -1) {
      this.tabSelected(selected);
    } else {
      this.tabSelected(0);
    }
  }

  public tabSelected(index: number): void {
    if (index === null || index === undefined) return;
    if (this.canChangeTabs) {
      if (this.showLoadingSpinnerOnTabChange) {
        this._loadingOpts.addRequest(this.loadingMessage);
        setTimeout(() => {
          this._loadingOpts.removeRequest(this.loadingMessage);
        }, this.loadingTimeMs);
      }
      this.selectedTabIndex.emit(index);
      this.currentSelectedTab = index;
      this.tabs?.map(t => (t.active = false));
      if (!!this.tabs && this.tabs?.length > this.currentSelectedTab) {
        this.tabs[this.currentSelectedTab].active = true;
        this.selectedTab.emit(this.tabs[this.currentSelectedTab]);
        this._appendFragmentToUrl.next(this.tabs[this.currentSelectedTab]);
      }
    }
    this.cdr.detectChanges();
  }

  private appendFragmentToUrl(tab: TabBarItem) {
    if (!this.skipFragmentAppending) {
      const frag = StringExtensions.camelize(tab.title);
      const navigationExtras: NavigationExtras = { replaceUrl: true, fragment: frag };
      const current = this.router.parseUrl(this.router.url);
      this.router.navigate(current.root.segments, navigationExtras).then();
    }
  }
}
