import { BaseDomainModel } from '../models/base/base-domain-model';
import { Injectable } from '@angular/core';
import { EmployersAPI } from '../api/employers-api';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { EmployerUser } from '../models/users/dto/employer-user';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { ODataResponse } from '../models/protocols/odata-response';
import { catchError, map, tap } from 'rxjs/operators';
import { CreateEmployerUserRequest } from '../models/users/requests/create-employer-user-request';
import { ChangeEmailRequest } from '../models/users/requests/change-email-request';
import { HttpResponse } from '@angular/common/http';
import { EmployerPlan } from '../models/employers/plans/employer-plan';
import { EmployerPlanDesignatedUnit } from '../models/employers/plans/employer-plan-designated-unit';
import { EmployerPlanDesignatedUnitVersion } from '../models/employers/plans/employer-plan-designated-unit-version';
import { EmployerPlanComment } from '../models/employers/plans/employer-plan-comment';
import { EmployerPlanCommentRequest } from '../models/employers/plans/employer-plan-comment-request';
import { CreateEmployerPlanDesignatedUnit } from '../models/employers/plans/create-employer-plan-designated-unit';
import { EmployerSearchResult } from '../models/employers/dto/employer-search-result';
import { DesignatedUnitSearchResult } from '../models/employers/plans/designated-unit-search-result';
import { CreateMemberRequest } from '../models/users/requests/create-member-request';
import { MemberUser } from '../models/users/dto/member-user';
import { Employer } from '../models/users/dto/employer';

// Provided by Logged In Scope
@Injectable()
export class EmployersDomainModel extends BaseDomainModel {
  constructor(private employersAPI: EmployersAPI) {
    super();
  }

  private _employerUsers = new BehaviorSubject<EmployerUser[] | null>(null);
  public employerUsers$ = this._employerUsers as Observable<EmployerUser[]>;

  private _employerUsersTotalCount = new BehaviorSubject<number | undefined>(undefined);
  public employerUsersTotalCount$ = this._employerUsersTotalCount as Observable<number | undefined>;

  public getEmployer(employerId: string): Observable<Employer> {
    return this.employersAPI.getEmployer(employerId).pipe(
      catchError(err => {
        return throwError(() => err);
      })
    );
  }

  public getEmployerUsers(
    oDataQueryOptions: ODataQueryOptions,
    employerId: string
  ): Observable<ODataResponse<EmployerUser>> {
    return this.employersAPI.getUsersForEmployers(oDataQueryOptions, employerId).pipe(
      tap(odataRes => this._employerUsersTotalCount.next(odataRes['@odata.count'])),
      tap(odataRes => this._employerUsers.next(odataRes.value)),
      catchError(err => {
        this._employerUsers.next([]);
        this._employerUsersTotalCount.next(0);
        return throwError(() => err);
      })
    );
  }

  public getEmployerUserById(employerId: string, employerUserId: string): Observable<EmployerUser> {
    return this.employersAPI.getEmployerUserById(employerId, employerUserId);
  }

  public createEmployerUser(req: CreateEmployerUserRequest, employerId: string): Observable<EmployerUser> {
    return this.employersAPI.createEmployerUser(req, employerId);
  }

  public updateEmployerUser(u: EmployerUser): Observable<EmployerUser> {
    return this.employersAPI.updateEmployerUser(u);
  }

  public forceChangeEmail(u: EmployerUser, req: ChangeEmailRequest): Observable<EmployerUser> {
    return this.employersAPI.forceChangeEmail(u, req);
  }

  public forceChangePassword(u: EmployerUser): Observable<HttpResponse<any>> {
    return this.employersAPI.forceResetPassword(u);
  }

  public getEmployerPlanByEmployerId(employerId: string): Observable<EmployerPlan> {
    return this.employersAPI.getEmployerPlanById(employerId);
  }

  public updateEmployerPlan(employerPlan: EmployerPlan): Observable<EmployerPlan> {
    return this.employersAPI.updateEmployerPlan(employerPlan);
  }

  public getEmployerPlanDesignatedUnits(
    planId: string,
    odataQueryOptions: ODataQueryOptions
  ): Observable<ODataResponse<EmployerPlanDesignatedUnit>> {
    return this.employersAPI.getEmployerPlanDesignatedUnitsByPlanId(planId, odataQueryOptions);
  }

  public getEmployerPlanDesignatedUnitById(planId: string, unitId: string): Observable<EmployerPlanDesignatedUnit> {
    return this.employersAPI.getEmployerPlanDesignatedUnitById(planId, unitId);
  }

  public createEmployerPlanDesignatedUnitVersion(
    req: EmployerPlanDesignatedUnitVersion,
    unitId: string
  ): Observable<EmployerPlanDesignatedUnitVersion> {
    return this.employersAPI.createEmployerPlanDesignatedUnitVersion(req, unitId);
  }

  public updateEmployerPlanDesignatedUnitVersion(
    req: EmployerPlanDesignatedUnitVersion
  ): Observable<EmployerPlanDesignatedUnitVersion> {
    return this.employersAPI.updateEmployerPlanDesignatedUnitVersion(req);
  }

  public getEmployerPlanComments(employerPlanId: string): Observable<EmployerPlanComment[]> {
    return this.employersAPI.getEmployerPlanComments(employerPlanId);
  }

  public addEmployerPlanComment(req: EmployerPlanCommentRequest): Observable<EmployerPlanComment[]> {
    return this.employersAPI.addEmployerPlanComment(req);
  }

  public createEmployerPlanDesignatedUnit(
    req: CreateEmployerPlanDesignatedUnit
  ): Observable<EmployerPlanDesignatedUnit> {
    return this.employersAPI.createEmployerPlanDesignatedUnit(req);
  }

  public updateEmployerPlanDesignatedUnit(req: EmployerPlanDesignatedUnit): Observable<EmployerPlanDesignatedUnit> {
    return this.employersAPI.updateEmployerPlanDesignatedUnit(req);
  }

  public searchEmployersByIdOrName(searchString: string): Observable<EmployerSearchResult[]> {
    const queryOptions = new ODataQueryOptions();
    const searchStringIsId = !isNaN(Number(searchString));
    queryOptions.setExpand('GoverningJurisdiction');
    if (searchStringIsId) {
      queryOptions.setFilter(`id eq ${searchString}`);
    } else {
      queryOptions.setFilter(`contains(Name, '${searchString}')`);
    }
    return this.employersAPI.searchEmployersByIdOrName(queryOptions).pipe(map(r => r.value));
  }

  public searchActiveDesignatedUnitsByIdOrName(
    searchString: string,
    employerId: string
  ): Observable<DesignatedUnitSearchResult[]> {
    const queryOptions = new ODataQueryOptions();
    const searchStringIsId = !isNaN(Number(searchString));
    if (searchStringIsId) {
      queryOptions.setFilter(`clientUnitId eq ${searchString}`);
    } else {
      queryOptions.setFilter(`contains(UnitName, '${searchString}')`);
    }
    return this.employersAPI.searchActiveDesignatedUnitsByIdOrName(queryOptions, employerId).pipe(map(r => r.value));
  }

  public getActiveDesignatedUnitsForEmployer(employerId: string): Observable<DesignatedUnitSearchResult[]> {
    const queryOptions = new ODataQueryOptions();
    return this.employersAPI.searchActiveDesignatedUnitsByIdOrName(queryOptions, employerId).pipe(map(r => r.value));
  }

  public createMemberUser(req: CreateMemberRequest, employerId: string): Observable<MemberUser> {
    return this.employersAPI.createMemberUser(req, employerId);
  }
}
