import { Pipe, PipeTransform } from '@angular/core';
import { NavItem } from '../models/nav-item';
import { ProfileNavItem } from '../models/profile-nav-item';

@Pipe({
  name: 'isProfileNavItem'
})
export class IsProfileNavItemPipe implements PipeTransform {
  transform(value: NavItem): boolean {
    return value instanceof ProfileNavItem;
  }
}
