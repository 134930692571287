import { OdataFilterGenerator } from '../../../interfaces/odata-filter-generator';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { Deserializable } from '../../protocols/deserializable';

export class EmployerUserTableFilterRequest implements OdataFilterGenerator, UniquelyIdentifiable, Deserializable {
  public active: boolean[] = [];
  public roles: string[] = [];

  public searchFirstName: string = '';
  public searchLastName: string = '';
  public searchEmail: string = '';
  public searchRoleName: string | null = '';
  public searchStatus: string | null = '';

  private filterCount: number = 0;
  private searchCount: number = 0;

  [key: string]: any; // Index signature to allow dynamic property querying

  getFilterString(): string | null {
    const filterArray = [];
    if (this.active?.length > 0) {
      filterArray.push(`active eq ${this.active}`);
      this.filterCount++;
    }
    if (this.roles?.length > 0) {
      let base = `roles/any(role: `;
      this.roles.forEach(r => {
        base += `role/roleId eq ${r} or `;
        this.filterCount++;
      });
      // removing the last or
      filterArray.push(base.slice(0, -4) + ')');
    }
    if (this.searchFirstName !== '') {
      filterArray.push(`contains(firstName, '${this.searchFirstName}')`);
      this.searchCount++;
    }
    if (this.searchLastName !== '') {
      filterArray.push(`contains(lastName, '${this.searchLastName}')`);
      this.searchCount++;
    }
    if (this.searchEmail !== '') {
      filterArray.push(`contains(email, '${this.searchEmail}')`);
      this.searchCount++;
    }
    if (this.searchRoleName !== '' && this.searchRoleName !== null) {
      filterArray.push(`roles/any(role: role/roleId eq ${this.searchRoleName})`);
      this.searchCount++;
    }
    if (this.searchStatus !== '' && this.searchStatus !== null) {
      filterArray.push(`(active eq ${this.searchStatus})`);
      this.searchCount++;
    }
    if (filterArray.length > 1) {
      return filterArray.join(' and ');
    } else if (filterArray.length === 1) {
      return filterArray[0];
    } else {
      return null;
    }
  }

  public getFilterCount(): number {
    this.filterCount = 0;
    this.getFilterString();
    return this.filterCount;
  }

  public getSearchCount(): number {
    this.searchCount = 0;
    this.getFilterString();
    return this.searchCount;
  }

  public clearFilters(): void {
    this.active = [];
    this.roles = [];
  }

  getUniqueIdentifier(): string {
    return `${this.active.join(',')} +
      ${this.roles.join(',')} +
      ${this.searchFirstName} +
      ${this.searchLastName} +
      ${this.searchEmail} +
      ${this.searchRoleName} +
      ${this.searchStatus} +
      ${this.filterCount} +
      ${this.searchCount}`;
  }

  onDeserialize(): void {}
}
