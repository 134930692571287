import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '../../../utils/date-utils';

@Pipe({
  name: 'currentFormattedDateTime'
})
export class CurrentFormattedDateTimePipe implements PipeTransform {
  transform(value: Date | null): string {
    if (!value) return '-';
    return DateUtils.formatUnixToCurrentDateTime((new Date(value).getTime() ?? 0) / 1000);
  }
}
