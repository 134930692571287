import { BaseViewModel } from '../../../../models/base/base-view-model';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { BaseCommentRequest } from '../../../../models/base/base-comment-request';
import { BaseComment } from '../../../../models/base/base-comment';
import { PortalService } from '../../../../services/portal/portal.service';
import { TabBarItem } from '../tab-bar/tab-bar-item';
import { map } from 'rxjs/operators';
import { SlidingWindowCommentsTabComponent } from './sliding-window-comments-tab/sliding-window-comments-tab.component';
import { SlidingWindowHistoryTabComponent } from './sliding-window-history-tab/sliding-window-history-tab.component';
import { UserTaggingDropdownItem } from '../../../../models/shared/user-tagging-dropdown-item';
import { RemittanceHistory } from '../../../../models/remittances/remittance-history';
import { SlidingWindowOptions } from '../../../../models/shared/sliding-window-options';
import { StringExtensions } from '../../../../utils/string.extensions';

@Injectable()
export class SlidingWindowCommentsAndHistoryViewModel extends BaseViewModel {
  private portalService = inject(PortalService);

  private _isTypingComment = new BehaviorSubject<boolean>(false);
  public isTypingComment$ = this._isTypingComment as Observable<boolean>;

  private _commentReq = new BehaviorSubject<BaseCommentRequest>(new BaseCommentRequest());
  public commentReq$ = this._commentReq as Observable<BaseCommentRequest>;

  private _comments = new BehaviorSubject<BaseComment[]>([]);
  public comments$ = this._comments as Observable<BaseComment[]>;

  private _history = new BehaviorSubject<RemittanceHistory[]>([]);
  public history$ = this._history as Observable<RemittanceHistory[]>;

  private _userDropdownItems = new BehaviorSubject<UserTaggingDropdownItem[]>([]);
  public userDropdownItems$ = this._userDropdownItems as Observable<UserTaggingDropdownItem[]>;

  private _activeTab = new BehaviorSubject<TabBarItem>({} as TabBarItem);
  public activeTab$ = this._activeTab as Observable<TabBarItem>;

  private _isLoadingComments = new BehaviorSubject<boolean>(false);
  public isLoadingComments$ = this._isLoadingComments as Observable<boolean>;

  public newComment$ = new Subject<BaseCommentRequest>();
  public filterText$ = new Subject<string>();
  public submitCommentForm$ = new Subject<void>();

  public tabs$ = this.portalService.isEmployer$.pipe(
    map(isEmployer => {
      const tabs = [];
      if (!isEmployer) {
        tabs.push(new TabBarItem(SlidingWindowCommentsTabComponent, $localize`Comments`, ''));
      }
      tabs.push(new TabBarItem(SlidingWindowHistoryTabComponent, $localize`History`, ''));
      return tabs;
    })
  );

  public commentInProgress$ = combineLatest([this.isTypingComment$, this.activeTab$]).pipe(
    map(([isTypingComment, tab]) => {
      const isCommentsTab = tab.componentType === SlidingWindowCommentsTabComponent;
      return isTypingComment && isCommentsTab;
    })
  );

  public commentWindowOptions$ = this.commentInProgress$.pipe(
    map(commentInProgress => {
      if (commentInProgress) {
        return new SlidingWindowOptions($localize`Updates`, $localize`Cancel`, $localize`Post`);
      } else {
        return new SlidingWindowOptions($localize`Updates`, null, $localize`Done`);
      }
    })
  );

  public setActiveTab(tab: TabBarItem): void {
    this._activeTab.next(tab);
    this._isTypingComment.next(false);
  }

  public onCommentValueChange(comment: string): void {
    this._isTypingComment.next(!StringExtensions.isEmptyOrWhitespace(comment));
  }

  public setComments(comments: BaseComment[]) {
    this._comments.next(comments);
  }

  public setCommentReq(req: BaseCommentRequest) {
    this._commentReq.next(req);
  }

  public submitCommentForm() {
    this.submitCommentForm$.next();
    this._isTypingComment.next(false);
  }

  public newComment(commentReq: BaseCommentRequest) {
    this.newComment$.next(commentReq);
  }

  public filterText(filterString: string) {
    this.filterText$.next(filterString);
  }

  public setUserDropdownItems(userDropdownItems: UserTaggingDropdownItem[]) {
    this._userDropdownItems.next(userDropdownItems);
  }

  public setIsLoadingComments(isLoadingComments: boolean) {
    this._isLoadingComments.next(isLoadingComments);
  }

  public setHistory(history: RemittanceHistory[]) {
    this._history.next(history);
  }
}
