import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-data-label',
  templateUrl: './data-label.component.html',
  styleUrls: ['./data-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataLabelComponent extends BaseComponent {
  @Input() label: string = '--';
  @Input() value: string | number = '--';
  @Input() url: string = '';
  @Input() isLoading = false;

  setupViews(): void {}
  setupBindings(): void {}
}
