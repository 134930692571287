<div class="auth-flow-subtext">
  <div class="header">{{ 'Set Password' | localization }}</div>
  <div class="subtext">
    {{
      'A verification code has been sent to the email address below. If that’s not the correct email address, you’ll have to go back and set a new one.'
        | localization
    }}
  </div>
</div>

<lib-reactive-form-group
  #form
  [bindTo]="req$ | async"
  [customErrorMessageMap]="passwordErrorMap"
  (formSubmitted$)="formSubmitted($event)">
  <lib-reactive-form-email
    [inputName]="'disabled-email'"
    [placeholder]="''"
    [label]="'Email' | localization"
    [showRequiredAstrix]="false"
    [bindingProperty]="'email'"
    [disabled]="true"
    [required]="true">
  </lib-reactive-form-email>

  <lib-reactive-form-string-number
    [inputName]="'code'"
    [integersOnly]="true"
    [required]="true"
    [showRequiredAstrix]="false"
    [label]="'Code' | localization"
    [customValueParser]="null"
    [placeholder]="''"
    [bindingProperty]="'code'">
  </lib-reactive-form-string-number>

  <lib-reactive-form-password
    [inputName]="'password'"
    [required]="true"
    [showRequiredAstrix]="false"
    [creatingOrUpdatingPassword]="true"
    [label]="'New Password' | localization"
    [hintText]="passwordHintText"
    [placeholder]="''"
    [bindingProperty]="'newPassword'">
  </lib-reactive-form-password>

  <lib-reactive-form-password
    [inputName]="'confirmPassword'"
    [required]="true"
    [showRequiredAstrix]="false"
    [confirmPassword]="true"
    [label]="'Confirm Password' | localization"
    [placeholder]="''">
  </lib-reactive-form-password>
</lib-reactive-form-group>

<lib-button-primary class="w-100" (buttonClicked)="form.submitForm()">
  {{ 'Set New Password' | localization }}
</lib-button-primary>
