export enum AlertBackgroundColorEnum {
  CardGreen = 1,
  CardBlue = 2,
  CardYellow = 3,
  CardRed = 4,
  CardGrey = 5,
  BannerGreen = 6,
  BannerBlue = 7,
  BannerYellow = 8,
  BannerRed = 9,
  BannerGrey = 10
}
