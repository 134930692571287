<div class="auth-flow-subtext">
  <div class="header">{{ 'Reset Password' | localization }}</div>
  <div class="subtext">{{ "Enter your email and we'll send you a link to reset your password." | localization }}</div>
</div>

<lib-reactive-form-group #form [bindTo]="req$ | async" (formSubmitted$)="formSubmitted($event)">
  <lib-reactive-form-email
    #resetCodeEmail
    [inputName]="'email'"
    [label]="'Email' | localization"
    [placeholder]="''"
    [bindingProperty]="'email'"
    [showRequiredAstrix]="false"
    [required]="true">
  </lib-reactive-form-email>
</lib-reactive-form-group>

<lib-button-primary
  class="action-button"
  [disabled]="(viewModel.emailSent$ | async) ?? false"
  (buttonClicked)="form.submitForm()">
  {{ (viewModel.emailSent$ | async) ? emailSentText : resetPasswordText }}
</lib-button-primary>

<div class="subtext pt-16px" *ngIf="viewModel.emailSent$ | async">
  {{ 'The email has been sent to the address above with a link to set a new password.' | localization }}
</div>
