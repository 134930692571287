import { Component } from '@angular/core';
import { LookAheadItemComponent } from '../look-ahead-item.component';
import { SubmissionStakeholder } from '../../../../../../../models/submissions/member-submission';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-profile-look-ahead-item',
  templateUrl: './profile-look-ahead-item.component.html',
  styleUrls: ['./profile-look-ahead-item.component.scss', '../look-ahead-item.component.scss']
})
export class ProfileLookAheadItemComponent extends LookAheadItemComponent {
  constructor() {
    super();
  }

  private getItemAsSubmissionStakeHolder$ = this.item$.pipe(map(i => i as SubmissionStakeholder));

  public initials$ = this.getItemAsSubmissionStakeHolder$.pipe(map(i => i.initials));
  public textColor$ = this.getItemAsSubmissionStakeHolder$.pipe(map(i => i.textColor));
  public profileColour$ = this.getItemAsSubmissionStakeHolder$.pipe(map(i => i.profileColour));
}
