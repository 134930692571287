import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalUtils } from '../utils/modal-utils';
import { Injector } from '@angular/core';
import { MemberPersonalInfoModalComponent } from '../views/records/modals/member-personal-info-modal/member-personal-info-modal.component';

export class ModalMemberAddContactInfo {
  static open(ngbModal: NgbModal, injector: Injector): Promise<boolean> {
    const modalRef = ngbModal.open(MemberPersonalInfoModalComponent, ModalUtils.ignoreEscapeModalOptions(injector));
    return modalRef.result;
  }
}
