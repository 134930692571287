<div class="submission-container" [ngClass]="{ 'expiring-soon': submission.expiringSoon }">
  <div class="d-flex flex-column w-100">
    <div class="d-flex justify-content-between">
      <app-status-pill [statusPill]="submission.statusPill"></app-status-pill>
      <div class="sub-id">{{ submission.submissionId }}</div>
    </div>
    <div class="sub-info">
      <span class="sub-info-title" (click)="submissionClicked.emit(submission)">{{
        submission.submissionTypeName
      }}</span>
      <span class="sub-info-date">{{ submissionDatePrefix$ | async }} {{ submission.formattedCreatedDate }}</span>
      <div class="d-flex gap-2 mt-2" *ngIf="submission.expiringSoon">
        <img src="/assets/icons/orange/Clock.svg" alt="Clock" i18n-alt />
        <span i18n>You have until {{ submission.formattedExpirationDate }} to complete this submission</span>
      </div>
    </div>
  </div>
</div>
