/* ******************************** Logged In Scope *****************************
 * Anything that you want to be alive while the user is logged in should be
 * provided here. That means any services, view models, or domain models that are
 * provided here will be alive while the user is logged in, and will be destroyed
 * when the user logs out.
 */

import { TypeService } from '../services/type/type-service';
import { MembersDomainModel } from '../domainModels/members-domain-model';
import { InternalUsersDomainModel } from '../domainModels/internal-users-domain-model.service';
import { PermissionDomainModel } from '../domainModels/permission-domain-model';
import { EmployersDomainModel } from '../domainModels/employers-domain-model';
import { EmployerUsersDataTableViewModel } from '../views/settings/components/employees/employer-users-data-table/employer-users-data-table-view-model';
import { SubmissionsDomainModel } from '../domainModels/submissions-domain-model';
import { ProfileViewModel } from '../views/profile/components/profile-view-model';
import { AllInternalUsersDataTableViewModel } from '../views/settings/components/internal-users/internal-users/all-interal-users-data-table/all-internal-users-data-table-view-model';
import { MemberSubmissionsDataTableViewModel } from '../views/submissions/tables/member-submissions-data-table/member-submissions-data-table-view-model';
import { AlertsDomainModel } from '../domainModels/alerts-domain-model';
import { AlertsDataTableViewModel } from '../views/settings/components/alerts/alerts-data-table/alerts-data-table-view-model';
import { TransactionDomainModel } from '../domainModels/transaction-domain-model';
import { RemittanceDomainModel } from '../domainModels/remittance-domain-model';
import { ReleaseNotesDomainModel } from '../domainModels/release-notes-domain-model';
import { ReleaseNotesTableViewModel } from '../views/settings/components/release-notes/release-notes-table/release-notes-table-view-model';
import { WebSocketService } from '../services/web-socket.service';
import { DesignatedUnitsDataTableViewModel } from '../views/settings/components/employer-plan/designated-units-table/designated-units-data-table-view-model';

export const domainModelsGloballyScopedToLoginContext = [
  MembersDomainModel,
  InternalUsersDomainModel,
  PermissionDomainModel,
  EmployersDomainModel,
  SubmissionsDomainModel,
  AlertsDomainModel,
  TransactionDomainModel,
  RemittanceDomainModel,
  ReleaseNotesDomainModel,
  RemittanceDomainModel
];

export const viewModelsGloballyScopedToLoginContext = [
  AllInternalUsersDataTableViewModel,
  EmployerUsersDataTableViewModel,
  MemberSubmissionsDataTableViewModel,
  AlertsDataTableViewModel,
  ReleaseNotesTableViewModel,
  DesignatedUnitsDataTableViewModel,
  ProfileViewModel
];

export const servicesGloballyScopedToLoginContext = [TypeService, WebSocketService];
