import { Deserializable } from '../../protocols/deserializable';
import { LookAheadItem } from '../../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { GoverningJurisdictionType } from '../employer-history/types/governing-jurisdiction-type';

export class EmployerSearchResult implements Deserializable, LookAheadItem {
  id!: number;
  name!: string;
  governingJurisdictionId!: number;
  governingJurisdiction!: GoverningJurisdictionType;

  onDeserialize() {
    if (!!this.governingJurisdiction) {
      this.governingJurisdiction = window?.injector?.Deserialize?.instanceOf(
        GoverningJurisdictionType,
        this.governingJurisdiction
      );
    }
  }

  lookAheadDisabled(): boolean {
    return false;
  }

  lookAheadDisabledMessage(): string {
    return '';
  }
}
