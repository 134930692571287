export enum RemittancePaymentStatusPrimaryColorEnum {
  Pending = '#CC7212',
  Paid = '#0D5D4B',
  PastDue = '#A5291C',
  Failed = '#A5291C',
  Cancelled = '#666666',
  Partial = '#024256',
  Refunded = '#552773',
  NotApplicable = '#666666'
}

export enum RemittancePaymentStatusSecondaryColorEnum {
  Pending = '#FCF6EB',
  Paid = '#E8F3F1',
  PastDue = '#FCEBEA',
  Failed = '#FCEBEA',
  Cancelled = '#F7F7F7',
  Partial = '#E6EEF1',
  Refunded = '#EFE6F1',
  NotApplicable = '#666666'
}
/* eslint-disable @typescript-eslint/naming-convention */
export const RemittancePaymentStatusPrimaryColorMap: Record<number, string> = {
  1: RemittancePaymentStatusPrimaryColorEnum.Pending,
  2: RemittancePaymentStatusPrimaryColorEnum.Paid,
  3: RemittancePaymentStatusPrimaryColorEnum.PastDue,
  4: RemittancePaymentStatusPrimaryColorEnum.Failed,
  5: RemittancePaymentStatusPrimaryColorEnum.Cancelled,
  6: RemittancePaymentStatusPrimaryColorEnum.Partial,
  7: RemittancePaymentStatusPrimaryColorEnum.Refunded,
  8: RemittancePaymentStatusPrimaryColorEnum.NotApplicable
};

export const RemittancePaymentStatusSecondaryColorMap: Record<number, string> = {
  1: RemittancePaymentStatusSecondaryColorEnum.Pending,
  2: RemittancePaymentStatusSecondaryColorEnum.Paid,
  3: RemittancePaymentStatusSecondaryColorEnum.PastDue,
  4: RemittancePaymentStatusSecondaryColorEnum.Failed,
  5: RemittancePaymentStatusSecondaryColorEnum.Cancelled,
  6: RemittancePaymentStatusSecondaryColorEnum.Partial,
  7: RemittancePaymentStatusSecondaryColorEnum.Refunded,
  8: RemittancePaymentStatusSecondaryColorEnum.NotApplicable
};
/* eslint-enable @typescript-eslint/naming-convention */
