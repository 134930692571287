import { StatusPill } from './status-pill';
import {
  TransactionStatusPrimaryColorMap,
  TransactionStatusSecondaryColorMap
} from '../enum/shared/transaction-status-colors.enum';

export class TransactionStatusPill extends StatusPill {
  constructor(id: number, name: string) {
    super(id, name);
    this.primaryColor = TransactionStatusPrimaryColorMap[this.id];
    this.secondaryColor = TransactionStatusSecondaryColorMap[this.id];
  }
}
