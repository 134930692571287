import { inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { BehaviorSubject, combineLatest, EMPTY, Observable, throwError, withLatestFrom } from 'rxjs';
import { UserDomainModel } from '../../domainModels/user-domain-model';
import { catchError, filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { CustomError } from '../../models/shared/custom-error';
import { ToastService } from '../toast-service';
import { Router } from '@angular/router';
import { SessionService } from '../session-service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptorInterceptor implements HttpInterceptor {
  private userDomainModel = inject(UserDomainModel);
  private toastService = inject(ToastService);
  private router = inject(Router);
  private sessionService = inject(SessionService);

  private tokenSubject = new BehaviorSubject<string | null>(null);

  private ignoredRequests = [
    '/login',
    '/verify',
    '/confirmmember',
    '/complete',
    '/forgotpassword',
    '/resetforgottenpassword',
    '/refreshsession',
    '/logout'
  ];

  constructor() {}

  /**
   * Interceptor for handling authentication and session refresh in HTTP requests.
   * Adds authorization headers to requests and refreshes session when needed.
   * @param request The HTTP request being intercepted.
   * @param next The next HTTP handler in the interceptor chain.
   * @returns An observable representing the HTTP event stream.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return combineLatest([this.userDomainModel.authToken$, this.sessionService.refreshingSession$]).pipe(
      take(1),
      switchMap(([authToken, isRefreshing]) => {
        // Check if the request URL is in the list of ignored requests
        if (
          this.ignoredRequests.includes(request.url) ||
          request.url.includes('amazonaws') ||
          request.url.includes('/refreshsession')
        ) {
          // Clone the request with headers that exclude authorization
          request = request.clone({
            headers: this.createHeaders(undefined, request.headers)
          });
          // Proceed with the modified request
          return next.handle(request);
        } else {
          if (!isRefreshing) {
            // Clone the request with the current authorization headers
            request = request.clone({
              headers: this.createHeaders(authToken, request.headers)
            });
            // Handle the request and errors
            return next.handle(request).pipe(
              catchError(err => {
                // If a 401 error is encountered, initiate session refresh
                if (err instanceof HttpErrorResponse && err.status === 401) {
                  return this.refreshSession(request, next);
                }
                // Pass other errors along
                return throwError(() => err);
              })
            );
          } else {
            return this.sessionService.refreshingSession$.pipe(
              filter(refreshing => !refreshing),
              take(1),
              withLatestFrom(this.userDomainModel.authToken$),
              switchMap(([_, token]) => {
                return next.handle(
                  request.clone({
                    headers: this.createHeaders(token, request.headers, true)
                  })
                );
              })
            );
          }
        }
      })
    );
  }

  /**
   * Handles session refresh in case of a 401 error.
   * @param request The original HTTP request that encountered the 401 error.
   * @param next The next HTTP handler in the interceptor chain.
   * @returns An observable representing the refreshed HTTP event stream.
   */
  private refreshSession(request: HttpRequest<any>, next: HttpHandler) {
    return combineLatest([this.userDomainModel.isAuthenticated(), this.sessionService.refreshingSession$]).pipe(
      take(1),
      switchMap(([isAuthenticated, isRefreshing]) => {
        if (!isRefreshing) {
          this.tokenSubject.next(null);
          if (!!isAuthenticated) {
            return this.userDomainModel.refreshSession().pipe(
              take(1),
              switchMap(user => {
                if (user?.userSession?.accessToken) {
                  this.tokenSubject.next(user.userSession.accessToken);
                }
                // Clone the request with updated authorization headers
                const requestWithHeader = request.clone({
                  headers: this.createHeaders(user?.userSession?.accessToken, request.headers, true)
                });
                return next.handle(requestWithHeader);
              }),
              catchError(error => {
                if (error instanceof CustomError && error.code === 400) {
                  this.toastService.publishInfoMessage($localize`Your session has expired. Please sign in again.`);
                  this.sessionService.destroySession.next(true);
                  this.router.navigate([`/auth/sign-in`]).then(() => {});
                }
                // Pass other errors along
                return throwError(() => error);
              })
            );
          } else {
            return EMPTY;
          }
        } else {
          return this.tokenSubject.notNull().pipe(
            filter((token): token is string => typeof token === 'string'),
            take(1),
            switchMap(token => {
              const requestWithHeader = request.clone({
                headers: this.createHeaders(token, request.headers, true)
              });
              return next.handle(requestWithHeader);
            }),
            takeUntil(this.sessionService.destroySession)
          );
        }
      })
    );
  }

  private createHeaders(token: string | undefined, headers: HttpHeaders, force = false): HttpHeaders {
    if (!headers.get('Content-Type')) {
      headers = headers.append('Content-Type', 'application/json');
    }
    if (!headers.get('Accept')) {
      headers = headers.append('Accept', 'application/json');
    }
    if ((!headers.get('Authorization') && !!token) || force) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }
    if (environment.encryptionBypass) {
      headers = headers.set('DisableEncryptionKey', environment.disableEncryptionKey);
    }
    return headers;
  }
}
