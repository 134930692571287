import { UserDomainModel } from '../../../../domainModels/user-domain-model';
import { inject, Injectable } from '@angular/core';
import { ToastService } from '../../../../services/toast-service';
import { ChangePasswordRequest } from '../../../../models/users/requests/change-password-request';
import { BaseModalViewModel } from '../../../../models/base/base-modal-view-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ChangePasswordViewModel extends BaseModalViewModel {
  private userDomainModel = inject(UserDomainModel);
  private toastService = inject(ToastService);

  public req: ChangePasswordRequest = new ChangePasswordRequest();

  constructor(router: Router, ngbModal: NgbModal) {
    super(router, ngbModal);
  }

  changePassword(req: ChangePasswordRequest) {
    const loadingMessage = $localize`Changing Password...`;
    this._loadingOpts.addRequest(loadingMessage);
    this.userDomainModel.changePassword(req).subscribe({
      next: res => {
        this._loadingOpts.removeRequest(loadingMessage);
        this.closeModal();
        this.toastService.publishSuccessMessage(
          $localize`Password Updated`,
          $localize`You will now be able to sign in to your CSS Pension
          Plan account with your updated password.`
        );
      },
      error: error => {
        this.toastService.publishErrorMessage('Error', error.title);
        this._loadingOpts.removeRequest(loadingMessage);
      }
    });
  }
}
