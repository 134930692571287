import { Viewable } from '../protocols/viewable';
import { Subscribable } from './subscribable';
import { TypeUtils } from '../../utils/type-utils';
import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  template: ''
})
export abstract class BaseComponent extends Subscribable implements OnInit, AfterViewInit, Viewable {
  Types = TypeUtils;
  abstract setupViews(): void;
  abstract setupBindings(): void;
  ngOnInit(): void {
    this.setupViews();
  }
  ngAfterViewInit(): void {
    this.setupBindings();
  }
}
