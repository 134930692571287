<nav class="custom-breadcrumb-container" aria-label="breadcrumb">
  <ol class="custom-breadcrumb-list">
    <li *ngFor="let breadcrumb of breadcrumbs; let last = last" class="d-flex gap-2">
      <a
        [ngClass]="{ active: breadcrumb.active, disabled: breadcrumb.disabled }"
        class="custom-breadcrumb-item"
        [routerLink]="breadcrumb.active || breadcrumb.disabled ? null : breadcrumb.targetUrl"
        [attr.aria-disabled]="breadcrumb.active || breadcrumb.disabled">
        {{ breadcrumb.title }}
      </a>
      <img
        *ngIf="!last"
        class="breadcrumb-divider"
        [src]="'assets/icons/grey/Chevron Right.svg'"
        alt="breadcrumb divider"
        i18n-alt />
    </li>
  </ol>
</nav>
