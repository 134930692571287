<div class="reorder-container" (cdkDropListDropped)="drop($event)" cdkDropList>
  <app-reorder-list-item
    *ngFor="let item of items; let f = first; let l = last"
    [item]="item"
    [showIcons]="showIcons"
    [showVisibility]="showVisibility"
    [style.display]="'block'"
    [style.margin-top.rem]="f ? 0 : 1.5"
    [style.margin-bottom.rem]="l ? 0 : 1.5">
  </app-reorder-list-item>
</div>
