import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest } from 'rxjs';
// @ts-ignore
import FuzzySearch from 'fuzzy-search';
import { debounceTime, map } from 'rxjs/operators';
import { LookAheadItem } from './look-ahead-list/look-ahead-item/protocol/look-ahead-item';

@Injectable()
export class SearchWithLookAheadViewModel extends BaseViewModel {
  constructor() {
    super();
    this.init();
  }

  private _searchText = new BehaviorSubject<string | null>(null);
  private _searchProperties = new BehaviorSubject<string[]>([]);
  private _nLookAheadItems = new BehaviorSubject<number>(5);
  private _searchItems = new BehaviorSubject<LookAheadItem[]>([]);
  private _outputAllItemsWhenSearchIsEmpty = new BehaviorSubject<boolean>(false);
  public fuzzyHits$ = combineLatest([
    this._searchText,
    this._searchProperties,
    this._nLookAheadItems,
    this._searchItems,
    this._outputAllItemsWhenSearchIsEmpty
  ]).pipe(
    debounceTime(50),
    map(([searchText, searchProperties, returnNItems, items, outputAllItemsWhenSearchIsEmpty]) => {
      const hasSearchProperties = !!searchProperties && searchProperties?.length > 0;
      const hasSearchableItems = items?.length > 0;
      let matches = outputAllItemsWhenSearchIsEmpty ? items : [];
      if (!!searchText && hasSearchProperties && hasSearchableItems) {
        const searcher = new FuzzySearch(items, searchProperties, { caseSensitive: false });
        matches = searcher.search(searchText);
        return matches.take<any>(returnNItems);
      }
      return matches;
    })
  );

  // Popper
  public popperModifier = {
    flip: {
      behavior: ['bottom', 'top']
    }
  };
  public popperStyles = {
    'background-color': '#FFFFFF',
    'z-index': 99
  };

  init() {
    super.init();
  }

  public connectToSearchText(search: string) {
    this._searchText.next(search);
  }

  public connectToSearchProperties(properties: string[]) {
    this._searchProperties.next(properties);
  }

  public connectToNLookAheadItems(n: number) {
    this._nLookAheadItems.next(n);
  }

  public connectToSearchItems(items: LookAheadItem[]) {
    this._searchItems.next(items);
  }

  public connectToOutputAllItemsWhenSearchIsEmpty(outputAllItemsWhenSearchIsEmpty: boolean) {
    this._outputAllItemsWhenSearchIsEmpty.next(outputAllItemsWhenSearchIsEmpty);
  }
}
