import { Component, inject } from '@angular/core';
import { NavItemComponent } from '../nav-item.component';
import { NavItemViewModel } from '../nav-item-view-model';
import { Router } from '@angular/router';
import { UserDomainModel } from '../../../../../../domainModels/user-domain-model';
import { Observable } from 'rxjs';
import { InternalUser } from '../../../../../../models/account/dto/internal-user';
import { PortalService } from '../../../../../../services/portal/portal.service';

@Component({
  selector: 'app-profile-nav-item',
  templateUrl: './profile-nav-item.component.html',
  styleUrls: ['./profile-nav-item.component.scss', '../nav-item.component.scss'],
  providers: [NavItemViewModel]
})
export class ProfileNavItemComponent extends NavItemComponent {
  constructor(public viewModel: NavItemViewModel, protected router: Router) {
    super(viewModel, router);
    this.isNavItemActive$ = this.viewModel.isNavItemActive$;
    this.multiLineNavItem$ = this.viewModel.multiLineNavItem$;
    this.hasIcons$ = this.viewModel.hasIcons$;
  }

  private userDomainModel = inject(UserDomainModel);
  private portalService = inject(PortalService);

  public readonly user$ = this.userDomainModel.user$ as Observable<InternalUser>;
  public readonly isInternalUser$ = this.portalService.isInternal$;
  public isNavItemActive$: Observable<boolean>;
  public multiLineNavItem$: Observable<boolean>;
  public hasIcons$: Observable<boolean>;
}
