import { ChangeDetectorRef, Component, EventEmitter, inject, Output } from '@angular/core';
import { MemberAuthFlow } from '../../../../../models/account/enum/member-auth-flow.enum';
import { MemberAuthViewModel } from '../../../viewModels/member-auth-view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseAuthComponent } from '../../../../../models/base/base-auth-component';
import { LoadingOptions } from '../../../../../models/shared/loading-options';

@Component({
  selector: 'app-member-auth',
  templateUrl: './member-auth.component.html',
  styleUrls: ['./member-auth.component.scss']
})
export class MemberAuthComponent extends BaseAuthComponent {
  private route = inject(ActivatedRoute);

  @Output() loadingOpts = new EventEmitter<LoadingOptions>();

  constructor(
    public viewModel: MemberAuthViewModel,
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  protected readonly AuthFlow = MemberAuthFlow;

  parseAuthFlow() {
    const urlSub = this.route.url.subscribeWhileAlive({
      owner: this,
      next: url => {
        switch (url[url.length - 1].path) {
          case 'sign-in': {
            this.viewModel.setAuthFlow(this.AuthFlow.SignIn);
            break;
          }
          case 'forgot-password': {
            this.viewModel.setAuthFlow(this.AuthFlow.ForgotPassword);
            break;
          }
          case 'reset-password': {
            this.viewModel.setAuthFlow(this.AuthFlow.ResetPassword);
            break;
          }
          case 'member-welcome': {
            this.viewModel.setAuthFlow(this.AuthFlow.MemberWelcome);
            break;
          }
          case 'member-setup': {
            this.viewModel.setAuthFlow(this.AuthFlow.MemberSetup);
            break;
          }
          case 'member-enter-email-verification': {
            this.viewModel.setAuthFlow(this.AuthFlow.MemberEnterEmailVerification);
            break;
          }
          case 'member-send-email-verification': {
            this.viewModel.setAuthFlow(this.AuthFlow.MemberSendEmailVerification);
            break;
          }
          case 'verify-mfa': {
            this.viewModel.setAuthFlow(this.AuthFlow.MemberMFAVerification);
            break;
          }
        }
        this.changeDetector.detectChanges();
      }
    });
    this.pushSub(urlSub);
  }

  setupBindings(): void {
    this.parseAuthFlow();
    // Navigate to the account page on successful auth
    const authSuccessSubject = this.viewModel.authSuccess.notNull().subscribe(_ => {
      this.router.navigate(['dashboard']).then(() => {});
    });
    this.pushSub(authSuccessSubject);

    this.viewModel.loadingOpts$.subscribeWhileAlive({
      owner: this,
      next: opts => this.loadingOpts.emit(opts)
    });

    // Navigate to the reset password page
    const authFlowSubject = this.viewModel.nextAuthFlow.notNull().subscribe(nextAuthFlow => {
      switch (nextAuthFlow) {
        case this.AuthFlow.ResetPassword:
          this.router.navigate(['auth/reset-password']).then(() => {});
          break;
        case this.AuthFlow.SignIn:
          this.router.navigate(['auth/sign-in']).then(() => {});
          break;
        case this.AuthFlow.MemberWelcome:
          this.router.navigate(['auth/member-welcome']).then(() => {});
          break;
        case this.AuthFlow.MemberSetup:
          this.router.navigate(['auth/member-setup']).then(() => {});
          break;
        case this.AuthFlow.MemberEnterEmailVerification:
          this.router.navigate(['auth/member-enter-email-verification']).then(() => {});
          break;
        case this.AuthFlow.MemberSendEmailVerification:
          this.router.navigate(['auth/member-send-email-verification']).then(() => {});
          break;
        case this.AuthFlow.MemberMFAVerification:
          this.router.navigate(['auth/verify-mfa']).then(() => {});
          break;
      }
    });
    this.pushSub(authFlowSubject);
  }

  setupViews(): void {}
}
