import { Cachable } from '../protocols/cachable';
import { CachePolicy } from '../enum/shared/cachable-image-policy.enum';

export class CacheVersion implements Cachable {
  public static readonly key: string = 'cache-version';

  cachedTime!: number;
  version!: string;

  constructor(version?: string) {
    if (!!version) {
      this.version = version;
    }
  }

  cacheExpirySeconds(): number {
    return 0;
  }

  cacheKey(): string {
    return CacheVersion.key;
  }

  isExpired(): boolean {
    return false;
  }

  onDeserialize() {}

  imageCachePolicy(): CachePolicy {
    return CachePolicy.Session;
  }
}
