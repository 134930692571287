import { PortalTypeIdEnum } from '../enum/shared/portal-type-id.enum';
import { Deserializable } from '../protocols/deserializable';

export class RemittanceHistory implements Deserializable {
  public id!: string;
  public remittanceId!: string;
  public originPortalId!: PortalTypeIdEnum;
  public event!: string;
  public eventDate!: Date;
  public originPortal!: OriginPortal;

  onDeserialize(): void {
    if (!!this.eventDate) {
      this.eventDate = new Date(this.eventDate);
    }
  }
}

export class OriginPortal implements Deserializable {
  public id!: PortalTypeIdEnum;
  public name!: string;

  onDeserialize(): void {}
}
