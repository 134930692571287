// noinspection JSUnusedLocalSymbols

import { inject, Injectable } from '@angular/core';
import { UserDomainModel } from '../../../domainModels/user-domain-model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../services/toast-service';
import { CacheService } from '../../../services/cache-service';
import { ForgotPasswordRequest } from '../../../models/account/requests/forgot-password-request';
import { ResetPasswordRequest } from '../../../models/account/requests/reset-password-request';
import { BaseViewModel } from '../../../models/base/base-view-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { PortalType } from '../../../models/enum/shared/portal-type';

@Injectable({ providedIn: 'root' })
export class ForgotPasswordViewModel extends BaseViewModel {
  route = inject(ActivatedRoute);

  constructor(
    private userDomainModel: UserDomainModel,
    private cacheService: CacheService,
    public router: Router,
    public toastService: ToastService
  ) {
    super();
  }

  private _emailSent = new BehaviorSubject<boolean>(false);
  public readonly emailSent$ = this._emailSent as Observable<boolean>;

  private _preFillEmail = new BehaviorSubject<string>('');
  public readonly preFillEmail$ = this._preFillEmail as Observable<string>;

  private _preFillCode = new BehaviorSubject<string>('');
  public readonly preFillCode$ = this._preFillCode as Observable<string>;

  public getUrlParams(route: ActivatedRoute) {
    route.queryParams.subscribeWhileAlive({
      owner: this,
      next: params => {
        const email = decodeURIComponent(decodeURIComponent(params?.Email || params?.email || ''));
        const password = decodeURIComponent(decodeURIComponent(params?.Password || params?.password || ''));
        const code = decodeURIComponent(decodeURIComponent(params?.Code || params?.code || ''));
        if (!!email) this._preFillEmail.next(email);
        if (!!code) this._preFillCode.next(code);
        if (!!email || !!code) {
          this.router?.navigate([], { queryParams: {}, replaceUrl: true, relativeTo: this.route }).then();
        }
      }
    });
  }

  // Toast messages
  private sendingEmail = $localize`Sending E-mail...`;
  private resettingPassword = $localize`Resetting Password...`;
  private resetSuccessTitle = $localize`Success!`;
  private resetSuccessMessage = $localize`Please sign-in with your new password.`;

  public forgotPassword(req: ForgotPasswordRequest, portalType: PortalType): void {
    this._loadingOpts.addRequest(this.sendingEmail);
    this.userDomainModel.forgotPassword(req, portalType).subscribe({
      next: res => {
        this._loadingOpts.removeRequest(this.sendingEmail);
        this._emailSent.next(true);
      },
      error: error => {
        this.toastService.publishError(error);
        this._loadingOpts.removeRequest(this.sendingEmail);
      }
    });
  }

  public resetPassword(req: ResetPasswordRequest, portalType: PortalType): void {
    this._loadingOpts.addRequest(this.resettingPassword);
    this.userDomainModel.resetPassword(req, portalType).subscribe({
      next: res => {
        this._loadingOpts.removeRequest(this.resettingPassword);
        this.router.navigate(['/auth/sign-in']).then();
        this.toastService.publishSuccessMessage(this.resetSuccessTitle, this.resetSuccessMessage);
      },
      error: error => {
        this.toastService.publishError(error);
        this._loadingOpts.removeRequest(this.resettingPassword);
      }
    });
  }
}
