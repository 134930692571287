import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-data-label-pill',
  templateUrl: './data-label-pill.component.html',
  styleUrls: ['./data-label-pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataLabelPillComponent extends BaseComponent {
  @Input() label: string = '--';
  @Input() value: string | number = '--';

  setupViews(): void {}
  setupBindings(): void {}
}
