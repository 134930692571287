<lib-reactive-form-group
  #form
  [bindTo]="req"
  (formSubmitted$)="formSubmitted($event)"
  (formChanged$)="formChanged($event)">
  <lib-reactive-form-column-layout [nColumns]="(isMobile$ | async) ? 1 : 2" [columnGap]="'2rem'">
    <lib-reactive-form-birthday
      [inputName]="'birthdate'"
      label="Birthdate"
      i18n-label
      [bindingProperty]="'birthdate'"
      [required]="true"
      hintText="dd / mm / yyyy"
      i18n-hintText>
    </lib-reactive-form-birthday>
    <lib-reactive-form-sin-input
      *ngIf="canViewFullSIN || canViewPartialSIN"
      [inputName]="'socialInsuranceNumber'"
      [required]="canViewFullSIN"
      [disabled]="!canViewFullSIN"
      label="Social Insurance Number"
      i18n-label
      [bindingProperty]="'socialInsuranceNumber'">
    </lib-reactive-form-sin-input>
    <lib-reactive-form-drop-down
      *ngIf="!canViewFullSIN && !canViewPartialSIN"
      [inputName]="'genderId'"
      [required]="true"
      label="Gender at Birth"
      i18n-label
      [inlineLabel]="false"
      [dropdowns]="(genderDropdowns$ | async) ?? undefined"
      [bindingProperty]="'genderId'">
    </lib-reactive-form-drop-down>
  </lib-reactive-form-column-layout>
  <lib-reactive-form-column-layout [nColumns]="(isMobile$ | async) ? 1 : 2" [columnGap]="'2rem'">
    <lib-reactive-form-drop-down
      *ngIf="canViewFullSIN || canViewPartialSIN"
      [inputName]="'genderId'"
      [required]="true"
      label="Gender at Birth"
      i18n-label
      [inlineLabel]="false"
      [dropdowns]="(genderDropdowns$ | async) ?? undefined"
      [bindingProperty]="'genderId'">
    </lib-reactive-form-drop-down>
    <lib-reactive-form-drop-down
      [inputName]="'pronounId'"
      label="Preferred Pronouns"
      hintText="Optional"
      i18n-hintText
      i18n-label
      [inlineLabel]="false"
      [clearable]="true"
      [dropdowns]="(pronounDropdowns$ | async) ?? undefined"
      [bindingProperty]="'pronounId'">
    </lib-reactive-form-drop-down>
  </lib-reactive-form-column-layout>
  <hr class="divider" />
  <ng-content></ng-content>
  <lib-reactive-form-file-uploader
    #fileUploader
    (previewClicked)="MediaUtils.openFileInNewTab($event)"
    (duplicateFileNamesAdded)="duplicateFilesAdded()"
    [inputName]="'file'"
    [id]="'file'"
    [disabled]="!(infoChanged$ | async) ?? false"
    label="Document"
    uploadTitle="Upload a File"
    previewLabel="Preview"
    removeLabel="Remove"
    chooseAnotherLabel="Add Another File"
    uploadBody="or drag and drop .png or .jpeg up to 3MB"
    i18n-label
    i18n-uploadTitle
    i18n-previewLabel
    i18n-removeLabel
    i18n-chooseAnotherLabel
    i18n-uploadBody
    [showPreview]="true"
    [multiple]="true"
    [maxFileSizeInMb]="3"
    [maxTotalFileSizeInMb]="3"
    [maxFiles]="5"
    [chooseAnother]="true"
    [required]="!!(infoChanged$ | async)">
  </lib-reactive-form-file-uploader>
  <hr class="mb-3" />
</lib-reactive-form-group>
