import { Component, EventEmitter, inject, Output } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { VerifyMfaViewModel } from './verify-mfa-view-model';
import { LoadingOptions } from '../../../../../models/shared/loading-options';

@Component({
  selector: 'app-verify-mfa',
  templateUrl: './verify-mfa.component.html',
  styleUrls: ['./verify-mfa.component.scss', '../auth.component.scss'],
  providers: [VerifyMfaViewModel]
})
export class VerifyMfaComponent extends BaseComponent {
  public viewModel = inject(VerifyMfaViewModel);

  @Output() loadingOpts = new EventEmitter<LoadingOptions>();

  constructor() {
    super();
  }

  setupBindings(): void {
    this.viewModel.loadingOpts$.subscribeWhileAlive({
      owner: this,
      next: opts => this.loadingOpts.emit(opts)
    });
  }

  setupViews(): void {}
}
