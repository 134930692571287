import { MediaType } from '../enum/dto/media-type.enum';
import { MediaUtils } from '../../utils/media-utils';

export class CustomFile {
  public name!: string;
  public type!: string;
  public url!: string | ArrayBuffer | null;
  public size!: number;
  public progress!: number;
  public success!: boolean;
  public failed!: boolean;
  public failureError!: string;
  public hasPreview!: boolean;

  isImage(): boolean {
    return this.type.match(/image\/*/) !== null;
  }

  isVideo(): boolean {
    return this.type.match(/video\/*/) !== null;
  }

  getMediaType(): MediaType {
    const extension = this.name.split('.').pop();
    if (extension) {
      return MediaUtils.getMediaType(extension.toLowerCase());
    }
    throw new Error('File has no extension');
  }

  getUploadDelay(): number {
    let delayInSec: number;
    if (this.size < 1000000) {
      // Files under 1MB
      delayInSec = 5;
    } else if (this.size < 3000000) {
      // Files under 3MB
      delayInSec = 8;
    } else if (this.size < 7000000) {
      // Files under 7MB
      delayInSec = 10;
    } else {
      delayInSec = 12;
    }
    return delayInSec * 1000;
  }
}
