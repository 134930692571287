import { Deserializable } from '../../protocols/deserializable';
import { LookAheadItem } from '../../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { BaseUser } from '../../base/base-user';
import { UserSession } from './user-session';
import { Address } from './address';
import { UserChallengeName } from '../enum/user-challenge.name';
import { MfaType } from '../../types/dto/mfa-type';
import { DateUtils } from '../../../utils/date-utils';

export class EmployerUser extends BaseUser implements Deserializable, LookAheadItem {
  public birthDate: Date;
  public genderId: number;
  public gender: string;
  public employerId: number;
  public active: boolean | null;
  public setInactiveById: number | null = null;
  public setInactiveByName: string | null = null;
  public setInactiveDate: Date | null = null;

  public mfaTypeId!: number;
  public mfaType!: MfaType;

  // Not From API
  public mfaActive!: boolean;
  public setToInactiveString: string = '';

  constructor(
    id: number = -1,
    userSession: UserSession | null = new UserSession(),
    challengeName: UserChallengeName | null = null,
    sessionId: string = '',
    firstName: string = '',
    lastName: string = '',
    email: string = '',
    passwordChangedDate: Date = new Date(),
    eulaConfirmation: boolean = false,
    eulaConfirmationDate: Date = new Date(),
    lastLoginDate: Date = new Date(),
    birthDate: Date = new Date(),
    genderId: number = 0,
    gender: string = '',
    employerId: number = 0,
    addressId: string = '',
    address: Address = new Address(),
    active: boolean = false,
    setInactiveById: number | null = null,
    setInactiveByName: string | null = null,
    setInactiveDate: Date | null = null
  ) {
    super(
      id,
      firstName,
      lastName,
      email,
      passwordChangedDate,
      eulaConfirmation,
      eulaConfirmationDate,
      lastLoginDate,
      userSession,
      challengeName,
      sessionId,
      addressId,
      address
    );
    this.birthDate = birthDate;
    this.addressId = addressId;
    this.address = address;
    this.genderId = genderId;
    this.gender = gender;
    this.employerId = employerId;
    this.active = active;
    this.setInactiveById = setInactiveById;
    this.setInactiveByName = setInactiveByName;
    this.setInactiveDate = setInactiveDate;
  }

  onDeserialize() {
    super.onDeserialize();
    if (!this.active && !!this.setInactiveDate) {
      this.setToInactiveString = `Set to inactive by ${this.setInactiveByName} on ${DateUtils.formatDateStringToCSTDate(
        this.setInactiveDate.toString()
      )}`;
    }
  }

  // Look ahead item interface

  lookAheadDisabled(): boolean {
    return false;
  }

  lookAheadDisabledMessage(): string {
    return 'disabled';
  }
}
