import { RemittanceFileRecord } from './remittance-file-record';
import { ContributionEntry } from './contribution-entry';
import { Deserializable } from '../protocols/deserializable';
import { DateUtils } from '../../utils/date-utils';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';

export class Contribution implements Deserializable, UniquelyIdentifiable {
  public id!: string;
  public remittanceId!: number;
  public socialInsuranceNumber!: string;
  public memberId: number | null = null;
  public memberFirstName!: string;
  public memberLastName!: string;
  public birthDate!: string;
  public entries: ContributionEntry[] = [];
  public entrySumAmount!: number;

  constructor(record: RemittanceFileRecord) {
    this.socialInsuranceNumber = record.SIN.toString(10);
    this.memberFirstName = record['FIRST NAME'];
    this.memberLastName = record['LAST NAME'];
    this.birthDate = DateUtils.simpleDateStringtoUTCDateString(record['BIRTH DATE']);
    this.entries = [new ContributionEntry(record)];
  }

  onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.entries = Deserialize?.arrayOf(ContributionEntry, this.entries);
  }

  getUniqueIdentifier(): string {
    return `${this.id}-
    ${this.remittanceId}-
    ${this.socialInsuranceNumber}-
    ${this.memberId}-
    ${this.memberFirstName}-
    ${this.memberLastName}-
    ${this.birthDate}-
    ${this.entries.map(e => e.getUniqueIdentifier())}-${this.entrySumAmount}`;
  }
}
