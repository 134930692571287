<div class="progress-body-title">
  <img [src]="iconSrc" alt="Progress" class="progress-icon" />
  <span
    (click)="routeTo()"
    class="item-title"
    [ngStyle]="{ cursor: item.routeTo ? 'pointer' : 'default' }"
    [ngClass]="{
      'item-title-active': item.status === ApplicationProcessItemStatus.Active,
      'item-title-complete': item.status === ApplicationProcessItemStatus.Complete,
      'item-title-pending': item.status === ApplicationProcessItemStatus.Pending
    }">
    {{ item.title }}
  </span>
</div>
<hr />
