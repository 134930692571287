<div [ngbCollapse]="!(showSearchBar$ | async) ?? false" [animation]="true">
  <lib-reactive-form-group #searchForm [bindTo]="searchForm$ | async" (formSubmitted$)="formSubmitted($event)">
    <lib-reactive-form-drop-down
      [inputName]="(bindingProperty$ | async) ?? ''"
      [placeholder]="(placeholder$ | async) ?? ''"
      [clearable]="true"
      [useClearableButton]="true"
      [bindingProperty]="(bindingProperty$ | async) ?? ''"
      [dropdowns]="(dropdowns$ | async) ?? undefined"
      (valueChanged)="searchForm.submitForm()">
    </lib-reactive-form-drop-down>
  </lib-reactive-form-group>
</div>
