import { StatusPill } from './status-pill';
import {
  RemittanceStatusPrimaryColorMap,
  RemittanceStatusSecondaryColorMap
} from '../enum/shared/remittance-status-colors.enum';

export class RemittanceStatusPill extends StatusPill {
  constructor(id: number, name: string) {
    super(id, name);
    this.primaryColor = RemittanceStatusPrimaryColorMap[this.id];
    this.secondaryColor = RemittanceStatusSecondaryColorMap[this.id];
  }
}
