import { Event } from './event';

export class EventQueue {
  private display: Event[] = [];
  private waiting: Event[] = [];
  private readonly queueLength: number;

  constructor(queueLength: number = 3) {
    this.queueLength = queueLength;
  }

  addToQueue(event: Event): EventQueue {
    if (this.display?.length < this.queueLength) {
      this.display = this.display.concat(event);
      event.startTimer();
    } else {
      this.waiting = this.waiting.concat(event);
    }
    return this;
  }

  removeFromQueue(event: Event | null): EventQueue {
    if (!event) return this;
    if (this.display?.contains(event)) {
      this.display?.splice(this.display.indexOf(event), 1);
      if (this.waiting?.length > 0) {
        const displayWaitingEvent = this?.waiting?.shift();
        if (!!displayWaitingEvent) {
          this.display = this.display.concat(displayWaitingEvent);
          displayWaitingEvent.startTimer();
        }
      }
    } else if (this.waiting?.contains(event)) {
      this.waiting?.splice(this.waiting.indexOf(event), 1);
    }
    return this;
  }

  getDisplayableQueue(): Event[] {
    return this.display;
  }
}
