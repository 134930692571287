import { StatusPill } from './status-pill';
import {
  SubmissionStatusPrimaryColorMap,
  SubmissionStatusSecondaryColorMap
} from '../enum/shared/submission-status-colors.enum';

export class SubmissionStatusPill extends StatusPill {
  public parentId: number = 0;
  public parentName: string = '';

  constructor(id: number, name: string, parentId: number, parentName: string) {
    super(id, name);
    this.parentId = parentId;
    this.parentName = parentName;
    this.primaryColor = SubmissionStatusPrimaryColorMap[this.parentId];
    this.secondaryColor = SubmissionStatusSecondaryColorMap[this.parentId];
  }
}

export class ActiveStatusPill extends SubmissionStatusPill {
  constructor() {
    super(1, $localize`Active`, 4, '');
  }
}

export class InactiveStatusPill extends SubmissionStatusPill {
  constructor() {
    super(2, $localize`Inactive`, 5, '');
  }
}
