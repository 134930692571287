import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-labeled-list-sin-item',
  templateUrl: './labeled-list-sin-item.component.html',
  styleUrls: ['./labeled-list-sin-item.component.scss', '../labeled-list-item/labeled-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabeledListSinItemComponent implements OnChanges {
  @Input() label: string | undefined;
  @Input() sin!: string;

  private _showFullSin = new BehaviorSubject(false);
  public showFullSin$ = this._showFullSin as Observable<boolean>;

  public iconSrc$ = this.showFullSin$.pipe(
    map(showFullSin => (showFullSin ? '/assets/icons/dark/Eye On.svg' : '/assets/icons/dark/Eye Off.svg'))
  );

  public toggleShowFullSin(): void {
    this.showFullSin$.once(masked => this._showFullSin.next(!masked));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sin) {
      this._showFullSin.next(false);
    }
  }
}
