import { Deserializable } from '../../protocols/deserializable';

export class VerifyMemberRequest implements Deserializable {
  public sin: string = '';
  public birthdate: string = '';

  constructor(sin: string, birthdate: string) {
    this.sin = sin;
    this.birthdate = birthdate;
  }

  onDeserialize = () => {};
}
