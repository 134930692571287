import { Component, inject, OnChanges, SimpleChanges } from '@angular/core';
import { AddressChangeSubmissionFormViewModel } from './address-change-submission-form-view-model';
import { ChangeAddressRequest } from '../../../../models/account/requests/submissionRequests/change-address-request';
import { AbstractSubmissionFormComponent } from '../submission-forms/abstract-submission-form/abstract-submission-form.component';

@Component({
  selector: 'app-address-change-submission-form',
  templateUrl: './address-change-submission-form.component.html',
  styleUrls: ['./address-change-submission-form.component.scss'],
  providers: [AddressChangeSubmissionFormViewModel]
})
export class AddressChangeSubmissionFormComponent
  extends AbstractSubmissionFormComponent<ChangeAddressRequest>
  implements OnChanges
{
  protected viewModel = inject(AddressChangeSubmissionFormViewModel);

  public countryDropdowns$ = this.viewModel.countryDropdowns$;
  public stateDropdowns$ = this.viewModel.stateDropdowns$;
  public selectedCountryId$ = this.viewModel.selectedCountryId$;

  public req!: ChangeAddressRequest;

  public countryChanged(countryId: number): void {
    this.viewModel.countryChanged(countryId);
  }

  public submitAddressChange(req: ChangeAddressRequest): void {
    this.processFilesAndSubmit(req);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user) {
      this.req = new ChangeAddressRequest(this.user);
      if (!this.req.countryId) {
        this.req.countryId = 1;
      }
      this.countryChanged(this.req.countryId);
    }
  }
}
