import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { SlidingWindowOptions } from '../../../../../models/shared/sliding-window-options';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-sliding-window-footer',
  templateUrl: './sliding-window-footer.component.html',
  styleUrls: ['./sliding-window-footer.component.scss']
})
export class SlidingWindowFooterComponent extends BaseComponent implements OnChanges {
  @Input() slidingWindowOptions!: SlidingWindowOptions | null;
  @Input() displayCompletedStateButtons: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() hideActionButton: boolean = false;
  @Output() secondaryButtonClicked = new EventEmitter<void>();
  @Output() primaryButtonClicked = new EventEmitter<void>();

  private _slidingWindowOptions = new BehaviorSubject<SlidingWindowOptions | null>(new SlidingWindowOptions());
  public readonly slidingWindowOptions$ = this._slidingWindowOptions as Observable<SlidingWindowOptions>;

  private _showCompletedButtons = new BehaviorSubject<boolean>(false);
  public readonly showCompletedButtons$ = this._showCompletedButtons as Observable<boolean>;

  private _hideActionButton = new BehaviorSubject<boolean>(false);
  public readonly hideActionButton$ = this._hideActionButton as Observable<boolean>;

  setupBindings(): void {
    this.displayCompletedStateButtons.subscribeWhileAlive({
      owner: this,
      next: display => {
        this._showCompletedButtons.next(display);
      }
    });
  }

  setupViews(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.slidingWindowOptions) {
      this._slidingWindowOptions.next(this.slidingWindowOptions);
    }
    if (changes.hideActionButton) {
      this._hideActionButton.next(this.hideActionButton);
    }
  }
}
