import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PortalType } from '../../models/enum/shared/portal-type';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { PortalTypeIdEnum } from '../../models/enum/shared/portal-type-id.enum';

@Injectable({
  providedIn: 'root'
})
export class PortalService {
  // Behavior Subjects
  private _portalType = new BehaviorSubject<PortalType>(PortalType.Member);
  public readonly portalType$ = this._portalType
    .notNull()
    .pipe(distinctUntilChanged(), shareReplay({ bufferSize: 1, refCount: true }));

  public readonly portalTypeId$ = this.portalType$.pipe(
    map(pt => {
      switch (pt) {
        case PortalType.Member:
          return PortalTypeIdEnum.Member;
        case PortalType.Employer:
          return PortalTypeIdEnum.Employer;
        case PortalType.Internal:
          return PortalTypeIdEnum.Internal;
      }
    })
  );

  constructor() {
    const url = window.location.href;
    if (url.startsWith('http://')) {
      // Localhost
      switch (true) {
        case url.startsWith('http://member'):
          this._portalType.next(PortalType.Member);
          break;
        case url.startsWith('http://internal'):
          this._portalType.next(PortalType.Internal);
          break;
        case url.startsWith('http://employer'):
          this._portalType.next(PortalType.Employer);
          break;
      }
    } else {
      // Dev / Demo
      switch (true) {
        case url.includes('.member.'):
          this._portalType.next(PortalType.Member);
          break;
        case url.includes('.internal.'):
          this._portalType.next(PortalType.Internal);
          break;
        case url.includes('.employer.'):
          this._portalType.next(PortalType.Employer);
          break;
      }
    }
  }

  public isInternal$ = this.portalType$.pipe(
    map(type => {
      return type === PortalType.Internal;
    })
  );

  public isEmployer$ = this.portalType$.pipe(
    map(type => {
      return type === PortalType.Employer;
    })
  );

  public isMember$ = this.portalType$.pipe(
    map(type => {
      return type === PortalType.Member;
    })
  );
}
