export enum RemittanceStatusPrimaryColorEnum {
  Pending = '#CC7212',
  Submitted = '#024256',
  InReview = '#552773',
  Approved = '#0D5D4B',
  Failed = '#A5291C',
  NeedsAttention = '#A5291C'
}

export enum RemittanceStatusSecondaryColorEnum {
  Pending = '#FCF6EB',
  Submitted = '#E6EEF1',
  InReview = '#EFE6F1',
  Approved = '#E8F3F1',
  Failed = '#FCEBEA',
  NeedsAttention = '#FCEBEA'
}
/* eslint-disable @typescript-eslint/naming-convention */
export const RemittanceStatusPrimaryColorMap: Record<number, string> = {
  1: RemittanceStatusPrimaryColorEnum.Pending,
  2: RemittanceStatusPrimaryColorEnum.Submitted,
  3: RemittanceStatusPrimaryColorEnum.InReview,
  4: RemittanceStatusPrimaryColorEnum.Approved,
  5: RemittanceStatusPrimaryColorEnum.Failed,
  6: RemittanceStatusPrimaryColorEnum.NeedsAttention
};

export const RemittanceStatusSecondaryColorMap: Record<number, string> = {
  1: RemittanceStatusSecondaryColorEnum.Pending,
  2: RemittanceStatusSecondaryColorEnum.Submitted,
  3: RemittanceStatusSecondaryColorEnum.InReview,
  4: RemittanceStatusSecondaryColorEnum.Approved,
  5: RemittanceStatusSecondaryColorEnum.Failed,
  6: RemittanceStatusSecondaryColorEnum.NeedsAttention
};
/* eslint-enable @typescript-eslint/naming-convention */
