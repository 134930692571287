export enum SubmissionSubStatusEnum {
  Ready = 'Ready',
  PendingDocuments = 'Pending Documents',
  Processing = 'Processing',
  MemberActionRequired = 'Member Action Required',
  EmployerActionRequired = 'Employer Action Required',
  ReadyForApproval = 'Ready for Approval',
  Approved = 'Approved',
  AutoApproved = 'Auto-Approved',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled'
}
