import { Deserializable } from '../../protocols/deserializable';
import { RelationshipStatusType } from '../../types/dto/relationship-status-type';
import { RelationshipStatus } from '../../enum/shared/relationship-status';

export class MemberRelationshipStatus implements Deserializable {
  public relationshipStatusId!: RelationshipStatus;
  public effectiveDate!: Date;
  public firstName!: string;
  public lastName!: string;
  public socialInsuranceNumber!: string;
  public relationshipStatus!: RelationshipStatusType;

  onDeserialize(): void {
    if (!!this.effectiveDate) {
      this.effectiveDate = new Date(this.effectiveDate);
    }
    this.relationshipStatus = window.injector.Deserialize?.instanceOf(RelationshipStatusType, this.relationshipStatus);
  }
}
