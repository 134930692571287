// noinspection JSUnusedLocalSymbols

import { of, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { CacheService } from '../../../services/cache-service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastService } from '../../../services/toast-service';
import {
  EmployerAuthFlow,
  EmployerAuthFlowSubtext,
  EmployerAuthFlowTitle
} from '../../../models/users/enum/employer-auth-flow.enum';
import { BaseAuthViewModel } from '../../../models/base/base-auth-view-model';
import { ChoosePasswordRequest } from '../../../models/users/requests/choose-password-request';

@Injectable({ providedIn: 'root' })
export class EmployerAuthViewModel extends BaseAuthViewModel {
  constructor(public cacheService: CacheService, public router: Router, public toastService: ToastService) {
    super(router, toastService, cacheService);
    this.init();
  }

  // Observables
  public nextAuthFlow: Subject<EmployerAuthFlow> = new Subject<EmployerAuthFlow>();

  public authFlowMap = new Map<number, EmployerAuthFlow>();

  public readonly authFlowTitle$ = this.authFlow$.pipe(map(flow => EmployerAuthFlowTitle(flow)));
  public readonly authFlowSubtext$ = this.authFlow$.pipe(map(flow => EmployerAuthFlowSubtext(flow)));

  // TODO: CSS-1146 Placeholder until we get text from API
  public eulaText$ = of(
    $localize`<p>An end-user license agreement or EULA (/ˈjuːlə/) is a legal
    contract between a software supplier and a customer or end-user, generally
     made available to the customer via a retailer acting as an intermediary.
     An EULA specifies in detail the rights and restrictions which apply to the
     use of the software.</p><p>Form contracts for digital services (such as terms
      of service and privacy policies) were traditionally presented on paper
      (see shrink-wrap agreement) but are now often presented digitally via
      browsewrap or clickwrap[2][3] formats. As the user may not see the
      agreement until after they have already purchased or engaged with the
      software, these documents may be contracts of adhesion.</p><p>Software
      companies often make special agreements with large businesses and
      government entitles that include support contracts and specially
      drafted warranties.</p><p>Many EULAs assert extensive liability limitations.
      Most commonly, an EULA will attempt to hold harmless the software licensor
       in the event that the software causes damage to the user's computer or
       data, but some software also proposes limitations on whether the licensor
        can be held liable for damage that arises through improper use of the
        software (for example, incorrectly using tax preparation software and
        incurring penalties as a result). One case upholding such limitations
         on consequential damages is M.A. Mortenson Co. v. Timberline Software
         Corp., et al.[4] Some EULAs also claim restrictions on venue and
         applicable law in the event that a legal dispute arises.</p><p>Some
         copyright owners use EULAs in an effort to circumvent limitations
         the applicable copyright law places on their copyrights (such as the
         limitations in sections 107–122 of the United States Copyright Act),
         or to expand the scope of control over the work into areas for which
         copyright protection is denied by law (such as attempting to charge
         for, regulate or prevent private performances of a work beyond a
         certain number of performances or beyond a certain period of time).
          Such EULAs are, in essence, efforts to gain control, by contract,
           over matters upon which copyright law precludes control.[5]</p>`
  );

  public showBackButton$ = this.authFlow$.pipe(
    map(flow => {
      return flow !== EmployerAuthFlow.SignIn;
    })
  );

  init() {
    super.init();
    this.generateAuthFlowMap();
  }

  // Auth Flows

  goBack(): void {
    this.authFlow$.once(flow => {
      switch (flow) {
        case EmployerAuthFlow.ResetPassword: {
          this.router.navigate(['auth/forgot-password']).then();
          break;
        }
        case EmployerAuthFlow.ForgotPassword: {
          this.router.navigate(['auth/sign-in']).then();
          break;
        }
        case EmployerAuthFlow.EmployerChoosePassword: {
          this.router.navigate(['auth/sign-in']).then();
          break;
        }
        case EmployerAuthFlow.EmployerMFAVerification: {
          this.router.navigate(['/auth/sign-in']).then();
          break;
        }
      }
    });
  }

  public generateAuthFlowMap() {
    this.authFlowMap?.set(0, EmployerAuthFlow.SignIn);
    this.authFlowMap?.set(1, EmployerAuthFlow.ForgotPassword);
    this.authFlowMap?.set(2, EmployerAuthFlow.ResetPassword);
    this.authFlowMap?.set(3, EmployerAuthFlow.SetNewPassword);
    this.authFlowMap?.set(4, EmployerAuthFlow.EmployerChoosePassword);
  }

  // Auth Requests

  public setNewEmployerPassword(req: ChoosePasswordRequest): void {
    const loadingMessage = $localize`Signing in`;
    this._loadingOpts.addRequest(loadingMessage);
    this.userDomainModel.setNewEmployerPassword(req).subscribe({
      next: res => {
        this._loadingOpts.removeRequest(loadingMessage);
        this.toastService.publishSuccessMessage($localize`Success`, $localize`Your password has been confirmed.`);
        this.router.navigate(['/dashboard'], { replaceUrl: true }).then(() => {});
      },
      error: error => {
        this.toastService.publishErrorMessage('Error', error);
        this._loadingOpts.removeRequest(loadingMessage);
      }
    });
  }
}
