import { Deserializable } from '../../protocols/deserializable';
import { Address } from '../dto/address';
import { MemberUser } from '../dto/member-user';
import { Gender } from '../dto/gender';
import { GenderTypeEnum } from '../enum/gender-type';

export class MemberOnboardingInfoRequest implements Deserializable {
  public id: number;
  public firstName: string;
  public middleInitial: string | null;
  public lastName: string;
  public preferredName: string | null;
  public genderId: GenderTypeEnum;
  public gender: Gender;
  public mobilePhoneNumber: string;
  public mobilePhoneNumberCountryCode: string;
  public workPhoneNumber: string;
  public workPhoneNumberCountryCode?: string;
  public homePhoneNumber: string;
  public homePhoneNumberCountryCode?: string;
  public addressId?: string;
  public address: Address;

  constructor(user: MemberUser) {
    this.id = user.id;
    this.firstName = user.firstName;
    this.middleInitial = user.middleInitial;
    this.lastName = user.lastName;
    this.preferredName = user.preferredName;
    this.genderId = user.genderId;
    this.gender = user.gender;
    this.mobilePhoneNumber = user.mobilePhoneNumber;
    this.mobilePhoneNumberCountryCode = user.mobilePhoneNumberCountryCode;
    this.homePhoneNumber = user.homePhoneNumber;
    this.homePhoneNumberCountryCode = user.homePhoneNumberCountryCode;
    this.workPhoneNumber = user.workPhoneNumber;
    this.workPhoneNumberCountryCode = user.workPhoneNumberCountryCode;
    this.addressId = user.addressId;
    this.address = user.address ?? new Address();
  }

  public onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.address = Deserialize?.instanceOf(Address, this.address);
  }
}
