<div class="list-item">
  <div class="label">{{ label }}</div>
  <div class="text-value" *ngIf="!!url; else pill">
    <a class="text-link" [routerLink]="url">{{ text }}</a>
  </div>
</div>

<ng-template #pill>
  <div *ngIf="!!statusPill; else profileBadge"><app-status-pill [statusPill]="statusPill"></app-status-pill></div>
</ng-template>
<ng-template #profileBadge>
  <app-user-profile-badge
    *ngIf="textColor || profileColor || initials; else simpleString"
    [profileColor]="profileColor"
    [textColor]="textColor"
    [initials]="initials">
  </app-user-profile-badge>
</ng-template>
<ng-template #simpleString
  ><div class="text-value">{{ text }}</div></ng-template
>
