import { LoggableAPI } from '../models/protocols/loggable-api';
import { Injectable } from '@angular/core';
import { AssetUrl } from '../models/asset/dto/asset-url';
import { CustomError } from '../models/shared/custom-error';
import { Asset } from '../models/asset/dto/asset';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApiClient } from './api-client';

@Injectable({
  providedIn: 'root'
})
export class ImageAPI implements LoggableAPI {
  constructor(public apiClient: ApiClient) {}

  // Variables

  public serviceName = 'Image';

  public getAsset(url: string): Observable<Asset> {
    return this.apiClient.getObj<Asset>(Asset, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getBlobFromUrl(assetUrl: AssetUrl): Observable<Blob | null | undefined> {
    if (!assetUrl.presignedUrl || assetUrl.urlExpired()) {
      return this.getAsset(assetUrl.presignedUrl).pipe(
        map(fetchedAsset => fetchedAsset.links?.find(url => url.size === assetUrl.size)),
        map(updatedAssetUrl => assetUrl.updateDataFrom(updatedAssetUrl ?? new AssetUrl())),
        switchMap(updatedAssetUrl => {
          return !!updatedAssetUrl?.presignedUrl ? this.fetchBlobFromApi(updatedAssetUrl.presignedUrl) : of<Blob>();
        })
      );
    } else {
      return this.fetchBlobFromApi(assetUrl.presignedUrl);
    }
  }

  private fetchBlobFromApi(url: string): Observable<Blob | null | undefined> {
    return this.apiClient.getBlob<Blob>(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }
}
