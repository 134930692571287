import { Deserializable } from '../protocols/deserializable';
import { PaymentDirectionType } from './payment-direction-type';
import { RemittancePaymentDetails } from './create-remittance-payment-request';

export class RemittancePayment implements Deserializable {
  public id!: number;
  public paymentDirectionId!: number;
  public paymentDirection!: PaymentDirectionType;
  public memberId!: number;
  public employerId!: number;
  public paymentTotal!: number;
  public createdDate!: Date;
  public createdByName!: string;
  public paymentDetails!: RemittancePaymentDetails;

  onDeserialize(): void {
    this.createdDate = new Date(this.createdDate);
    this.paymentDirection = window?.injector?.Deserialize.instanceOf(PaymentDirectionType, this.paymentDirection);
    this.paymentDetails = window?.injector?.Deserialize.instanceOf(RemittancePaymentDetails, this.paymentDetails);
  }
}
