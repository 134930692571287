import { MemberUser } from '../../dto/member-user';
import { SubmissionRequest } from './submission-request';

export class UpdateContactInfoRequest extends SubmissionRequest {
  public mobilePhoneNumber: string;
  public mobilePhoneNumberCountryCode?: string;
  public workPhoneNumber: string;
  public workPhoneNumberCountryCode?: string;
  public homePhoneNumber: string;
  public homePhoneNumberCountryCode?: string;

  constructor(user: MemberUser) {
    super();
    this.memberId = user.id;
    this.mobilePhoneNumber = user.mobilePhoneNumber;
    this.mobilePhoneNumberCountryCode = user.mobilePhoneNumberCountryCode;
    this.workPhoneNumber = user.workPhoneNumber;
    this.workPhoneNumberCountryCode = user.workPhoneNumberCountryCode;
    this.homePhoneNumber = user.homePhoneNumber;
    this.homePhoneNumberCountryCode = user.homePhoneNumberCountryCode;
  }
}
