<div *ngIf="reactiveFormGroup$ | async as formGroup" [formGroup]="reactiveFormGroupComponent?.getReactiveFormGroup()">
  <ng-container *ngIf="!clearable || !selectedEmployer">
    <app-odata-typeahead-search
      label="{{ label }}"
      [odataFunction]="odataFunction"
      placeHolder="{{ placeholder }}"
      [disabled]="!!selectedEmployer"
      [searchedProperties]="['name', 'id']"
      [customItemTemplate]="lookAheadItemTemplate"
      [popperClass]="'look-ahead-popper-full-width'"
      [hasErrors]="hasErrors$ | async"
      (lookAheadSelected)="employerSelected.emit($event)">
    </app-odata-typeahead-search>
  </ng-container>
  <ng-container *ngIf="clearable && selectedEmployer">
    <form class="search-bar-container">
      <label for="searchBar" [hidden]="!label">{{ label }}</label>
      <div class="selected-employer">
        <input
          [value]="selectedEmployer.name + ' (' + selectedEmployer.id + ')'"
          class="text-field search-text-field"
          id="searchBar"
          name="searchBar"
          readonly="readonly"
          autocomplete="off"
          type="text" />
        <div class="clear-button" (click)="employerSelected.emit(null)">
          <img [src]="'assets/icons/dark/X.svg'" alt="pill-icon" />
        </div>
      </div>
    </form>
  </ng-container>
  <lib-reactive-form-general-error
    [parentFormGroupComponent]="reactiveFormGroupComponent"
    [reactiveFormItemComponent]="this"
    [required]="required"
    [disabled]="disableInput$ | async"
    [performValidationWhenDisabled]="performValidationWhenDisabled"
    (hasError)="connectToHasErrors($event)">
  </lib-reactive-form-general-error>
  <input hidden [formControlName]="inputName" [id]="id" [name]="inputName" />
</div>
<ng-template #lookAheadItemTemplate let-employer>
  <div class="search-results-template">{{ employer.name }} ({{ employer.id }})</div>
</ng-template>
