import { Deserializable } from '../../protocols/deserializable';
import { AlertTypeEnum } from '../enum/alert-type.enum';
import { PortalTypeIdEnum } from '../../enum/shared/portal-type-id.enum';
import { AlertColor } from './alert-types';
import { AlertBackgroundColorEnum } from '../enum/alert-background-color.enum';
import { DateUtils } from '../../../utils/date-utils';

export class Alert implements Deserializable {
  public id!: number;
  public title!: string;
  public description!: string;
  public buttonText!: string | null;
  public buttonLink!: string | null;
  public startDate!: Date;
  public endDate!: Date;
  public lastUpdatedDate!: Date;
  public dismissable!: boolean;
  public status!: string;
  public typeId: AlertTypeEnum = AlertTypeEnum.Card;
  public colourId: number = AlertBackgroundColorEnum.CardGreen;
  public portalTypeId: PortalTypeIdEnum = PortalTypeIdEnum.Member;
  public portalType!: AlertPortalType;
  public type!: AlertShapeType;
  public colour!: AlertColor;

  // Not From API, required for Time Picker Dropdown
  public startTime: string = '';
  public startDateString: string = '';
  public endTime: string = '';
  public endDateString: string = '';

  // To only be used if a system generated alert is to be displayed without truncation
  // A user generated alert should never skip truncation
  public skipTitleTruncation: boolean = false;

  public showSystemBannerButton: boolean = false;
  public systemButtonText: string = '';

  onDeserialize(): void {
    // Breaking down the date and time into separate fields for the time picker dropdown
    const { dateString: startDateString, timeString: startTimeString } =
      DateUtils.formatDateStringToAlertTimePickerStrings(this?.startDate?.toString());
    this.startDateString = startDateString;
    this.startTime = startTimeString;
    const { dateString: endDateString, timeString: endTimeString } = DateUtils.formatDateStringToAlertTimePickerStrings(
      this?.endDate?.toString()
    );
    this.endDateString = endDateString;
    this.endTime = endTimeString;

    this.startDate = new Date(this.startDate);
    this.endDate = new Date(this.endDate);
    this.lastUpdatedDate = new Date(this.lastUpdatedDate);
  }

  // When updating an alert, we want to make sure we are not sending properties that do not belong to the alert type
  clearInvalidProperties(): void {
    if (this.typeId === AlertTypeEnum.Banner) {
      this.buttonText = null;
      this.buttonLink = null;
    }
    if (this.typeId === AlertTypeEnum.Card) {
      this.dismissable = false;
    }
  }

  setDates(): void {
    this.startDate = new Date(DateUtils.formatAlertTimePickerDateToDateString(this.startDateString, this.startTime));
    this.endDate = new Date(DateUtils.formatAlertTimePickerDateToDateString(this.endDateString, this.endTime));
  }

  constructor() {
    this.type = new AlertShapeType();
    this.portalType = new AlertPortalType();
    this.colour = new AlertColor();
  }
}

export class AlertPortalType implements Deserializable {
  public id: PortalTypeIdEnum = PortalTypeIdEnum.Internal;
  public name: string = '';

  onDeserialize(): void {}
}

export class AlertShapeType implements Deserializable {
  public id: AlertTypeEnum = AlertTypeEnum.Card;
  public name: string = '';

  onDeserialize(): void {}
}
