<ng-container *ngIf="!!(title$ | async); else noTitleContainer">
  <ng-template
    [ngTemplateOutlet]="infoTemplate"
    [ngTemplateOutletContext]="{
      items: items$ | async,
      hideActionButton: hideActionButton,
      actionButtonClicked: actionButtonClicked,
      actionButtonText: actionButtonText,
      showFullSin$: showFullSin$
    }"></ng-template>
</ng-container>

<hr *ngIf="!hideBottomDivider" [ngStyle]="{ 'margin-bottom': lastSection ? 0 : '2rem' }" class="divider" />

<ng-template #noTitleContainer>
  <ng-template
    [ngTemplateOutlet]="noTitleTemplate"
    [ngTemplateOutletContext]="{
      items: items,
      hideActionButton: hideActionButton,
      actionButtonClicked: actionButtonClicked,
      actionButtonText: actionButtonText,
      showFullSin$: showFullSin$
    }"></ng-template>
</ng-template>

<ng-template
  #infoTemplate
  let-items="items"
  let-hideActionButton="hideActionButton"
  let-actionButtonClicked="actionButtonClicked"
  let-actionButtonText="actionButtonText"
  let-showFullSin$="showFullSin$">
  <div class="info">
    <div class="info-header">
      <div class="container-label">{{ title$ | async }}</div>
      <button *ngIf="!hideActionButton" class="primary-paddingless-text-button" (click)="actionButtonClicked.emit()">
        {{ actionButtonText }}
      </button>
    </div>
  </div>
  <div class="info-container">
    <div class="data-container" [ngClass]="{ 'w-100': item?.fullWidth }" *ngFor="let item of items">
      <span class="info-label">{{ item?.infoLabel }}</span>
      <ng-container
        *ngTemplateOutlet="nameLabelTemplate; context: { item: item, showFullSin$: showFullSin$ }"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template
  #noTitleTemplate
  let-items="items"
  let-hideActionButton="hideActionButton"
  let-actionButtonClicked="actionButtonClicked"
  let-actionButtonText="actionButtonText"
  let-showFullSin$="showFullSin$">
  <div class="d-flex flex-column">
    <ng-container *ngFor="let item of items; let first = first">
      <div *ngIf="!!item?.statusPill" class="mb-3">
        <app-status-pill [statusPill]="item.statusPill"></app-status-pill>
      </div>
      <div
        *ngIf="first && separateFirstItem"
        class="d-flex w-100 justify-content-between align-items-center"
        [style.gap.rem]="2">
        <div class="data-container" [style.margin-top.rem]="0">
          <span class="info-label">{{ item?.infoLabel }}</span>
          <ng-container
            *ngTemplateOutlet="nameLabelTemplate; context: { item: item, showFullSin$: showFullSin$ }"></ng-container>
        </div>
        <button *ngIf="!hideActionButton" class="primary-paddingless-text-button" (click)="actionButtonClicked.emit()">
          {{ actionButtonText }}
        </button>
      </div>
    </ng-container>
    <div [class]="twoColLayout ? 'item-container-two-col' : 'item-container'">
      <ng-container *ngFor="let item of items; let first = first">
        <div
          *ngIf="!first || !separateFirstItem"
          [class]="twoColLayout ? 'two-col-data-container' : 'data-container'"
          [ngClass]="{ 'w-100': item?.fullWidth && !twoColLayout }">
          <span class="info-label">{{ item?.infoLabel }}</span>
          <ng-container
            *ngTemplateOutlet="nameLabelTemplate; context: { item: item, showFullSin$: showFullSin$ }"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #nameLabelTemplate let-item="item" let-showFullSin$="showFullSin$">
  <div class="name-label justify-content-start gap-3">
    <div
      [ngClass]="{ clickable: !!item?.url }"
      [class.sin-number]="item?.isSinNumber"
      (click)="navigateToLink(item?.url)">
      {{
        item?.isSinNumber
          ? (item?.value | maskSinNumber : (showFullSin$ | async) ?? false : item?.isSinNumber)
          : item?.value ?? '-'
      }}
    </div>
    <div *ngIf="item?.isSinNumber && item?.canViewFullSin">
      <button (click)="toggleSin($event)" class="eye">
        <img [src]="sinButtonIcon$ | async" alt="eye-icon" />
      </button>
    </div>
  </div>
</ng-template>
