<app-loading *ngIf="viewModel.isLoading$ | async" [options]="(viewModel.loadingOpts$ | async) ?? undefined">
</app-loading>

<!--Desktop Nav-->
<ng-container *ngIf="!(viewModel.isMobile$ | async)">
  <app-desktop-nav></app-desktop-nav>
</ng-container>
<!--Desktop Nav-->

<!--Mobile Nav-->
<ng-container *ngIf="viewModel.isMobile$ | async">
  <app-mobile-nav></app-mobile-nav>
</ng-container>
<!--Mobile Nav-->
