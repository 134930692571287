import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Checkbox } from '../../../../../models/shared/stylesheet/checkbox';
import { CheckboxContainerOptions } from '../../../../../models/shared/stylesheet/checkbox-container-options';

@Component({
  selector: 'app-checkbox-container',
  templateUrl: './checkbox-container.component.html',
  styleUrls: ['./checkbox-container.component.scss']
})
export class CheckboxContainerComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() checkBoxes: Checkbox[] = [];
  @Input() checkBoxContainerOptions: CheckboxContainerOptions = new CheckboxContainerOptions();
  @Output() changed = new EventEmitter<Checkbox[]>();

  public cid = Math.random().toString(24);
  public refresh = new EventEmitter<any>();
  public percentageChecked!: number;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.setCheckAllState();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setCheckAllState();
  }

  public selectAllClicked(event: Event) {
    this.checkBoxContainerOptions.touched = true;
    const element = event.currentTarget as HTMLInputElement;
    const checked = element.checked;
    this.percentageChecked = checked ? 1 : 0;
    this.checkBoxes.forEach(item => (item.checked = checked));
    this.refresh.emit(true);
    this.emitToOutput();
  }

  public selectSingleOptionClicked() {
    this.checkBoxContainerOptions.touched = true;
    this.setCheckAllState();
    this.emitToOutput();
  }

  public setCheckAllState() {
    let selectedCount = 0;
    this.checkBoxes.forEach(ch => {
      if (ch.checked) {
        selectedCount++;
      }
    });
    this.percentageChecked = selectedCount / this.checkBoxes.length;
  }

  emitToOutput() {
    this.changed.emit(this.checkBoxes);
  }
}
