import { Deserializable } from '../../protocols/deserializable';
import { Asset } from '../../asset/dto/asset';

export class EmployerLogo implements Deserializable {
  public id!: number;
  public employerId!: number;
  public attachmentId!: string;
  public attachment!: Asset;

  public onDeserialize() {
    this.attachment = window?.injector?.Deserialize?.instanceOf(Asset, this.attachment);
  }
}
