<div *ngIf="reactiveFormGroup$ | async as formGroup" [formGroup]="reactiveFormGroupComponent?.getReactiveFormGroup()">
  <app-odata-typeahead-search
    [label]="label"
    [odataFunction]="odataFunction"
    [placeHolder]="'Search by name or member ID' | localization"
    [disabled]="!!selectedMember"
    [searchedProperties]="['name', 'id']"
    [customItemTemplate]="lookAheadItemTemplate"
    [popperClass]="'look-ahead-popper-full-width'"
    [hasErrors]="hasErrors$ | async"
    (lookAheadSelected)="memberSelected.emit($event)">
  </app-odata-typeahead-search>
  <lib-reactive-form-general-error
    [parentFormGroupComponent]="reactiveFormGroupComponent"
    [reactiveFormItemComponent]="this"
    [required]="required"
    [disabled]="disableInput$ | async"
    [performValidationWhenDisabled]="performValidationWhenDisabled"
    (hasError)="connectToHasErrors($event)">
  </lib-reactive-form-general-error>
  <input hidden [formControlName]="inputName" [id]="id" [name]="inputName" />
</div>

<ng-template #lookAheadItemTemplate let-member>{{ member.name }} ({{ member.id }}) </ng-template>
