import { Component, inject } from '@angular/core';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { ChangeUserEmailModalViewModel } from './change-user-email-modal-view-model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseUser } from '../../../../models/base/base-user';

@Component({
  selector: 'app-change-user-email-modal',
  templateUrl: './change-user-email-modal.component.html',
  styleUrls: [
    './change-user-email-modal.component.scss',
    '../../../profile/modals/change-email-modal/change-email-modal.component.scss'
  ],
  providers: [ChangeUserEmailModalViewModel]
})
export class ChangeUserEmailModalComponent extends BaseModalComponent {
  public ignoreEscape: boolean = false;

  constructor(protected activeModal: NgbActiveModal) {
    super(activeModal);
  }

  public viewModel = inject(ChangeUserEmailModalViewModel);

  public setupBindings(): void {
    this.viewModel.dismissModalSubject.subscribeWhileAlive({
      owner: this,
      next: close => {
        if (close instanceof BaseUser) {
          this.close(close);
        } else {
          this.close(false);
        }
      }
    });
  }

  public setupViews(): void {}

  public setUser<T extends BaseUser>(user: T): void {
    this.viewModel.setUser<T>(user);
  }
}
