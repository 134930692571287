import { PermissionMap } from '../base/permission-map';
import { PermissionTypeMap } from '../base/permission-type-map';
import { PermissionType } from '../base/permission-type';

export class EmployerPermissionMap extends PermissionMap {
  public map: Map<number, PermissionTypeMap> = new Map<number, PermissionTypeMap>();

  constructor() {
    super();
    this.map.set(1, new PermissionTypeMap(1, PermissionType.Route, 'settings#employees'));
    // These were removed on the backend, but leaving as comments to show why IDs are skipped
    // this.map.set(2, new PermissionTypeMap(2, PermissionType.Field, 'employeeFirstName'));
    // this.map.set(3, new PermissionTypeMap(3, PermissionType.Field, 'employeeLastName'));
    this.map.set(4, new PermissionTypeMap(4, PermissionType.Field, 'employeeEmail'));
    this.map.set(5, new PermissionTypeMap(5, PermissionType.Field, 'employeeRole'));
    this.map.set(6, new PermissionTypeMap(6, PermissionType.Field, 'employeeStatus'));
    this.map.set(7, new PermissionTypeMap(7, PermissionType.Route, 'view-role/:roleId'));
    this.map.set(8, new PermissionTypeMap(8, PermissionType.Action, 'createEmployee'));
    this.map.set(9, new PermissionTypeMap(9, PermissionType.Field, 'employeeFirstName'));
    this.map.set(10, new PermissionTypeMap(10, PermissionType.Field, 'employeeLastName'));
    this.map.set(11, new PermissionTypeMap(11, PermissionType.Field, 'employeeRole'));
    this.map.set(12, new PermissionTypeMap(12, PermissionType.Field, 'employeeStatus'));
    this.map.set(13, new PermissionTypeMap(13, PermissionType.Action, 'updateEmployeeEmail'));
    this.map.set(14, new PermissionTypeMap(14, PermissionType.Action, 'resetEmployeePassword'));
    this.map.set(15, new PermissionTypeMap(15, PermissionType.Action, 'resendEmployeeInvite'));
  }
}
