// noinspection JSUnusedLocalSymbols

import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../models/base/base-view-model';
import { UserDomainModel } from '../../../domainModels/user-domain-model';
import { SignInRequest } from '../../../models/users/requests/sign-in-request';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../services/toast-service';
import { CacheService } from '../../../services/cache-service';
import { PortalType } from '../../../models/enum/shared/portal-type';
import { UserChallengeName } from '../../../models/users/enum/user-challenge.name';

@Injectable({ providedIn: 'root' })
export class SignInViewModel extends BaseViewModel {
  private readonly loadingMessage = $localize`Signing in`;

  constructor(
    private userDomainModel: UserDomainModel,
    private cacheService: CacheService,
    public router: Router,
    public toastService: ToastService
  ) {
    super();
  }

  private _preFillEmail = new BehaviorSubject<string>('');
  public readonly preFillEmail$ = this._preFillEmail as Observable<string>;

  private _preFillCode = new BehaviorSubject<string>('');
  public readonly preFillCode$ = this._preFillCode as Observable<string>;

  public getUrlParams(route: ActivatedRoute) {
    route.queryParams.subscribeWhileAlive({
      owner: this,
      next: params => {
        const email = decodeURIComponent(decodeURIComponent(params?.Email || params?.email || ''));
        const code = decodeURIComponent(decodeURIComponent(params?.Code || params?.code || ''));
        if (!!email) this._preFillEmail.next(email);
        if (!!code) this._preFillCode.next(code);
      }
    });
  }

  public internalUserSignIn(req: SignInRequest): void {
    this._loadingOpts.addRequest(this.loadingMessage);
    this.userDomainModel.signIn(req, PortalType.Internal).subscribe({
      next: user => {
        const { challengeName } = user;

        switch (challengeName) {
          case UserChallengeName.NEW_PASSWORD_REQUIRED:
            this.router.navigate(['/auth/internal-user-choose-password'], { replaceUrl: true }).then(() => {});
            break;
          case UserChallengeName.MFA_SETUP:
            this.router.navigate(['/auth/internal-user-auth-setup'], { replaceUrl: true }).then(() => {});
            break;
          case UserChallengeName.SOFTWARE_TOKEN_MFA:
            this.router.navigate(['/auth/internal-user-verify-code'], { replaceUrl: true }).then(() => {});
            break;
          default:
            this.router.navigate(['/auth/sign-in'], { replaceUrl: true }).then();
            break;
        }
        this._loadingOpts.removeRequest(this.loadingMessage);
      },
      error: error => {
        this.toastService.publishError(error);
        this._loadingOpts.removeRequest(this.loadingMessage);
      }
    });
  }

  public memberSignIn(req: SignInRequest): void {
    this._loadingOpts.addRequest(this.loadingMessage);
    this.userDomainModel.signIn(req, PortalType.Member).subscribe({
      next: user => {
        const { challengeName } = user;
        switch (challengeName) {
          case UserChallengeName.SOFTWARE_TOKEN_MFA:
            this.router.navigate(['/auth/verify-mfa'], { replaceUrl: true }).then(() => {});
            break;
          case UserChallengeName.SMS_MFA:
            this.router.navigate(['/auth/verify-mfa'], { replaceUrl: true }).then(() => {});
            break;
          default:
            this.router.navigate(['/dashboard'], { replaceUrl: true }).then(() => {});
            break;
        }
        this._loadingOpts.removeRequest(this.loadingMessage);
      },
      error: error => {
        this.toastService.publishError(error);
        this._loadingOpts.removeRequest(this.loadingMessage);
      }
    });
  }

  public employerSignIn(req: SignInRequest): void {
    this._loadingOpts.addRequest(this.loadingMessage);
    this.userDomainModel.signIn(req, PortalType.Employer).subscribe({
      next: user => {
        const { challengeName } = user;
        switch (challengeName) {
          case UserChallengeName.NEW_PASSWORD_REQUIRED:
            this.router.navigate(['/auth/employer-choose-password'], { replaceUrl: true }).then(() => {});
            break;
          case UserChallengeName.SOFTWARE_TOKEN_MFA:
            this.router.navigate(['/auth/verify-mfa'], { replaceUrl: true }).then(() => {});
            break;
          case UserChallengeName.SMS_MFA:
            this.router.navigate(['/auth/verify-mfa'], { replaceUrl: true }).then(() => {});
            break;
          default:
            this.router.navigate(['/dashboard'], { replaceUrl: true }).then(() => {});
            break;
        }
        this._loadingOpts.removeRequest(this.loadingMessage);
      },
      error: error => {
        this.toastService.publishError(error);
        this._loadingOpts.removeRequest(this.loadingMessage);
      }
    });
  }
}
