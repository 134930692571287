<div class="auth-flow-logo-container">
  <img src="assets/logo/dark/css-logo.svg" alt="CSS Logo" i18n-alt />
</div>

<div class="auth-flow-title">
  {{ authFlowTitle$ | async }}
</div>

<div class="auth-flow-subtext">
  {{ authFlowSubtext$ | async }}
</div>

<lib-reactive-form-group #form [bindTo]="req" (formSubmitted$)="formSubmitted($event)">
  <lib-reactive-form-birthday
    [inputName]="'birthdate'"
    label="Birthdate"
    i18n-label
    [bindingProperty]="'birthdate'"
    [required]="true"
    [showRequiredAstrix]="false"
    [placeholder]="'dd / mm / yyyy'">
  </lib-reactive-form-birthday>

  <lib-reactive-form-masked-input
    [inputName]="'sin'"
    [required]="true"
    label="Last 3 Digits of Your SIN"
    i18n-label
    [placeholder]="'--- --- 000'"
    (keydown.enter)="form.submitForm()"
    [bindingProperty]="'sin'"
    [mask]="mask">
  </lib-reactive-form-masked-input>
</lib-reactive-form-group>

<div class="sign-in-button-container">
  <lib-button-primary [style.width.%]="100" (buttonClicked)="form.submitForm()" i18n> Continue</lib-button-primary>
</div>
<div class="support-button-container">
  <span class="support-button" i18n>Having trouble?</span>
  <button class="paddingless-text-button mt-2" (click)="openContactPage()" i18n>Click here for support</button>
</div>
