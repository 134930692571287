import { Deserializable } from '../protocols/deserializable';

export class TransactionComment implements Deserializable {
  public id!: string;
  public transactionId!: string;
  public authorId!: number;
  public comment!: string;
  public createdDate!: Date;

  onDeserialize() {
    if (!!this.createdDate) {
      this.createdDate = new Date(this.createdDate);
    }
  }
}
