<app-base-action-modal
  [title]="'Change Email' | localization"
  [primaryLabel]="'Change Email' | localization"
  [secondaryLabel]="'Cancel' | localization"
  (closeClicked)="viewModel.closeModal()"
  (primaryClicked)="form.submitForm()"
  (secondaryClicked)="viewModel.closeModal()">
  <app-loading *ngIf="viewModel.isLoading$ | async" [options]="(viewModel.loadingOpts$ | async) ?? undefined">
  </app-loading>
  <div class="flow-text">
    {{ viewModel.flowText$ | async }}
  </div>
  <lib-reactive-form-group
    #form
    [bindTo]="viewModel.changeEmailReq$ | async"
    (formSubmitted$)="viewModel.formSubmitted($event)">
    <lib-reactive-form-email
      [required]="true"
      [label]="'New Email Address' | localization"
      [bindingProperty]="'newEmail'"
      [inputName]="'email'"></lib-reactive-form-email>
    <lib-reactive-form-email
      [required]="true"
      [label]="'Confirm New Email Address' | localization"
      [confirmEmail]="true"
      [inputName]="'confirmEmail'"></lib-reactive-form-email>
  </lib-reactive-form-group>
</app-base-action-modal>
