<div
  class="alert-container"
  [style.background-color]="backgroundColour$ | async"
  [style.border-color]="borderColor$ | async">
  <div class="alert-body">
    <div class="overflow-auto">
      <div class="alert-title">{{ title$ | async }}</div>
      <div class="alert-description">
        {{ description$ | async }}
      </div>
      <div class="learn-more-button" (click)="learnMoreClicked.emit()" i18n>Learn more</div>
    </div>
    <div *ngIf="isDismissable$ | async" class="dismiss-button" (click)="dismissClicked.emit()">
      <img class="d-block" [src]="dismissButtonSrc$ | async" alt="Dismiss" />
    </div>
  </div>
</div>
