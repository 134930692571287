import { Deserializable } from '../../protocols/deserializable';

export class VerifiedPhoneNumber implements Deserializable {
  public phoneNumber: string;

  constructor(phoneNumber: string) {
    this.phoneNumber = phoneNumber;
  }

  onDeserialize(): void {}
}
