// noinspection JSUnusedLocalSymbols

import { Component, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { fromEvent } from 'rxjs';
import { BaseComponent } from '../../../../../models/base/base-component';
import { takeUntil, throttleTime } from 'rxjs/operators';
import { LookAheadItemComponent } from './look-ahead-item/look-ahead-item.component';
import '../../../../../utils/number-utils';
import { LookAheadItem } from './look-ahead-item/protocol/look-ahead-item';

@Component({
  selector: 'app-look-ahead-list',
  templateUrl: './look-ahead-list.component.html',
  styleUrls: ['./look-ahead-list.component.scss']
})
export class LookAheadListComponent extends BaseComponent {
  @Input() lookAheadItems!: LookAheadItem[];
  @Input() lookAheadProperties!: string[];
  @Input() lookAheadSeparator: string = ' ';
  @Input() useProfileItems: boolean = false;
  @Input() customItemTemplate: TemplateRef<any> | undefined;
  @Output() lookAheadItemClicked = new EventEmitter<any>(true);
  @ViewChildren(LookAheadItemComponent) itemComponents!: QueryList<LookAheadItemComponent>;

  constructor() {
    super();
  }

  public selectedPosition = 0;

  // @ts-ignore
  private mouseMoved = fromEvent<MouseEvent>(document, 'mousemove')
    .pipe(throttleTime(1000), takeUntil(this.onDestroy))
    .subscribe((event: MouseEvent) => {
      this.selectedPosition = -1;
    });

  // @ts-ignore
  private keyDowns = fromEvent<KeyboardEvent>(document, 'keydown')
    .pipe(takeUntil(this.onDestroy))
    .subscribe((event: KeyboardEvent) => {
      const key = event.key;
      const up = key === 'ArrowUp';
      const down = key === 'ArrowDown';
      const isUpOrDown = up || down;
      const hasNonDisabledItem = this.lookAheadItems?.some(item => !item.lookAheadDisabled());
      if (isUpOrDown && hasNonDisabledItem && this.selectedPosition === -1) {
        this.selectedPosition = this.lookAheadItems.findIndex(item => !item.lookAheadDisabled());
      } else if (isUpOrDown && hasNonDisabledItem) {
        const validIndices = this.lookAheadItems
          ?.map(item => {
            return !item.lookAheadDisabled() ? this.lookAheadItems?.findIndex(it => it === item) : null;
          })
          .filterNulls();
        if (validIndices) {
          let searchingForValid = true;
          while (searchingForValid) {
            this.selectedPosition = (this.selectedPosition + (up ? -1 : +1)).mod(this.lookAheadItems?.length ?? 0);
            if (!this.lookAheadItems[this.selectedPosition].lookAheadDisabled()) {
              searchingForValid = false;
            }
          }
        } else {
          this.selectedPosition = -1;
        }
      } else if (key === 'Enter' && hasNonDisabledItem) {
        this.selectAtIndex(this.selectedPosition);
      }
    });

  setupViews() {}

  setupBindings() {}

  selectAtIndex(i: number) {
    const items = this.lookAheadItems;
    const itemComponents = this.itemComponents.toArray();
    const hasItemsAndNotDisabled = !!items && items.length > i && !items[i].lookAheadDisabled();
    const componentLaidOut = !!itemComponents && items.length > i;
    if (hasItemsAndNotDisabled && componentLaidOut) {
      itemComponents[i].click();
    }
  }
}
