import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { MemberSubmissionType } from '../../../../models/submissions/member-submission-type';
import { SubmissionTypeEnum } from 'src/app/models/account/enum/submission-type-enum';
import { SubmissionPermissionType } from '../../../../models/permissions/dto/submission-permission-type';
import { SubmissionPermissionTypeEnum } from '../../../../models/permissions/enum/submission-permission-type.enum';
import { SubmissionPermissionWithType } from '../../../../models/permissions/dto/submission-permission-with-type';
import { SubmissionPermissionCheckboxTableViewModel } from './submission-permission-checkbox-table-view-model';
import { SubmissionPermissionSelectionTypeEnum } from '../../../../models/permissions/enum/submission-permission-selection-type.enum';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-submission-permission-checkbox-table',
  templateUrl: './submission-permission-checkbox-table.component.html',
  styleUrls: ['./submission-permission-checkbox-table.component.scss'],
  providers: [SubmissionPermissionCheckboxTableViewModel]
})
export class SubmissionPermissionCheckboxTableComponent extends BaseComponent implements OnChanges {
  private viewModel = inject(SubmissionPermissionCheckboxTableViewModel);

  @Input() memberSubmissionTypes: MemberSubmissionType[] = [];
  @Input() submissionPermissionTypes: SubmissionPermissionType[] = [];
  @Input() allSubmissionPermissions!: SubmissionPermissionWithType[];

  @Output() updatePermissionIds = new EventEmitter<number[]>();

  // Permission Checking
  public readonly canEditSubmissionPermissions$ = this.viewModel.canEditSubmissionPermissions$;

  // Member Permissions
  public readonly memberNameChangePermissions$ = this.viewModel.memberNameChangePermissions$;
  public readonly memberAddressPermissions$ = this.viewModel.memberAddressPermissions$;
  public readonly memberContactInfoPermissions$ = this.viewModel.memberContactInfoPermissions$;
  public readonly memberPersonalInfoPermissions$ = this.viewModel.memberPersonalInfoPermissions$;
  public readonly memberViewSelectionType$ = this.viewModel.memberViewSelectionType$;
  public readonly memberProcessSelectionType$ = this.viewModel.memberProcessSelectionType$;
  public readonly memberApproveSelectionType$ = this.viewModel.memberApproveSelectionType$;
  public readonly memberApproveOwnSelectionType$ = this.viewModel.memberApproveOwnSelectionType$;
  public readonly memberOverrideSelectionType$ = this.viewModel.memberOverrideSelectionType$;
  public readonly memberCreateSelectionType$ = this.viewModel.memberCreateSelectionType$;

  permissionClicked(
    id: number,
    permType: SubmissionPermissionTypeEnum,
    subType: SubmissionTypeEnum,
    checked: boolean
  ): void {
    this.viewModel.permissionClicked(id, permType, subType, checked);
  }

  selectAll(type: SubmissionPermissionTypeEnum, checked: boolean): void {
    this.viewModel.selectAll(type, checked);
  }

  getPermissionsByType(typeId: SubmissionTypeEnum): Observable<SubmissionPermissionWithType[]> {
    switch (typeId) {
      case SubmissionTypeEnum.NameChange:
        return this.memberNameChangePermissions$;
      case SubmissionTypeEnum.AddressChange:
        return this.memberAddressPermissions$;
      case SubmissionTypeEnum.UpdateContactInfo:
        return this.memberContactInfoPermissions$;
      case SubmissionTypeEnum.PersonalInfoChange:
        return this.memberPersonalInfoPermissions$;
      default:
        return of([]);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.allSubmissionPermissions?.currentValue) {
      this.viewModel.connectToMemberSubmissionPermissions(this.allSubmissionPermissions);
    }
  }

  setupBindings(): void {
    this.viewModel.updatedPermissionsSubject$.subscribeWhileAlive({
      owner: this,
      next: ids => {
        this.updatePermissionIds.emit(ids);
      }
    });
  }

  setupViews(): void {}

  protected readonly SubmissionTypeEnum = SubmissionTypeEnum;
  protected readonly SubmissionPermissionTypeEnum = SubmissionPermissionTypeEnum;
  protected readonly SubmissionPermissionSelectionTypeEnum = SubmissionPermissionSelectionTypeEnum;
}
