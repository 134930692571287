import { Component } from '@angular/core';
import { NavItemComponent } from '../nav-item.component';
import { Router } from '@angular/router';
import { NavItemViewModel } from '../nav-item-view-model';

@Component({
  selector: 'app-sign-out-nav-item',
  templateUrl: './sign-out-nav-item.component.html',
  styleUrls: ['./sign-out-nav-item.component.scss', '../nav-item.component.scss'],
  providers: [NavItemViewModel]
})
export class SignOutNavItemComponent extends NavItemComponent {
  constructor(public viewModel: NavItemViewModel, protected router: Router) {
    super(viewModel, router);
  }

  navItemSelected() {
    // if CanActivateGuard is used, navigate to the sign-out page
    // instead of signing out here so that the CanDeactivateGuard will work properly
    // just for demo mode this routes to sign-in
    this.router.navigate([`/auth/sign-in`]).then();
  }
}
