<app-loading [hidden]="!(viewModel.isLoading$ | async)" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<div class="modal-body">
  <app-header [header]="'Upload Profile Picture'"></app-header>
  <app-upload-asset [parentHandler]="this" [allowImage]="true" [allowVideo]="false" [maxAssets]="1"> </app-upload-asset>
  <button
    (click)="deleteProfilePicture()"
    *ngIf="viewModel?.existingProfilePicture"
    class="custom-button destructive-button float-left mb-20px">
    Delete Profile Picture
  </button>
  <button *ngIf="viewModel.file" (click)="uploadImage()" class="custom-button preferred-button float-right mb-20px">
    Upload
  </button>
  <button (click)="cancel()" class="custom-button neutral-button float-right mb-20px">Cancel</button>
</div>
