import { Component, inject } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { MemberAuthViewModel } from '../../../../viewModels/member-auth-view-model';
import { AssetSize } from '../../../../../../models/enum/dto/asset-size.enum';

@Component({
  selector: 'app-member-send-email-verification',
  templateUrl: './member-send-email-verification.component.html',
  styleUrls: ['./member-send-email-verification.component.scss', '../../auth.component.scss']
})
export class MemberSendEmailVerificationComponent extends BaseComponent {
  private viewModel = inject(MemberAuthViewModel);
  public authFlowTitle$ = this.viewModel.authFlowTitle$;
  public authFlowSubtext$ = this.viewModel.authFlowSubtext$;
  public newMemberEmail$ = this.viewModel.newMemberEmail$;

  setupBindings(): void {}

  setupViews(): void {}

  public openContactPage(): void {
    this.viewModel.openContactPage();
  }

  protected readonly AssetSize = AssetSize;
}
