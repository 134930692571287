import { MemberUser } from '../../dto/member-user';
import { SubmissionRequest } from './submission-request';

export class ChangeAddressRequest extends SubmissionRequest {
  public addressLine1: string;
  public addressLine2: string;
  public city: string;
  public stateId: number;
  public countryId: number;
  public postalCode: string;

  constructor(user: MemberUser) {
    super();
    this.memberId = user?.id;
    this.addressLine1 = user?.address?.addressLine1;
    this.addressLine2 = user?.address?.addressLine2;
    this.city = user?.address?.city;
    this.stateId = user?.address?.state?.id;
    this.countryId = user?.address?.country?.id;
    this.postalCode = user?.address?.postalCode;
  }
}
