<ngb-datepicker
  class="date-picker"
  (dateSelect)="handleDateSelection($event)"
  [displayMonths]="1"
  [dayTemplate]="t"
  outsideDays="hidden"
  [startDate]="fromDate!"
  tabindex="-1" />
<ng-template #t let-date let-focused="focused">
  <span
    class="custom-day"
    [class.focused]="isFocused(date, focused)"
    [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>
