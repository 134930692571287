<app-comments-form
  #form
  [headerHeight]="'5.5rem'"
  [comments]="comments$ | async"
  [isLoading]="isLoadingComments$ | async"
  [dropdownItems]="userDropdownItems$ | async"
  (filterText)="filterText($event)"
  (commentValueChanged)="onCommentValueChange($event)"
  (newComment)="newComment($event)">
</app-comments-form>
