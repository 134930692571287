import { Deserializable } from '../../protocols/deserializable';
import { UserChallengeName } from '../enum/user-challenge.name';

export class AuthCodeRequest implements Deserializable {
  public email: string = '';
  public timeBasedOneTimePassword: string = '';
  public challengeName: UserChallengeName | null = null;
  public sessionId: string = '';

  constructor(
    email: string,
    timeBasedOneTimePassword: string,
    challengeName: UserChallengeName | null,
    sessionId: string
  ) {
    this.email = email;
    this.challengeName = challengeName;
    this.sessionId = sessionId;
    this.timeBasedOneTimePassword = timeBasedOneTimePassword;
  }

  onDeserialize = () => {};
}
