import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseModalComponent } from '../../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-alert-modal',
  templateUrl: './delete-alert-modal.component.html',
  styleUrls: ['./delete-alert-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteAlertModalComponent extends BaseModalComponent {
  ignoreEscape: boolean = false;

  constructor(protected activeModal: NgbActiveModal) {
    super(activeModal);
  }

  setupBindings(): void {}

  setupViews(): void {}
}
