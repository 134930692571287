import { Deserializable } from '../../protocols/deserializable';
import { State } from './state';

export class Country implements Deserializable {
  public countryCode: string = '';
  public phoneCode: string = '';
  public states: State[] = [];
  public id: number = 0;
  public name: string = '';

  onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.states = Deserialize?.arrayOf(State, this.states);
  }
}
