import { Deserializable } from '../protocols/deserializable';
import { DateUtils } from '../../utils/date-utils';
import { Mentionable } from './mentionable';

export class BaseComment implements Deserializable {
  public id!: string;
  public authorId!: string;
  public comment!: string;
  public createdDate!: string;

  // Not from API
  public formattedDate!: string;
  public author!: Mentionable;

  onDeserialize(): void {
    if (!!this.createdDate) {
      const date = new Date(this.createdDate);
      this.formattedDate = DateUtils.formatUnixToCurrentDateTime((date.getTime() ?? 0) / 1000);
    }
  }

  public getFormattedComment<T>(users: Mentionable[]): T {
    this.comment = this.comment.replace(/\n/g, '<br>');
    return {
      ...this,
      comment: this.comment.replace(/\{\{(\d+)\}\}/g, (_, id) => {
        const replacement = users.find(item => item.id === +id);
        return replacement ? `<span class="tagged-user">@${replacement.fullName}</span>` : '';
      })
    } as T;
  }
}
