import { Directive, Inject } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { EmployerPlanDesignatedUnitVersion } from '../../../models/employers/plans/employer-plan-designated-unit-version';

@Directive({
  selector: '[appDesignatedUnitVersionUniqueStartDateValidator]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: DesignatedUnitVersionUniqueStartDateValidatorDirective, multi: true }
  ]
})
export class DesignatedUnitVersionUniqueStartDateValidatorDirective {
  static key: string = 'designatedUnitStartDateExists';

  constructor(@Inject('existingVersions') public existingVersions: EmployerPlanDesignatedUnitVersion[]) {
    this.existingVersions = existingVersions;
  }

  getErrorMsg(versions: EmployerPlanDesignatedUnitVersion[], value: string): string | null {
    if (!!versions.length && value?.length > 1) {
      const sameDates = versions.some(v => this.datesAreTheSame(value, v.startDate));
      if (sameDates) return $localize`A version already exists with this effective date`;
    }
    return null;
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    const errorMsg = this.getErrorMsg(this.existingVersions, control.value);
    if (errorMsg) return { [DesignatedUnitVersionUniqueStartDateValidatorDirective.key]: errorMsg };
    return null;
  }

  private datesAreTheSame(dateString: string, dateObj: Date): boolean {
    const [day, month, year] = dateString.split('/').map(Number);
    const stringDate = new Date(year, month - 1, day);

    const dateObjYear = dateObj.getFullYear();
    const dateObjMonth = dateObj.getMonth();
    const dateObjDay = dateObj.getDate();

    const stringDateYear = stringDate.getFullYear();
    const stringDateMonth = stringDate.getMonth();
    const stringDateDay = stringDate.getDate();

    return stringDateYear === dateObjYear && stringDateMonth === dateObjMonth && stringDateDay === dateObjDay;
  }
}
