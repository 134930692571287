export class ColorUtils {
  static validateHexColor(hex: string): string {
    if (hex?.length > 0) {
      const firstChar = hex?.substring(0, 1);
      if (firstChar !== '#') {
        hex = '#' + hex;
      }
    }
    hex = hex?.toLocaleUpperCase();
    const regexp = new RegExp(`^#([0-9A-F]{3}){1,2}$`);
    if (regexp?.test(hex)) {
      return hex;
    } else {
      return '';
    }
  }

  static isDarkColor(hexColor: string): boolean {
    hexColor = ColorUtils.validateHexColor(hexColor);
    if (!!hexColor) {
      if (hexColor?.length === 4) {
        // if 3 character hex code, convert to 6 character
        hexColor = hexColor?.replace('#([0-9a-fA-F])([0-9a-fA-F])([0-9a-fA-F])', '#$1$1$2$2$3$3');
      }
      const color = hexColor?.substring(1); // strip #
      const rgb = parseInt(color, 16); // convert rrggbb to decimal
      // eslint-disable-next-line no-bitwise
      const r = (rgb >> 16) & 0xff; // extract red
      // eslint-disable-next-line no-bitwise
      const g = (rgb >> 8) & 0xff; // extract green
      // eslint-disable-next-line no-bitwise
      const b = (rgb >> 0) & 0xff; // extract blue
      const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
      return luma < 128;
    } else {
      return false;
    }
  }
}
