<div class="auth-flow-logo-container">
  <img src="assets/logo/dark/css-logo.svg" alt="CSS Logo" i18n-alt />
</div>

<div class="auth-flow-subtext">
  <div class="header" i18n>Enter Verification Code</div>
  <div class="subtext">
    {{ viewModel.subText$ | async }}
  </div>
</div>

<lib-reactive-form-group #form [bindTo]="viewModel.req$ | async" (formSubmitted$)="viewModel.formSubmitted($event)">
  <lib-reactive-form-string-number
    [inputName]="'timeBasedOneTimePassword'"
    label="Verification Code"
    i18n-label
    [inlineLabel]="false"
    [integersOnly]="true"
    [required]="true"
    [minCharacterCount]="6"
    [maxCharacterCount]="6"
    [showRequiredAstrix]="false"
    [bindingProperty]="'timeBasedOneTimePassword'">
  </lib-reactive-form-string-number>
</lib-reactive-form-group>

<lib-button-primary class="w-100" (buttonClicked)="form.submitForm()" i18n>Verify Account</lib-button-primary>
