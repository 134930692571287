import { Component } from '@angular/core';
import { Toast } from 'ngx-toastr';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[custom-toast-component]',
  templateUrl: './custom-toast.component.html',
  preserveWhitespaces: false
})
export class CustomToastComponent extends Toast {}
