import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserDomainModel } from '../../domainModels/user-domain-model';
import { map, take } from 'rxjs/operators';

type Activation = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private userDomainModel = inject(UserDomainModel);
  constructor(public router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Activation {
    return this.userDomainModel.isAuthenticated().pipe(
      take(1),
      map(r => {
        return !!r || this.router.createUrlTree(['/auth/sign-in']);
      })
    );
  }
}
