import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { TabBarItem } from '../tab-bar/tab-bar-item';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent extends BaseComponent {
  // Ensure title is localized before being passed in
  @Input() title: string = '';
  @Input() showBackButton: boolean = false;
  // Ensure actionButtonLabel is localized before being passed in
  @Input() actionButtonLabel: string = '';
  @Input() actionButtonIcon: string = '';
  // Ensure cancelButtonLabel is localized before being passed in
  @Input() cancelButtonLabel: string = '';
  @Input() cancelButtonIcon: string = '';
  @Input() tabs: TabBarItem[] = [];
  @Output() actionButtonClicked = new EventEmitter();
  @Output() cancelButtonClicked = new EventEmitter();
  @Output() backButtonClicked = new EventEmitter();
  @Output() selectedTab = new EventEmitter<TabBarItem>(true);

  setupBindings(): void {}

  setupViews(): void {}

  emitActionButtonClicked(): void {
    this.actionButtonClicked.emit();
  }

  emitCancelButtonClicked(): void {
    this.cancelButtonClicked.emit();
  }
}
