import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, skip } from 'rxjs';
import { debounceTime, shareReplay, switchMap } from 'rxjs/operators';
import { EmployersDomainModel } from '../../../../../domainModels/employers-domain-model';
import { EmployerPlanDesignatedUnit } from '../../../../../models/employers/plans/employer-plan-designated-unit';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { ToastService } from '../../../../../services/toast-service';
import { ODataQueryOptions } from '../../../../../models/shared/odata-query-options';
import { CustomError } from '../../../../../models/shared/custom-error';
import { DesignatedUnitsTableFilterRequest } from '../../../../../models/shared/odata-filter-requests/designated-units-table-filter-request';
import { DesignatedUnitsTableOrderbyRequest } from '../../../../../models/shared/odata-sorting-requests/designated-units-table-orderby-request';
import { DesignatedUnitTableType } from '../../../../../models/employers/enum/designated-unit-table-type';
import { Router } from '@angular/router';

@Injectable()
export class DesignatedUnitsDataTableViewModel extends BaseViewModel {
  constructor(private toastService: ToastService) {
    super();
  }

  private employersDomainModel = inject(EmployersDomainModel);
  private router = inject(Router);

  private _planId = new BehaviorSubject<number | null>(null);
  public readonly planId$ = this._planId as Observable<number | null>;

  private _designatedUnits$ = new BehaviorSubject<EmployerPlanDesignatedUnit[] | null>(null);
  public designatedUnits$ = this._designatedUnits$.pipe(skip(1));

  private _designatedUnitsTotalCount$ = new BehaviorSubject<number>(0);
  public designatedUnitsTotalCount$ = this._designatedUnitsTotalCount$ as Observable<number>;

  private _designatedUnitsFilterRequest = new BehaviorSubject<DesignatedUnitsTableFilterRequest>(
    new DesignatedUnitsTableFilterRequest()
  );
  public readonly designatedUnitsFilterRequest$ = this._designatedUnitsFilterRequest
    .distinctUniquelyIdentifiable()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  private _designatedUnitsOrderbyRequest = new BehaviorSubject<DesignatedUnitsTableOrderbyRequest>(
    new DesignatedUnitsTableOrderbyRequest()
  );
  public readonly designatedUnitsOrderbyRequest$ = this
    ._designatedUnitsOrderbyRequest as Observable<DesignatedUnitsTableOrderbyRequest>;

  private _currPageNumber = new BehaviorSubject<number>(0);
  public readonly currPageNumber$ = this._currPageNumber as Observable<number>;

  private _pageSize = new BehaviorSubject<number>(10);
  public readonly pageSize$ = this._pageSize as Observable<number>;

  private listenToFetchDesignatedUnits = combineLatest([
    this.planId$.notNull(),
    this.designatedUnitsFilterRequest$,
    this.designatedUnitsOrderbyRequest$,
    this.currPageNumber$,
    this.pageSize$
  ])
    .pipe(debounceTime(100))
    .subscribe(([_, filterRequest, orderbyRequest, currPage, pageSize]) => {
      this.fetchDesignatedUnits(filterRequest, orderbyRequest, currPage, pageSize);
    });

  public designatedUnitClicked(du: EmployerPlanDesignatedUnit): void {
    this.router.navigate([`/settings/employer-plan/${du.employerPlanId}/unit/${du.id}`]);
  }

  public updateCurrPageNumber(currPage: number): void {
    this._currPageNumber.next(currPage);
  }

  public handleSortReq(sortReq: string): void {
    this._designatedUnitsOrderbyRequest.next(new DesignatedUnitsTableOrderbyRequest(sortReq));
  }

  public setPlanId(planId: number): void {
    this._planId.next(planId);
  }

  public setUnitType(type: DesignatedUnitTableType): void {
    switch (type) {
      case DesignatedUnitTableType.Active:
        const activeFilterReq = new DesignatedUnitsTableFilterRequest();
        activeFilterReq.activeUnits = true;
        this._designatedUnitsFilterRequest.next(activeFilterReq);
        break;
      case DesignatedUnitTableType.Inactive:
        const inactiveFilterReq = new DesignatedUnitsTableFilterRequest();
        inactiveFilterReq.activeUnits = false;
        this._designatedUnitsFilterRequest.next(inactiveFilterReq);
        break;
    }
  }

  public appendNewUnit(unit: EmployerPlanDesignatedUnit | null): void {
    if (!!unit) {
      const unitIsActive = unit.versions.some(v => v.active);
      combineLatest([this.designatedUnitsFilterRequest$, this._designatedUnits$, this.designatedUnitsTotalCount$]).once(
        ([filterReq, units, count]) => {
          if (!!filterReq && !!units) {
            if (unitIsActive && filterReq.activeUnits) {
              this._designatedUnits$.next([unit, ...units]);
              this._designatedUnitsTotalCount$.next(count + 1);
            } else if (!unitIsActive && !filterReq.activeUnits) {
              this._designatedUnits$.next([unit, ...units]);
              this._designatedUnitsTotalCount$.next(count + 1);
            }
          }
        }
      );
    }
  }

  private fetchDesignatedUnits(
    filterReq: DesignatedUnitsTableFilterRequest,
    orderbyReq: DesignatedUnitsTableOrderbyRequest,
    currPage: number,
    pageSize: number
  ): void {
    const lm = '';
    this._loadingOpts.addRequest(lm);
    const oDataParams = new ODataQueryOptions();
    const filterString = filterReq.getFilterString();
    const orderbyString = orderbyReq.getOrderByString();
    if (!!filterString) {
      oDataParams.setFilter(filterString);
    }
    if (!!orderbyString) {
      oDataParams.setOrderBy(orderbyString);
    }
    oDataParams.setCount(true);
    oDataParams.setTop(pageSize);
    oDataParams.setSkip(currPage * pageSize);
    oDataParams.setExpand('DesignatedUnitType, Versions');
    this.planId$
      .pipe(
        switchMap(planId => {
          return this.employersDomainModel.getEmployerPlanDesignatedUnits(planId?.toString(10) ?? '0', oDataParams);
        })
      )
      .subscribe({
        next: res => {
          this._designatedUnitsTotalCount$.next(res['@odata.count'] ?? 0);
          this._designatedUnits$.next(res.value);
        },
        error: (error: CustomError) => {
          this.toastService.publishError(error);
        },
        complete: () => {
          this._loadingOpts.removeRequest(lm);
        }
      });
  }
}
