import { Deserializable } from '../protocols/deserializable';

export class CreateRemittancePaymentRequest implements Deserializable {
  // TODO: Hardcoded for now as backend doesn't know how this will work just yet
  public paymentDirectionId: number = 1;
  public employerId!: number;
  public paymentDetails: RemittancePaymentDetails[] = [new RemittancePaymentDetails()];

  constructor() {
    this.onDeserialize();
    this.paymentDirectionId = 1;
    this.paymentDetails = [new RemittancePaymentDetails()];
  }

  public onDeserialize(): void {}
}

export class RemittancePaymentDetails implements Deserializable {
  public id!: string;
  public paymentId!: number;
  public remittanceId!: number;
  public amount!: number;
  public accountId!: string | null;

  public onDeserialize(): void {}
}
