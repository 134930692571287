import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { SharedModule } from '../../views/shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [ConfirmationModalComponent],
  exports: []
})
export class ConfirmationModule {}
