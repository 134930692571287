import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { QrCodeSupportComponent } from '../views/auth/components/modals/qr-code-support/qr-code-support.component';
import { ModalUtils } from '../utils/modal-utils';
import { Injector } from '@angular/core';

export class ModalQrSupport {
  static open(ngbModal: NgbModal, injector: Injector) {
    const modalRef = ngbModal.open(QrCodeSupportComponent, ModalUtils.defaultModalOptions(injector));
    modalRef.result.then(_ => {});
  }
}
