import { Cachable } from '../../protocols/cachable';
import { DateUtils } from '../../../utils/date-utils';
import { DefaultCacheKey } from '../../enum/shared/default-cache-key.enum';
import { CachePolicy } from '../../enum/shared/cachable-image-policy.enum';

export class DismissedAlerts implements Cachable {
  public alertIds: number[] = [];
  public cachedTime!: number;

  public onDeserialize() {}

  cacheExpirySeconds(): number {
    return DateUtils.unixOneMonth();
  }

  cacheKey(): string {
    return DefaultCacheKey.SessionContainer;
  }

  imageCachePolicy(): CachePolicy {
    return CachePolicy.Service;
  }

  isExpired(): boolean {
    return false;
  }
}
