import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent extends BaseComponent {
  @Input() title: string = '';
  @Output() rearrangeButtonClicked = new EventEmitter();

  setupBindings(): void {}

  setupViews(): void {}
}
