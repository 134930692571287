import { AfterViewInit, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CustomFile } from '../../../../models/shared/custom-file';
import { ReplaySubject, Subject } from 'rxjs';
import { SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationOptions } from '../../../../models/shared/stylesheet/confirmation-options';
import { UploadImageInterface } from '../upload-asset/upload-image-interface';
import { BaseComponent } from '../../../../models/base/base-component';
import { UploadAssetComponent } from '../upload-asset/upload-asset.component';
import { delay } from 'rxjs/operators';
import { Asset } from '../../../../models/asset/dto/asset';
import { ModalConfirmation } from '../../../../modals/modal-confirmation';

@Component({
  selector: 'app-logo-upload',
  templateUrl: './logo-upload.component.html',
  styleUrls: ['./logo-upload.component.scss']
})
export class LogoUploadComponent extends BaseComponent implements OnInit, AfterViewInit, UploadImageInterface {
  @ViewChild('uploadContainer') uploadContainer!: UploadAssetComponent;

  @Input() id!: number;
  @Input() locationId: number = 0;
  @Input() rowTitle!: string;
  @Input() rowDescription!: string;
  @Input() logo!: Asset;
  @Input() logoToUpload!: CustomFile | undefined;
  @Input() existingLogo!: ReplaySubject<string | SafeResourceUrl>;
  @Input() clearImages: Subject<boolean> = new Subject<boolean>();

  @Output() deleteLogo: EventEmitter<any> = new EventEmitter<any>();
  @Output() uploadLogo: EventEmitter<CustomFile> = new EventEmitter<CustomFile>();

  constructor(private modalService: NgbModal, private injector: Injector) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupViews(): void {}

  setupBindings(): void {
    const s = this.clearImages.pipe(delay(100)).subscribe(clear => {
      if (clear) {
        this.uploadContainer?.clear();
      }
    });
    this.pushSub(s);
  }

  openReplaceLogoModal(): void {}

  openDeleteLogoModal(): void {
    const opts = new ConfirmationOptions();
    opts.title = 'Delete Photo';
    opts.bodyText = 'Are you sure you want to delete this logo?';
    opts.cancelText = 'Cancel';
    opts.continueText = 'Delete Logo';
    const confirmation = (cont: boolean) => {
      if (cont) {
        this.deleteLogo.emit(true);
      }
    };
    ModalConfirmation.open(this.modalService, this.injector, opts, confirmation);
  }

  fileList(f: CustomFile[], id: number) {
    if (f.length > 0) {
      this.logoToUpload = f[0];
      this.uploadLogo.emit(f[0]);
    } else {
      this.logoToUpload = undefined;
    }
  }
}
