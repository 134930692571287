import { inject, Injectable } from '@angular/core';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { ApiClient } from './api-client';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { Observable, throwError } from 'rxjs';
import { ODataResponse } from '../models/protocols/odata-response';
import { Endpoints } from './endpoints';
import { catchError, map } from 'rxjs/operators';
import { CustomError } from '../models/shared/custom-error';
import { MemberSubmission, SubmissionStakeholder } from '../models/submissions/member-submission';
import { ChangeNameRequest } from '../models/account/requests/submissionRequests/change-name-request';
import { Deserializable } from '../models/protocols/deserializable';
import { CreateAttachmentRequest } from '../models/account/requests/create-attachment-request';
import { SubmissionAttachment } from '../models/submissions/submission-attachment';
import { SubmissionCreated } from '../models/submissions/submission-created';
import { SubmissionCommentRequest } from '../models/account/requests/submission-comment-request';
import { SubmissionComment } from '../models/submissions/submission-comment';
import { SubmissionStatusCommentRequest } from '../models/submissions/requests/submission-comment-request';
import { HttpResponse } from '@angular/common/http';
import { SubmissionSubStatusEnum } from '../models/enum/shared/submission-sub-status.enum';
import { UpdateSubmissionOwnerRequest } from '../models/account/requests/update-submission-owner-request';
import { UpdateSubmissionApproverRequest } from '../models/account/requests/update-submission-approver-request';
import { SubmissionRoleTemplate } from '../models/submissions/submission-role-template';
import { MemberSubmissionType } from '../models/submissions/member-submission-type';
import { SubmissionPermissionType } from '../models/permissions/dto/submission-permission-type';
import { SubmissionPermissionWithType } from '../models/permissions/dto/submission-permission-with-type';
import { ChangeAddressRequest } from '../models/account/requests/submissionRequests/change-address-request';
import { SubmissionReminderRequest } from '../models/account/requests/submission-reminder-request';
import { UpdateContactInfoRequest } from '../models/account/requests/submissionRequests/update-contact-info-request';
import { ChangePersonalInfoRequest } from '../models/account/requests/submissionRequests/change-personal-info-request';

@Injectable({
  providedIn: 'root'
})
export class SubmissionsAPI implements LoggableAPI {
  constructor() {}

  private apiClient = inject(ApiClient);

  public serviceName = 'Submissions';

  public getMemberSubmissions(oDataQueryOptions: ODataQueryOptions): Observable<ODataResponse<MemberSubmission>> {
    const url = Endpoints.getMemberSubmissions();
    return this.apiClient.getOdata<MemberSubmission>(url, MemberSubmission, oDataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getMemberSubmissionById(id: string): Observable<MemberSubmission> {
    const url = Endpoints.getMemberSubmissionById(id);
    return this.apiClient.getObj<MemberSubmission>(MemberSubmission, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getSubmissionViewersForSubmissionType(subTypeId: string): Observable<ODataResponse<SubmissionStakeholder>> {
    const url = Endpoints.getMemberSubmissionViewersForSubmissionType(subTypeId);
    const oDataQueryOptions = new ODataQueryOptions();
    return this.apiClient.getOdata<SubmissionStakeholder>(url, SubmissionStakeholder, oDataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createNameChangeSubmission(req: ChangeNameRequest): Observable<SubmissionCreated> {
    const url = Endpoints.createNameChangeSubmission();
    return this.apiClient.postObj<SubmissionCreated, Deserializable>(SubmissionCreated, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createPersonalInfoChangeSubmission(req: ChangePersonalInfoRequest): Observable<SubmissionCreated> {
    const url = Endpoints.createPersonalInfoSubmission();
    return this.apiClient.postObj<SubmissionCreated, Deserializable>(SubmissionCreated, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createAddressChangeSubmission(req: ChangeAddressRequest): Observable<SubmissionCreated> {
    const url = Endpoints.createAddressChangeSubmission();
    return this.apiClient.postObj<SubmissionCreated, Deserializable>(SubmissionCreated, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createUpdateContactInformationSubmission(req: UpdateContactInfoRequest): Observable<SubmissionCreated> {
    const url = Endpoints.createUpdateContactInformationSubmission();
    return this.apiClient.postObj<SubmissionCreated, Deserializable>(SubmissionCreated, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createSubmissionAttachment(
    req: CreateAttachmentRequest,
    submissionId: string
  ): Observable<SubmissionAttachment> {
    const url = Endpoints.createSubmissionAttachment(submissionId);
    return this.apiClient.postObj<SubmissionAttachment, Deserializable>(SubmissionAttachment, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateSubmissionStatusWithComment(
    req: SubmissionStatusCommentRequest,
    url: string
  ): Observable<HttpResponse<any>> {
    return this.apiClient.putWithUntypedRes(url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateSubmissionStatus(status: SubmissionSubStatusEnum, submissionId: string): Observable<HttpResponse<any>> {
    let endpoint = '';
    switch (status) {
      case SubmissionSubStatusEnum.Processing:
        endpoint = Endpoints.submissionStatusToProcessing(submissionId);
        break;
      case SubmissionSubStatusEnum.Approved:
        endpoint = Endpoints.submissionStatusToApproved(submissionId);
        break;
    }
    return this.apiClient.putWithUntypedRes(endpoint, { id: submissionId });
  }

  public addSubmissionComment(req: SubmissionCommentRequest): Observable<SubmissionComment[]> {
    const url = Endpoints.addSubmissionComment(req.submissionId);
    return this.apiClient.simplePost(url, req).pipe(
      map(res => {
        const comments = window?.injector?.Deserialize?.arrayOf(SubmissionComment, res.body);
        return comments.sort((a, b) => {
          // @ts-ignore
          return new Date(b.createdDate) - new Date(a.createdDate);
        });
      }),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getMemberSubmissionApprovers(
    oDataQueryOptions: ODataQueryOptions
  ): Observable<ODataResponse<SubmissionStakeholder>> {
    const url = Endpoints.getMemberSubmissionApprovers();
    return this.apiClient.getOdata<SubmissionStakeholder>(url, SubmissionStakeholder, oDataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getMemberSubmissionOwners(
    oDataQueryOptions: ODataQueryOptions
  ): Observable<ODataResponse<SubmissionStakeholder>> {
    const url = Endpoints.getMemberSubmissionOwners();
    return this.apiClient.getOdata<SubmissionStakeholder>(url, SubmissionStakeholder, oDataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getMemberSubmissionOwnersForSubmissionType(
    subTypeId: string
  ): Observable<ODataResponse<SubmissionStakeholder>> {
    const url = Endpoints.getMemberSubmissionOwnersForSubmissionType(subTypeId);
    const oDataQueryOptions = new ODataQueryOptions();
    return this.apiClient.getOdata<SubmissionStakeholder>(url, SubmissionStakeholder, oDataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getSubmissionApproversForSubmissionType(subTypeId: string): Observable<ODataResponse<SubmissionStakeholder>> {
    const url = Endpoints.getMemberSubmissionApproversForSubmissionType(subTypeId);
    const oDataQueryOptions = new ODataQueryOptions();
    return this.apiClient.getOdata<SubmissionStakeholder>(url, SubmissionStakeholder, oDataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getSubmissionRoleTemplates(): Observable<ODataResponse<SubmissionRoleTemplate>> {
    const url = Endpoints.getSubmissionRoleTemplates();
    const oDataQueryOptions = new ODataQueryOptions();
    return this.apiClient.getOdata<SubmissionRoleTemplate>(url, SubmissionRoleTemplate, oDataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getSubmissionPermissionTypes(): Observable<SubmissionPermissionType[]> {
    const url = Endpoints.getSubmissionPermissionTypes();
    return this.apiClient.getArr<SubmissionPermissionType>(SubmissionPermissionType, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getAllSubmissionPermissions(): Observable<ODataResponse<SubmissionPermissionWithType>> {
    const url = Endpoints.getAllSubmissionPermissions();
    const oDataQueryOptions = new ODataQueryOptions();
    return this.apiClient
      .getOdata<SubmissionPermissionWithType>(url, SubmissionPermissionWithType, oDataQueryOptions)
      .pipe(
        catchError(e => {
          const err = new CustomError(e, this.serviceName);
          return throwError(() => err);
        })
      );
  }

  public getMemberSubmissionTypes(): Observable<ODataResponse<MemberSubmissionType>> {
    const url = Endpoints.getMemberSubmissionTypes();
    const oDataQueryOptions = new ODataQueryOptions();
    return this.apiClient.getOdata<MemberSubmissionType>(url, MemberSubmissionType, oDataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateMemberSubmissionOwner(req: UpdateSubmissionOwnerRequest): Observable<MemberSubmission> {
    const url = Endpoints.updateMemberSubmissionOwner(req.id.toString(10));
    return this.apiClient.putObj(MemberSubmission, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateMemberSubmissionApprover(req: UpdateSubmissionApproverRequest): Observable<MemberSubmission> {
    const url = Endpoints.updateMemberSubmissionApprover(req.id.toString(10));
    return this.apiClient.putObj(MemberSubmission, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public setSubmissionToReadyForApproval(req: UpdateSubmissionApproverRequest): Observable<MemberSubmission> {
    const url = Endpoints.submissionStatusToReadyForApproval(req.id.toString(10));
    return this.apiClient.putObj(MemberSubmission, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createMemberSubmissionReminder(req: SubmissionReminderRequest): Observable<MemberSubmission> {
    const url = Endpoints.createMemberSubmissionReminder(req.memberSubmissionId);
    return this.apiClient.postObj(MemberSubmission, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public deleteMemberSubmissionReminder(submissionId: string, reminderId: string): Observable<HttpResponse<any>> {
    const url = Endpoints.deleteMemberSubmissionReminder(submissionId, reminderId);
    return this.apiClient.deleteWithUntypedRes(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }
}
