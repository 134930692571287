import { Deserializable } from '../../protocols/deserializable';

export class MfaSecretCode implements Deserializable {
  public secretCode: string;

  constructor(code: string) {
    this.secretCode = code;
  }

  onDeserialize(): void {}
}
