import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';

@Component({
  selector: 'app-qr-code-support',
  templateUrl: './qr-code-support.component.html',
  styleUrls: ['./qr-code-support.component.scss', '../../auth/auth.component.scss']
})
export class QrCodeSupportComponent extends BaseModalComponent {
  public ignoreEscape: boolean = false;

  constructor(protected activeModal: NgbActiveModal) {
    super(activeModal);
  }

  setupBindings(): void {}

  setupViews(): void {}
}
