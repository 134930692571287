import { Deserializable } from '../../protocols/deserializable';
import { LookAheadItem } from '../../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';

export class MemberUserSearchResult implements Deserializable, LookAheadItem {
  id!: number;
  name!: string;
  firstName!: string;
  lastName!: string;

  onDeserialize() {}

  lookAheadDisabled(): boolean {
    return false;
  }

  lookAheadDisabledMessage(): string {
    return '';
  }
}
