import { Directive, Inject } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { EmployerPlanDesignatedUnit } from '../../../models/employers/plans/employer-plan-designated-unit';

@Directive({
  selector: '[appDesignatedUnitUniqueNameValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DesignatedUnitUniqueNameValidatorDirective, multi: true }]
})
export class DesignatedUnitUniqueNameValidatorDirective {
  static key: string = 'designatedUnitUniqueName';

  constructor(@Inject('existingUnits') public existingUnits: EmployerPlanDesignatedUnit[]) {
    this.existingUnits = existingUnits;
  }

  getErrorMsg(versions: EmployerPlanDesignatedUnit[], value: string): string | null {
    if (!!versions.length && value?.length > 1) {
      const sameDates = versions.some(v => this.namesAreTheSame(value, v.unitName));
      if (sameDates) return $localize`A unit already exists with this name`;
    }
    return null;
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    const errorMsg = this.getErrorMsg(this.existingUnits, control.value);
    if (errorMsg) return { [DesignatedUnitUniqueNameValidatorDirective.key]: errorMsg };
    return null;
  }

  private namesAreTheSame(currName: string, unitName: string): boolean {
    return currName.toLowerCase() === unitName.toLowerCase();
  }
}
