import { BaseViewModel } from '../../../../models/base/base-view-model';
import { inject, Injectable } from '@angular/core';
import { UserDomainModel } from '../../../../domainModels/user-domain-model';

@Injectable()
export class LayoutViewModel extends BaseViewModel {
  private userDomainModel = inject(UserDomainModel);

  public user$ = this.userDomainModel.user$;
}
