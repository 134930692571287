import { Deserializable } from '../protocols/deserializable';
import { RemittanceStatusPill } from '../shared/remittance-status-pill';
import { RemittanceStatus } from './remittance-status';
import { RemittancePaymentStatus } from './remittance-payment-status';
import { RemittancePaymentStatusPill } from '../shared/remittance-payment-status-pill';
import { RemittanceType } from './remittance-type';
import { RemittanceSource } from './remittance-source';
import { ContributionSummary } from './contribution-summary';
import { Contribution } from './contribution';
import { RemittanceOwner } from './remittance-owner';
import { PortalType } from '../enum/shared/portal-type';
import { Employer } from '../account/dto/employer';
import { Transaction } from '../transactions/transaction';

export class Remittance implements Deserializable {
  public id!: number;
  public employerId!: number;
  public typeId!: number;
  public statusId!: number;
  public paymentStatusId!: number;
  public sourceId!: number;
  public ownerId!: number;
  public owner: RemittanceOwner | null = null;
  public submittedDate!: Date | null;
  public payPeriodEndDate!: Date | null;
  public lastStatusUpdateDate!: Date;
  public taxationYear!: Date | null;
  public totalRemittanceAmount!: number;
  public contributionCount!: number;
  public contributionEntryCount!: number;
  public type!: RemittanceType;
  public status!: RemittanceStatus;
  public paymentStatus!: RemittancePaymentStatus;
  public source!: RemittanceSource;
  public employer!: Employer;
  public employerName!: string;
  public contributionSummaries: ContributionSummary[] = [];
  public contributions: Contribution[] = [];
  public transactions: Transaction[] = [];

  // not from API
  public statusPill: RemittanceStatusPill | undefined;
  public paymentStatusPill: RemittancePaymentStatusPill | undefined;

  public isEditable(portal: PortalType): boolean {
    if (portal === PortalType.Internal) {
      return ['Submitted', 'In Review', 'Pending'].includes(this.status?.name);
    } else if (portal === PortalType.Employer) {
      return ['Pending', 'In Review'].includes(this.status?.name);
    } else {
      return false;
    }
  }

  public onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    if (!!this.status) {
      this.status = Deserialize?.instanceOf(RemittanceStatus, this.status);
    }
    if (!!this.paymentStatus) {
      this.paymentStatus = Deserialize?.instanceOf(RemittancePaymentStatus, this.paymentStatus);
    }
    this.statusPill = new RemittanceStatusPill(this.status.id, this.status.name);
    this.paymentStatusPill = new RemittancePaymentStatusPill(this.paymentStatus.id, this.paymentStatus.name);
    if (!!this.source) {
      this.source = Deserialize?.instanceOf(RemittanceSource, this.source);
    }
    if (!!this.type) {
      this.type = Deserialize?.instanceOf(RemittanceType, this.type);
    }
    if (!!this.employer) {
      this.employer = Deserialize?.instanceOf(Employer, this.employer);
    }
    this.contributionSummaries = Deserialize?.arrayOf(ContributionSummary, this.contributionSummaries);
    this.contributionSummaries.sort((a, b) => a.contributionTypeId - b.contributionTypeId);
    this.contributions = Deserialize?.arrayOf(Contribution, this.contributions);
    this.submittedDate = !!this.submittedDate ? new Date(this.submittedDate) : null;
    if (!!this.payPeriodEndDate) {
      this.payPeriodEndDate = new Date(this.payPeriodEndDate);
      // nullifying any time zone conversion
      this.payPeriodEndDate = new Date(
        this.payPeriodEndDate.getUTCFullYear(),
        this.payPeriodEndDate.getUTCMonth(),
        this.payPeriodEndDate.getUTCDate()
      );
    }
    if (!!this.taxationYear) {
      this.taxationYear = new Date(this.taxationYear);
    }
    this.lastStatusUpdateDate = new Date(this.lastStatusUpdateDate);
    if (!!this.owner) {
      this.owner = Deserialize?.instanceOf(RemittanceOwner, this.owner);
    }
    if (!!this.transactions) {
      this.transactions = Deserialize?.arrayOf(Transaction, this.transactions);
    }
  }
}
