import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '../../../utils/date-utils';

@Pipe({
  name: 'formattedDateStringInCST'
})
export class FormattedDateStringInCSTPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    return DateUtils.formatDateStringToCSTDate(value);
  }
}
