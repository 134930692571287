import { Modifier } from '@popperjs/core';

export class PopperUtils {
  static flipModifier(placements: string[] = ['right', 'bottom', 'top', 'left']): Partial<Modifier<any, any>> {
    return {
      name: 'flip',
      options: {
        fallbackPlacements: placements
      }
    };
  }

  static offsetModifier(offset: [number, number]): Partial<Modifier<any, any>> {
    return {
      name: 'offset',
      options: {
        offset
      }
    };
  }
}
