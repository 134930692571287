<div class="toast-content">
  <div class="icon-container" *ngIf="!options.payload?.undo && options.payload?.icon">
    <img [src]="options.payload?.icon" alt="" />
  </div>
  <div class="toast-message-container">
    <div class="d-flex flex-row justify-content-between">
      <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
        {{ title | localization }}
      </div>
      <div *ngIf="options.payload?.undo" class="d-flex">
        <span class="toast-undo-button" (click)="options.payload?.undoFn()">{{ 'Undo' | localization }}</span>
      </div>
    </div>
    <div
      *ngIf="message && !options.payload?.undo"
      role="alert"
      [class]="options.messageClass"
      [attr.aria-label]="message">
      {{ message | localization }}
    </div>
  </div>
  <div class="toast-close" *ngIf="options.closeButton">
    <app-close-button (closeClicked)="remove()"></app-close-button>
  </div>
</div>
