import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { Event, NavigationStart, PRIMARY_OUTLET, Router } from '@angular/router';
import { RouteUtils } from '../../../../../utils/route-utils';
import { NavItem } from '../nav-item/models/nav-item';
import { NavSubItem } from './models/nav-sub-item';

@Injectable()
export class NavSubItemViewModel extends BaseViewModel {
  protected currentNavUrl = new BehaviorSubject<string>('');
  public currentNavUrl$ = this.currentNavUrl.pipe(distinctUntilChanged());
  protected currentNavFragment = new BehaviorSubject<string>('');
  protected navItem = new BehaviorSubject<NavItem | null>(null);
  protected subItem = new BehaviorSubject<NavSubItem | null>(null);
  public isNavItemActive$ = combineLatest([this.navItem, this.subItem, this.currentNavUrl$]).pipe(
    debounceTime(1),
    map(([nav, sub, url]) => {
      const isCurrentPath = url.indexOf(`${nav?.routerPath}/${sub?.relativeRouterPath}`) > -1;
      const isCurrentFragment = url.indexOf(`${nav?.baseFragmentRoute}#${sub?.subItemFragment}`) > -1;
      const fragment = RouteUtils.getFragmentFromUrl(url);
      if (!!nav && !!sub && isCurrentPath) {
        nav?.saveSubPosition(sub, fragment);
        this.checkToSaveSubFragment(nav, sub, url);
      }
      if (!!sub?.subItemFragment) {
        return isCurrentFragment;
      } else {
        return isCurrentPath;
      }
    })
  );

  constructor(protected router: Router) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.bindUrlParams();
  }

  private bindUrlParams() {
    this.currentNavUrl.next(this.router.url);
    const frag = this.router.parseUrl(this.router.url).fragment;
    if (!!frag) {
      this.currentNavFragment.next(frag);
    }
    this.router.events
      .pipe(
        filter((e: Event): e is NavigationStart => e instanceof NavigationStart),
        takeUntil(this.onDestroy)
      )
      .subscribe(evt => this.urlChanged(evt));
  }

  connectToNavItem(item: NavItem) {
    this.navItem.next(item);
  }

  connectToSubNavItem(item: NavSubItem) {
    this.subItem.next(item);
  }

  private urlChanged(nav: NavigationStart) {
    this.currentNavUrl.next(nav.url);
  }

  private checkToSaveSubFragment(nav: NavItem, sub: NavSubItem, url: string): void {
    const segments = this.router.parseUrl(url)?.root?.children[PRIMARY_OUTLET]?.segments;
    const strippedUrl = `/${segments?.join('/')}`; // url without fragments or parameters
    const shouldSaveSubFragment = strippedUrl === `${nav?.baseFragmentRoute}/${sub?.relativeRouterPath}`;
    if (shouldSaveSubFragment) {
      const fragment = RouteUtils.getFragmentFromUrl(url);
      sub?.saveSubFragment(fragment);
    }
  }
}
