<lib-reactive-form-group #form [bindTo]="commentReq" (formSubmitted$)="newComment.emit($event)" [clearOnSubmit]="true">
  <div class="card-title" i18n>Comments</div>
  <hr class="divider" />
  <div>
    <ng-container *ngIf="comments?.length">
      <div class="comments" *ngFor="let comment of comments">
        <div class="comment" [innerHTML]="comment.comment"></div>
        <div class="comment-info-container">
          <app-user-profile-badge
            [initials]="comment?.author?.initials"
            [profileColor]="comment?.author?.profileColour"
            [textColor]="comment?.author?.textColor">
          </app-user-profile-badge>
          <span class="comment-info comment-name">
            {{ comment?.author?.firstName }} {{ comment?.author?.lastName }}
          </span>
          <span class="comment-info" [ngStyle]="{ 'margin-right': comment?.visibleToMember ? '0.75rem' : 0 }">{{
            comment?.formattedDate
          }}</span>
          <span *ngIf="comment?.visibleToMember" class="comment-info" i18n>Visible to Member</span>
        </div>
      </div>
      <hr class="mt-0" />
    </ng-container>
    <ng-container *ngIf="!comments?.length">
      <span class="no-comments" i18n>No comments yet.</span>
      <hr class="divider" />
    </ng-container>
    <lib-reactive-form-tagging-input
      #tag
      [inputName]="'comment'"
      [required]="true"
      [bindingProperty]="'comment'"
      label="Comments"
      (dropdownSearchText)="filterText.emit($event)"
      [users]="dropdownItems"
      [customResultTemplate]="customTemplate"
      [showCharacterCount]="true"
      [maxLength]="1000"
      placeholder="Add a Comment"
      i18n-placeholder
      i18n-label>
    </lib-reactive-form-tagging-input>
    <div class="action-buttons mt-2">
      <lib-button-primary (buttonClicked)="form.submitForm()" i18n>Save</lib-button-primary>
      <lib-button-neutral (buttonClicked)="form.resetForm()" i18n>Cancel</lib-button-neutral>
    </div>
  </div>
</lib-reactive-form-group>
<ng-template #customTemplate let-user>
  <div class="dropdown-item" [attr.data-user-id]="user.id">
    <app-user-profile-badge [initials]="user.initials" [profileColor]="user.profileColor" [textColor]="user.textColor">
    </app-user-profile-badge>
    {{ user.value }}
  </div>
</ng-template>
