import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector } from '@angular/core';
import { ModalUtils } from '../utils/modal-utils';
import { DeleteAlertModalComponent } from '../views/settings/components/alerts/modals/delete-alert-modal/delete-alert-modal.component';

export class ModalDeleteAlert {
  static open(ngbModal: NgbModal, injector: Injector): Promise<boolean> {
    const modalRef = ngbModal.open(DeleteAlertModalComponent, ModalUtils.defaultModalOptions(injector));
    return modalRef.result;
  }
}
