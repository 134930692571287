import { Component, inject } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { NavbarViewModel } from '../../navbar-view-model';

@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss']
})
export class MobileNavComponent extends BaseComponent {
  private viewModel = inject(NavbarViewModel);

  public isMobile$ = this.viewModel.isMobile$;
  public navItems$ = this.viewModel.navItems$;
  public isNavbarCollapsed$ = this.viewModel.isNavbarCollapsed$;

  public navigateToRoot(): void {
    this.viewModel.navigateToRoot();
  }

  public toggleNavbarCollapse(): void {
    this.viewModel.toggleNavbarCollapse();
  }

  setupBindings(): void {}

  setupViews(): void {}
}
