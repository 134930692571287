import { Component, inject, Injector, ViewChild } from '@angular/core';
import { ChoosePasswordRequest } from '../../../../../../models/account/requests/choose-password-request';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { InternalUserAuthViewModel } from '../../../../viewModels/internal-user-auth-view-model.service';
import { SessionService } from '../../../../../../services/session-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormGroupComponent } from '@csspension/reactive-form';
import { PasswordInputUtils } from '../../../../../../utils/password-input-utils';

@Component({
  selector: 'app-internal-user-choose-password',
  templateUrl: './internal-user-choose-password.component.html',
  styleUrls: ['./internal-user-choose-password.component.scss', '../../auth.component.scss']
})
export class InternalUserChoosePasswordComponent extends BaseComponent {
  ngbModal = inject(NgbModal);
  injector = inject(Injector);
  viewModel = inject(InternalUserAuthViewModel);

  public eulaChecked: boolean = false;

  protected readonly passwordHintText = PasswordInputUtils.passwordHintText;

  @ViewChild('form') form!: ReactiveFormGroupComponent;

  constructor(private session: SessionService) {
    super();
  }

  setupViews(): void {}

  req = new ChoosePasswordRequest('', '', '', '', '', false);

  setupBindings() {
    this.session.sessionContainer$.once(session => {
      if (!session) session = this.viewModel.getCachedSession();
      const user = session?.user;
      this.req.email = user?.email ?? '';
      this.req.sessionId = user?.sessionId ?? '';
      this.req.challengeName = user?.challengeName ?? '';
    });
  }

  setEulaChecked(checked: boolean) {
    this.eulaChecked = checked;
    const control = this.form.reactiveFormGroup.controls.eulaAccepted;
    control.patchValue(checked);
    control.updateValueAndValidity();
  }

  formSubmitted(req: ChoosePasswordRequest) {
    this.viewModel.setNewInternalUserPassword(req);
  }

  openEulaModal(): void {
    this.setEulaChecked(false);
    this.viewModel.openEulaModal();
  }
}
