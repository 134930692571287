import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { StatusPill } from '../../../../models/shared/status-pill';

@Component({
  selector: 'app-status-pill',
  templateUrl: './status-pill.component.html',
  styleUrls: ['./status-pill.component.scss']
})
export class StatusPillComponent extends BaseComponent {
  @Input() statusPill!: StatusPill;

  setupBindings(): void {}

  setupViews(): void {}
}
