import { Component, EventEmitter, inject, Output } from '@angular/core';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { ResetPasswordRequest } from '../../../../../models/account/requests/reset-password-request';
import { BaseComponent } from '../../../../../models/base/base-component';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PortalService } from '../../../../../services/portal/portal.service';
import { ForgotPasswordViewModel } from '../../../viewModels/forgot-password-view-model';
import { ActivatedRoute } from '@angular/router';
import { PasswordInputUtils } from '../../../../../utils/password-input-utils';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss', '../auth.component.scss']
})
export class ResetPasswordComponent extends BaseComponent {
  route = inject(ActivatedRoute);
  viewModel = inject(ForgotPasswordViewModel);
  portalService = inject(PortalService);

  @Output() loadingOpts = new EventEmitter<LoadingOptions>();

  // View
  public _loadingOpts = new BehaviorSubject(LoadingOptions.default());
  public loadingOpts$ = this._loadingOpts as Observable<LoadingOptions>;
  public isLoading$ = this._loadingOpts.asObservable().pipe(map(opts => opts.isLoading));
  // Variables
  public readonly req$ = combineLatest([this.viewModel.preFillEmail$, this.viewModel.preFillCode$]).pipe(
    map(([email, code]) => new ResetPasswordRequest(email, code))
  );
  public passwordHintText = PasswordInputUtils.passwordHintText;
  public passwordErrorMap = PasswordInputUtils.passwordErrorMap;

  constructor() {
    super();
  }

  setupBindings() {
    this.viewModel.loadingOpts$.subscribeWhileAlive({
      owner: this,
      next: opts => this.loadingOpts.emit(opts)
    });
    this.viewModel.getUrlParams(this.route);
  }

  setupViews() {}

  formSubmitted(req: ResetPasswordRequest) {
    this.portalService.portalType$.once(type => {
      this.viewModel.resetPassword(req, type);
    });
  }
}
