import { OdataFilterGenerator } from '../../../interfaces/odata-filter-generator';
import * as moment from 'moment';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { Deserializable } from '../../protocols/deserializable';
import { AlertStatusIntEnum } from '../../alerts/enum/alert-status.enum';

export class AlertsTableFilterRequest implements OdataFilterGenerator, UniquelyIdentifiable, Deserializable {
  public startDate: string = '';
  public endDate: string = '';
  public active: boolean[] = [];
  public portalType: string[] = [];
  public alertType: string[] = [];
  public status: AlertStatusIntEnum[] = [];

  public searchTitle: string = '';
  public searchDescription: string = '';
  public searchStartDate: string = '';
  public searchEndDate: string = '';
  public searchPortal: string = '';
  public searchType: string = '';
  public searchStatus: number = 0;

  private filterCount: number = 0;
  private searchCount: number = 0;

  [key: string]: any; // Index signature to allow dynamic property querying

  getFilterString(): string | null {
    const filterArray = [];
    if (this.portalType?.length > 0) {
      const portalTypes = this.portalType.map(portalTypeId => `portalTypeId eq ${portalTypeId}`).join(' or ');
      this.filterCount = this.filterCount + this.portalType.length;
      filterArray.push(`(${portalTypes})`);
    }
    if (this.alertType?.length > 0) {
      const alertTypes = this.alertType.map(alertTypeId => `typeId eq ${alertTypeId}`).join(' or ');
      this.filterCount = this.filterCount + this.alertType.length;
      filterArray.push(`(${alertTypes})`);
    }
    if (this.startDate !== '') {
      const startDate = moment(this.startDate).startOf('day');
      let endDate = startDate.clone().endOf('day');
      if (this.endDate !== '') {
        endDate = moment(this.endDate).endOf('day');
      }
      filterArray.push(
        `startDate ge cast(${startDate.toISOString(
          true
        )}, Edm.DateTimeOffset) and startDate le cast(${endDate.toISOString(true)}, Edm.DateTimeOffset)`
      );

      this.filterCount++;
    }
    if (this.status?.length > 0) {
      const statusArray: string[] = [];
      this.status.forEach(s => {
        statusArray.push(this.alertStatusStringBuilder(s));
      });
      filterArray.push(`(${statusArray.join(' or ')})`);
      this.filterCount = this.filterCount + this.status.length;
    }
    if (this.searchStartDate !== '') {
      const startDate = moment(this.searchStartDate).startOf('day');
      const endDate = startDate.clone().endOf('day');
      filterArray.push(
        `startDate ge cast(${startDate.toISOString(
          true
        )}, Edm.DateTimeOffset) and startDate le cast(${endDate.toISOString(true)}, Edm.DateTimeOffset)`
      );

      this.searchCount++;
    }
    if (this.searchEndDate !== '') {
      const startDate = moment(this.searchEndDate).startOf('day');
      const endDate = startDate.clone().endOf('day');
      filterArray.push(
        `endDate ge cast(${startDate.toISOString(true)}, Edm.DateTimeOffset) and endDate le cast(${endDate.toISOString(
          true
        )}, Edm.DateTimeOffset)`
      );

      this.searchCount++;
    }
    if (this.searchTitle !== '') {
      filterArray.push(`contains(title, '${this.searchTitle}')`);
      this.searchCount++;
    }
    if (this.searchDescription !== '') {
      filterArray.push(`contains(description, '${this.searchDescription}')`);
      this.searchCount++;
    }
    if (this.searchPortal !== '' && this.searchPortal !== null) {
      filterArray.push(`portalType/id eq ${this.searchPortal}`);
      this.searchCount++;
    }
    if (this.searchType !== '' && this.searchType !== null) {
      filterArray.push(`type/id eq ${this.searchType}`);
      this.searchCount++;
    }
    if (this.searchStatus !== 0 && this.searchStatus !== null) {
      filterArray.push(this.alertStatusStringBuilder(this.searchStatus));
      this.searchCount++;
    }
    if (filterArray.length > 1) {
      return filterArray.join(' and ');
    } else if (filterArray.length === 1) {
      return filterArray[0];
    } else {
      return null;
    }
  }

  private alertStatusStringBuilder(status: AlertStatusIntEnum): string {
    switch (status) {
      case AlertStatusIntEnum.Active:
        return this.getActiveString();
      case AlertStatusIntEnum.Past:
        return this.getPastString();
      case AlertStatusIntEnum.Upcoming:
        return this.getUpcomingString();
    }
  }

  private getActiveString(): string {
    return `startDate le cast(${moment().toISOString(
      true
    )}, Edm.DateTimeOffset) and endDate ge cast(${moment().toISOString(true)}, Edm.DateTimeOffset)`;
  }

  private getPastString(): string {
    return `endDate lt cast(${moment().toISOString(true)}, Edm.DateTimeOffset)`;
  }

  private getUpcomingString(): string {
    return `startDate ge cast(${moment().toISOString(true)}, Edm.DateTimeOffset)`;
  }

  public getFilterCount(): number {
    this.filterCount = 0;
    this.getFilterString();
    return this.filterCount;
  }

  public getSearchCount(): number {
    this.searchCount = 0;
    this.getFilterString();
    return this.searchCount;
  }

  public clearFilters(): void {
    this.startDate = '';
    this.endDate = '';
    this.active = [];
    this.portalType = [];
    this.alertType = [];
    this.status = [];
  }

  getUniqueIdentifier(): string {
    return `${this.startDate} +
    ${this.endDate} +
    ${this.active.join(',')} +
    ${this.portalType.join(',')} +
    ${this.alertType.join(',')} +
    ${this.status.join(',')} +
    ${this.searchTitle} +
    ${this.searchDescription} +
    ${this.searchStartDate} +
    ${this.searchEndDate} +
    ${this.searchPortal} +
    ${this.searchType} +
    ${this.searchStatus} +
    ${this.filterCount} +
    ${this.searchCount}`;
  }

  onDeserialize(): void {}
}
