import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'setHas'
})
export class SetHasPipe<T> implements PipeTransform {
  transform(set$: Observable<Set<T>>, value: T): Observable<boolean> {
    return set$.pipe(map(set => set?.has(value) ?? false));
  }
}
