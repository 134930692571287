<div class="auth-flow-logo-container">
  <img src="assets/logo/dark/css-logo.svg" alt="CSS Logo" i18n-alt />
</div>

<div class="auth-flow-title">
  {{ authFlowTitle$ | async }}
</div>

<div class="auth-flow-subtext">
  {{ authFlowSubtext$ | async }}
</div>

<lib-reactive-form-group
  #form
  [bindTo]="req"
  (formSubmitted$)="formSubmitted($event)"
  [customErrorMessageMap]="customErrorMap">
  <lib-reactive-form-email
    [inputName]="'email'"
    [placeholder]="''"
    label="Email"
    i18n-label
    [bindingProperty]="'email'"
    [required]="true"
    [hintText]="hintText"
    [showRequiredAstrix]="false">
  </lib-reactive-form-email>

  <lib-reactive-form-password
    [inputName]="'password'"
    [required]="true"
    [creatingOrUpdatingPassword]="true"
    label="New Password"
    i18n-label
    [placeholder]="''"
    [hintText]="passwordHintText"
    [bindingProperty]="'password'"
    [showRequiredAstrix]="false">
  </lib-reactive-form-password>

  <lib-reactive-form-password
    [inputName]="'confirmPassword'"
    [required]="true"
    [confirmPassword]="true"
    label="Confirm Password"
    i18n-label
    [placeholder]="''"
    [bindingProperty]="'confirmPassword'"
    [showRequiredAstrix]="false">
  </lib-reactive-form-password>

  <lib-reactive-form-checkboxes
    [ngClass]="{ 'mt-2': (form.formSubmitAttempt$ | async) && form.reactiveFormGroup.controls.confirmPassword.errors }"
    [bindingProperty]="'eulaAccepted'"
    class="d-flex"
    [label]="'eula'"
    [hideLabel]="true"
    [customValidators]="[eulaValidatorDirective]"
    [hideBulkCheckbox]="true"
    [customValueParser]="customValueParser"
    [inputName]="'eulaAccepted'">
    <div class="eula-container">
      <lib-reactive-form-checkbox
        [inputName]="'eula'"
        (valueChanged)="setEulaChecked($event)"
        [selectedValue]="true"
        [notSelectedValue]="false">
        <span class="non-terms-label" i18n>I accept the </span>
      </lib-reactive-form-checkbox>
      <span (click)="openEulaModal()" class="terms-label" i18n> Terms and Conditions</span>
    </div>
  </lib-reactive-form-checkboxes>
</lib-reactive-form-group>
<div
  class="d-flex justify-content-center align-items-center"
  [ngClass]="{ 'mt-4': (form.formSubmitAttempt$ | async) && form.reactiveFormGroup.controls?.eulaAccepted?.errors }">
  <lib-button-primary [style.width.%]="100" (buttonClicked)="form.submitForm()" i18n>
    Setup Account
  </lib-button-primary>
</div>
<div class="support-button-container">
  <span i18n class="support-button">Having trouble?</span>
  <button class="paddingless-text-button mt-2" (click)="openContactPage()" i18n>Click here for support</button>
</div>
