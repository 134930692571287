<div class="asset-container">
  <img
    *ngIf="(notLoading$ | async) && (asset?.isImage() || asset?.isPDFImage(this.size)) && asset?.isValid()"
    #image
    class="asset"
    (load)="ratio.emit(image.naturalWidth / image.naturalHeight)"
    [style.object-fit]="scaleFit ? 'contain' : 'cover'"
    [style.border-radius]="borderRadius"
    [style.object-position]="objectPosition"
    [ngStyle]="styleOverrides"
    [ngClass]="{
      'ease-me-in-from-top': easeInFromTop,
      'sweep-from-middle-to-top': sweepFromMiddleToTop,
      'sweep-from-bottom-to-top': sweepFromBottomToTop
    }"
    [src]="distinctAsset$ | async"
    alt="" />
  <img
    *ngIf="(notLoading$ | async) && (!asset || !asset?.isValid())"
    #placeHolder
    class="asset"
    (load)="ratio.emit(placeHolder.naturalWidth / placeHolder.naturalHeight)"
    [style.object-fit]="'cover'"
    [style.border-radius]="borderRadius"
    [src]="'assets/placeholder/no-image.svg'"
    alt="" />
  <video
    *ngIf="(notLoading$ | async) && asset?.isVideo() && asset?.isValid()"
    #video
    class="asset"
    [controls]="showControls"
    [src]="distinctAsset$ | async"
    [autoplay]="autoplay"
    [loop]="loopVideo"
    [style.object-fit]="scaleFit ? 'contain' : 'cover'"
    [style.border-radius]="borderRadius"
    [ngStyle]="styleOverrides"
    (loadedmetadata)="duration.emit(video.duration); ratio.emit(video.videoWidth / video.videoHeight || 1)"
    (ended)="this.videoEnded.emit($event.returnValue)"
    [muted]="true"></video>
  <div *ngIf="loading$ | async" #loading class="asset shimmer-loading">
    <ngx-shimmer-loading [width]="'100%'" [height]="'100%'" [shape]="'rect'" [borderRadius]="borderRadius">
    </ngx-shimmer-loading>
  </div>
</div>
