import { Role } from './role';
import { Deserializable } from '../protocols/deserializable';

export class UserRoleResponse implements Deserializable {
  public roleId!: number;
  public role!: Role;

  onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.role = Deserialize?.instanceOf(Role, this.role);
  }
}
