import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { DispersedFormGroupService, ReactiveFormItemComponent } from '@csspension/reactive-form';
import { AsyncValidatorFn, ValidatorFn, Validators } from '@angular/forms';
import { EmployerSearchResult } from '../../../../models/employers/dto/employer-search-result';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-employer-search-input',
  templateUrl: './employer-search-input.component.html',
  providers: [
    {
      provide: ReactiveFormItemComponent,
      useExisting: forwardRef(() => EmployerSearchInputComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployerSearchInputComponent extends ReactiveFormItemComponent implements OnChanges {
  constructor(elementRef: ElementRef, cdr: ChangeDetectorRef, dispersedFormGroupService: DispersedFormGroupService) {
    super(elementRef, dispersedFormGroupService, cdr);
  }

  @Input() odataFunction!: (searchString: string) => Observable<EmployerSearchResult[]>;
  @Input() selectedEmployer: EmployerSearchResult | null | undefined;
  @Output() employerSelected = new EventEmitter<EmployerSearchResult>();

  public getValidators(): ValidatorFn[] {
    return [Validators.required];
  }

  public getAsyncValidators(): AsyncValidatorFn[] {
    return [];
  }

  public setBindingProperty(): void {
    this.setDataInBindingProperty(this.getMyValue());
  }

  private markAsDirty(): void {
    this?.getSelfAsFormItem()?.markAsDirty();
  }

  public ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.selectedEmployer) {
      this.handleSelectedEmployerChange(this.selectedEmployer);
    }
  }

  private handleSelectedEmployerChange(selectedEmployer: EmployerSearchResult | null | undefined): void {
    this.handleInputChange(selectedEmployer);
    this.getSelfAsFormItem()?.patchValue(selectedEmployer);
    this.markAsDirty();
  }

  protected checkParentElement(): void {}
}
