<app-sliding-window [toggleWindow]="showCommentWindow$ | async" [contentPaddingRem]="0">
  <div header>
    <app-sliding-window-header
      [slidingWindowOptions]="commentWindowOptions$ | async"
      (closeButtonClicked)="toggleCommentWindow()">
    </app-sliding-window-header>
    <app-tab-bar
      class="d-block mt-3 mx-4"
      [tabs]="tabs$ | async"
      [skipFragmentAppending]="true"
      (selectedTab)="setActiveTab($event)">
    </app-tab-bar>
    <hr class="mb-0" />
  </div>
  <div content>
    <app-tab-content [showContent]="true" [tab]="(activeTab$ | async) ?? undefined"> </app-tab-content>
  </div>
  <app-sliding-window-footer
    footer
    [slidingWindowOptions]="commentWindowOptions$ | async"
    (primaryButtonClicked)="primaryButtonClicked()"
    (secondaryButtonClicked)="toggleCommentWindow()">
  </app-sliding-window-footer>
</app-sliding-window>

<button
  type="button"
  [style.margin-bottom.rem]="buttonMarginBottomRem"
  class="comment-button"
  (click)="toggleCommentWindow()">
  <img [src]="'assets/icons/dark/Chat.svg'" alt="comments" />
</button>
