import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  SimpleChanges,
  ViewRef
} from '@angular/core';
import {
  ReactiveTableLoadingStateBluePrintComponent,
  ReactiveTableOverlayLoadingStateBluePrintComponent
} from '@csspension/reactive-table';

@Component({
  selector: 'app-table-overlay-loading',
  templateUrl: './table-overlay-loading.component.html',
  styleUrls: ['./table-overlay-loading.component.scss'],
  providers: [
    {
      provide: ReactiveTableOverlayLoadingStateBluePrintComponent,
      useExisting: forwardRef(() => TableOverlayLoadingComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableOverlayLoadingComponent extends ReactiveTableOverlayLoadingStateBluePrintComponent {
  constructor(@Inject(ChangeDetectorRef) viewRef: ViewRef) {
    super(viewRef);
  }

  initializeFromBluePrint(bluePrint: ReactiveTableLoadingStateBluePrintComponent): void {}

  changesFromBluePrint(changes: SimpleChanges): void {}

  sendOutputsToBluePrint(bluePrint: ReactiveTableLoadingStateBluePrintComponent): void {}
}
