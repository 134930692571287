import { BaseDomainModel } from '../models/base/base-domain-model';
import { inject, Injectable } from '@angular/core';
import { SessionService } from '../services/session-service';
import { map, switchMap, take } from 'rxjs/operators';
import { BaseUser } from '../models/base/base-user';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { UserAPI } from '../api/user-api';
import { Router } from '@angular/router';
import { ToastService } from '../services/toast-service';
import { Role } from '../models/roles/role';
import { InternalUsersAPI } from '../api/internal-users-api.service';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { PortalService } from '../services/portal/portal.service';
import { PortalType } from '../models/enum/shared/portal-type';
import { DistinctUtils } from '../utils/distinct-utils';
import { EmployersAPI } from '../api/employers-api';
import { PermissionService } from '../services/permission-service';

// Provided by Logged In Scope
@Injectable()
export class PermissionDomainModel extends BaseDomainModel {
  constructor() {
    super();
    this.init();
  }

  private sessionService = inject(SessionService);
  private portalService = inject(PortalService);
  private userAPI = inject(UserAPI);
  private internalUsersAPI = inject(InternalUsersAPI);
  private employersAPI = inject(EmployersAPI);
  private router = inject(Router);
  private toastService = inject(ToastService);
  private permissionService = inject(PermissionService);

  private user$ = this.sessionService.sessionContainer$.pipe(map(sess => sess?.user as BaseUser));
  public userRoles$ = this.sessionService.sessionContainer$.pipe(map(sess => sess?.roles));

  private _roles = new BehaviorSubject<Role[]>([]);
  public roles$ = this._roles as Observable<Role[]>;

  private listenToFetchRole = combineLatest([
    this.user$.pipe(DistinctUtils.distinctUntilChanged),
    this.portalService.portalType$
  ]).subscribeWhileAlive({
    owner: this,
    next: ([user, portalType]) => {
      // TODO: Adjust this as other portal types gain permissions and roles
      if (!user?.challengeName && !!user?.id && portalType !== PortalType.Member) {
        this.getUserRole(user?.id?.toString(), portalType);
      }
    }
  });

  private fetchRolesForPortalType = combineLatest([
    this.user$.pipe(DistinctUtils.distinctUntilChanged),
    this.portalService.portalType$,
    this.permissionService.permissionMapReady$
  ]).subscribeWhileAlive({
    owner: this,
    next: ([_, portalType, mapReady]) => {
      // TODO: Adjust this as other portal types gain permissions and roles
      if (!!mapReady) {
        switch (portalType) {
          case PortalType.Internal:
            this.getInternalUsersRoles();
            break;
          case PortalType.Employer:
            this.getEmployerRoles();
            break;
          default:
            break;
        }
      }
    }
  });

  public getUserRole(userId: string, portalType: PortalType): void {
    this.userRoles$
      .pipe(
        take(1),
        switchMap(roles => {
          if (roles === null || roles.length === 0) {
            return this.userAPI.getRolesForUser(userId, portalType);
          } else {
            return of(null);
          }
        })
      )
      .subscribe({
        next: odataRoleResponse => {
          if (odataRoleResponse !== null) {
            const roles = odataRoleResponse.value.map(r => r.role);
            this.sessionService.setUserRoles(roles, false);
          }
        },
        error: err => {
          // If user roles/permissions cannot be fetched, log the user out immediately
          this.router.navigate(['sign-out']).then();
          this.toastService.publishError(err);
        }
      });
  }

  public getInternalUsersRoles(): void {
    const odataOptions = new ODataQueryOptions();
    odataOptions.setExpand('Users, Permissions');
    this.internalUsersAPI.getInternalUsersRoles(odataOptions).subscribe({
      next: odataRoles => {
        this._roles.next(odataRoles?.value);
      },
      error: err => {
        this.toastService.publishError(err);
      }
    });
  }

  public getEmployerRoles(): void {
    const odataOptions = new ODataQueryOptions();
    odataOptions.setExpand('Users, Permissions');
    this.employersAPI.getEmployerRoles(odataOptions).subscribe({
      next: odataRoles => {
        this._roles.next(odataRoles?.value);
      },
      error: err => {
        this.toastService.publishError(err);
      }
    });
  }
}
