import { Component, inject } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { MemberAuthViewModel } from '../../../../viewModels/member-auth-view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { VerifyEmailRequest } from '../../../../../../models/users/requests/verify-email-request';
import { BehaviorSubject, Observable } from 'rxjs';
import { AssetSize } from '../../../../../../models/enum/dto/asset-size.enum';

@Component({
  selector: 'app-member-enter-email-verification',
  templateUrl: './member-enter-email-verification.component.html',
  styleUrls: ['./member-enter-email-verification.component.scss', '../../auth.component.scss']
})
export class MemberEnterEmailVerificationComponent extends BaseComponent {
  private viewModel = inject(MemberAuthViewModel);
  private route = inject(ActivatedRoute);
  private router = inject(Router);

  public authFlowTitle$ = this.viewModel.authFlowTitle$;

  private _userEmail = new BehaviorSubject<string>('');
  public readonly userEmail$ = this._userEmail as Observable<string>;

  public req$ = this.userEmail$.pipe(
    map(email => {
      return new VerifyEmailRequest(email, '');
    })
  );

  private listenToQueryParams = this.route.queryParams.subscribeWhileAlive({
    owner: this,
    next: params => {
      const email = decodeURIComponent(decodeURIComponent(params?.Email || params?.email || ''));
      if (!!email) {
        this._userEmail.next(email);
        this.viewModel.setNewMemberEmail(email);
        this.router.navigate([], { queryParams: {}, replaceUrl: true, relativeTo: this.route }).then();
      }
    }
  });

  setupBindings(): void {}

  setupViews(): void {}

  formSubmitted(req: VerifyEmailRequest) {
    this.viewModel.verifyMemberEmail(req);
  }

  openContactPage(): void {
    this.viewModel.openContactPage();
  }

  protected readonly AssetSize = AssetSize;
}
