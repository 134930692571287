import { ChangeDetectionStrategy, Component, inject, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SlidingWindowCommentsAndHistoryViewModel } from '../sliding-window-comments-and-history-view-model';
import { BaseComponent } from '../../../../../models/base/base-component';
import { Tabbable } from '../../../../../models/protocols/tabbable';
import { ReactiveFormGroupComponent } from '@csspension/reactive-form';
import { BaseCommentRequest } from '../../../../../models/base/base-comment-request';

@Component({
  selector: 'app-sliding-window-comments-tab',
  templateUrl: './sliding-window-comments-tab.component.html',
  styleUrls: ['./sliding-window-comments-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlidingWindowCommentsTabComponent extends BaseComponent implements Tabbable {
  public tabActivated$ = new BehaviorSubject<boolean>(false);

  private viewModel = inject(SlidingWindowCommentsAndHistoryViewModel);

  public comments$ = this.viewModel.comments$;
  public userDropdownItems$ = this.viewModel.userDropdownItems$;
  public isLoadingComments$ = this.viewModel.isLoadingComments$;

  @ViewChild('form') form!: ReactiveFormGroupComponent;

  setupBindings(): void {
    this.viewModel.submitCommentForm$.subscribeWhileAlive({
      owner: this,
      next: () => {
        this.form.submitForm();
      }
    });
  }

  setupViews(): void {}

  public onCommentValueChange(comment: string): void {
    this.viewModel.onCommentValueChange(comment);
  }

  public newComment(commentReq: BaseCommentRequest) {
    this.viewModel.newComment(commentReq);
  }

  public filterText(filterString: string) {
    this.viewModel.filterText(filterString);
  }
}
