import { Pipe, PipeTransform } from '@angular/core';
import { TransactionEntryCodeType } from '../../../models/transactions/transaction-entry-code-type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'entryCodeName'
})
export class EntryCodeNamePipe implements PipeTransform {
  transform(code: number, entryCodeTypes$: Observable<TransactionEntryCodeType[]>): Observable<string> {
    return entryCodeTypes$.pipe(
      map(types => {
        const type = types.find(e => e.id === code);
        return type ? type.name : '';
      })
    );
  }
}
