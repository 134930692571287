export enum NavItemId {
  Accounts,
  Home,
  Notifications,
  Employers,
  Members,
  MemberActions,
  Profile,
  Remittance,
  Resources,
  Settings,
  Submissions,
  SignOut,
  Dashboard,
  Reports,
  Records,
  Work
}
