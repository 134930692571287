import { Directive, Inject } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { RemittanceFileRecord } from '../../../models/remittances/remittance-file-record';

@Directive({
  selector: '[appValidCreateRemittanceFileDirective]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ValidCreateRemittanceFileDirective, multi: true }]
})
export class ValidCreateRemittanceFileDirective {
  static key: string = 'validRemittanceFile';

  constructor(@Inject('records') public records: RemittanceFileRecord[] | null) {
    this.records = records;
  }

  getErrorMsg(records: RemittanceFileRecord[] | null, value: string): string | null {
    if (!!records) {
      const validPattern = {
        SIN: 'string',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'FIRST NAME': 'string',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'LAST NAME': 'string',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'BIRTH DATE': 'string',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'ENTRY CODE': 'number',
        AMOUNT: 'number',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'TAXATION YEAR': 'string',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'PAY PERIOD END DATE': 'string',
        SALARY: 'number',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'DESIGNATED UNIT ID': 'number'
      };
      const errors = records.filter(record => !this.isValidRecord(record, validPattern));
      if (errors.length > 0) {
        return $localize`Please ensure all records match the required pattern.`;
      }
    }
    return null;
  }

  private isValidRecord(record: any, validPattern: Record<string, string>): boolean {
    for (const [key, type] of Object.entries(validPattern)) {
      if (typeof record[key] !== type) {
        return false;
      }
    }

    const recordKeys = Object.keys(record);
    const validKeys = Object.keys(validPattern);
    return recordKeys.length === validKeys.length;
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    const errorMsg = this.getErrorMsg(this.records, control.value);
    if (!!errorMsg) return { [ValidCreateRemittanceFileDirective.key]: errorMsg };
    return null;
  }
}
