import { ChangeDetectorRef, Component, inject, Injector } from '@angular/core';
import { SessionService } from '../../../../services/session-service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../../models/base/base-component';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { LayoutViewModel } from './layout-view-model';
import {
  domainModelsGloballyScopedToLoginContext,
  servicesGloballyScopedToLoginContext,
  viewModelsGloballyScopedToLoginContext
} from '../../../../di/logged-in-scope';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PortalService } from '../../../../services/portal/portal.service';
import { PortalType } from '../../../../models/enum/shared/portal-type';
import { MemberUser } from '../../../../models/account/dto/member-user';
import { ModalMemberAddContactInfo } from '../../../../modals/modal-member-add-contact-info';
import { ScreenService } from '../../../../services/screen-service.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [
    ...domainModelsGloballyScopedToLoginContext,
    ...viewModelsGloballyScopedToLoginContext,
    ...servicesGloballyScopedToLoginContext,
    LayoutViewModel
  ]
})
export class LayoutComponent extends BaseComponent {
  private ngbModal = inject(NgbModal);
  private injector = inject(Injector);
  private portalService = inject(PortalService);
  private cdr = inject(ChangeDetectorRef);
  private screenService = inject(ScreenService);

  public viewModel = inject(LayoutViewModel);
  public _loadingOpts = new BehaviorSubject(LoadingOptions.default(false, true));
  public loadingOpts$ = this._loadingOpts as Observable<LoadingOptions>;
  public isLoading$ = this.loadingOpts$.pipe(map(opts => opts.isLoading));
  public _blurPage = new BehaviorSubject(false);
  public blurPage$ = this._blurPage as Observable<boolean>;
  public navbarMinimized = false;

  public disableScroll$ = this.screenService.disableScroll$;

  constructor(private sessionService: SessionService, public router: Router) {
    super();
  }

  setupBindings() {
    const refSub = this.sessionService.refreshingSession$.notNull().subscribe(ref => {
      const lm = $localize`Refreshing Session`;
      if (!this._loadingOpts.containsRequest(lm) && ref) {
        this._loadingOpts.addRequest(lm);
      } else if (this._loadingOpts.containsRequest(lm) && !ref) {
        this._loadingOpts.removeRequest(lm);
      }
    });
    this.pushSub(refSub);
    const sessSub = this.sessionService.sessionContainer$.subscribe(sess => {
      if (!sess) {
        this.navbarMinimized = true;
      } else {
        this.navbarMinimized = !sess.validSession();
      }
    });
    this.pushSub(sessSub);

    combineLatest([this.portalService.portalType$, this.viewModel.user$])
      .pipe(
        tap(([pt, user]) => {
          if (pt === PortalType.Member) {
            const member = user as MemberUser;
            if (!member.contactInformationConfirmed) {
              this._blurPage.next(true);
              ModalMemberAddContactInfo.open(this.ngbModal, this.injector).then(response => {
                if (response) this._blurPage.next(false);
                else this.router.navigate(['/auth/sign-out']).then();
              });
            }
          } else {
            this._blurPage.next(false);
          }
          this.cdr.detectChanges();
        })
      )
      .once(() => {});
  }

  setupViews() {
    this.setupLoadingOpts();
  }

  setupLoadingOpts() {
    this.loadingOpts$.once(opts => {
      opts.zIndex = 100;
      this._loadingOpts.next(opts);
    });
  }

  toggleMinimize(e: boolean) {
    this.navbarMinimized = e;
  }
}
