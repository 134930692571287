export enum MediaType {
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
  MP4 = 'video/mp4',
  AVI = 'video/x-msvideo',
  MPEG = 'video/mpeg',
  OGV = 'video/ogg',
  WEBM = 'video/webm'
}
