import { PermissionType } from './permission-type';
import { PermissionEnforcementType } from './permission-enforcement-type';

export class PermissionTypeMap {
  public id: number;
  public permissionType: PermissionType;
  public restrictedProperty: string;
  public accessGranted: boolean = false;
  public permissionEnforcement: PermissionEnforcementType = 0;

  constructor(id: number, permissionType: PermissionType, restrictedProperty: string) {
    this.id = id;
    this.permissionType = permissionType;
    this.restrictedProperty = restrictedProperty;
  }

  public setAccessGranted(permissionGranted: boolean): void {
    this.accessGranted = permissionGranted;
  }

  public setPermissionEnforcement(permissionEnforcement: PermissionEnforcementType): void {
    this.permissionEnforcement = permissionEnforcement;
  }
}
