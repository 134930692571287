<app-member-search-input
  [dispersedKey]="dispersedKey"
  [inputName]="'selectedMember'"
  [required]="required"
  [label]="label"
  [odataFunction]="memberSearchFunction"
  [bindingProperty]="'selectedMember'"
  [selectedMember]="selectedMember$ | async"
  [disabled]="disabled"
  (memberSelected)="setSelectedMember($event)">
</app-member-search-input>
<app-pill
  *ngIf="selectedMember$ | async"
  [pillItem]="selectedMemberPillItem$ | async"
  [fontSize]="'0.875rem'"
  [padding]="'0.5rem 0.75rem'"
  [showXButton]="true"
  [disabled]="disabled"
  (clicked)="setSelectedMember(null)">
</app-pill>
