import { OdataFilterGenerator } from '../../../interfaces/odata-filter-generator';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { Deserializable } from '../../protocols/deserializable';
import * as moment from 'moment/moment';

export class DesignatedUnitsTableFilterRequest implements OdataFilterGenerator, UniquelyIdentifiable, Deserializable {
  public activeUnits: boolean = true;

  [key: string]: any; // Index signature to allow dynamic property querying

  getFilterString(): string | null {
    const filterArray = [];

    if (this.activeUnits) {
      const today = moment().startOf('day');
      filterArray.push(`Versions/any(dv: dv/EndDate ge ${today.toISOString()} or dv/EndDate eq null)`);
    } else {
      const today = moment().startOf('day');
      filterArray.push(`Versions/any(dv: dv/EndDate lt ${today.toISOString()})`);
    }

    if (filterArray.length > 1) {
      return filterArray.join(' and ');
    } else if (filterArray.length === 1) {
      return filterArray[0];
    } else {
      return null;
    }
  }

  getUniqueIdentifier(): string {
    return this.activeUnits ? 'activeUnits' : 'inactiveUnits';
  }

  onDeserialize(): void {}

  getFilterCount(): number {
    return 0;
  }

  getSearchCount(): number {
    return 0;
  }
}
