import { Deserializable } from '../../protocols/deserializable';
import * as moment from 'moment';
import { DesignatedUnitContributionRateBasisType } from './designated-unit-contribution-rate-basis-type';
import { DesignatedUnitContributionRateCalculationType } from './designated-unit-contribution-rate-calculation-type';
import { DesignatedUnitLessThanFullTimeParticipationType } from './designated-unit-less-than-full-time-participation-type';

export class EmployerPlanDesignatedUnitVersion implements Deserializable {
  public id!: string;
  public designatedUnitId!: number;
  public employerPlanId!: number;
  public designatedUnitTypeId!: number;
  public contributionRateBasisTypeId!: number;
  public contributionRateBasisType!: DesignatedUnitContributionRateBasisType;
  public matchedContributionRateTypeId!: number;
  public matchedContributionRateType!: DesignatedUnitContributionRateCalculationType;
  public matchedContributionRatePercentage!: number | null;
  public matchedContributionRateRangeFirstPercentage!: number | null;
  public matchedContributionRateRangeSecondPercentage!: number | null;
  public unmatchedContributionRateTypeId!: number;
  public unmatchedContributionRateType!: DesignatedUnitContributionRateCalculationType;
  public unmatchedContributionRatePercentage!: number | null;
  public unmatchedContributionRateRangeFirstPercentage!: number | null;
  public unmatchedContributionRateRangeSecondPercentage!: number | null;
  public fullTimeWaitingPeriod!: number;
  public lessThanFullTimeParticipationTypeId!: number;
  public lessThanFullTimeParticipationType!: DesignatedUnitLessThanFullTimeParticipationType;
  public lessThanFullTimeWaitingPeriod!: number;
  public startDate!: Date;
  public endDate!: Date | null;

  // Not From API
  public formattedStartDate!: string;
  public formattedEndDate!: string;
  public active: boolean = false;

  onDeserialize(data?: { employerPlanId: number; designatedUnitId: number }): void {
    if (data) {
      this.employerPlanId = data.employerPlanId;
      this.designatedUnitId = data.designatedUnitId;
    }
    if (!!this.startDate) {
      const utcDate = new Date(this.startDate);
      this.startDate = new Date(utcDate.getTime() + 12 * 60 * 60 * 1000);
    }
    if (!!this.endDate) {
      const utcDate = new Date(this.endDate);
      this.endDate = new Date(utcDate.getTime() + -6 * 60 * 60 * 1000);
    }
    if (!!this.startDate) {
      this.formattedStartDate = moment(this.startDate).local().format('MM DD, YYYY');
    }
    if (!!this.endDate) {
      this.formattedEndDate = moment(this.endDate).local().format('MM DD, YYYY');
    }
    if (!!this.contributionRateBasisType) {
      this.contributionRateBasisType = window.injector.Deserialize?.instanceOf(
        DesignatedUnitContributionRateBasisType,
        this.contributionRateBasisType
      );
    }
    if (!!this.matchedContributionRateType) {
      this.matchedContributionRateType = window.injector.Deserialize?.instanceOf(
        DesignatedUnitContributionRateCalculationType,
        this.matchedContributionRateType
      );
    }
    if (!!this.unmatchedContributionRateType) {
      this.unmatchedContributionRateType = window.injector.Deserialize?.instanceOf(
        DesignatedUnitContributionRateCalculationType,
        this.unmatchedContributionRateType
      );
    }
    if (!!this.lessThanFullTimeParticipationType) {
      this.lessThanFullTimeParticipationType = window.injector.Deserialize?.instanceOf(
        DesignatedUnitLessThanFullTimeParticipationType,
        this.lessThanFullTimeParticipationType
      );
    }
    this.checkForActive();
  }

  checkForActive(): void {
    if (!!this.startDate && !this.endDate) {
      this.active = true;
    } else if (!!this.endDate) {
      this.active = new Date() < this.endDate;
    }
  }
}
