<div class="chart-row row">
  <div class="col-auto">
    <div class="chart-container">
      <app-donut-chart class="chart" [data]="data" />
    </div>
  </div>
  <div class="col">
    <div id="legend-container">
      <app-donut-chart-legend [showTotalRow]="showTotalRow" [data]="data" />
    </div>
  </div>
</div>
