import { BaseViewModel } from '../../../../models/base/base-view-model';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { LookAheadItem } from '../search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { debounceTime, finalize, take, takeUntil } from 'rxjs/operators';
import { CustomError } from '../../../../models/shared/custom-error';
import { ToastService } from '../../../../services/toast-service';
import { Injectable } from '@angular/core';

@Injectable()
export class OdataTypeaheadSearchViewModel extends BaseViewModel {
  constructor(private toastService: ToastService) {
    super();
  }

  private _searchText = new BehaviorSubject<string | null>(null);
  public readonly searchText$ = this._searchText as Observable<string>;

  private _lookAheadHits = new BehaviorSubject<LookAheadItem[]>([]);
  public readonly lookAheadHits$ = this._lookAheadHits as Observable<LookAheadItem[]>;

  public odataFunction!: (searchString: string) => Observable<LookAheadItem[]>;

  private listenToSearchText = this.searchText$
    .pipe(debounceTime(250), takeUntil(this.onDestroy))
    .subscribe(searchText => {
      if (searchText !== '' && searchText !== null) {
        this.search(searchText);
      } else {
        this._lookAheadHits.next([]);
      }
    });

  setupBindings() {}
  setupViews() {}

  public connectToSearchText(search: string) {
    this._searchText.next(search);
  }

  public connectToOdataFunction(odataFunction: (searchString: string) => Observable<LookAheadItem[]>) {
    this.odataFunction = odataFunction;
  }

  private search(searchText: string) {
    const lm = $localize`Searching...`;
    this._loadingOpts.addRequest(lm);
    this.odataFunction(searchText)
      .pipe(
        take(1),
        finalize(() => this._loadingOpts.removeRequest(lm))
      )
      .subscribe({
        next: items => {
          if (items) {
            this._lookAheadHits.next(items);
          } else {
            this._lookAheadHits.next([]);
            this.toastService.publishErrorMessage($localize`Error`, $localize`No results found.`);
          }
        },
        error: (error: CustomError) => {
          this.toastService.publishError(error);
          return throwError(() => error);
        }
      });
  }
}
