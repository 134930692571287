export enum MemberAuthFlow {
  SignIn = 0,
  ForgotPassword = 1,
  ResetPassword = 2,
  MemberWelcome = 3,
  MemberSetup = 4,
  MemberSendEmailVerification = 5,
  MemberEnterEmailVerification = 6,
  MemberMFAVerification = 7
}

export function MemberAuthFlowTitle(af: MemberAuthFlow): string {
  switch (af) {
    case MemberAuthFlow.SignIn:
      return $localize`Sign In`;
    case MemberAuthFlow.ResetPassword:
      return $localize`Reset Password`;
    case MemberAuthFlow.MemberWelcome:
      return $localize`Welcome to the CSS Pension Plan!`;
    case MemberAuthFlow.MemberSetup:
      return $localize`Setup Account`;
    case MemberAuthFlow.MemberSendEmailVerification:
      return $localize`Please Verify Your Email`;
    default:
      return '';
  }
}

export function MemberAuthFlowSubtext(af: MemberAuthFlow): string {
  switch (af) {
    case MemberAuthFlow.SignIn:
      return '';
    case MemberAuthFlow.ResetPassword:
      return '';
    case MemberAuthFlow.MemberWelcome:
      return $localize`To access your
      accounts, you’ll need to confirm a few details.`;
    case MemberAuthFlow.MemberSetup:
      return $localize`Confirm details about how you want to access your CSS Pension Plan account.`;
    default:
      return '';
  }
}
