import { Component, ElementRef, inject, Renderer2, ViewChild } from '@angular/core';
import { ResizeObserver } from '@juggle/resize-observer';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-header-container',
  templateUrl: './header-container.component.html',
  styleUrls: ['./header-container.component.scss']
})
export class HeaderContainerComponent extends BaseComponent {
  @ViewChild('header') header: ElementRef | undefined;
  @ViewChild('buffer') buffer: ElementRef | undefined;

  private renderer = inject(Renderer2);
  private observer = new ResizeObserver(() => {
    const headerHeight = this.header?.nativeElement?.offsetHeight + 'px';
    this.renderer.setStyle(this.buffer?.nativeElement, 'height', headerHeight);
  });

  public setupBindings(): void {
    this.observer.observe(this.header?.nativeElement);
  }

  public setupViews(): void {}

  public destroy(): void {
    super.destroy();
    this.observer.disconnect();
  }
}
