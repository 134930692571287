import { BaseViewModel } from '../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DropDownItem } from '../../../../models/shared/stylesheet/drop-down-item';
import { TypeService } from '../../../../services/type/type-service';
import { inject } from '@angular/core';
import { UserDomainModel } from '../../../../domainModels/user-domain-model';
import { MemberUser } from '../../../../models/account/dto/member-user';
import { MemberOnboardingInfoRequest } from '../../../../models/account/requests/member-onboarding-info-request';
import { CustomError } from '../../../../models/shared/custom-error';
import { MembersDomainModel } from '../../../../domainModels/members-domain-model';
import { ToastService } from '../../../../services/toast-service';

export class MemberContactInfoViewModel extends BaseViewModel {
  private typeService = inject(TypeService);
  private userDomainModel = inject(UserDomainModel);
  private membersDomainModel = inject(MembersDomainModel);
  private toastService = inject(ToastService);

  constructor() {
    super();
    this.user$.once(user => {
      this.countryChanged(user?.address?.countryId ?? 1);
    });
  }

  public user$ = this.userDomainModel.user$.pipe(map(user => user as MemberUser));

  public dateMask = [/\d/, /\d/, '/', /\d/, /\d/];

  public selectedCountryId$ = new BehaviorSubject<number>(1);

  public genderDropdowns$ = this.typeService.genders$.pipe(
    map(genders => {
      return genders.map(g => new DropDownItem(g?.name, g?.id));
    })
  );

  public countryDropdowns$ = this.typeService.countries$.pipe(
    map(countries => {
      return countries.map(c => new DropDownItem(c?.name, c?.id));
    })
  );

  public stateDropdowns$ = combineLatest([this.typeService.countries$, this.selectedCountryId$]).pipe(
    map(([countries, selectedCountryId]) => {
      return countries?.find(c => c.id === selectedCountryId)?.states?.map(s => new DropDownItem(s?.name, s?.id));
    })
  );

  public dismissModalSubject$ = new Subject<boolean>();

  public countryChanged(countryId: number): void {
    this.selectedCountryId$.next(countryId);
  }

  public updateMemberOnboardingInformation(req: MemberOnboardingInfoRequest): void {
    const loadingMessage = $localize`Updating member`;
    this._loadingOpts.addRequest(loadingMessage);
    this.membersDomainModel.updateMemberOnboardingInformation(req).subscribe({
      next: () => {
        this._loadingOpts.removeRequest(loadingMessage);
        this.dismissModalSubject$.next(true);
      },
      error: (error: CustomError) => {
        this._loadingOpts.removeRequest(loadingMessage);
        this.toastService.publishError(error);
      }
    });
  }
}
