import { Component, inject, Injector } from '@angular/core';
import { BaseComponent } from 'src/app/models/base/base-component';
import { AccessedByPortalType } from '../../../../../interfaces/accessed-by-portal-type';
import { RequiredPermissions } from '../../../../../interfaces/required-permissions';
import { PortalType } from '../../../../../models/enum/shared/portal-type';
import { AlertDetailsViewModel } from './alert-details-view-model';
import { Router } from '@angular/router';
import { StringExtensions } from '../../../../../utils/string.extensions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertTypeEnum } from '../../../../../models/alerts/enum/alert-type.enum';
import { combineLatest, Observable, of } from 'rxjs';
import { Alert } from '../../../../../models/alerts/dto/alert';
import { map, switchMap } from 'rxjs/operators';
import { ModalDeleteAlert } from '../../../../../modals/modal-delete-alert';
import { AlertStatusStringEnum } from '../../../../../models/alerts/enum/alert-status.enum';
import { StatusPill } from '../../../../../models/shared/status-pill';
import { ComponentCanDeactivate } from '../../../../../models/protocols/component-can-deactivate';
import { ModalConfirmation } from '../../../../../modals/modal-confirmation';
import { ConfirmationOptions } from '../../../../../models/shared/stylesheet/confirmation-options';

export const MAX_ALERT_TITLE_LENGTH = 80;
export const MAX_ALERT_DESCRIPTION_LENGTH = 200;
export const MAX_ALERT_BUTTON_LENGTH = 40;

@Component({
  selector: 'app-alert-details',
  templateUrl: './alert-details.component.html',
  styleUrls: ['./alert-details.component.scss'],
  providers: [AlertDetailsViewModel]
})
export class AlertDetailsComponent
  extends BaseComponent
  implements AccessedByPortalType, RequiredPermissions, ComponentCanDeactivate
{
  protected viewModel = inject(AlertDetailsViewModel);
  private router = inject(Router);
  protected ngbModal = inject(NgbModal);
  protected injector = inject(Injector);

  public alert$ = this.viewModel.alert$;

  public isLoading$ = this.viewModel.isLoading$;

  public alertStatusPill$ = this.alert$.pipe(
    map(alert => {
      if (alert.status === AlertStatusStringEnum.Past) {
        return new StatusPill(0, $localize`Past`, '#666666', '#F7F7F7');
      } else if (alert.status === AlertStatusStringEnum.Upcoming) {
        return new StatusPill(0, $localize`Upcoming`, '#024256', '#E6EEF1');
      } else {
        return new StatusPill(0, $localize`Active`, '#0D5D4B', '#E8F3F1');
      }
    })
  );

  public showAlertOverview$ = this.alert$.pipe(map(alert => !!alert?.status));

  public isCardTypeAlert$ = this.viewModel.isCardTypeAlert$;

  public pageTitle$ = of($localize`Alert Details`);
  public canEditAlertDetails$ = this.viewModel.canEditAlertDetails$;
  public isPastAlert$ = this.viewModel.isPastAlert$;

  public alertPreview$: Observable<Alert> = this.viewModel.alertPreview$;
  public showAlertPreview$ = this.viewModel.showAlertPreview$;
  public previewLoading$ = this.viewModel.previewLoading$;

  public showAlertPreviewButtonText$ = this.showAlertPreview$.pipe(
    map(show => {
      return show ? $localize`Hide Preview` : $localize`Show Preview`;
    })
  );

  public showCardAlertPreview$ = combineLatest([this.showAlertPreview$, this.isCardTypeAlert$]).pipe(
    map(([show, isCard]) => show && isCard)
  );

  public showBannerAlertPreview$ = combineLatest([this.showAlertPreview$, this.isCardTypeAlert$]).pipe(
    map(([show, isCard]) => show && !isCard)
  );

  public goBack(): void {
    this.router.navigate(['/settings/alerts']).then();
  }

  public toggleShowAlertPreview(): void {
    this.viewModel.toggleShowAlertPreview();
  }

  public submitForm(alert: Alert): void {
    this.viewModel.createAlert(alert);
  }

  public openDeleteModal(): void {
    this.alert$.once(alert => {
      ModalDeleteAlert.open(this.ngbModal, this.injector).then(result => {
        if (result) {
          this.viewModel.deleteAlert(alert.id.toString());
        }
      });
    });
  }

  public canDeactivate(): boolean | Promise<boolean> | Observable<boolean> {
    return combineLatest([this.viewModel.alertFormDirty$, this.viewModel.alert$]).pipe(
      switchMap(([dirty, alert]) => {
        const title = alert?.id ? $localize`Your changes will be discarded` : $localize`The alert will be deleted`;
        if (dirty) {
          const opts = new ConfirmationOptions();
          opts.title = title;
          opts.bodyText = $localize`Are you sure you want to cancel?`;
          opts.continueText = $localize`Confirm Cancellation`;
          opts.cancelText = $localize`Close`;
          return ModalConfirmation.open(this.ngbModal, this.injector, opts, undefined, true) as Observable<boolean>;
        } else {
          return of(true);
        }
      })
    );
  }

  public getAllowedPortalTypes(): PortalType[] {
    return [PortalType.Internal];
  }

  getRequiredPermissions(): number[] {
    return [1030];
  }

  setupBindings(): void {}

  setupViews(): void {}

  protected readonly StringExtensions = StringExtensions;
  protected readonly AlertTypeEnum = AlertTypeEnum;
  protected readonly AlertStatusStringEnum = AlertStatusStringEnum;
}
