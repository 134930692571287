import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PillItem } from '../../../../models/shared/pill-item';

/**
 * @param pillItem: uses pill item to determine internal state, else uses below
 * @param clickable: can click, else no
 * @param selected: added to something, else not
 * @param disabled: disabled, else not
 */
@Component({
  selector: 'app-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss']
})
export class PillComponent implements OnInit {
  @Input() pillItem!: PillItem;
  @Input() clickable = true;
  @Input() selected = false;
  @Input() disabled = false;
  @Input() fontSize = '1rem';
  @Input() activeCount = 0;
  @Input() showXButton = false;
  @Input() padding = '0.75rem 1rem';
  @Input() gap = '0.5rem';
  @Output() clicked = new EventEmitter<any>();
  public imageSrc!: string;
  public text!: string;

  constructor() {}

  ngOnInit(): void {
    if (this.pillItem) {
      this.clickable = this.pillItem.clickable;
      this.selected = this.pillItem.selected;
      this.disabled = this.pillItem.disabled;
      this.text = this.pillItem.text;
      this.imageSrc = this.pillItem.imgSrc;
    }
  }

  pillClicked() {
    if (this.clickable) {
      this.selected = !this.selected;
      this.clicked.emit(this.pillItem.value);
    }
  }
}
