import { Component, Input } from '@angular/core';
import { ButtonGenericComponent } from '@csspension/reactive-buttons';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent extends ButtonGenericComponent {
  @Input() label: string = '';
}
