import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { Alert } from '../../../../models/alerts/dto/alert';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent extends BaseComponent implements OnChanges {
  @Input() alert!: Alert;

  private _alert = new BehaviorSubject<Alert>(new Alert());
  public alert$ = this._alert as Observable<Alert>;

  setupBindings(): void {}

  setupViews(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.alert) this._alert.next(this.alert);
  }
}
