export class PasswordInputUtils {
  static readonly passwordHintText = $localize`
  Must contain 8+ characters,
  including 1 number,
  1 special character,
  1 uppercase letter,
  and 1 lowercase letter
  `;

  static readonly passwordErrorMap = new Map<string, string>([
    ['password', $localize`Password does not meet requirements.`]
  ]);
}
