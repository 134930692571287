import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  SimpleChanges,
  ViewRef
} from '@angular/core';
import {
  ReactiveTableLoadingStateBluePrintComponent,
  ReactiveTableOverlayBufferBluePrintComponent
} from '@csspension/reactive-table';

@Component({
  selector: 'app-table-overlay-buffer',
  templateUrl: './table-overlay-buffer.component.html',
  styleUrls: ['./table-overlay-buffer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ReactiveTableOverlayBufferBluePrintComponent,
      useExisting: forwardRef(() => TableOverlayBufferComponent)
    }
  ]
})
export class TableOverlayBufferComponent extends ReactiveTableOverlayBufferBluePrintComponent {
  constructor(@Inject(ChangeDetectorRef) viewRef: ViewRef) {
    super(viewRef);
  }

  changesFromBluePrint(changes: SimpleChanges): void {}

  initializeFromBluePrint(bluePrint: ReactiveTableLoadingStateBluePrintComponent): void {}

  sendOutputsToBluePrint(bluePrint: ReactiveTableLoadingStateBluePrintComponent): void {}
}
