import { Component, inject, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { MemberAuthViewModel } from '../../../../viewModels/member-auth-view-model';
import { ConfirmMemberRequest } from '../../../../../../models/users/requests/confirm-member-request';
import { ReactiveFormGroupComponent } from '@csspension/reactive-form';
import { AssetSize } from 'src/app/models/enum/dto/asset-size.enum';
import { PasswordInputUtils } from '../../../../../../utils/password-input-utils';

@Component({
  selector: 'app-member-setup',
  templateUrl: './member-setup.component.html',
  styleUrls: ['./member-setup.component.scss', '../../auth.component.scss']
})
export class MemberSetupComponent extends BaseComponent {
  private viewModel = inject(MemberAuthViewModel);

  public authFlowTitle$ = this.viewModel.authFlowTitle$;
  public authFlowSubtext$ = this.viewModel.authFlowSubtext$;

  public eulaChecked: boolean = false;

  @ViewChild('form') form!: ReactiveFormGroupComponent;

  public hintText = $localize`Must use a personal email address`;

  public req = new ConfirmMemberRequest('', '');

  public customErrorMap = this.viewModel.customErrorMap;
  public eulaValidatorDirective = this.viewModel.eulaValidatorDirective;
  public customValueParser = this.viewModel.customValueParser;

  public readonly AssetSize = AssetSize;
  public readonly passwordHintText = PasswordInputUtils.passwordHintText;

  setupBindings(): void {}

  setupViews(): void {}

  setEulaChecked(checked: boolean): void {
    this.eulaChecked = checked;
    const control = this.form.reactiveFormGroup.controls.eulaAccepted;
    control.patchValue(checked);
    control.updateValueAndValidity();
  }

  formSubmitted(req: ConfirmMemberRequest) {
    this.viewModel.confirmMember(req);
  }

  openContactPage(): void {
    this.viewModel.openContactPage();
  }

  openEulaModal(): void {
    this.viewModel.openEulaModal();
  }
}
