import { inject, Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SubmissionPermissionTypeEnum } from '../../../../models/permissions/enum/submission-permission-type.enum';
import { SubmissionTypeEnum } from '../../../../models/account/enum/submission-type-enum';
import { SubmissionPermissionWithType } from '../../../../models/permissions/dto/submission-permission-with-type';
import { map } from 'rxjs/operators';
import { SubmissionPermissionSelectionTypeEnum } from '../../../../models/permissions/enum/submission-permission-selection-type.enum';
import { PermissionService } from '../../../../services/permission-service';

@Injectable()
export class SubmissionPermissionCheckboxTableViewModel extends BaseViewModel {
  // Permission Checking
  private permissionService = inject(PermissionService);
  public canEditSubmissionPermissions$ = this.permissionService.permissionGranted$([22]);

  // Member Permissions
  private _allMemberSubmissionPermissions = new BehaviorSubject<SubmissionPermissionWithType[]>([]);
  public readonly allMemberSubmissionPermissions$ = this._allMemberSubmissionPermissions as Observable<
    SubmissionPermissionWithType[]
  >;
  public readonly memberNameChangePermissions$ = this.allMemberSubmissionPermissions$.pipe(
    map(perm => {
      return perm.filter(p => p.memberSubmissionTypeId === SubmissionTypeEnum.NameChange);
    })
  );

  public readonly memberAddressPermissions$ = this.allMemberSubmissionPermissions$.pipe(
    map(perm => this.getPermissionsOfType(perm, SubmissionTypeEnum.AddressChange))
  );

  public readonly memberContactInfoPermissions$ = this.allMemberSubmissionPermissions$.pipe(
    map(perm => this.getPermissionsOfType(perm, SubmissionTypeEnum.UpdateContactInfo))
  );

  public readonly memberPersonalInfoPermissions$ = this.allMemberSubmissionPermissions$.pipe(
    map(perm => this.getPermissionsOfType(perm, SubmissionTypeEnum.PersonalInfoChange))
  );

  // Selection Types
  public readonly memberViewSelectionType$ = this.allMemberSubmissionPermissions$.pipe(
    map(perm => this.getPermissionSelectionType(perm, SubmissionPermissionTypeEnum.View))
  );

  public readonly memberProcessSelectionType$ = this.allMemberSubmissionPermissions$.pipe(
    map(perm => this.getPermissionSelectionType(perm, SubmissionPermissionTypeEnum.Process))
  );

  public readonly memberApproveSelectionType$ = this.allMemberSubmissionPermissions$.pipe(
    map(perm => this.getPermissionSelectionType(perm, SubmissionPermissionTypeEnum.Approve))
  );

  public readonly memberApproveOwnSelectionType$ = this.allMemberSubmissionPermissions$.pipe(
    map(perm => this.getPermissionSelectionType(perm, SubmissionPermissionTypeEnum.ApproveOwn))
  );

  public readonly memberOverrideSelectionType$ = this.allMemberSubmissionPermissions$.pipe(
    map(perm => this.getPermissionSelectionType(perm, SubmissionPermissionTypeEnum.Override))
  );

  public readonly memberCreateSelectionType$ = this.allMemberSubmissionPermissions$.pipe(
    map(perm => this.getPermissionSelectionType(perm, SubmissionPermissionTypeEnum.Create))
  );

  public readonly updatedPermissionsSubject$ = new Subject<number[]>();

  public permissionClicked(
    id: number,
    permType: SubmissionPermissionTypeEnum,
    subType: SubmissionTypeEnum,
    checked: boolean
  ): void {
    this.allMemberSubmissionPermissions$.once(permissions => {
      // Permission the user clicked
      const permission = permissions.find(p => p.id === id);
      const relatedPermissions = permissions.filter(p => p.memberSubmissionTypeId === subType);
      // If the permission depends on another permission being selected, select/deselect those as well.
      if (checked) {
        this.selectRelatedPermissions(permType, relatedPermissions);
      } else {
        this.deselectRelatedPermissions(permType, relatedPermissions);
      }
      if (permission) {
        permission.selected = checked;
      }
      this.setUpdatedPermissions(permissions);
    });
  }

  public connectToMemberSubmissionPermissions(permissions: SubmissionPermissionWithType[]): void {
    this._allMemberSubmissionPermissions.next(permissions);
  }

  public selectAll(type: SubmissionPermissionTypeEnum, checked: boolean): void {
    this.allMemberSubmissionPermissions$.once(permissions => {
      permissions
        .filter(p => p.permissionTypeId === type)
        .forEach(p => {
          this.permissionClicked(p.id, type, p.memberSubmissionTypeId, checked);
        });
      this.setUpdatedPermissions(permissions);
    });
  }

  private selectRelatedPermissions(
    permType: SubmissionPermissionTypeEnum,
    relatedPermissions: SubmissionPermissionWithType[]
  ): void {
    let dependencies: SubmissionPermissionWithType[] = [];
    switch (permType) {
      case SubmissionPermissionTypeEnum.Process:
      case SubmissionPermissionTypeEnum.Approve:
      case SubmissionPermissionTypeEnum.Override:
      case SubmissionPermissionTypeEnum.Create:
        dependencies = relatedPermissions.filter(p => p.permissionTypeId === SubmissionPermissionTypeEnum.View);
        break;
      case SubmissionPermissionTypeEnum.ApproveOwn:
        dependencies = relatedPermissions.filter(
          p =>
            p.permissionTypeId === SubmissionPermissionTypeEnum.View ||
            p.permissionTypeId === SubmissionPermissionTypeEnum.Process
        );
        break;
    }
    if (dependencies.length) {
      dependencies.forEach(d => (d.selected = true));
    }
  }

  private deselectRelatedPermissions(
    permType: SubmissionPermissionTypeEnum,
    relatedPermissions: SubmissionPermissionWithType[]
  ): void {
    let dependencies: SubmissionPermissionWithType[] = [];
    switch (permType) {
      case SubmissionPermissionTypeEnum.View:
        dependencies = relatedPermissions.filter(p => p.permissionTypeId !== SubmissionPermissionTypeEnum.View);
        break;
      case SubmissionPermissionTypeEnum.Process:
        dependencies = relatedPermissions.filter(p => p.permissionTypeId === SubmissionPermissionTypeEnum.ApproveOwn);
        break;
    }
    if (dependencies.length) {
      dependencies.forEach(d => (d.selected = false));
    }
  }

  private setUpdatedPermissions(permissions: SubmissionPermissionWithType[]): void {
    this._allMemberSubmissionPermissions.next(permissions);
    this.updatedPermissionsSubject$.next(permissions.filter(p => p.selected).map(p => p.id));
  }

  private getPermissionSelectionType(
    permissions: SubmissionPermissionWithType[],
    permissionType: SubmissionPermissionTypeEnum
  ): SubmissionPermissionSelectionTypeEnum {
    const permissionsByType = permissions.filter(p => p.permissionTypeId === permissionType);
    if (permissionsByType.every(p => p.selected)) {
      return SubmissionPermissionSelectionTypeEnum.All;
    } else if (permissionsByType.some(p => p.selected)) {
      return SubmissionPermissionSelectionTypeEnum.Some;
    } else {
      return SubmissionPermissionSelectionTypeEnum.None;
    }
  }

  private getPermissionsOfType(
    permissions: SubmissionPermissionWithType[],
    type: SubmissionTypeEnum
  ): SubmissionPermissionWithType[] {
    return permissions.filter(p => p.memberSubmissionTypeId === type);
  }
}
