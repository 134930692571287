import { Deserializable } from '../../protocols/deserializable';
import { LookAheadItem } from '../../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';

export class DesignatedUnitSearchResult implements Deserializable, LookAheadItem {
  public id!: number;
  public clientUnitId!: number;
  public unitName!: string;

  onDeserialize(): void {}

  lookAheadDisabled(): boolean {
    return false;
  }

  lookAheadDisabledMessage(): string {
    return '';
  }
}
