import { Deserializable } from '../../protocols/deserializable';
import { DateUtils } from '../../../utils/date-utils';

export class UserSession implements Deserializable {
  public accessToken: string;
  public expiresIn: number;
  public idToken: string;
  public newDeviceMetadata: string | null;
  public refreshToken: string;
  public tokenType: string;

  constructor() {
    this.accessToken = '';
    this.expiresIn = 0;
    this.idToken = '';
    this.newDeviceMetadata = null;
    this.refreshToken = '';
    this.tokenType = '';
  }

  public validSession(cachedTime: number): boolean {
    return !!this.accessToken && this.expiresIn + cachedTime > DateUtils.currentTimestamp();
  }

  onDeserialize(): void {}
}
