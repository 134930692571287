import { Deserializable } from '../../protocols/deserializable';
import { BaseUser } from '../../base/base-user';
import { UserSession } from './user-session';
import { Address } from './address';
import { UserChallengeName } from '../enum/user-challenge.name';
import { ColorUtils } from '../../../utils/color-utils';
import { SubmissionPermission } from '../../permissions/dto/submission-permission';
import * as moment from 'moment';

export class InternalUser extends BaseUser implements Deserializable {
  mfaSecretCode: string | null;
  active: boolean | null;
  profileColour: string = '';
  setInactiveById: number | null = null;
  setInactiveByName: string | null = null;
  setInactiveDate: Date | null = null;
  submissionPermissions: SubmissionPermission[] = [];

  // Not from API
  public initials: string = '';
  public textColor: string = '';
  public setToInactiveString: string = '';

  constructor(
    id: number = -1,
    userSession: UserSession | null = new UserSession(),
    challengeName: UserChallengeName | null = null,
    sessionId: string = '',
    mfaSecretCode: string | null = null,
    firstName: string = '',
    lastName: string = '',
    email: string = '',
    passwordChangedDate: Date = new Date(),
    eulaConfirmation: boolean = false,
    eulaConfirmationDate: Date = new Date(),
    lastLoginDate: Date = new Date(),
    addressId: string = '',
    address: Address = new Address(),
    active: boolean = false,
    submissionPermissions: SubmissionPermission[] = [],
    setInactiveById: number | null = null,
    setInactiveByName: string | null = null,
    setInactiveDate: Date | null = null
  ) {
    super(
      id,
      firstName,
      lastName,
      email,
      passwordChangedDate,
      eulaConfirmation,
      eulaConfirmationDate,
      lastLoginDate,
      userSession,
      challengeName,
      sessionId,
      addressId,
      address
    );
    this.mfaSecretCode = mfaSecretCode;
    this.active = active;
    this.submissionPermissions = submissionPermissions;
    this.setInactiveById = setInactiveById;
    this.setInactiveByName = setInactiveByName;
    this.setInactiveDate = setInactiveDate;
  }

  onDeserialize() {
    super.onDeserialize();
    this.initials = `${this.firstName?.charAt(0)}${this.lastName?.charAt(0)}`;
    this.textColor = ColorUtils.isDarkColor(this.profileColour) ? '#FFFFFF' : '#000000';
    if (!this.active && !!this.setInactiveDate) {
      this.setToInactiveString = `Set to inactive by ${this.setInactiveByName} on ${moment(this.setInactiveDate).format(
        'MMM DD, YYYY'
      )}`;
    }
  }
}
