import { Deserializable } from '../../protocols/deserializable';

export class ChoosePasswordRequest implements Deserializable {
  public email: string = '';
  public password: string = '';
  public confirmPassword: string = '';
  public challengeName: string = '';
  public sessionId: string = '';
  public eulaAccepted: boolean = false;

  constructor(
    email: string,
    password: string,
    confirmPassword: string,
    challengeName: string,
    sessionId: string,
    eulaAccepted: boolean
  ) {
    this.email = email;
    this.password = password;
    this.challengeName = challengeName;
    this.sessionId = sessionId;
    this.confirmPassword = confirmPassword;
    this.eulaAccepted = eulaAccepted;
  }

  onDeserialize = () => {};
}
