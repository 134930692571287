<app-back-button
  *ngIf="viewModel.showBackButton$ | async"
  (backClicked)="viewModel.goBack()"
  [paddingBottomRem]="2"></app-back-button>

<app-logo
  class="css-logo"
  [ngStyle]="{ 'margin-top': (viewModel.showBackButton$ | async) ? '2rem' : 0 }"
  [logoImage]="'assets/logo/dark/css-logo.svg'"></app-logo>

<ng-container [ngSwitch]="viewModel.authFlow$ | async">
  <!--  Sign In Flow  -->
  <ng-container *ngSwitchCase="AuthFlow.SignIn">
    <app-sign-in (loadingOpts)="loadingOpts.emit($event)"></app-sign-in>
  </ng-container>
  <!--  Forgot Password Flow  -->
  <ng-container *ngSwitchCase="AuthFlow.ForgotPassword">
    <app-forgot-password (loadingOpts)="loadingOpts.emit($event)"></app-forgot-password>
  </ng-container>
  <!--  Reset Password Flow  -->
  <ng-container *ngSwitchCase="AuthFlow.ResetPassword">
    <app-reset-password (loadingOpts)="loadingOpts.emit($event)"></app-reset-password>
  </ng-container>
  <!--  Internal User Choose Password Flow  -->
  <ng-container *ngSwitchCase="AuthFlow.InternalUserChoosePassword">
    <app-internal-user-choose-password></app-internal-user-choose-password>
  </ng-container>
  <!--  Internal User Setup MFA Flow  -->
  <ng-container *ngSwitchCase="AuthFlow.InternalUserSetupMfa">
    <app-internal-user-auth-setup></app-internal-user-auth-setup>
  </ng-container>
  <!--  Internal User Verify Code Flow  -->
  <ng-container *ngSwitchCase="AuthFlow.InternalVerifyCode">
    <app-internal-user-verify-code></app-internal-user-verify-code>
  </ng-container>
</ng-container>
