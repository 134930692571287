import { Deserializable } from '../../protocols/deserializable';

export class StringResponse implements Deserializable {
  code: number;
  message: string;

  constructor(code: number, message: string) {
    this.code = code;
    this.message = message;
  }
  onDeserialize(): void {}
}
