import { AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';
import { Directive } from '@angular/core';
import * as moment from 'moment';

export function dateValidator(): ValidatorFn {
  return (control: AbstractControl): { [p: string]: any } | null => {
    const { value } = control;
    if (!value) return null;
    const parsedDate = moment(value, 'DD/MM/YYYY', true);

    if (parsedDate.isValid() && parsedDate.format('DD/MM/YYYY') === value) {
      return null;
    } else {
      return { [DateValidDirective.key]: true };
    }
  };
}

@Directive({
  selector: '[appDateValidDirective]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DateValidDirective, multi: true }]
})
export class DateValidDirective {
  static key: string = 'validDate';

  validate(control: AbstractControl): { [key: string]: any } {
    // @ts-ignore
    return dateValidator()(control);
  }
}
