import { BaseService } from '@csspension/base-angular';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { PortalTypeIdEnum } from '../models/enum/shared/portal-type-id.enum';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService extends BaseService {
  public webSocketConnections = new Map<number, WebSocket>();

  public connect(portalType: PortalTypeIdEnum, id: number): WebSocket {
    const existingConnection = this.webSocketConnections.get(id);
    if (!!existingConnection) {
      return existingConnection;
    }

    let retryCount = 0;

    const createWebSocket = () => {
      const socket = new WebSocket(`${environment.mainSocketUrl}?portaltypeid=${portalType}&recipientid=${id}`);

      // Attempt to reconnect every 2 seconds, up to 5 times
      socket.onerror = error => {
        if (retryCount < 5) {
          retryCount++;
          setTimeout(createWebSocket, 2000);
        } else {
          console.error('Failed to connect after 5 attempts.');
        }
      };

      socket.onopen = () => {
        this.webSocketConnections.set(id, socket);
        retryCount = 0;
      };

      return socket;
    };

    return createWebSocket();
  }

  public disconnect(id: number): void {
    const socket = this.webSocketConnections.get(id);
    if (!!socket) {
      if (socket.readyState === WebSocket.OPEN) socket.close();
      this.webSocketConnections.delete(id);
    }
  }

  public closeAllConnections(): void {
    this.webSocketConnections.forEach(socket => {
      socket.close();
    });
    this.webSocketConnections.clear();
  }

  override destroy(): void {
    super.destroy();
    this.closeAllConnections();
  }
}
