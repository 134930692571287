import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './components/auth/auth.component';
import { SignInComponent } from './components/auth/sign-in/sign-in.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { ChangePasswordViewModel } from '../profile/modals/change-password-modal/change-password-view-model';
import { MemberAuthViewModel } from './viewModels/member-auth-view-model';
import { UploadProfilePictureComponent } from './components/modals/upload-profile-picture/upload-profile-picture.component';
import { UploadProfilePictureViewModel } from './components/modals/upload-profile-picture/upload-profile-picture-view-model';
import { ReactiveFormModule } from '@csspension/reactive-form';
import { ReactiveButtonsModule } from '@csspension/reactive-buttons';
import { FlexModule } from '@angular/flex-layout';
import { SignOutComponent } from './components/auth/sign-out/sign-out.component';
import { MemberWelcomeComponent } from './components/auth/member/member-welcome/member-welcome.component';
import { MemberSetupComponent } from './components/auth/member/member-setup/member-setup.component';
import { MemberEnterEmailVerificationComponent } from './components/auth/member/member-enter-email-verification/member-enter-email-verification.component';
import { MemberSendEmailVerificationComponent } from './components/auth/member/member-send-email-verification/member-send-email-verification.component';
import { InternalUserAuthSetupComponent } from './components/auth/internal-user/internal-user-auth-setup/internal-user-auth-setup.component';
import { QrCodeSupportComponent } from './components/modals/qr-code-support/qr-code-support.component';
import { MemberAuthComponent } from './components/auth/member/member-auth.component';
import { InternalUserAuthWrapperComponent } from './components/auth/wrappers/internal-user-auth-wrapper/internal-user-auth-wrapper.component';
import { AuthWrapperComponent } from './components/auth/wrappers/auth-wrapper/auth-wrapper.component';
import { InternalUserAuthComponent } from './components/auth/internal-user/internal-user-auth.component';
import { InternalUserVerifyCodeComponent } from './components/auth/internal-user/internal-user-verify-code/internal-user-verify-code.component';
import { EmployerAuthComponent } from './components/auth/employer/employer-auth.component';
import { InternalUserChoosePasswordComponent } from './components/auth/internal-user/internal-user-choose-password/internal-user-choose-password.component';
import { EmployerChoosePasswordComponent } from './components/auth/employer/employer-choose-password/employer-choose-password.component';
import { TextMaskModule } from 'angular2-text-mask';
import { MobileAuthNavbarComponent } from './components/auth/mobile-auth-navbar/mobile-auth-navbar.component';
import { VerifyMfaComponent } from './components/auth/verify-mfa/verify-mfa.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SessionExpiryInterceptor } from '../../services/interceptors/session-expiry.interceptor';

@NgModule({
  declarations: [
    AuthComponent,
    SignInComponent,
    SignOutComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UploadProfilePictureComponent,
    MemberWelcomeComponent,
    MemberSetupComponent,
    MemberEnterEmailVerificationComponent,
    MemberSendEmailVerificationComponent,
    InternalUserChoosePasswordComponent,
    InternalUserAuthSetupComponent,
    QrCodeSupportComponent,
    MemberAuthComponent,
    InternalUserAuthWrapperComponent,
    AuthWrapperComponent,
    InternalUserAuthComponent,
    InternalUserVerifyCodeComponent,
    MobileAuthNavbarComponent,
    InternalUserVerifyCodeComponent,
    EmployerAuthComponent,
    EmployerChoosePasswordComponent,
    VerifyMfaComponent
  ],
  imports: [
    AuthRoutingModule,
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormModule,
    ReactiveButtonsModule,
    FlexModule,
    TextMaskModule
  ],
  providers: [
    MemberAuthViewModel,
    ChangePasswordViewModel,
    UploadProfilePictureViewModel,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionExpiryInterceptor,
      multi: true
    }
  ]
})
export class AuthModule {}
