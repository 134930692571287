import { Role } from '../roles/role';

export class AddRoleMap {
  public roleMap = new Map<number, Role | undefined>();

  constructor(map?: Map<number, Role | undefined>) {
    if (!!map) {
      this.roleMap = map;
    }
  }
}
