<div
  appThrottleClick
  (throttleClick)="navItemSelected()"
  [ngClass]="{
    active: viewModel.isNavItemActive$ | async,
    animating: animating$ | async
  }"
  class="nav-item active custom-nav-item">
  <div *ngIf="viewModel.hasIcons$ | async" class="nav-item-icon">
    <img [src]="navItem.iconSrc" alt="icon" class="nav-bar-icon" />
    <img [src]="navItem.iconFilledSrc" alt="icon-filled" class="nav-bar-icon-filled" />
  </div>
  <div class="nav-item-label" [ngClass]="{ 'multi-line-label': viewModel.multiLineNavItem$ | async }">
    {{ navItem.name }}
  </div>
</div>
