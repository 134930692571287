<div class="loading-container">
  <div class="loading-overlay"></div>
  <div class="loading-data">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
