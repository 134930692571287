import { Component, inject, Injector } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { AuthCodeRequest } from '../../../../../../models/account/requests/auth-code-request';
import * as QRCode from 'qrcode';
import { SessionService } from '../../../../../../services/session-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InternalUserAuthViewModel } from '../../../../viewModels/internal-user-auth-view-model.service';
import { InternalUser } from '../../../../../../models/account/dto/internal-user';
import { ToastService } from '../../../../../../services/toast-service';
import { ClipboardService } from '../../../../../../services/clipboard-service';
import { NumberUtils } from '../../../../../../utils/number-utils';
import { ModalQrSupport } from '../../../../../../modals/modal-qr-code-support';

@Component({
  selector: 'app-internal-user-auth-setup',
  templateUrl: './internal-user-auth-setup.component.html',
  styleUrls: ['./internal-user-auth-setup.component.scss', '../../auth.component.scss']
})
export class InternalUserAuthSetupComponent extends BaseComponent {
  viewModel = inject(InternalUserAuthViewModel);
  sessionService = inject(SessionService);
  ngbModal = inject(NgbModal);
  injector = inject(Injector);
  toastService = inject(ToastService);
  clipboardService = inject(ClipboardService);

  req = new AuthCodeRequest('', '', null, '');

  public mfaKey = '';

  setupBindings() {
    this.sessionService.sessionContainer$.once(session => {
      if (!session) session = this.viewModel.getCachedSession();
      const user = session?.user as InternalUser;
      if (!!user) {
        this.req.challengeName = user?.challengeName ?? null;
        this.req.sessionId = user?.sessionId ?? '';
        this.req.email = user?.email ?? '';
        // Generate QR code with secret provided by user object
        const canvas = document.getElementById('qr-code');
        const url = `otpauth://totp/${user.email}%20(Internal)?secret=${user.mfaSecretCode}`;
        this.mfaKey = user.mfaSecretCode as string;
        QRCode.toCanvas(canvas, url).then();
      }
    });
  }

  formSubmitted(req: AuthCodeRequest) {
    this.viewModel.verifyMfaCode(
      {
        ...req,
        timeBasedOneTimePassword: NumberUtils.formatNumberWithLeadingZeros(+req.timeBasedOneTimePassword, 6)
      },
      true
    );
  }

  setupViews(): void {}

  openSupportModal() {
    ModalQrSupport.open(this.ngbModal, this.injector);
  }
}
