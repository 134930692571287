import { Deserializable } from '../../protocols/deserializable';
import { EmployerPlanDesignatedUnitVersion } from './employer-plan-designated-unit-version';
import { DesignatedUnitType } from '../../shared/designated-unit-type';

export class EmployerPlanDesignatedUnit implements Deserializable {
  public id!: string;
  public clientUnitId!: number;
  public employerPlanId!: number;
  public designatedUnitTypeId!: number;
  public unitName!: string;
  public designatedUnitType!: DesignatedUnitType;
  public versions!: EmployerPlanDesignatedUnitVersion[];

  onDeserialize(): void {
    if (!!this.versions) {
      this.versions = window.injector.Deserialize?.arrayOf(EmployerPlanDesignatedUnitVersion, this.versions, {
        employerPlanId: this.employerPlanId,
        designatedUnitId: this.id
      });
    }
    if (!!this.designatedUnitType) {
      this.designatedUnitType = window.injector.Deserialize?.instanceOf(DesignatedUnitType, this.designatedUnitType);
    }
  }
}
