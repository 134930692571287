import { ApiClient } from './api-client';
import { Observable, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { inject, Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { CustomError } from '../models/shared/custom-error';
import { State } from '../models/types/dto/state';
import { Gender } from '../models/types/dto/gender';
import { Country } from '../models/types/dto/country';
import { SubmissionStatus } from '../models/shared/submission-status';
import { AlertType } from '../models/alerts/dto/alert-types';
import { TransactionEntryCodeType } from '../models/transactions/transaction-entry-code-type';
import { TransactionStatus } from '../models/transactions/transaction-status';
import { Pronoun } from '../models/users/dto/member-user';
import { RemittanceStatusType } from '../models/remittances/remittance-status-type';
import { MfaType } from '../models/types/dto/mfa-type';
import { RemittanceFrequencyType } from '../models/shared/remittance-frequency-type';
import { DesignatedUnitType } from '../models/shared/designated-unit-type';
import { DesignatedUnitContributionRateBasisType } from '../models/employers/plans/designated-unit-contribution-rate-basis-type';
import { DesignatedUnitContributionRateCalculationType } from '../models/employers/plans/designated-unit-contribution-rate-calculation-type';
import { DesignatedUnitLessThanFullTimeParticipationType } from '../models/employers/plans/designated-unit-less-than-full-time-participation-type';
import { EmploymentType } from '../models/employers/employer-history/types/employment-type';
import { LeaveDetailsType } from '../models/employers/employer-history/types/leave-details-type';
import { EmploymentStatusType } from '../models/employers/employer-history/types/employment-status-type';
import { RelationshipStatusType } from '../models/types/dto/relationship-status-type';

@Injectable({
  providedIn: 'root'
})
export class TypeAPI implements LoggableAPI {
  private apiClient = inject(ApiClient);

  // Variables

  public serviceName = 'Types';

  // States

  public getStatesByCountry(countryId: number): Observable<State[]> {
    const url = Endpoints.getStatesByCountryId(countryId);
    return this.apiClient.getArr<State>(State, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // Members

  public getGenders(): Observable<Gender[]> {
    const url = Endpoints.getGenders();
    return this.apiClient.getArr<Gender>(Gender, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getPronouns(): Observable<Pronoun[]> {
    const url = Endpoints.getPronouns();
    return this.apiClient.getArr<Gender>(Gender, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getMemberRelationshipTypes(): Observable<RelationshipStatusType[]> {
    const url = Endpoints.getMemberRelationshipTypes();
    return this.apiClient.getArr<RelationshipStatusType>(RelationshipStatusType, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // Countries

  public getCountries(): Observable<Country[]> {
    const url = Endpoints.getCountries();
    return this.apiClient.getArr<Country>(Country, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // Submissions

  public getSubmissionStatusTypes(): Observable<SubmissionStatus[]> {
    const url = Endpoints.getSubmissionStatusTypes();
    return this.apiClient.getArr<SubmissionStatus>(SubmissionStatus, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // Transactions

  public getTransactionStatusTypes(): Observable<TransactionStatus[]> {
    const url = Endpoints.getTransactionStatusTypes();
    return this.apiClient.getArr<TransactionStatus>(TransactionStatus, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // Remittances

  public getRemittanceStatusTypes(): Observable<RemittanceStatusType[]> {
    const url = Endpoints.getRemittanceStatusTypes();
    return this.apiClient.getArr<RemittanceStatusType>(RemittanceStatusType, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getRemittancePaymentStatusTypes(): Observable<RemittanceStatusType[]> {
    const url = Endpoints.getRemittancePaymentStatusTypes();
    return this.apiClient.getArr<RemittanceStatusType>(RemittanceStatusType, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getRemittanceFrequencyTypes(): Observable<RemittanceFrequencyType[]> {
    const url = Endpoints.getRemittanceFrequencyTypes();
    return this.apiClient.getArr<RemittanceFrequencyType>(RemittanceFrequencyType, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // Entries

  public getTransactionEntryCodeTypes(): Observable<TransactionEntryCodeType[]> {
    const url = Endpoints.getTransactionEntryCodeTypes();
    return this.apiClient.getArr<TransactionEntryCodeType>(TransactionEntryCodeType, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // Contribution Entry Options

  public getContributionEntryCodeTypes(): Observable<TransactionEntryCodeType[]> {
    const url = Endpoints.getContributionEntryOptionTypes();
    return this.apiClient.getArr<TransactionEntryCodeType>(TransactionEntryCodeType, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // Alerts

  public getAlertTypes(): Observable<AlertType[]> {
    const url = Endpoints.getAlertTypes();
    return this.apiClient.getArr<AlertType>(AlertType, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // Multi-Factor Authentication Types

  public getMfaTypes(): Observable<MfaType[]> {
    const url = Endpoints.getMfaTypes();
    return this.apiClient.getArr<AlertType>(AlertType, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // Employer Plans

  public getEmployerPlanDesignatedUnitTypes(): Observable<DesignatedUnitType[]> {
    const url = Endpoints.getEmployerPlanDesignatedUnitTypes();
    return this.apiClient.getArr<DesignatedUnitType>(DesignatedUnitType, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getEmployerPlanContributionRateBasisTypes(): Observable<DesignatedUnitContributionRateBasisType[]> {
    const url = Endpoints.getEmployerPlanContributionRateBasisTypes();
    return this.apiClient
      .getArr<DesignatedUnitContributionRateBasisType>(DesignatedUnitContributionRateBasisType, url)
      .pipe(
        catchError(e => {
          const err = new CustomError(e, this.serviceName);
          return throwError(() => err);
        })
      );
  }

  public getEmployerPlanContributionRateCalculationTypes(): Observable<
    DesignatedUnitContributionRateCalculationType[]
  > {
    const url = Endpoints.getEmployerPlanContributionRateCalculationTypes();
    return this.apiClient
      .getArr<DesignatedUnitContributionRateCalculationType>(DesignatedUnitContributionRateCalculationType, url)
      .pipe(
        catchError(e => {
          const err = new CustomError(e, this.serviceName);
          return throwError(() => err);
        })
      );
  }

  public getEmployerPlanLessThanFullTimeParticipationTypes(): Observable<
    DesignatedUnitLessThanFullTimeParticipationType[]
  > {
    const url = Endpoints.getEmployerPlanLessThanFullTimeParticipationTypes();
    return this.apiClient
      .getArr<DesignatedUnitLessThanFullTimeParticipationType>(DesignatedUnitLessThanFullTimeParticipationType, url)
      .pipe(
        catchError(e => {
          const err = new CustomError(e, this.serviceName);
          return throwError(() => err);
        })
      );
  }

  // Member Employment History

  public getMemberEmploymentTypes(): Observable<EmploymentType[]> {
    const url = Endpoints.getMemberEmploymentTypes();
    return this.apiClient.getArr<EmploymentType>(EmploymentType, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getMemberEmploymentLeaveTypes(): Observable<LeaveDetailsType[]> {
    const url = Endpoints.getMemberEmploymentLeaveTypes();
    return this.apiClient.getArr<LeaveDetailsType>(LeaveDetailsType, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getMemberEmploymentStatusTypes(): Observable<EmploymentStatusType[]> {
    const url = Endpoints.getMemberEmploymentStatusTypes();
    return this.apiClient.getArr<EmploymentStatusType>(EmploymentStatusType, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }
}
