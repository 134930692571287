import { Component, Input } from '@angular/core';
import { StatusPill } from '../../../../models/shared/status-pill';

@Component({
  selector: 'app-labeled-list-item',
  templateUrl: './labeled-list-item.component.html',
  styleUrls: ['./labeled-list-item.component.scss']
})
export class LabeledListItemComponent {
  @Input() label: string | undefined;
  @Input() text: string | number | undefined;
  @Input() url: string | undefined;
  // Status Pill
  @Input() statusPill: StatusPill | undefined;
  // User Profile Badge
  @Input() initials: string | undefined;
  @Input() textColor: string | undefined;
  @Input() profileColor: string | undefined;
}
