import { UserFile } from './user-file';

export class AttachmentFile extends UserFile {
  public attachmentId: string;
  public presignedUrl: string;

  constructor(file: File, attachmentId: string, fileUrl: string, presignedUrl: string) {
    super(file, fileUrl);
    this.attachmentId = attachmentId;
    this.presignedUrl = presignedUrl;
  }

  public getFileType(): string {
    return this.file.type;
  }
}
