import { Deserializable } from '../../protocols/deserializable';
import { SubmissionPermissionTypeEnum } from '../enum/submission-permission-type.enum';
import { SubmissionTypeEnum } from '../../account/enum/submission-type-enum';

export class SubmissionPermissionWithType implements Deserializable {
  public id!: number;
  public name!: string;
  public permissionTypeId!: SubmissionPermissionTypeEnum;
  public memberSubmissionTypeId!: SubmissionTypeEnum;
  // Not from api
  public selected!: boolean;

  onDeserialize() {}
}
