<label
  class="custom-checkbox-container"
  [ngClass]="{ 'inline-checkbox': inline, disabled: disabled }"
  [ngStyle]="{ margin: customCheckboxMargin ? customCheckboxMargin : null }"
  for="{{ cid }}">
  <input
    type="checkbox"
    class="custom-checkbox"
    id="{{ cid }}"
    name="custom-cb"
    [checked]="checked"
    [disabled]="disabled"
    (change)="onClick()" />
  <span class="custom-checkbox-label">
    <ng-content *ngIf="!label"></ng-content>
    <ng-container *ngIf="label">{{ label }}</ng-container>
  </span>
  <span [class]="selectAll && partiallySelected ? 'custom-partial-checkmark' : 'custom-checkmark'"></span>
</label>
