import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CacheService } from '../../../../services/cache-service';
import { ToastService } from '../../../../services/toast-service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnChanges {
  private logoClickCount: number = 0;
  @Input() public logoImage: string = '';
  @Input() public height: string = '2.875rem';
  @Input() public width: string = '11.75rem';

  private _logoImage: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public readonly logoImage$ = this._logoImage.asObservable();

  constructor(private cacheService: CacheService, private toastService: ToastService) {}

  public logoClicked() {
    // If the user clicks the logo 7 times quickly, it will reset the caches
    this.logoClickCount++;
    if (this.logoClickCount > 6) {
      this.cacheService.clearAllCaches();
      this.logoClickCount = 0;
      this.toastService.publishInfoMessage('Caches cleared', 'All caches have been cleared.');
    }
    setTimeout(() => {
      this.logoClickCount = 0;
    }, 5000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.logoImage) {
      this._logoImage.next(changes.logoImage.currentValue);
    }
  }
}
