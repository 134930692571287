import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { Viewable } from '@csspension/base-angular';
import { NavbarViewModel } from './navbar-view-model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NavbarViewModel]
})
export class NavbarComponent extends BaseComponent implements OnInit, Viewable {
  private viewModel = inject(NavbarViewModel);

  public isLoading$ = this.viewModel.isLoading$;
  public loadingOpts$ = this.viewModel.loadingOpts$;
  public isMobile$ = this.viewModel.isMobile$;

  setupViews() {}

  setupBindings() {}
}
