import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { LoadingOptions } from '../models/shared/loading-options';
import { filter, take } from 'rxjs/operators';

declare module 'rxjs/internal/BehaviorSubject' {
  interface BehaviorSubject<T> {
    bind(obvs$: Observable<any>): Subscription;
    deepCopy(): Observable<T>;
    consumeFlag(consume: (n: any) => void): Subscription;
    addRequest(msg: string): void;
    removeRequest(msg: string): void;
    containsRequest(msg: string): boolean;
  }
}

BehaviorSubject.prototype.bind = function (obvs$: Observable<any>): Subscription {
  return obvs$.subscribe(n => {
    this.next(n);
  });
};

BehaviorSubject.prototype.deepCopy = function <T>(): Observable<T> {
  return this.asObservable().deepCopy();
};

BehaviorSubject.prototype.consumeFlag = function (consume: (n: any) => void): Subscription {
  return this.subscribe(n => {
    if (n) {
      consume(n);
      this.next(null);
    }
  });
};

BehaviorSubject.prototype.addRequest = function (msg: string) {
  this.pipe(
    take(1),
    filter(val => val instanceof LoadingOptions)
  ).subscribe(val => {
    val.addRequest(msg, this);
  });
};

BehaviorSubject.prototype.removeRequest = function (msg: string) {
  this.pipe(
    take(1),
    filter(val => val instanceof LoadingOptions)
  ).subscribe(val => {
    val.removeRequest(msg, this);
  });
};

BehaviorSubject.prototype.containsRequest = function (msg: string) {
  let result = false;
  this.pipe(
    take(1),
    filter(val => val instanceof LoadingOptions)
  ).subscribe(val => {
    result = val.containsRequest(msg);
  });
  return result;
};
