import { Component } from '@angular/core';
import { BaseBannerComponent } from '../base-banner.component';

/**
 * Create by: Kevin Baker
 * Date: October 22, 2021
 */

@Component({
  selector: 'app-info-banner',
  templateUrl: './info-banner.component.html',
  styleUrls: ['./info-banner.component.scss']
})
export class InfoBannerComponent extends BaseBannerComponent {
  constructor() {
    super();
  }
}
