import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appMinDesignatedUnitPercentageValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinDesignatedUnitPercentageValidatorDirective, multi: true }]
})
export class MinDesignatedUnitPercentageValidatorDirective {
  static key: string = 'minUnitPercentage';

  constructor() {}

  getErrorMsg(value: string | number): string | null {
    if (!!value && typeof value === 'string') {
      const percentAsNumber = +value?.replace('%', '');
      if (percentAsNumber < 0.1) return 'Minimum percentage is 0.1%';
    } else if (!!value && typeof value === 'number') {
      if (value < 0.1) return 'Minimum percentage is 0.1%';
    }
    return null;
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    const errorMsg = this.getErrorMsg(control.value);
    if (errorMsg) return { [MinDesignatedUnitPercentageValidatorDirective.key]: errorMsg };
    return null;
  }
}
