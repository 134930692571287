import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { CustomFile } from '../../../../models/shared/custom-file';
import { Subject } from 'rxjs';
import { UploadImageInterface } from './upload-image-interface';
import { ToastService } from '../../../../services/toast-service';
import { UploadItemInterface } from './upload-item/upload-item-interface';
import { MediaType } from '../../../../models/enum/dto/media-type.enum';
import { MediaUtils } from '../../../../utils/media-utils';
import { debounceTime } from 'rxjs/operators';

@Injectable()
export class UploadAssetViewModel extends BaseViewModel implements UploadItemInterface {
  static MAX_FILE_SIZE = 10485760; // 10 MB

  public id!: number;
  public resetInputState: Subject<boolean> = new Subject<boolean>();
  public files: CustomFile[] = [];
  public accept!: string;
  public parentHandler!: UploadImageInterface;
  public maxAssets: number = -1;
  public changeDetect = new Subject<boolean>();

  private uploadDebouncer: Subject<boolean> = new Subject<boolean>();

  constructor(private toastService: ToastService) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.uploadDebouncer.pipe(debounceTime(50)).subscribe(_ => {
      this.uploadFiles();
    });
  }

  initMaxAssets(ma: number) {
    this.maxAssets = ma;
  }

  initParentHandler(h: UploadImageInterface) {
    if (h) {
      this.parentHandler = h;
    }
  }

  initAcceptType(image: boolean = true, video: boolean = true) {
    let s = '';
    if (video && image) {
      s = s.concat('video/*|asset/*');
    } else if (video) {
      s = s.concat('video/*');
    } else {
      s = s.concat('asset/*');
    }
    this.accept = s;
  }

  fileBrowseHandler(target: EventTarget) {
    const element = target as HTMLInputElement;
    if (element.files) {
      this.handleUploadedFiles(element.files);
    }
  }

  handleUploadedFiles(uploadedFiles: FileList) {
    Array.from(uploadedFiles).forEach(file => {
      // ensure the content type is asset or video
      if (!this.properFileType(file)) {
        this.toastService.publishErrorMessage('File Error', 'Unsupported file type.');
        console.warn('Only images and videos are supported.');
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = event => {
        const f = new CustomFile();
        f.name = file.name.toLowerCase();
        f.type = file.type;
        f.size = file.size;
        if (event.target) {
          f.url = event.target.result;
        }
        this.prepareFilesList(new Array(f));
      };
    });
  }

  properFileType(f: File): boolean {
    const mimeType = f.type;
    if (mimeType.match(/image\/*/) !== null || mimeType.match(/video\/*/) !== null) {
      if (this.getMediaType(f) !== null) {
        return true;
      }
    }
    return false;
  }

  getMediaType(f: File): MediaType {
    return MediaUtils.getMediaType(f.name);
  }

  prepareFilesList(files: CustomFile[]) {
    const indexesToUpload = [];
    for (const file of files) {
      if (this.isFileTooLarge(file)) {
        this.toastService.publishErrorMessage('File Size Error', 'File size is too large.');
      } else if (!file.success && !file.failed) {
        // if no success or failure, the file can be processed
        file.progress = 0;
        if (this.maxAssets <= 0) {
          this.files.push(file);
        } else {
          if (files.length >= this.maxAssets) {
            this.files.shift();
          }
          this.files.push(file);
        }
        indexesToUpload.push(this.files.indexOf(file));
      }
    }
    this.resetInputState.next(true);
    if (indexesToUpload.length > 0) {
      indexesToUpload.forEach(i => {
        this.files[i].progress = 100;
        this.files[i].failed = false;
        this.files[i].success = true;
      });
      this.uploadDebouncer.next(true);
    }
  }

  uploadFiles() {
    if (this.parentHandler) {
      // Show as successful because we will handle the upload logic elsewhere
      this.parentHandler.fileList(this.files, this.id);
      return;
    } else {
      console.error('Add parent handler for upload asset component.');
    }
  }

  imagePreview(index: number) {
    if (this.files[index].url && this.files[index].hasPreview) {
      return this.files[index].url;
    } else {
      switch (this.files[index].type) {
        case 'asset/jpg':
          return '../../../../assets/img/icons/JPG.svg';
        case 'asset/jpeg':
          return '../../../../assets/img/icons/JPG.svg';
        case 'asset/png':
          return '../../../../assets/img/icons/PNG.svg';
        case 'application/pdf':
          return '../../../../assets/img/icons/PDF.svg';
        case 'application/msword':
          return '../../../../assets/img/icons/manage.svg';
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          return '../../../../assets/img/icons/manage.svg';
      }
    }
  }

  getPlaceholderImagePadding(index: number) {
    if (this.files[index].url && this.files[index].hasPreview) {
      return '0';
    } else {
      return '0.625rem';
    }
  }

  isFileTooLarge(f: CustomFile): boolean {
    return f.size > UploadAssetViewModel.MAX_FILE_SIZE;
  }

  removeMe(f: CustomFile) {
    const i = this.files?.indexOf(f);
    if (i >= 0 && this.files.length > i) {
      this.files.splice(i, 1);
    }
    this.parentHandler.fileList(this.files, this.id);
  }

  clear() {
    this.files = [];
    this.parentHandler.fileList(this.files, this.id);
  }
}
