import { Deserializable } from '../protocols/deserializable';
import { TransactionStatus } from './transaction-status';
import { TransactionEntry } from './transaction-entry';
import { TransactionStatusPill } from '../shared/transaction-status-pill';
import { TransactionComment } from './transaction-comment';

export class Transaction implements Deserializable {
  public id!: number;
  public typeId!: number;
  public typeName!: string;
  public statusId!: number;
  public status!: TransactionStatus;
  public createdDate!: Date;
  public createdById!: number;
  public createdByName!: string;
  public createdByUserType!: string;
  public postedDate!: Date;
  public comments!: TransactionComment[];
  public entries!: TransactionEntry[];

  // Not From API
  public statusPill!: TransactionStatusPill;

  onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    if (!!this.status) {
      this.status = Deserialize?.instanceOf(TransactionStatus, this.status);
      this.statusPill = new TransactionStatusPill(this.statusId, this.status.name);
    }
    this.postedDate = new Date(this.postedDate);
    this.createdDate = new Date(this.createdDate);
    if (!!this.comments) {
      this.comments = Deserialize?.arrayOf(TransactionComment, this.comments);
    }
    if (!!this.entries) {
      this.entries = Deserialize?.arrayOf(TransactionEntry, this.entries);
    }
  }
}
