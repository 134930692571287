import { AfterViewInit, Component, Input, Type, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Orderable } from '../../../../models/protocols/orderable';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { Observable, throwError } from 'rxjs';
import { ToastService } from '../../../../services/toast-service';
import { finalize, take } from 'rxjs/operators';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { ReorderOptions } from '../../../../models/shared/reorder-options';
import { CustomError } from '../../../../models/shared/custom-error';

@Component({
  selector: 'app-reorder-modal',
  templateUrl: './reorder-modal.component.html',
  styleUrls: ['./reorder-modal.component.scss']
})
export class ReorderModalComponent extends BaseModalComponent implements AfterViewInit {
  public loadingOpts: LoadingOptions = LoadingOptions.default();
  public reorderOptions!: ReorderOptions;
  public items: Orderable[] = [];
  public reorderOperation!: (items: Orderable[]) => Observable<any>;
  private initialOrderIds: string[] = [];

  @Input() ignoreEscape: boolean = false;

  @ViewChild('modalBody') modalBody!: HTMLDivElement;

  constructor(protected activeModal: NgbActiveModal, private toastService: ToastService) {
    super(activeModal);
  }

  setReorderOptions(opts: ReorderOptions) {
    this.reorderOptions = opts;
  }

  setReorderItems<T extends Orderable>(items: Orderable[]) {
    // Create new instance of objects as to not affect the original memory address
    this.items = items?.map(i => {
      const type = i?.constructor as Type<T>;
      return window?.injector?.Deserialize?.instanceOf(type, i);
    });
    this.initialOrderIds = items?.map(i => i.getOrderableUniqueId());
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.modalBody.scrollTop = this.modalBody.scrollHeight;
  }

  override cancel() {
    this.activeModal.close(false);
  }

  continue() {
    const lm = this.reorderOptions.loadingMess;
    if (!this.loadingOpts.containsRequest(lm)) {
      this.loadingOpts.addRequest(lm);
      this.reorderOperation(this.items)
        .pipe(
          take(1),
          finalize(() => this.loadingOpts.removeRequest(lm))
        )
        .subscribe({
          next: success => {
            if (success) {
              this.toastService.publishSuccessMessage(
                this.reorderOptions.successTitle,
                this.reorderOptions.successMess
              );
              this.activeModal.close(this.items);
            } else {
              this.toastService.publishErrorMessage(this.reorderOptions.failureMess, this.reorderOptions.failureTitle);
            }
          },
          error: (error: CustomError) => {
            this.toastService.publishError(error);
            return throwError(() => error);
          }
        });
    }
  }

  setupBindings() {}

  setupViews() {}

  orderHasChanged(): boolean {
    return !this.initialOrderIds.equals(this.items.map(i => i.getOrderableUniqueId()));
  }
}
