import { Subject } from 'rxjs';
import { LoadingSpinnerSize } from '../enum/shared/loading-spinner-size.enum';
import { Deserializable } from '../protocols/deserializable';
import { ButtonLoadingOpts } from '@csspension/reactive-buttons/lib/interfaces/button-loading-opts';

export class LoadingOptions implements Deserializable, ButtonLoadingOpts {
  public isLoading: boolean = false;
  public fullscreen: boolean = false;
  public determinateLoading: boolean = false;
  public showLoadingText: boolean = false;
  public progress = 0;
  public loadingText: string | null = null;
  public backgroundColor: string | null = null;
  public spinnerColor: string | null = null;
  public color: string | null = null;
  public topMarginRem: number = 0;
  public cornerRadiusRem: number = 0;
  public spinnerSize: LoadingSpinnerSize = LoadingSpinnerSize.Default;
  public zIndex = 99;
  public awaitingRequests: string[] = [];

  static default(determinate: boolean = false, fullscreen: boolean = false): LoadingOptions {
    const opt = new LoadingOptions();
    opt.backgroundColor = 'rgba(247,247,247,0.85)';
    opt.spinnerColor = '#13856b';
    opt.color = '#13856b';
    opt.determinateLoading = determinate;
    opt.showLoadingText = true;
    opt.fullscreen = fullscreen;
    return opt;
  }

  static defaultNoSpinner(): LoadingOptions {
    const opts = LoadingOptions.default();
    opts.spinnerColor = 'transparent';
    return opts;
  }

  static defaultInModal(determinate: boolean = false, fullscreen: boolean = false): LoadingOptions {
    const opt = new LoadingOptions();
    opt.backgroundColor = 'rgba(247,247,247,0.85)';
    opt.spinnerColor = '#13856b';
    opt.color = '#13856b';
    opt.determinateLoading = determinate;
    opt.showLoadingText = true;
    opt.fullscreen = fullscreen;
    opt.cornerRadiusRem = 0.625;
    return opt;
  }

  static getAssetLoadingOpts(): LoadingOptions {
    const opt = LoadingOptions.default();
    opt.backgroundColor = '#FFF';
    opt.zIndex = 50;
    return opt;
  }

  static defaultWhiteBackground(determinate: boolean = false, fullscreen: boolean = false): LoadingOptions {
    const loadingOpts = LoadingOptions.default(determinate, fullscreen);
    loadingOpts.backgroundColor = 'white';
    return loadingOpts;
  }

  static defaultOpaqueWhiteBackground(
    opacity: number = 0.5,
    determinate: boolean = false,
    fullscreen: boolean = false
  ): LoadingOptions {
    const loadingOpts = LoadingOptions.default(determinate, fullscreen);
    loadingOpts.backgroundColor = `rgba(255, 255, 255, ${opacity})`;
    return loadingOpts;
  }

  static defaultInButton(): LoadingOptions {
    const opt = new LoadingOptions();
    opt.backgroundColor = 'transparent';
    opt.spinnerColor = '#FFF';
    opt.color = '#FFF';
    opt.spinnerSize = LoadingSpinnerSize.Small;
    return opt;
  }

  static defaultInBadge(): LoadingOptions {
    const opt = new LoadingOptions();
    opt.backgroundColor = 'transparent';
    opt.spinnerColor = '#13856b';
    opt.color = '#FFF';
    opt.spinnerSize = LoadingSpinnerSize.Medium;
    return opt;
  }

  static defaultInTable(): LoadingOptions {
    const opt = new LoadingOptions();
    opt.backgroundColor = 'transparent';
    opt.spinnerColor = '#13856b';
    opt.color = '#13856b';
    opt.spinnerSize = LoadingSpinnerSize.Small;
    return opt;
  }

  onDeserialize() {}

  containsRequest(mess: string): boolean {
    const existingIndex = this.awaitingRequests?.indexOf(mess);
    return existingIndex > -1;
  }

  addRequest(mess: string, subject?: Subject<LoadingOptions>) {
    const currReqs = this.awaitingRequests?.shallowCopy() || [];
    const existingIndex = currReqs.indexOf(mess);
    if (existingIndex === -1) {
      currReqs.push(mess);
      if (subject) {
        const deepCopy = window?.injector?.Deserialize?.instanceOf(LoadingOptions, this);
        if (!!deepCopy) {
          deepCopy.setAwaitingRequests(currReqs);
          subject.next(deepCopy);
        }
      } else {
        this.setAwaitingRequests(currReqs);
      }
    }
  }

  removeRequest(mess: string, subject?: Subject<LoadingOptions>) {
    const currReqs = this.awaitingRequests?.shallowCopy() || [];
    const removeIndex = currReqs.indexOf(mess);
    if (removeIndex > -1) {
      currReqs.splice(removeIndex, 1);
      if (subject) {
        const deepCopy = window?.injector?.Deserialize?.instanceOf(LoadingOptions, this);
        if (!!deepCopy) {
          deepCopy.setAwaitingRequests(currReqs);
          subject.next(deepCopy);
        }
      } else {
        this.setAwaitingRequests(currReqs);
      }
    }
  }

  private setAwaitingRequests(reqs: string[]): void {
    this.awaitingRequests = reqs;
    this.isLoading = this.awaitingRequests?.length > 0;
    this.loadingText = this.awaitingRequests?.length ? this.awaitingRequests[this.awaitingRequests.length - 1] : '';
  }

  manuallyUpdateRequest(reqs: string[]) {
    this.setAwaitingRequests(reqs);
  }

  combineWith(other: LoadingOptions): LoadingOptions | undefined {
    const copy = window?.injector?.Deserialize?.instanceOf(LoadingOptions, this);
    if (copy) {
      copy.manuallyUpdateRequest([...(this.awaitingRequests || []), ...(other.awaitingRequests || [])]);
    }
    return copy;
  }
}
