import { Deserializable } from '../protocols/deserializable';
import { AccountType } from './account-type';
import { LastContribution } from './last-contribution';

export class Account implements Deserializable {
  public id!: number;
  public typeId!: number;
  public type!: AccountType;
  public memberId!: number;
  public member!: string;
  public employerId!: number;
  public employer!: string;
  public financialBalance!: number;
  public active!: boolean;
  public comment!: string;
  public createdDate!: Date;
  public lastContribution: LastContribution | null = null;

  public onDeserialize(): void {
    const Deserialize = window?.injector?.Deserialize;
    if (this.type) {
      this.type = Deserialize?.instanceOf(AccountType, this.type);
    }
    if (this.createdDate) {
      this.createdDate = new Date(this.createdDate);
    }
    if (this.lastContribution) {
      this.lastContribution = Deserialize?.instanceOf(LastContribution, this.lastContribution);
    }
  }
}
