import { Pipe, PipeTransform } from '@angular/core';
import { NavItem } from '../models/nav-item';
import { DefaultNavItem } from '../models/default-nav-item';

@Pipe({
  name: 'isDefaultNavItem'
})
export class IsDefaultNavItemPipe implements PipeTransform {
  transform(value: NavItem): boolean {
    return value instanceof DefaultNavItem;
  }
}
