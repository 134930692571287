import { BaseComment } from '../../base/base-comment';
import { CommentMention } from '../../base/comment-mention';
import { InternalUser } from '../../users/dto/internal-user';

export class EmployerPlanComment extends BaseComment {
  public employerPlanId!: string;
  public mentions: CommentMention[] = [];

  onDeserialize() {
    super.onDeserialize();
    if (!!this.author) {
      this.author = window?.injector?.Deserialize?.instanceOf(InternalUser, this.author);
    }
  }
}
