<div class="d-flex flex-column gap-2">
  <div class="label">{{ label }}</div>
  <div *ngIf="isLoading; else content">
    <lib-loading-shimmer></lib-loading-shimmer>
  </div>
  <ng-template #content>
    <a *ngIf="url; else textValue" class="value link" [routerLink]="url">{{ value }}</a>
    <ng-template #textValue>
      <div class="value">{{ value }}</div>
    </ng-template>
  </ng-template>
</div>
