<lib-reactive-form-group [atLeastNItemsFilledIn]="1" [bindTo]="viewModel.roleMap$ | async" [mergeKey]="mergeKey">
  <ng-container
    *ngFor="
      let dropdown of (viewModel.roleDropDownFormItems$ | async) ?? undefined;
      let i = index;
      let last = last;
      let first = first;
      trackBy: dropdownFormItemTrackBy
    ">
    <div [style.display]="inlineLabels ? 'flex' : 'block'" class="role-row">
      <div class="role-dropdown">
        <lib-reactive-form-drop-down
          #dropdownItem
          [inputName]="'role' + i"
          label="Role"
          i18n-label
          [inlineLabel]="false"
          [required]="true"
          [disabled]="
            ((first || !last) && i + 1 !== (viewModel.roleDropDownFormItems$ | async)?.length) ||
            !(viewModel.canEditUserDetails$ | async)
          "
          [reinitializeWhenListChanges]="false"
          placeholder="Choose a Role"
          i18n-placeholder
          [dropdowns]="(viewModel.roleDropdownOptions$ | async) ?? undefined"
          [bindingProperty]="'roleMap'"
          (valueChanged)="viewModel.roleSelected($event, i)"
          [bindingPropertyMapKey]="i">
        </lib-reactive-form-drop-down>
      </div>
      <div *ngIf="!first" class="remove-role-button">
        <button
          [disabled]="!(viewModel.canEditUserDetails$ | async) || (first && last) || !last"
          [ngClass]="{
            'disable-button': !(viewModel.canEditUserDetails$ | async) || (first && last) || !last
          }"
          (click)="viewModel.removeRole(i)"
          class="destructive-paddingless-text-button">
          <ng-container i18n>Remove Role</ng-container>
        </button>
      </div>
      <hr *ngIf="!inlineLabels" />
    </div>
    <div *ngIf="last && !(viewModel.hideAddRoleButton$ | async)" class="additional-button">
      <lib-button-inline-text
        [style.margin.rem]="0"
        [disabled]="!dropdownItem.getMyValue() || !(viewModel.canEditUserDetails$ | async)"
        (buttonClicked)="viewModel.addAdditionalRole(i)">
        <ng-container i18n>Add Additional Role</ng-container>
      </lib-button-inline-text>
    </div>
  </ng-container>
  <hr *ngIf="inlineLabels" />
</lib-reactive-form-group>
