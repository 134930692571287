import { Deserializable } from '../protocols/deserializable';

export class SubmissionHistoryItem implements Deserializable {
  public id!: string;
  public memberSubmissionId!: string;
  public event!: string;
  public eventDate!: Date;

  onDeserialize() {
    if (!!this.eventDate) {
      this.eventDate = new Date(this.eventDate);
    }
  }
}
