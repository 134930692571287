import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { SafeResourceUrl } from '@angular/platform-browser';
import { BaseComponent } from '../../../../models/base/base-component';
import { MediaUtils } from '../../../../utils/media-utils';
import { AssetPreviewOptions } from '../../../../models/shared/stylesheet/asset-preview-options';
import { AssetComponent } from '../asset/asset.component';
import { ScreenService } from '../../../../services/screen-service.service';
import { map } from 'rxjs/operators';
import { Asset } from '../../../../models/asset/dto/asset';
import { AssetSize } from '../../../../models/enum/dto/asset-size.enum';

@Component({
  selector: 'app-asset-preview',
  templateUrl: './asset-preview.component.html',
  styleUrls: ['./asset-preview.component.scss']
})
export class AssetPreviewComponent extends BaseComponent implements OnInit, OnChanges {
  @ViewChild('appAsset') assetPointer!: AssetComponent;
  @Input() templateMode: boolean = false;
  @Input() asset!: Asset;
  @Input() options: AssetPreviewOptions = new AssetPreviewOptions();
  @Output() primaryButtonPressed = new EventEmitter();
  @Input() isDisabledByTemplates: boolean = false;
  @Output() secondaryButtonPressed = new EventEmitter();
  @Output() tertiaryButtonPressed = new EventEmitter();
  @Output() refreshButtonPressed = new EventEmitter<string>();

  private _assetWidth = new BehaviorSubject<number>(0);
  public assetWidth$ = this._assetWidth.asObservable();
  public assetSrc: ReplaySubject<string | SafeResourceUrl> = new ReplaySubject<string | SafeResourceUrl>(1);

  public screenWidth$ = this.screenService.width$;
  public screenLessThan1200px$ = this.screenWidth$.pipe(map(it => it < 1200));

  constructor(private screenService: ScreenService) {
    super();
  }

  ngOnInit(): void {
    this.setupBindings();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setupBindings();
  }

  setupViews() {}

  setupBindings() {
    if (this.asset) {
      const size = MediaUtils.isVideo(this.asset?.mediaType) ? AssetSize.Original : this.options.previewSize;
      const imgSrcSub = this.assetSrc.bind(this.asset?.getAssetUrl(size)?.srcUrl);
      if (imgSrcSub) {
        this.pushSub(imgSrcSub);
      }
    }
    const assetWidthSub = this.assetPointer?.ratio.subscribe(ratio => {
      this._assetWidth.next(this.assetPointer?.el?.nativeElement?.clientHeight * ratio);
    });
    this.pushSub(assetWidthSub);
  }

  isImage(): boolean {
    return MediaUtils.isImage(this.asset?.mediaType);
  }
}
