import { Deserializable } from '../../protocols/deserializable';

export class FailRemittanceRequest implements Deserializable {
  public id: number;
  public failureReason: string;

  constructor(id: number, failureReason: string) {
    this.id = id;
    this.failureReason = failureReason;
  }

  public onDeserialize() {}
}
