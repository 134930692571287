<form class="search-bar-container" (reset)="emitEmpty()">
  <label for="searchBar" hidden>Search Label</label>
  <input
    #searchText
    (input)="searchChanged($event)"
    class="text-field search-text-field"
    id="searchBar"
    name="searchBar"
    placeholder="{{ placeHolder }}"
    autocomplete="off"
    type="text" />
  <button #clearButton class="clear-search" type="reset">CLEAR</button>
</form>
<div
  popper
  [popper]="popperContent"
  [popperShowOnStart]="false"
  [popperTrigger]="'none'"
  [popperModifiers]="viewModel.popperModifier"
  [popperHideOnClickOutside]="false"
  [popperHideOnScroll]="false"
  [popperPreventOverflow]="false"
  [popperPositionFixed]="true"
  [popperApplyClass]="'look-ahead-popper'"
  [popperStyles]="viewModel.popperStyles"
  [popperPlacement]="'bottom-start'"></div>
<popper-content #popperContent [style.z-index]="1500">
  <app-look-ahead-list
    [lookAheadItems]="viewModel.fuzzyHits$ | async"
    [lookAheadProperties]="searchTheseProperties"
    (lookAheadItemClicked)="lookAheadSelected.emit($event); clearButton.click()">
  </app-look-ahead-list>
</popper-content>
