import { Type } from '@angular/core';
import { Subject } from 'rxjs';
import { UniquelyIdentifiable } from '../../../../models/protocols/uniquely-identifiable';
import { Tabbable } from '../../../../models/protocols/tabbable';

export class TabBarItem implements UniquelyIdentifiable {
  public active: boolean = false;
  public title: string;
  public iconSrc: string;
  public componentType: Type<Tabbable>;
  public activated = new Subject<boolean>();
  public disabled: boolean = false;
  public disabledTooltip: string = '';

  constructor(
    componentType: Type<Tabbable>,
    title: string,
    iconSrc: string,
    active = false,
    disabled?: boolean,
    disabledTooltip?: string
  ) {
    this.title = title;
    this.iconSrc = iconSrc;
    this.componentType = componentType;
    this.active = active;
    this.disabled = disabled ?? false;
    this.disabledTooltip = disabledTooltip ?? '';
  }

  getUniqueIdentifier(): string {
    return `
      ${this.active}-
      ${this.title}-
      ${this.iconSrc}-
      ${this.componentType.name}-
      ${this.disabled}-
      ${this.disabledTooltip}
    `;
  }

  static setSelectedTab(tabs: TabBarItem[], currentTabId: number) {
    if (!!tabs && tabs?.length >= currentTabId) {
      tabs[currentTabId].active = true;
    }
  }
}
