import { Pipe, PipeTransform } from '@angular/core';
import { Alert } from '../../../../../models/alerts/dto/alert';
import { AlertTypeEnum } from '../../../../../models/alerts/enum/alert-type.enum';

@Pipe({
  name: 'isCardTypeAlert'
})
export class IsCardTypeAlertPipe implements PipeTransform {
  transform(alert: Alert | null): boolean {
    return alert?.typeId === AlertTypeEnum.Card;
  }
}
