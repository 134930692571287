import { Component, inject } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { InternalUserAuthViewModel } from '../../../../viewModels/internal-user-auth-view-model.service';
import { AuthCodeRequest } from '../../../../../../models/users/requests/auth-code-request';
import { InternalUser } from '../../../../../../models/users/dto/internal-user';
import { SessionService } from '../../../../../../services/session-service';
import { CacheService } from '../../../../../../services/cache-service';
import { NumberUtils } from '../../../../../../utils/number-utils';

@Component({
  selector: 'app-internal-user-verify-code',
  templateUrl: './internal-user-verify-code.component.html',
  styleUrls: ['./internal-user-verify-code.component.scss', '../../auth.component.scss']
})
export class InternalUserVerifyCodeComponent extends BaseComponent {
  viewModel = inject(InternalUserAuthViewModel);

  constructor(private sessionService: SessionService, private cacheService: CacheService) {
    super();
  }

  req = new AuthCodeRequest('', '', null, '');

  formSubmitted(req: AuthCodeRequest) {
    this.viewModel.verifyMfaCode(
      {
        ...req,
        timeBasedOneTimePassword: NumberUtils.formatNumberWithLeadingZeros(+req.timeBasedOneTimePassword, 6)
      },
      false
    );
  }

  setupBindings(): void {
    this.sessionService.sessionContainer$.notNull().once(session => {
      if (!session) session = this.viewModel.getCachedSession();
      const user = session?.user as InternalUser;
      this.req.challengeName = user.challengeName ?? null;
      this.req.sessionId = user.sessionId ?? '';
      this.req.email = user.email ?? '';
    });
  }

  setupViews(): void {}
}
