import { Component } from '@angular/core';
import { BaseAlertComponent } from '../base-alert.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-card-alert',
  templateUrl: './card-alert.component.html',
  styleUrls: ['./card-alert.component.scss']
})
export class CardAlertComponent extends BaseAlertComponent {
  public buttonText$ = this.alert$.pipe(map(a => a?.buttonText));
  setupBindings(): void {}

  setupViews(): void {}

  public handleClick(): void {
    this.alert$.once(a => {
      const buttonLink = a?.buttonLink;
      if (!!buttonLink) {
        let modifiedLink = buttonLink;
        if (!/^(http|https):\/\//.test(buttonLink)) {
          modifiedLink = 'https://' + buttonLink;
        }
        window.open(modifiedLink, '_blank');
      }
    });
  }
}
