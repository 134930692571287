import { Component, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { MemberUser } from '../../../../../models/account/dto/member-user';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { ReactiveFormFileUploaderComponent, ReactiveFormGroupComponent } from '@csspension/reactive-form';
import { BaseComponent } from '../../../../../models/base/base-component';
import { ScreenService } from '../../../../../services/screen-service.service';
import { MediaUtils } from '../../../../../utils/media-utils';
import { SubmissionRequest } from '../../../../../models/account/requests/submissionRequests/submission-request';
import { AbstractSubmissionFormViewModel } from './abstract-submission-form-view-model';

@Component({ template: '' })
export abstract class AbstractSubmissionFormComponent<T extends SubmissionRequest> extends BaseComponent {
  @Input() user!: MemberUser;
  @Input() acceptedFileTypes: string[] = ['image/png', 'image/jpeg', 'application/pdf'];
  @Input() chooseAnotherLabel: string = $localize`Choose a Different File`;
  @Input() allowMultipleFiles: boolean = false;
  @Input() maxFileSizeInMb: number = 3;
  @Input() maxTotalFileSizeInMb: number = 0;
  @Input() maxFiles: number = 5;
  @Input() filesRequired: boolean = false;

  @Output() loadingOpts = new EventEmitter<LoadingOptions>();

  @ViewChild('form') protected form!: ReactiveFormGroupComponent;
  @ViewChild('fileUploader') protected fileUploader: ReactiveFormFileUploaderComponent | undefined;

  protected abstract viewModel: AbstractSubmissionFormViewModel<T>;

  protected screenService = inject(ScreenService);

  public isMobile$ = this.screenService.isMobile$;

  protected processFilesAndSubmit(req: T) {
    if (this.filesRequired && this.fileUploader) {
      this.fileUploader.selectedFiles$.once(files => {
        const promises = files.map(file => MediaUtils.fileToUserFile(file));
        Promise.all(promises).then(userFiles => {
          this.viewModel.submitRequest(req, userFiles);
        });
      });
    } else {
      this.viewModel.submitRequest(req);
    }
  }

  public openFileInNewTab(file: File): void {
    MediaUtils.openFileInNewTab(file);
  }

  public submitForm(): void {
    this.form.submitForm();
  }

  public duplicateFilesAdded(): void {
    this.viewModel.duplicateFilesAdded();
  }

  public setupBindings(): void {
    this.viewModel.loadingOpts$.subscribeWhileAlive({
      owner: this,
      next: opts => {
        this.loadingOpts.emit(opts);
      }
    });
  }

  public setupViews(): void {}
}
