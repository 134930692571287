import { Deserializable } from '../protocols/deserializable';
import { AccountType } from './account-type';
import { GoverningJurisdictionType } from '../employers/employer-history/types/governing-jurisdiction-type';
import { ODataResponse } from '../protocols/odata-response';

export class MemberAccount implements Deserializable {
  public id!: number;
  public typeId!: number;
  public type!: AccountType;
  public memberId!: number;
  public employerId!: number;
  public employer!: string;
  public active!: boolean;
  public createdDate!: Date;
  public memberLastContributionAmount!: number;
  public memberLastContributionDate!: Date;
  public governingJurisdictionId!: number;
  public governingJurisdiction!: GoverningJurisdictionType;
  public totalBalance!: number;

  public onDeserialize(): void {
    const Deserialize = window?.injector?.Deserialize;
    if (this.type) {
      this.type = Deserialize?.instanceOf(AccountType, this.type);
    }

    if (this.governingJurisdiction) {
      this.governingJurisdiction = Deserialize?.instanceOf(GoverningJurisdictionType, this.governingJurisdiction);
    }

    if (this.createdDate) {
      this.createdDate = new Date(this.createdDate);
    }

    if (this.memberLastContributionDate) {
      this.memberLastContributionDate = new Date(this.memberLastContributionDate);
    }
  }

  static generateFakes(): ODataResponse<MemberAccount> {
    const accountJson1 = `{
      "id": 173948302,
      "typeId": 1,
      "type": {
         "id": 1,
         "name": "Defined Contribution Account"
      },
      "memberId": 1,
      "employerId": 1,
      "employer": "Affinity Credit Union",
      "active": true,
      "createdDate": "2021-08-17T00:00:00Z",
      "memberLastContributionDate": "2024-12-15T00:00:00Z",
      "memberLastContributionAmount": 302.00,
      "governingJurisdictionId": 3,
      "governingJurisdiction": {
      "id": 1,
        "name": "Saskatchewan",
        "shortCode": "SK"
      },
      "totalBalance": 100000
    }`;

    const accountJson2 = `{
      "id": 793948555,
      "typeId": 1,
      "type": {
         "id": 1,
         "name": "Defined Contribution Account"
      },
      "memberId": 1,
      "employerId": 2,
      "employer": "Cornerstone Co-op Account",
      "active": true,
      "createdDate": "2021-08-17T00:00:00Z",
      "memberLastContributionDate": "2024-12-15T00:00:00Z",
      "memberLastContributionAmount": 215.99,
      "governingJurisdictionId": 3,
      "governingJurisdiction": {
      "id": 1,
        "name": "Saskatchewan",
        "shortCode": "SK"
      },
      "totalBalance": 45000
    }`;

    const accountJson3 = `{
      "id": 164845665,
      "typeId": 2,
      "type": {
         "id": 2,
         "name": "Transferred-In Account"
      },
      "memberId": 1,
      "employerId": 2,
      "employer": "ABC Pensions",
      "active": false,
      "createdDate": "2021-08-17T00:00:00Z",
      "memberLastContributionDate": "2024-12-15T00:00:00Z",
      "memberLastContributionAmount": 215.99,
      "governingJurisdictionId": 3,
      "governingJurisdiction": {
      "id": 1,
        "name": "Saskatchewan",
        "shortCode": "SK"
      },
      "totalBalance": 55000
    }`;
    const fakeAccounts = [accountJson1, accountJson2, accountJson3].map(json => {
      return window?.injector?.Deserialize?.instanceOf(MemberAccount, JSON.parse(json));
    });
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '@odata.context': '',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '@odata.count': fakeAccounts.length,
      value: fakeAccounts
    };
  }
}
