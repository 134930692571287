import { inject, Injectable } from '@angular/core';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { ApiClient } from './api-client';
import { InternalUser } from '../models/account/dto/internal-user';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { Endpoints } from './endpoints';
import { ODataResponse } from '../models/protocols/odata-response';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomError } from '../models/shared/custom-error';
import { HttpResponse } from '@angular/common/http';
import { ChangeEmailRequest } from '../models/account/requests/change-email-request';
import { CreateInternalUserRequest } from '../models/account/requests/create-internal-user-request';
import { Role } from '../models/roles/role';

@Injectable({
  providedIn: 'root'
})
export class InternalUsersAPI implements LoggableAPI {
  constructor() {}

  private apiClient = inject(ApiClient);

  public serviceName = 'InternalUsers';

  public getAllInternalUsers(oDataQueryOptions: ODataQueryOptions): Observable<ODataResponse<InternalUser>> {
    const url = Endpoints.getInternalUsers();
    oDataQueryOptions.setExpand('Roles');
    return this.apiClient.getOdata<InternalUser>(url, InternalUser, oDataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getInternalUserById(id: string): Observable<ODataResponse<InternalUser>> {
    const url = Endpoints.getInternalUsers();
    const oDataQueryOptions = new ODataQueryOptions();
    oDataQueryOptions.setFilter(`Id eq ${id}`);
    oDataQueryOptions.setExpand('Roles, SubmissionPermissions');
    return this.apiClient.getOdata<InternalUser>(url, InternalUser, oDataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateInternalUser(internalUser: InternalUser): Observable<InternalUser> {
    const url = Endpoints.updateInternalUser(internalUser?.id.toString(10));
    return this.apiClient.putObj(InternalUser, url, internalUser).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // This endpoint works to both force reset another Internal User's password,
  // and resend the welcome email to an Internal User.
  public forceResetInternalUserPassword(internalUser: InternalUser): Observable<HttpResponse<any>> {
    const url = Endpoints.forceResetInternalUserPassword(internalUser?.id.toString(10));
    return this.apiClient.simpleBodylessPost(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public forceResetInternalUserEmail(req: ChangeEmailRequest, internalUser: InternalUser): Observable<InternalUser> {
    const url = Endpoints.forceResetInternalUserEmail(internalUser?.id.toString(10));
    return this.apiClient.postObj(InternalUser, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createInternalUser(req: CreateInternalUserRequest): Observable<InternalUser> {
    const url = Endpoints.createInternalUser();
    return this.apiClient.postObj(InternalUser, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getInternalUsersRoles(odataQueryOptions: ODataQueryOptions): Observable<ODataResponse<Role>> {
    const url = Endpoints.getInternalRoles();
    const permissionHeaders = { permissions: [5, 11] };
    return this.apiClient.getOdata(url, Role, odataQueryOptions, permissionHeaders).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }
}
