import { Component } from '@angular/core';
import { BannerEventsService } from '../../service/banner-events.service';
import { Event } from '../../models/event';
import { EventType } from '../../models/event-type.enum';
import { Observable } from 'rxjs';

/**
 * Create by: Kevin Baker
 * Date: October 22, 2021
 *
 * This component allows for banner events to be displayed to the screen.
 */
@Component({
  selector: 'app-banner-events',
  templateUrl: './banner-events.component.html',
  styleUrls: ['./banner-events.component.scss'],
  providers: [BannerEventsService]
})
export class BannerEventsComponent {
  constructor(private bannerEvents: BannerEventsService) {
    this.bannerEvents$ = this.bannerEvents.events$;
  }

  public EventType: typeof EventType = EventType;
  public bannerEvents$: Observable<Event[]>;

  eventClicked(event: Event) {
    this.bannerEvents.dismiss(event);
  }
}
