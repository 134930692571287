import { Deserializable } from '../../protocols/deserializable';
import { AlertTypeEnum } from '../enum/alert-type.enum';
import { Alert } from '../dto/alert';
import { DateUtils } from '../../../utils/date-utils';
import { PortalTypeIdEnum } from '../../enum/shared/portal-type-id.enum';

export class CreateAlertRequest implements Deserializable {
  public typeId: AlertTypeEnum = AlertTypeEnum.Card;
  public title!: string;
  public description!: string;
  public buttonText!: string | null;
  public buttonLink!: string | null;
  public colourId: number = 1;
  public startDate!: string;
  public endDate!: string;
  public dismissable!: boolean;
  public portalTypeId: PortalTypeIdEnum = PortalTypeIdEnum.Internal;

  onDeserialize(): void {}

  public static hydrateFromAlert(alert: Alert): CreateAlertRequest {
    const req = new CreateAlertRequest();
    req.typeId = alert.typeId;
    req.title = alert.title;
    req.description = alert.description;
    req.colourId = alert.colourId;
    if (alert.typeId === AlertTypeEnum.Card) {
      req.buttonText = alert.buttonText;
      req.buttonLink = alert.buttonLink;
    }
    if (alert.typeId === AlertTypeEnum.Banner) {
      req.dismissable = alert.dismissable;
    }
    const formattedStartDate = DateUtils.formatAlertTimePickerDateToDateString(alert.startDateString, alert.startTime);
    const formattedEndDate = DateUtils.formatAlertTimePickerDateToDateString(alert.endDateString, alert.endTime);
    req.startDate = formattedStartDate;
    req.endDate = formattedEndDate;
    // TODO: Uncomment when we add targeting
    // req.portalTypeId = alert.portalTypeId;
    return req;
  }
}
