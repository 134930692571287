import { Deserializable } from '../../protocols/deserializable';

export class ErrorResponse implements Deserializable {
  StatusCode: number;
  Error: string;

  constructor(code: number, error: string) {
    this.StatusCode = code;
    this.Error = error;
  }
  onDeserialize(): void {}
}
