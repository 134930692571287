import { MediaType } from '../models/enum/dto/media-type.enum';
import { UserFile } from '../models/account/dto/user-file';

export const ASSET_RETRY_COUNT = 10;
export const ASSET_RETRY_DELAY = 10;
export const DELETE_ASSET_DELAY = 10;

export class MediaUtils {
  static getMediaType(name: string): MediaType {
    const extension = name ? name.split('.').pop()?.toLowerCase() : undefined;
    if (extension) {
      switch (extension) {
        case 'jpg':
          return MediaType.JPG;
        case 'jpeg':
          return MediaType.JPEG;
        case 'png':
          return MediaType.PNG;
        case 'gif':
          return MediaType.GIF;
        case 'mp4':
          return MediaType.MP4;
        case 'webm':
          return MediaType.WEBM;
        case 'ogg':
          return MediaType.OGV;
        case 'avi':
          return MediaType.AVI;
        case 'mpeg':
          return MediaType.MPEG;
      }
    }
    throw new Error(`Unexpected file type: ${name}`);
  }

  static isImage(mediaType: MediaType): boolean {
    const imageTypes: MediaType[] = [MediaType.JPG, MediaType.JPEG, MediaType.PNG, MediaType.GIF];
    return imageTypes.includes(mediaType);
  }

  static isVideo(mediaType: MediaType): boolean {
    const videoTypes: MediaType[] = [MediaType.MP4, MediaType.WEBM, MediaType.OGV, MediaType.AVI, MediaType.MPEG];
    return videoTypes.includes(mediaType);
  }

  static fileToB64(file: File): Promise<string> {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = event => resolve(event.target?.result as string);
      reader.onerror = error => reject(error);
    });
  }

  static fileToUserFile(file: File): Promise<UserFile> {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = event => resolve(new UserFile(file, event.target?.result as string));
      reader.onerror = error => reject(error);
    });
  }

  static openFileInNewTab(file: File): void {
    const fileReader = new FileReader();

    fileReader.onload = (e: any) => {
      const fileContent = e.target.result;
      const blob = new Blob([fileContent], { type: file.type });
      const objectUrl = URL.createObjectURL(blob);

      window.open(objectUrl);
      URL.revokeObjectURL(objectUrl);
    };

    fileReader.readAsArrayBuffer(file);
  }

  static getRefreshAssetLoadingMessage(remainingRetries: number) {
    switch (remainingRetries) {
      case 1:
        return 'Reloading Asset.';
      case 2:
        return 'Reloading Asset.';
      case 3:
        return 'Reloading Asset.';
      case 4:
        return 'Resizing Asset.';
      case 5:
        return 'Resizing Asset.';
      case 6:
        return 'Resizing Asset.';
      case 7:
        return 'Compressing Asset.';
      case 8:
        return 'Compressing Asset.';
      case 9:
        return 'Compressing Asset.';
      case 10:
        return 'Compressing Asset.';
      default:
        return `Loading Asset (${remainingRetries})`;
    }
  }

  static getRefreshMenuMediaAsset(remainingRetries: number) {
    switch (remainingRetries) {
      case 1:
        return 'Reloading Media.';
      case 2:
        return 'Reloading Media.';
      case 3:
        return 'Reloading Media.';
      case 4:
        return 'Resizing Media.';
      case 5:
        return 'Resizing Media.';
      case 6:
        return 'Resizing Media.';
      default:
        return `Compressing Media.`;
    }
  }
}
