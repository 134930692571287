import { PhoneNumberDropdownItem } from '@csspension/reactive-form/lib/components/reactive-form-item/reactive-form-phone-country-code/phone-number-item';
import { Country } from '../types/dto/country';

export class PhoneDropdownItem implements PhoneNumberDropdownItem {
  public countryName: string;
  public countryCode: string;
  public prefix: string;

  constructor(country: Country) {
    this.countryName = country.name;
    this.countryCode = country.countryCode;
    this.prefix = country.phoneCode;
  }

  getSelectionTitle(): string {
    return this.countryName;
  }

  getSelectionValue(): any {
    return this.countryCode;
  }

  getSelectionUniqueIdentifier(): any {
    return this.countryCode;
  }

  getSelectionIsDisabled(): boolean {
    return false;
  }
}
