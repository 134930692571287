import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { LoadingComponent } from './components/loading/loading.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ColorPickerModule } from 'ngx-color-picker';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxContainerComponent } from './components/checkboxes/checkbox-container/checkbox-container.component';
import { CheckboxComponent } from './components/checkboxes/checkbox/checkbox.component';
import { DropDownMenuComponent } from './components/drop-down-menu/drop-down-menu.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BaseCardComponent } from './components/base-card/base-card.component';
import { UploadItemComponent } from './components/upload-asset/upload-item/upload-item.component';
import { UploadAssetComponent } from './components/upload-asset/upload-asset.component';
import { DndDirective } from './directives/dnd-directive';
import { HeaderComponent } from './components/header/header.component';
import { NgbCollapse, NgbDatepicker, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ActiveComponent } from './components/active/active.component';
import { AssetPreviewComponent } from './components/asset-preview/asset-preview.component';
import { LogoUploadComponent } from './components/logo-upload/logo-upload.component';
import { DropDownComponent } from './components/drop-down/drop-down.component';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { ColorSketchModule } from 'ngx-color/sketch';
import { AssetComponent } from './components/asset/asset.component';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { FlexModule } from '@angular/flex-layout';
import { ThrottleClickDirective } from './directives/throttle-click.directive';
import { ReactiveFormModule } from '@csspension/reactive-form';
import { LookAheadItemComponent } from './components/search-with-look-ahead/look-ahead-list/look-ahead-item/look-ahead-item.component';
import { LookAheadListComponent } from './components/search-with-look-ahead/look-ahead-list/look-ahead-list.component';
import { SearchWithLookAheadComponent } from './components/search-with-look-ahead/search-with-look-ahead.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { NotNullPipe } from './pipes/not-null.pipe';
import { CloseButtonComponent } from './components/close-button/close-button.component';
import { LogoComponent } from './components/logo/logo.component';
import { CustomToastComponent } from './components/custom-toast/custom-toast.component';
import { BaseActionModalComponent } from './modals/base-action-modal/base-action-modal.component';
import { ReactiveButtonsModule } from '@csspension/reactive-buttons';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { TrustAsHtmlPipe } from './pipes/trust-as-html-pipe';
import { SlidingWindowComponent } from './components/sliding-window/sliding-window.component';
import { SlidingWindowHeaderComponent } from './components/sliding-window/sliding-window-header/sliding-window-header.component';
import { SlidingWindowFooterComponent } from './components/sliding-window/sliding-window-footer/sliding-window-footer.component';
import { LocalizationPipe } from './pipes/localization.pipe';
import { PillComponent } from './components/pill/pill.component';
import { TableOverlayLoadingComponent } from './components/table-overlay-loading/table-overlay-loading.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { TabBarComponent } from './components/tab-bar/tab-bar.component';
import { TabContentComponent } from './components/tab-bar/tab-content/tab-content.component';
import { EulaModalComponent } from './modals/eula-modal/eula-modal.component';
import { EulaRequiredValidatorDirective } from './directives/eula-required-validator-directive';
import { ApplicationProgressItemComponent } from './components/application-progress-item/application-progress-item.component';
import { AddRemoveRoleFormComponent } from './components/add-remove-role-form/add-remove-role-form.component';
import { ViewUserComponent } from './components/view-user/view-user.component';
import { ChangeUserEmailModalComponent } from './modals/change-user-email-modal/change-user-email-modal.component';
import { FakeCheckBoxComponent } from './components/fake-check-box/fake-check-box.component';
import { TableColumnSearchBarComponent } from './components/table-column-search-bar/table-column-search-bar.component';
import { StatusPillComponent } from './components/status-pill/status-pill.component';
import { UserProfileBadgeComponent } from './components/user-profile-badge/user-profile-badge.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DashboardHeaderComponent } from './components/dashboard-header/dashboard-header.component';
import { ReorderModalComponent } from './modals/reorder-modal/reorder-modal.component';
import { ReorderListComponent } from './components/reorder-list/reorder-list.component';
import { ReorderListItemComponent } from './components/reorder-list-item/reorder-list-item.component';
import { TableColumnDropDownBarComponent } from './components/table-column-drop-down-bar/table-column-drop-down-bar.component';
import { OdataTypeaheadSearchComponent } from './components/odata-typeahead-search/odata-typeahead-search.component';
import { ProfileLookAheadItemComponent } from './components/search-with-look-ahead/look-ahead-list/look-ahead-item/profile-look-ahead-item/profile-look-ahead-item.component';
import { SearchableModalComponent } from './modals/searchable-modal/searchable-modal.component';
import { SubmissionPermissionCheckboxTableComponent } from './components/submission-permission-checkbox-table/submission-permission-checkbox-table.component';
import { MaskSinNumberPipe } from './pipes/mask-sin-number.pipe';
import { DateValidDirective } from './directives/date-valid-directive.directive';
import { DateValidAndBeforeDirective } from './directives/date-valid-and-before-directive';
import { AlertComponent } from './components/alert/alert.component';
import { CardAlertComponent } from './components/alert/components/card-alert/card-alert.component';
import { BannerAlertComponent } from './components/alert/components/banner-alert/banner-alert.component';
import { IsCardTypeAlertPipe } from './components/alert/pipes/is-card-type-alert.pipe';
import { IsBannerTypeAlertPipe } from './components/alert/pipes/is-banner-type-alert.pipe';
import { StartEndDateValidatorDirective } from './directives/start-end-date-validator.directive';
import { NameChangeSubmissionFormComponent } from './components/name-change-submission-form/name-change-submission-form.component';
import { AddressChangeSubmissionFormComponent } from './components/address-change-submission-form/address-change-submission-form.component';
import { UpdateContactInfoSubmissionFormComponent } from './components/update-contact-info-submission-form/update-contact-info-submission-form.component';
import { HeaderContainerComponent } from './components/header-container/header-container.component';
import { EntryFirstCurrencySymbolPipe } from './pipes/entry-currency.pipe';
import { RouterLink } from '@angular/router';
import { LabeledListItemComponent } from './components/labeled-list-item/labeled-list-item.component';
import { HistoryItemComponent } from './components/history-item/history-item.component';
import { DateStringWithAtPipe } from './components/history-item/history-item-date-string.pipe';
import { EntryCodeNamePipe } from './pipes/entry-code-name.pipe';
import { PersonalInfoSubmissionFormComponent } from './components/personal-info-submission-form/personal-info-submission-form.component';
import { BreadcrumbHeaderComponent } from './components/breadcrumb-header/breadcrumb-header.component';
import { TableOverlayBufferComponent } from './components/table-overlay-buffer/table-overlay-buffer.component';
import { LeadingZeroPipe } from './pipes/leading-zero.pipe';
import { DataLabelComponent } from './components/data-label/data-label.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { FileUploaderTextComponent } from './components/file-uploader-text/file-uploader-text.component';
import { SimplePillComponent } from './components/simple-pill/simple-pill.component';
import { IncludesPipe } from './pipes/includes.pipe';
import { SetHasPipe } from './pipes/set-has.pipe';
import { CurrencySpacePipe } from './pipes/currency-space.pipe';
import { StickyFooterComponent } from './components/sticky-footer/sticky-footer.component';
import { MemberSearchInputComponent } from './components/member-search-input/member-search-input.component';
import { MemberSearchComponent } from './components/member-search/member-search.component';
import { PortalTypePipe } from './pipes/portal-type.pipe';
import { VersionNumberFormatValidatorDirective } from './directives/version-number-format-validator.directive';
import { VersionNumberExistsValidatorDirective } from './directives/version-number-exists-validator.directive';
import { FormattedDateStringInCSTPipe } from './pipes/formatted-date-string-in-cst.pipe';
import { ReleaseNoteModalComponent } from './components/release-note-modal/release-note-modal.component';
import { DateInFutureValidatorDirective } from './directives/date-in-future-validator.directive';
import { SlidingWindowCommentsComponent } from './components/sliding-window-comments/sliding-window-comments.component';
import { LoadingShimmerModule } from 'loading-shimmer';
import { SubmissionCommentsFormComponent } from './components/submission-comments-form/submission-comments-form.component';
import { DesignatedUnitVersionUniqueStartDateValidatorDirective } from './directives/designated-unit-version-unique-start-date-validator.directive';
import { MinDesignatedUnitPercentageValidatorDirective } from './directives/min-designated-unit-percentage-validator.directive';
import { MatchedDesignatedUnitRangeValidatorDirective } from './directives/matched-designated-unit-range-validator.directive';
import { UnmatchedDesignatedUnitRangeValidatorDirective } from './directives/unmatched-designated-unit-range-validator.directive';
import { DesignatedUnitUniqueNameValidatorDirective } from './directives/designated-unit-unique-name-validator.directive';
import { EmployerSearchInputComponent } from './components/employer-search-input/employer-search-input.component';
import { DesignatedUnitSearchInputComponent } from './components/designated-unit-search-input/designated-unit-search-input.component';
import { StaticInfoContainerComponent } from './components/static-info-container/static-info-container.component';
import { HumanReadablePipe } from './pipes/human-readable.pipe';
import { ValidCreateRemittanceFileDirective } from './directives/valid-create-remittance-file.directive';
import { CurrentFormattedDateTimePipe } from './pipes/current-formatted-date-time.pipe';
import { DataLabelPillComponent } from './components/data-label-pill/data-label-pill.component';
import { UniqueSinValidatorDirective } from './directives/unique-sin-validator.directive';
import { LabeledListSinItemComponent } from './components/labeled-list-sin-item/labeled-list-sin-item.component';

/*
 * ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' })
 * see https://github.com/angular/angular/issues/48350
 */
@NgModule({
  providers: [],
  declarations: [
    ApplicationProgressItemComponent,
    DropDownComponent,
    LoadingComponent,
    ProgressBarComponent,
    CheckboxContainerComponent,
    CheckboxComponent,
    DropDownMenuComponent,
    UploadItemComponent,
    UploadAssetComponent,
    DndDirective,
    DateValidAndBeforeDirective,
    DateInFutureValidatorDirective,
    VersionNumberFormatValidatorDirective,
    CurrentFormattedDateTimePipe,
    EulaRequiredValidatorDirective,
    HeaderComponent,
    BreadcrumbsComponent,
    ActiveComponent,
    AssetPreviewComponent,
    LogoUploadComponent,
    AssetComponent,
    ThrottleClickDirective,
    LookAheadItemComponent,
    LookAheadListComponent,
    SearchWithLookAheadComponent,
    BackButtonComponent,
    NotNullPipe,
    NotNullPipe,
    BaseCardComponent,
    CloseButtonComponent,
    LogoComponent,
    CustomToastComponent,
    BaseActionModalComponent,
    IconButtonComponent,
    TrustAsHtmlPipe,
    SlidingWindowComponent,
    SlidingWindowHeaderComponent,
    SlidingWindowFooterComponent,
    LocalizationPipe,
    PillComponent,
    TableOverlayLoadingComponent,
    PageHeaderComponent,
    TabBarComponent,
    TabContentComponent,
    EulaModalComponent,
    AddRemoveRoleFormComponent,
    ViewUserComponent,
    StaticInfoContainerComponent,
    ChangeUserEmailModalComponent,
    FakeCheckBoxComponent,
    TableColumnSearchBarComponent,
    StatusPillComponent,
    UserProfileBadgeComponent,
    DashboardHeaderComponent,
    ReorderModalComponent,
    ReorderListComponent,
    ReorderListItemComponent,
    DatePickerComponent,
    TableColumnDropDownBarComponent,
    OdataTypeaheadSearchComponent,
    ProfileLookAheadItemComponent,
    SearchableModalComponent,
    SubmissionPermissionCheckboxTableComponent,
    MaskSinNumberPipe,
    DateValidDirective,
    AlertComponent,
    CardAlertComponent,
    BannerAlertComponent,
    IsCardTypeAlertPipe,
    IsBannerTypeAlertPipe,
    StartEndDateValidatorDirective,
    NameChangeSubmissionFormComponent,
    AddressChangeSubmissionFormComponent,
    UpdateContactInfoSubmissionFormComponent,
    VersionNumberExistsValidatorDirective,
    HeaderContainerComponent,
    EntryFirstCurrencySymbolPipe,
    LabeledListItemComponent,
    HistoryItemComponent,
    DateStringWithAtPipe,
    EntryCodeNamePipe,
    PersonalInfoSubmissionFormComponent,
    BreadcrumbHeaderComponent,
    TableOverlayBufferComponent,
    LeadingZeroPipe,
    DataLabelComponent,
    StepperComponent,
    FileUploaderTextComponent,
    SimplePillComponent,
    IncludesPipe,
    SetHasPipe,
    CurrencySpacePipe,
    StickyFooterComponent,
    MemberSearchComponent,
    MemberSearchInputComponent,
    PortalTypePipe,
    FormattedDateStringInCSTPipe,
    ReleaseNoteModalComponent,
    SlidingWindowCommentsComponent,
    SubmissionCommentsFormComponent,
    DesignatedUnitVersionUniqueStartDateValidatorDirective,
    MinDesignatedUnitPercentageValidatorDirective,
    MatchedDesignatedUnitRangeValidatorDirective,
    UnmatchedDesignatedUnitRangeValidatorDirective,
    DesignatedUnitUniqueNameValidatorDirective,
    EmployerSearchInputComponent,
    DesignatedUnitSearchInputComponent,
    HumanReadablePipe,
    ValidCreateRemittanceFileDirective,
    DataLabelPillComponent,
    UniqueSinValidatorDirective,
    LabeledListSinItemComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgxSpinnerModule,
    ColorPickerModule,
    FormsModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode'
    }),
    BsDropdownModule,
    NgbTooltipModule,
    NgxPopperjsModule,
    ColorSketchModule,
    NgxShimmerLoadingModule,
    FlexModule,
    ReactiveFormModule,
    ReactiveButtonsModule,
    NgbCollapse,
    NgbDatepicker,
    RouterLink,
    LoadingShimmerModule
  ],
  exports: [
    // Shared Components
    MaterialModule,
    LoadingComponent,
    NgxSpinnerModule,
    DropDownComponent,
    ColorPickerModule,
    ProgressBarComponent,
    FormsModule,
    ReactiveFormsModule,
    CheckboxComponent,
    CheckboxContainerComponent,
    BsDropdownModule,
    DropDownMenuComponent,
    UploadItemComponent,
    UploadAssetComponent,
    HeaderComponent,
    BreadcrumbsComponent,
    ActiveComponent,
    AssetPreviewComponent,
    LogoUploadComponent,
    ThrottleClickDirective,
    DateValidAndBeforeDirective,
    LookAheadItemComponent,
    LookAheadListComponent,
    SearchWithLookAheadComponent,
    StaticInfoContainerComponent,
    BackButtonComponent,
    NotNullPipe,
    BaseCardComponent,
    DateInFutureValidatorDirective,
    CurrentFormattedDateTimePipe,
    CloseButtonComponent,
    LogoComponent,
    BaseActionModalComponent,
    TrustAsHtmlPipe,
    SlidingWindowComponent,
    SlidingWindowHeaderComponent,
    SlidingWindowFooterComponent,
    VersionNumberFormatValidatorDirective,
    IconButtonComponent,
    VersionNumberExistsValidatorDirective,
    LocalizationPipe,
    AssetComponent,
    LocalizationPipe,
    PillComponent,
    TableOverlayLoadingComponent,
    PageHeaderComponent,
    TabContentComponent,
    ApplicationProgressItemComponent,
    AddRemoveRoleFormComponent,
    FakeCheckBoxComponent,
    TableColumnSearchBarComponent,
    StatusPillComponent,
    UserProfileBadgeComponent,
    DashboardHeaderComponent,
    ReorderModalComponent,
    DatePickerComponent,
    TableColumnDropDownBarComponent,
    OdataTypeaheadSearchComponent,
    ViewUserComponent,
    SubmissionPermissionCheckboxTableComponent,
    MaskSinNumberPipe,
    DateValidDirective,
    CardAlertComponent,
    BannerAlertComponent,
    IsBannerTypeAlertPipe,
    IsCardTypeAlertPipe,
    StartEndDateValidatorDirective,
    NameChangeSubmissionFormComponent,
    AddressChangeSubmissionFormComponent,
    UpdateContactInfoSubmissionFormComponent,
    HeaderContainerComponent,
    EntryFirstCurrencySymbolPipe,
    LabeledListItemComponent,
    HistoryItemComponent,
    DateStringWithAtPipe,
    EntryCodeNamePipe,
    PersonalInfoSubmissionFormComponent,
    BreadcrumbHeaderComponent,
    TableOverlayBufferComponent,
    LeadingZeroPipe,
    DataLabelComponent,
    StepperComponent,
    FileUploaderTextComponent,
    SimplePillComponent,
    IncludesPipe,
    SetHasPipe,
    CurrencySpacePipe,
    StickyFooterComponent,
    MemberSearchComponent,
    MemberSearchInputComponent,
    TabBarComponent,
    PortalTypePipe,
    FormattedDateStringInCSTPipe,
    SlidingWindowCommentsComponent,
    SubmissionCommentsFormComponent,
    EmployerSearchInputComponent,
    DesignatedUnitSearchInputComponent,
    HumanReadablePipe,
    DataLabelPillComponent,
    LabeledListSinItemComponent
  ]
})
export class SharedModule {}
