import { BaseType } from '../base/base-type';

export class FundType extends BaseType {
  public isCash(): boolean {
    // TODO: replace with actual cash fund ID
    return this.id === 4;
  }

  public getChartDataColor(): string {
    const colors = ['#DC3725', '#79B6A7', '#03536B', '#CF9E3C', '#13856B'];
    return colors[this.id % colors.length];
  }
}
