import { ApiClient } from './api-client';
import { Observable, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { SignInRequest } from '../models/users/requests/sign-in-request';
import { inject, Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { CustomError } from '../models/shared/custom-error';
import { MemberUser } from '../models/users/dto/member-user';
import { RefreshSessionRequest } from '../models/users/requests/refresh-session-request';
import { VerifyMemberRequest } from '../models/users/requests/verify-member-request';
import { StringResponse } from '../models/shared/responses/string-response';
import { ConfirmMemberRequest } from '../models/users/requests/confirm-member-request';
import { InternalUser } from '../models/users/dto/internal-user';
import { ChoosePasswordRequest } from '../models/users/requests/choose-password-request';
import { VerifyEmailRequest } from '../models/users/requests/verify-email-request';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { EmployerUser } from '../models/users/dto/employer-user';
import { AuthCodeRequest } from '../models/users/requests/auth-code-request';
import { ForgotPasswordRequest } from '../models/users/requests/forgot-password-request';
import { ResetPasswordRequest } from '../models/users/requests/reset-password-request';
import { BaseUser } from '../models/base/base-user';
import { PortalType } from '../models/enum/shared/portal-type';
import { Deserializable } from '../models/protocols/deserializable';
import { ChangeEmailRequest } from '../models/users/requests/change-email-request';
import { ChangePasswordRequest } from '../models/users/requests/change-password-request';
import { ChangeMfaPhoneRequest } from '../models/users/requests/change-mfa-phone-request';
import { ConfirmCodeRequest } from '../models/users/requests/confirm-code-request';
import { MfaSecretCode } from '../models/users/dto/mfa-secret-code';
import { VerifiedPhoneNumber } from '../models/users/dto/verified-phone-number';
import { EmployerDetails } from '../models/users/dto/employer-details';
import { ODataResponse } from '../models/protocols/odata-response';
import { UserRoleResponse } from '../models/roles/user-role-response';
import { MemberSIN } from '../models/users/dto/member-sin';
import { ODataQueryOptions } from '../models/shared/odata-query-options';

@Injectable({
  providedIn: 'root'
})
export class UserAPI implements LoggableAPI {
  private apiClient = inject(ApiClient);

  constructor() {}

  // Variables

  public serviceName = 'Account';

  // Session

  public signIn(req: SignInRequest | AuthCodeRequest, portalType: PortalType): Observable<BaseUser> {
    const url = Endpoints.signIn(portalType);
    const userType = this.getUserTypeFromPortalType(portalType);

    return this.apiClient.postObj<BaseUser, Deserializable>(userType, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  private getUserTypeFromPortalType(
    portalType: PortalType
  ): typeof InternalUser | typeof EmployerUser | typeof MemberUser {
    switch (portalType) {
      case PortalType.Member:
        return MemberUser;
      case PortalType.Internal:
        return InternalUser;
      case PortalType.Employer:
        return EmployerUser;
    }
  }

  public getMemberSIN(memberId: string): Observable<MemberSIN> {
    const url = Endpoints.getMemberSIN(memberId);
    return this.apiClient.getOdataObj<MemberSIN>(url, MemberSIN).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public signOut(email: string, portalType: PortalType): Observable<any> {
    const url = Endpoints.signOut(email, portalType);
    return this.apiClient.simpleBodylessPost(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public refreshSession(req: RefreshSessionRequest, email: string, portalType: PortalType): Observable<BaseUser> {
    const userType = this.getUserTypeFromPortalType(portalType);
    const url = Endpoints.refreshSession(email, portalType);
    return this.apiClient.postObj<BaseUser, Deserializable>(userType, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public changePassword(req: ChangePasswordRequest, email: string, portalType: PortalType): Observable<StringResponse> {
    const url = Endpoints.changePassword(email, portalType);
    return this.apiClient.simplePost(url, req).pipe(
      map(res => {
        return new StringResponse(res.status, res.body);
      }),
      catchError((e: HttpErrorResponse) => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public changeEmail(req: ChangeEmailRequest, email: string, portalType: PortalType): Observable<StringResponse> {
    const url = Endpoints.changeEmail(email, portalType);
    return this.apiClient.simplePost(url, req).pipe(
      map(res => {
        return new StringResponse(res.status, res.body);
      }),
      catchError((e: HttpErrorResponse) => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public verifyEmail(req: VerifyEmailRequest, portalType: PortalType): Observable<BaseUser> {
    const userType = this.getUserTypeFromPortalType(portalType);
    const url = Endpoints.verifyEmail(req.email, portalType);
    return this.apiClient.postObj<BaseUser, Deserializable>(userType, url, req).pipe(
      catchError((e: HttpErrorResponse) => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public verifyMember(req: VerifyMemberRequest, inviteId: string): Observable<StringResponse> {
    const url = Endpoints.verifyMember(inviteId);
    return this.apiClient.simplePost(url, req).pipe(
      map(res => {
        return new StringResponse(res.status, res.body);
      }),
      catchError((e: HttpErrorResponse) => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public verifyMemberEmail(req: VerifyEmailRequest): Observable<StringResponse> {
    const url = Endpoints.verifyMemberEmail();
    return this.apiClient.simplePost(url, req).pipe(
      map(res => {
        return new StringResponse(res.status, res.body);
      }),
      catchError((e: HttpErrorResponse) => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public confirmMember(req: ConfirmMemberRequest, inviteId: string): Observable<HttpResponse<any>> {
    const url = Endpoints.confirmMember(inviteId);
    return this.apiClient.simplePost(url, req).pipe(
      catchError((e: HttpErrorResponse) => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public setInternalUserPassword(req: ChoosePasswordRequest): Observable<InternalUser> {
    return this.apiClient.postObj(InternalUser, Endpoints.signIn(PortalType.Internal), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public setEmployerPassword(req: ChoosePasswordRequest): Observable<EmployerUser> {
    return this.apiClient.postObj(EmployerUser, Endpoints.signIn(PortalType.Employer), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public setupInternalUserMfa(req: AuthCodeRequest): Observable<InternalUser> {
    return this.apiClient.postObj(InternalUser, Endpoints.signIn(PortalType.Internal), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public disableMfa(email: string, portalType: PortalType): Observable<MemberUser | EmployerUser> {
    const url = Endpoints.disableMfa(email, portalType);
    return this.apiClient.simpleBodylessPost(url).pipe(
      map(res => {
        if (portalType === PortalType.Member) {
          return window?.injector?.Deserialize?.instanceOf(MemberUser, res.body);
        } else {
          return window?.injector?.Deserialize?.instanceOf(EmployerUser, res.body);
        }
      }),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getVerifiedPhoneNumber(email: string, portalType: PortalType): Observable<VerifiedPhoneNumber> {
    const url = Endpoints.getVerifiedPhoneNumber(email, portalType);
    return this.apiClient.getObj<VerifiedPhoneNumber>(VerifiedPhoneNumber, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public associateMfaToken(email: string, portalType: PortalType): Observable<MfaSecretCode> {
    const url = Endpoints.associateMfaToken(email, portalType);
    return this.apiClient.simpleBodylessPost(url).pipe(
      map(res => {
        return window?.injector?.Deserialize?.instanceOf(MfaSecretCode, res.body);
      }),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public forgotPassword(req: ForgotPasswordRequest, portalType: PortalType): Observable<HttpResponse<any>> {
    const url = Endpoints.generateForgotPasswordUrl(req.email, portalType);
    return this.apiClient.simpleGet(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public resetPassword(req: ResetPasswordRequest, portalType: PortalType): Observable<HttpResponse<any>> {
    const url = Endpoints.generateResetPasswordUrl(req.email, portalType);
    return this.apiClient.simplePost(url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public changeMfaPhoneNumber(
    req: ChangeMfaPhoneRequest,
    email: string,
    portalType: PortalType
  ): Observable<MemberUser | EmployerUser> {
    const url = Endpoints.changeMfaPhone(email, portalType);
    const userType = portalType === PortalType.Member ? MemberUser : EmployerUser;
    return this.apiClient.postObj<MemberUser | EmployerUser, Deserializable>(userType, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public verifySmsCode(req: ConfirmCodeRequest, email: string, portalType: PortalType): Observable<BaseUser> {
    const url = Endpoints.verifySmsCode(email, portalType);
    const userType = this.getUserTypeFromPortalType(portalType);
    return this.apiClient.postObj<BaseUser, Deserializable>(userType, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public verifyMfaToken(req: ConfirmCodeRequest, email: string, portalType: PortalType): Observable<BaseUser> {
    const url = Endpoints.verifyMfaToken(email, portalType);
    const userType = this.getUserTypeFromPortalType(portalType);
    return this.apiClient.postObj<BaseUser, Deserializable>(userType, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getEmployerDetails(memberInviteId: string): Observable<EmployerDetails> {
    const url = Endpoints.getEmployerDetails(memberInviteId);
    return this.apiClient.getObj<EmployerDetails>(EmployerDetails, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // Roles
  public getRolesForUser(userId: string, portalType: PortalType): Observable<ODataResponse<UserRoleResponse>> {
    const url = Endpoints.getRoleForUser(userId, portalType);
    const odataQueryOptions = new ODataQueryOptions();
    odataQueryOptions.setExpand('Role($expand=Permissions)');
    return this.apiClient.getOdata(url, UserRoleResponse, odataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }
}
