import { inject, Injectable } from '@angular/core';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { ApiClient } from './api-client';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomError } from '../models/shared/custom-error';
import { HttpHeaders } from '@angular/common/http';
import * as buffer from 'buffer';
import { AttachmentFile } from '../models/users/dto/attachment-file';
import { Endpoints } from './endpoints';
import { SubmissionAttachment } from '../models/submissions/submission-attachment';

@Injectable({
  providedIn: 'root'
})
export class AttachmentApi implements LoggableAPI {
  constructor() {}

  private apiClient = inject(ApiClient);

  public serviceName = 'Attachments';

  public uploadAttachmentFile(file: AttachmentFile): Observable<any> {
    const fileType = file.getFileType();
    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': fileType,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Encoding': 'base64',
      'x-amz-meta-attachmentid': file.attachmentId
    });
    const replacementString = 'data:' + fileType + ';base64,';
    const newFileName = file.fileUrl.replace(replacementString, '');
    const buff = buffer.Buffer.from(newFileName, 'base64');

    const blob = new Blob([new Uint8Array(buff)]);
    return this.apiClient.putAssetToS3(file.presignedUrl, blob, headers).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public deleteSubmissionAttachment(attachmentId: string, submissionId: string): Observable<any> {
    const url = Endpoints.deleteSubmissionAttachment(attachmentId, submissionId);
    return this.apiClient.deleteWithUntypedRes(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getSubmissionAttachments(submissionId: string): Observable<SubmissionAttachment[]> {
    const url = Endpoints.getSubmissionAttachments(submissionId);
    return this.apiClient.getArr<SubmissionAttachment>(SubmissionAttachment, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }
}
