import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { MemberSubmission } from '../../../../models/submissions/member-submission';
import { BehaviorSubject, Observable } from 'rxjs';
import { SubmissionStatusIdEnum } from '../../../../models/enum/shared/submission-status-id.enum';

@Component({
  selector: 'app-member-submission-container',
  templateUrl: './member-submission-container.component.html',
  styleUrls: ['./member-submission-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberSubmissionContainerComponent extends BaseComponent implements OnChanges {
  @Input() submission = new MemberSubmission();
  @Output() submissionClicked = new EventEmitter<MemberSubmission>();

  private _submissionDatePrefix = new BehaviorSubject<string>('');
  public submissionDatePrefix$ = this._submissionDatePrefix as Observable<string>;

  ngOnChanges(): void {
    if (this.submission) {
      switch (this.submission.statusId) {
        case SubmissionStatusIdEnum.Pending:
          this._submissionDatePrefix.next($localize`Created On`);
          break;
        case SubmissionStatusIdEnum.Cancelled:
          this._submissionDatePrefix.next($localize`Cancelled On`);
          break;
        case SubmissionStatusIdEnum.Completed:
          this._submissionDatePrefix.next($localize`Completed On`);
          break;
        case SubmissionStatusIdEnum.Submitted:
        case SubmissionStatusIdEnum.InReview:
          this._submissionDatePrefix.next($localize`Submitted On`);
          break;
        default:
          this._submissionDatePrefix.next('');
          break;
      }
    }
  }

  setupBindings(): void {}

  setupViews(): void {}
}
