import { UniquelyIdentifiable } from '../models/protocols/uniquely-identifiable';
import { distinctUntilChanged } from 'rxjs/operators';

export class DistinctUtils {
  public static distinctUniquelyIdentifiable = <T extends UniquelyIdentifiable>(prev: T, curr: T) => {
    return prev?.getUniqueIdentifier() === curr?.getUniqueIdentifier();
  };
  public static distinctUniquelyIdentifiableArray = <T extends UniquelyIdentifiable>(prev: T[], curr: T[]) => {
    const prevLength = prev?.length || 0;
    const currLength = curr?.length || 0;
    if (prevLength !== currLength) return false;
    const prevId = prev
      ?.map(m => m?.getUniqueIdentifier())
      ?.sort()
      ?.join(',');
    const currId = curr
      ?.map(m => m?.getUniqueIdentifier())
      ?.sort()
      ?.join(',');
    return prevId === currId;
  };
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public static distinctUntilChanged = distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable);
  // Note: the compared lists are sorted in place, which means that the input data will be altered by this function.
  public static distinctSortedStrings = (prev: string[], curr: string[]) => {
    return prev?.sort().join(',') === curr?.sort().join(',');
  };
  public static distinctUnsortedStrings = (prev: string[], curr: string[]) => prev?.join(',') === curr?.join(',');

  public static distinctKeyValueMap<K, V extends UniquelyIdentifiable>(
    prev: Map<K, V | undefined>,
    curr: Map<K, V | undefined>
  ) {
    if (prev.size !== curr.size) return false;

    // Extract the keys from both maps
    const prevKeys = Array.from(prev.keys());
    const currKeys = Array.from(curr.keys());

    // Sort the keys
    prevKeys.sort();
    currKeys.sort();

    // Compare keys and their associated values
    for (let i = 0; i < prevKeys.length; i++) {
      const prevKey = prevKeys[i];
      const currKey = currKeys[i];
      const prevValue = prev.get(prevKey);
      const currValue = curr.get(currKey);

      // Filter out undefined values
      if (
        prevKey !== currKey ||
        (prevValue !== undefined &&
          currValue !== undefined &&
          !DistinctUtils.distinctUniquelyIdentifiable(prevValue, currValue))
      ) {
        return false;
      }
    }

    return true;
  }
}
