import { Component, inject, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChangeNameRequest } from '../../../../models/users/requests/submissionRequests/change-name-request';
import { NameChangeSubmissionFormViewModel } from './name-change-submission-form-view-model';
import { MediaUtils } from '../../../../utils/media-utils';
import { AbstractSubmissionFormComponent } from '../submission-forms/abstract-submission-form/abstract-submission-form.component';

@Component({
  selector: 'app-name-change-submission-form',
  templateUrl: './name-change-submission-form.component.html',
  styleUrls: ['./name-change-submission-form.component.scss'],
  providers: [NameChangeSubmissionFormViewModel]
})
export class NameChangeSubmissionFormComponent
  extends AbstractSubmissionFormComponent<ChangeNameRequest>
  implements OnChanges
{
  protected viewModel = inject(NameChangeSubmissionFormViewModel);

  public readonly loadingOpts$ = this.viewModel.loadingOpts$;
  public readonly isLoading$ = this.viewModel.isLoading$;

  private _infoChanged = new BehaviorSubject<boolean>(false);
  public readonly infoChanged$ = this._infoChanged as Observable<boolean>;

  public readonly uploadTitle$ = this.infoChanged$.pipe(
    map(changed => {
      return changed ? $localize`Upload a file` : '';
    })
  );

  public readonly uploadBody$ = this.infoChanged$.pipe(
    map(changed => {
      return changed
        ? $localize`or drag and drop .png or .jpeg up to 3MB`
        : $localize`Images cannot be uploaded at this time.`;
    })
  );

  public req!: ChangeNameRequest;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user) {
      this.req = new ChangeNameRequest(this.user);
    }
  }

  formChanged(current: ChangeNameRequest): void {
    if (
      this.user.firstName !== current.firstName ||
      this.user.lastName !== current.lastName ||
      this.user.middleName !== current.middleName
    ) {
      this._infoChanged.next(true);
    } else {
      this._infoChanged.next(false);
    }
  }

  submitNameChange(req: ChangeNameRequest): void {
    if (this.fileUploader) {
      combineLatest([this.infoChanged$, this.fileUploader.selectedFiles$]).once(([changed, files]) => {
        if (changed) {
          const promises = files.map(file => MediaUtils.fileToUserFile(file));
          Promise.all(promises).then(userFiles => {
            this.viewModel.submitRequest(req, userFiles);
          });
        } else {
          this.viewModel.submitRequest(req);
        }
      });
    }
  }
}
