import { Deserializable } from '../protocols/deserializable';

export class LastContribution implements Deserializable {
  public transactionId!: number;
  public debitAmount!: number;
  public creditAmount!: number;
  public date!: Date;

  public onDeserialize(): void {
    if (this.date) {
      this.date = new Date(this.date);
    }
  }
}
