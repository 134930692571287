import { Deserializable } from '../../protocols/deserializable';
import { Asset } from '../../asset/dto/asset';
import { Cachable } from '../../protocols/cachable';
import { CachePolicy } from '../../enum/shared/cachable-image-policy.enum';
import { DateUtils } from '../../../utils/date-utils';

export class EmployerDetails implements Deserializable, Cachable {
  public employerId!: number;
  public employerName!: string;
  public logo!: Asset;
  public cachedTime!: number;

  public onDeserialize() {
    this.logo = window?.injector?.Deserialize?.instanceOf(Asset, this.logo);
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneMonth();
  }

  cacheKey(): string {
    return 'employer-details';
  }

  imageCachePolicy(): CachePolicy {
    return CachePolicy.Persistent;
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }
}
