import { Component, HostListener } from '@angular/core';
import { NavItemViewModel } from '../nav-item-view-model';
import { Router } from '@angular/router';
import { NavItemComponent } from '../nav-item.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-drop-down-nav-item',
  templateUrl: './drop-down-nav-item.component.html',
  styleUrls: ['./drop-down-nav-item.component.scss', '../nav-item.component.scss'],
  providers: [NavItemViewModel]
})
export class DropDownNavItemComponent extends NavItemComponent {
  constructor(public viewModel: NavItemViewModel, protected router: Router) {
    super(viewModel, router);
  }

  public showDropdown$ = new BehaviorSubject<boolean>(false);
  public idSeed = Math.random().toString(36).substring(2);

  // Close the dropdown when clicking outside of it
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.isDropdownClicked(event)) {
      this.closeDropdown();
    }
  }

  // Check if the click event occurred inside the dropdown
  isDropdownClicked(event: MouseEvent): boolean {
    const dropdownElement = document.getElementById('dropdown' + this.idSeed);
    if (!!dropdownElement) {
      return dropdownElement.contains(event.target as Node);
    }
    return false;
  }

  public toggleDropdown() {
    this.showDropdown$.once(d => {
      this.showDropdown$.next(!d);
    });
  }

  public closeDropdown() {
    this.showDropdown$.once(d => {
      if (d) {
        this.showDropdown$.next(false);
      }
    });
  }
}
