<lib-reactive-form-group
  #form
  [bindTo]="req"
  (formChanged$)="formChanged($event)"
  (formSubmitted$)="submitNameChange($event)">
  <lib-reactive-form-column-layout [nColumns]="(isMobile$ | async) ? 1 : 2" [columnGap]="'2rem'">
    <lib-reactive-form-text
      [inputName]="'firstName'"
      [label]="'First Name' | localization"
      [bindingProperty]="'firstName'"
      [required]="true">
    </lib-reactive-form-text>
    <lib-reactive-form-masked-input
      [inputName]="'middleInitial'"
      [label]="'Middle Initial' | localization"
      [bindingProperty]="'middleInitial'"
      [mask]="[singleLetterMask]"
      [hintText]="'Optional' | localization">
    </lib-reactive-form-masked-input>
  </lib-reactive-form-column-layout>
  <lib-reactive-form-column-layout [nColumns]="(isMobile$ | async) ? 1 : 2" [columnGap]="'2rem'">
    <lib-reactive-form-text
      [inputName]="'lastName'"
      [label]="'Last Name' | localization"
      [bindingProperty]="'lastName'"
      [required]="true">
    </lib-reactive-form-text>
    <lib-reactive-form-text
      [inputName]="'preferredName'"
      [label]="'Preferred Name' | localization"
      [bindingProperty]="'preferredName'"
      [hintText]="'Optional' | localization">
    </lib-reactive-form-text>
  </lib-reactive-form-column-layout>
  <hr class="mt-3" />
  <ng-content></ng-content>
  <lib-reactive-form-file-uploader
    #fileUploader
    (previewClicked)="openFileInNewTab($event)"
    (duplicateFileNamesAdded)="duplicateFilesAdded()"
    [inputName]="'file'"
    [id]="'file'"
    [accept]="acceptedFileTypes"
    [disabled]="!(infoChanged$ | async) ?? false"
    [label]="'Document' | localization"
    [uploadTitle]="(uploadTitle$ | async) ?? ''"
    [showPreview]="true"
    [multiple]="allowMultipleFiles"
    [maxFileSizeInMb]="maxFileSizeInMb"
    [maxTotalFileSizeInMb]="allowMultipleFiles ? maxTotalFileSizeInMb : 0"
    [maxFiles]="5"
    [uploadBody]="(uploadBody$ | async) ?? ''"
    [chooseAnother]="true"
    [required]="!!(infoChanged$ | async)"
    [chooseAnotherLabel]="chooseAnotherLabel"
    [previewLabel]="'Preview' | localization"
    [removeLabel]="'Remove' | localization">
  </lib-reactive-form-file-uploader>
</lib-reactive-form-group>
