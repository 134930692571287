import { Deserializable } from '../protocols/deserializable';
import { LookAheadItem } from '../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';

export class RemittanceMember implements Deserializable, LookAheadItem, UniquelyIdentifiable {
  public socialInsuranceNumber!: string;
  public firstName!: string;
  public lastName!: string;
  public fullName!: string;

  public onDeserialize(): void {}

  public lookAheadDisabled(): boolean {
    return false;
  }
  public lookAheadDisabledMessage(): string {
    return '';
  }

  getUniqueIdentifier(): string {
    return `
    ${this.socialInsuranceNumber}
    - ${this.firstName}
    - ${this.lastName}
    - ${this.fullName}`;
  }
}
