import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AuthWrapperViewModel } from '../../../../viewModels/auth-wrapper-view-model';
import { LoadingOptions } from '../../../../../../models/shared/loading-options';

@Component({
  selector: 'app-internal-user-auth-wrapper',
  templateUrl: './internal-user-auth-wrapper.component.html',
  styleUrls: ['./internal-user-auth-wrapper.component.scss']
})
export class InternalUserAuthWrapperComponent implements OnChanges {
  @Input() loadingOpts: LoadingOptions = LoadingOptions.default();

  constructor(public viewModel: AuthWrapperViewModel) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.loadingOpts) this.viewModel.setLoadingOpts(this.loadingOpts);
  }
}
