import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanReadable'
})
export class HumanReadablePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    // Split by underscore or space, capitalize the first letter of each word
    return value
      .split(/[\s_]+/) // Split by underscores or spaces
      .map(word => {
        // Capitalize the first letter and lowercase the rest of the word
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' '); // Join words with space
  }
}
