// EXAMPLE on how to use:
// Generating the following query string:
//
// {{baseurl}}/odata/ReferralPreviews/IncomingByLocationByCompany(locationId={{locationId}},
// companyId={{companyId}})?$top=10&$count=true&$expand=ReferringPatient($expand=GenderType),
// Statustype,ReferringEmployee,PreferredSpecialist,AssignedSpecialist,Procedures($expand=ProcedureType),
// ReferringCompany,ReferringLocation,ReferredCompany,ReferredLocation,WaitingStatusReason,
// Stages($expand=DeclinedStatusReason)

// Recommended way to use as types are enforced via setters:
// const query = new ODataQueryOptions();
// query.setTop(10);
// query.setCount(true);
// query.setExpand(
//    'ReferringPatient($expand=GenderType)',
//    'Statustype',
//    'ReferringEmployee',
//    'PreferredSpecialist',
//    'AssignedSpecialist',
//    'Procedures($expand=ProcedureType)',
//    'ReferringCompany',
//    'ReferringLocation',
//    'ReferredCompany',
//    'ReferredLocation',
//    'WaitingStatusReason',
//    'Stages($expand=DeclinedStatusReason)'
// );
// const queryString = query.toQueryString();
// const url = `{{baseurl}}/odata/ReferralPreviews/IncomingByLocationByCompany(locationId=
// {{locationId}},companyId={{companyId}})?${queryString}`;

// OR (bypasses setters and can lead to typing issues, not recommended)

// const baseUrl = "{{baseurl}}";
// const locationId = 1;
// const companyId = 1;
//
// const queryOptions = new ODataQueryOptions({
//     top: 10,
//     count: true,
//     expand: [
//         "ReferringPatient($expand=GenderType)",
//         "Statustype",
//         "ReferringEmployee",
//         "PreferredSpecialist",
//         "AssignedSpecialist",
//         "Procedures($expand=ProcedureType)",
//         "ReferringCompany",
//         "ReferringLocation",
//         "ReferredCompany",
//         "ReferredLocation",
//         "WaitingStatusReason",
//         "Stages($expand=DeclinedStatusReason)"
//     ]
// });
//
// const url = `${baseUrl}/odata/ReferralPreviews/IncomingByLocationByCompany(locationId=
// ${locationId},companyId=${companyId})${queryOptions}`;

import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';

export class ODataQueryOptions implements UniquelyIdentifiable {
  private _top?: number;
  private _skip?: number;
  private _count?: boolean;
  private _filter?: string;
  private _orderBy?: string;
  private _expand?: Array<string | ODataQueryOptions>;

  constructor(init?: Partial<ODataQueryOptions>) {
    Object.assign(this, init);
  }

  public setTop(value: number): ODataQueryOptions {
    this._top = value;
    return this;
  }

  public setSkip(value: number): ODataQueryOptions {
    this._skip = value;
    return this;
  }

  public setCount(value: boolean): ODataQueryOptions {
    this._count = value;
    return this;
  }

  public setFilter(value: string): ODataQueryOptions {
    this._filter = value;
    return this;
  }

  public setOrderBy(value: string): ODataQueryOptions {
    this._orderBy = value;
    return this;
  }

  public setExpand(...expandOptions: string[]): ODataQueryOptions {
    this._expand = expandOptions;
    return this;
  }

  /**
   * Converts the ODataQueryOptions instance to a query string representation. If a URL is included, the query string
   * will be appended to the URL. Else the query string will be returned.
   *
   * @param baseUrl The base URL to append the query string to.
   * @returns The generated query string.
   */
  public toQueryString(baseUrl?: string): string {
    const queryOptions = [];

    if (!!this._expand) {
      const expandOptions = [];
      for (const opt of this._expand) {
        if (typeof opt === 'string') {
          expandOptions.push(opt);
        } else if (opt instanceof ODataQueryOptions) {
          const optQueryString = opt.toQueryString('');
          if (optQueryString) {
            expandOptions.push(`$expand=${optQueryString}`);
          }
        }
      }
      if (expandOptions.length > 0) {
        queryOptions.push(`$expand=${expandOptions.join(',')}`);
      }
    }

    if (!!this._top) {
      queryOptions.push(`$top=${this._top}`);
    }
    if (!!this._skip) {
      queryOptions.push(`$skip=${this._skip}`);
    }
    if (!!this._count) {
      queryOptions.push(`$count=${this._count}`);
    }
    if (!!this._orderBy) {
      queryOptions.push(`$orderby=${this._orderBy}`);
    }
    if (!!this._filter) {
      queryOptions.push(`$filter=${encodeURIComponent(this._filter)}`);
    }

    const queryString = queryOptions.join('&');
    if (!!baseUrl) {
      return queryString ? `${baseUrl}?${queryString}` : baseUrl;
    } else {
      return queryString;
    }
  }

  // Converts to a Params object for use in HTTP requests
  toObject(): Map<string, string> {
    const queryObject = new Map<string, any>();
    for (const key in this) {
      if (this.hasOwnProperty(key)) {
        const value = (this as any)[key];
        if (value instanceof ODataQueryOptions) {
          queryObject.set(key, value.toObject());
        } else if (value !== undefined && value !== null) {
          queryObject.set(key, value);
        }
      }
    }
    return queryObject;
  }

  getUniqueIdentifier(): string {
    return `${this._top}
      -${this._count}
      -${this._skip}
      -${this._filter}
      -${this._skip}
      -${this._orderBy}
      -${this._expand}`;
  }
}
