<!--TODO: Profile Badge Border Color will eventually come from the back end-->
<div [style.background-color]="profileColor" class="profile-badge-border">
  <div [style.background-color]="profileColor" class="profile-badge">
    <div *ngIf="initials !== ''; else cssBotImage" [style.color]="textColor" class="badge-text">{{ initials }}</div>
  </div>
</div>

<ng-template #cssBotImage>
  <div>
    <img class="bot-logo" [src]="'assets/logo/light/css-bot-logo.svg'" />
  </div>
</ng-template>
