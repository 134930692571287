import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import Chart from 'chart.js/auto';
import { v4 as uuidv4 } from 'uuid';
import { DonutChartData } from './donut-chart-data';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DonutChartComponent implements AfterViewInit, OnChanges {
  @Input() data!: DonutChartData[];
  chartId = uuidv4();
  isDataEmpty: boolean = false;

  createChart() {
    new Chart(`donutChart-${this.chartId}`, {
      type: 'doughnut',
      options: {
        responsive: true,
        aspectRatio: 1,
        maintainAspectRatio: true,
        animation: false,
        cutout: '60%',
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          }
        }
      },
      data: {
        datasets: [
          {
            data: this.data?.map(d => d.value),
            backgroundColor: this.data?.map(d => d.color),
            hoverBackgroundColor: this.data?.map(d => d.color),
            hoverBorderColor: '#FFFFFF',
            borderWidth: 1
          }
        ]
      }
    });
  }

  updateEmptyData(): void {
    this.isDataEmpty = !this.data || this.data.length === 0 || this.data.every(d => d.value === 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.data) {
      this.updateEmptyData();
    }
  }

  ngAfterViewInit(): void {
    this.createChart();
  }
}
