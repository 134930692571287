<app-base-action-modal
  [title]="'Add Personal Information' | localization"
  [primaryLabel]="'Next' | localization"
  [hideSecondary]="true"
  [ignoreEscape]="true"
  [hideClose]="true"
  (primaryClicked)="form.submitForm(); coopForm?.submitForm()">
  <app-loading *ngIf="viewModel.isLoading$ | async" [options]="(viewModel.loadingOpts$ | async) ?? undefined">
  </app-loading>
  <lib-reactive-form-group #form (formSubmitted$)="formSubmitted($event)" [bindTo]="req$ | async">
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-text
        [inputName]="'firstName'"
        [label]="'First Name' | localization"
        [disabled]="true"
        [bindingProperty]="'firstName'"
        [required]="true">
      </lib-reactive-form-text>
      <lib-reactive-form-text
        [inputName]="'middleInitial'"
        [label]="'Middle Initial' | localization"
        [disabled]="true"
        [bindingProperty]="'middleInitial'"
        [hintText]="'Optional' | localization">
      </lib-reactive-form-text>
    </lib-reactive-form-column-layout>
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-text
        [inputName]="'lastName'"
        [label]="'Last Name' | localization"
        [disabled]="true"
        [bindingProperty]="'lastName'"
        [required]="true">
      </lib-reactive-form-text>
      <lib-reactive-form-text
        [inputName]="'preferredName'"
        [label]="'Preferred Name' | localization"
        [bindingProperty]="'preferredName'"
        [hintText]="'Optional' | localization">
      </lib-reactive-form-text>
    </lib-reactive-form-column-layout>
    <div class="tooltip-row">
      <img [src]="'assets/icons/grey/CircleQuestion.svg'" alt="" />
      <span [ngbTooltip]="tooltip" placement="top">{{ 'Information is incorrect' | localization }}</span>
    </div>
    <hr />
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-text
        [inputName]="'address1'"
        [required]="true"
        [label]="'Address' | localization"
        [bindingProperty]="'address.addressLine1'">
      </lib-reactive-form-text>
      <lib-reactive-form-text
        [inputName]="'address2'"
        [label]="'Address 2' | localization"
        [bindingProperty]="'address.addressLine2'"
        [hintText]="'Optional' | localization">
      </lib-reactive-form-text>
    </lib-reactive-form-column-layout>
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-text
        [inputName]="'city'"
        [label]="'City' | localization"
        [required]="true"
        [bindingProperty]="'address.city'">
      </lib-reactive-form-text>
      <lib-reactive-form-drop-down
        [inputName]="'province'"
        [label]="'Province' | localization"
        [inlineLabel]="false"
        [placeholder]="'Select Province' | localization"
        [required]="true"
        [dropdowns]="(viewModel.stateDropdowns$ | async) ?? undefined"
        [bindingProperty]="'address.stateId'">
      </lib-reactive-form-drop-down>
    </lib-reactive-form-column-layout>
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-drop-down
        [inputName]="'country'"
        [label]="'Country' | localization"
        [inlineLabel]="false"
        [required]="true"
        [dropdowns]="(viewModel.countryDropdowns$ | async) ?? undefined"
        [bindingProperty]="'address.countryId'"
        [programmaticallyChangeValue]="viewModel.selectedCountryId$ | async"
        (valueChanged)="viewModel.countryChanged($event)">
      </lib-reactive-form-drop-down>
      <lib-reactive-form-text
        [inputName]="'postal'"
        [required]="true"
        [label]="'Postal Code' | localization"
        [bindingProperty]="'address.postalCode'">
      </lib-reactive-form-text>
    </lib-reactive-form-column-layout>
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-phone-country-code
        [bindingProperty]="'mobilePhoneNumber'"
        [countryCodeBindingProperty]="'mobilePhoneNumberCountryCode'"
        [label]="'Mobile Phone Number' | localization"
        [countryCodes]="(countryCodes$ | async) ?? []"
        [required]="true"
        [inputName]="'mobilePhoneNumber'"></lib-reactive-form-phone-country-code>
      <lib-reactive-form-phone-country-code
        [bindingProperty]="'workPhoneNumber'"
        [countryCodeBindingProperty]="'workPhoneNumberCountryCode'"
        [countryCodes]="(countryCodes$ | async) ?? []"
        [label]="'Work Phone Number' | localization"
        [hintText]="'Optional' | localization"
        [inputName]="'workPhoneNumber'"></lib-reactive-form-phone-country-code>
    </lib-reactive-form-column-layout>
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-phone-country-code
        [bindingProperty]="'homePhoneNumber'"
        [countryCodeBindingProperty]="'homePhoneNumberCountryCode'"
        [countryCodes]="(countryCodes$ | async) ?? []"
        [label]="'Home Phone Number' | localization"
        [hintText]="'Optional' | localization"
        [inputName]="'homePhoneNumber'"></lib-reactive-form-phone-country-code>
      <lib-reactive-form-drop-down
        [inputName]="'gender'"
        [label]="'Gender' | localization"
        [required]="true"
        [inlineLabel]="false"
        [placeholder]="'Select gender' | localization"
        [dropdowns]="(viewModel.genderDropdowns$ | async) ?? undefined"
        [bindingProperty]="'genderId'">
      </lib-reactive-form-drop-down>
    </lib-reactive-form-column-layout>
    <hr class="coop-divider" />
    <lib-reactive-form-checkbox
      [inputName]="'workedAtCoop'"
      (valueChanged)="previouslyWorkedForCoopChanged($event)"
      [ngClass]="{ 'mb-4': previouslyWorkedForCoop$ | async }"
      class="d-flex">
      {{ "I've previously worked at a co-operative or credit union" | localization }}
    </lib-reactive-form-checkbox>
  </lib-reactive-form-group>
  <!-- TODO: Implement -->
  <lib-reactive-form-group
    *ngIf="previouslyWorkedForCoop$ | async"
    #coopForm
    (formSubmitted$)="coopFormSubmitted($event)">
    <lib-reactive-form-text
      [inputName]="'workName'"
      [label]="'Name' | localization"
      [bindingProperty]="'firstName'"
      [required]="true">
    </lib-reactive-form-text>
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-masked-input
        [inputName]="'from'"
        [label]="'From' | localization"
        [mask]="viewModel.dateMask"
        [required]="true"
        [bindingProperty]="'from'">
      </lib-reactive-form-masked-input>
      <lib-reactive-form-masked-input
        [inputName]="'to'"
        [label]="'To' | localization"
        [mask]="viewModel.dateMask"
        [required]="true"
        [bindingProperty]="'to'">
      </lib-reactive-form-masked-input>
    </lib-reactive-form-column-layout>
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-text
        [inputName]="'workCity'"
        [label]="'City/Town' | localization"
        [required]="true"
        [bindingProperty]="'workCity'"></lib-reactive-form-text>
    </lib-reactive-form-column-layout>
  </lib-reactive-form-group>
</app-base-action-modal>
<ng-template #tooltip>
  <div class="tooltip-title">{{ 'Incorrect Information' | localization }}</div>
  <div class="tooltip-body">
    {{ 'You may request a name change through the Member Portal once your account is created.' | localization }}
  </div>
</ng-template>
