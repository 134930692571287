import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { inject, Injectable } from '@angular/core';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';
import { map, switchMap } from 'rxjs/operators';
import { AuthCodeRequest } from '../../../../../models/account/requests/auth-code-request';
import { Router } from '@angular/router';
import { PortalService } from '../../../../../services/portal/portal.service';
import { ToastService } from '../../../../../services/toast-service';
import { UserChallengeName } from '../../../../../models/account/enum/user-challenge.name';
import { UserChallengeParamName } from '../../../../../models/account/enum/user-challenge-param-name';
import { NumberUtils } from '../../../../../utils/number-utils';

@Injectable()
export class VerifyMfaViewModel extends BaseViewModel {
  private userDomainModel = inject(UserDomainModel);
  private router = inject(Router);
  private portalService = inject(PortalService);
  private toastService = inject(ToastService);

  constructor() {
    super();
  }

  private readonly loadingMessage = $localize`Signing in`;

  private user$ = this.userDomainModel.user$;

  public subText$ = this.user$.pipe(
    map(u => {
      switch (u?.challengeName) {
        case UserChallengeName.SMS_MFA:
          return $localize`Enter the six-digit code from the SMS message sent to
          ${u.challengeParameters?.get(UserChallengeParamName.CODE_DELIVERY_DESTINATION)} into the field below.`;
        case UserChallengeName.SOFTWARE_TOKEN_MFA:
          return $localize`Enter the six-digit code from your authentication app into the field below.`;
      }
    })
  );

  public req$ = this.user$.pipe(
    map(u => {
      return new AuthCodeRequest(u?.email, '', u?.challengeName ?? null, u?.sessionId ?? '');
    })
  );

  setupBindings(): void {}

  setupViews(): void {}

  formSubmitted(req: AuthCodeRequest) {
    this._loadingOpts.addRequest(this.loadingMessage);
    this.portalService.portalType$
      .pipe(
        switchMap(portalType => {
          return this.userDomainModel.memberEmployerVerifyMFA(
            {
              ...req,
              timeBasedOneTimePassword: NumberUtils.formatNumberWithLeadingZeros(+req.timeBasedOneTimePassword, 6)
            },
            portalType
          );
        })
      )
      .subscribe({
        next: u => {
          if (!u?.challengeName) {
            this._loadingOpts.removeRequest(this.loadingMessage);
            this.router.navigate(['/dashboard'], { replaceUrl: true }).then(() => {});
          }
        },
        error: err => {
          this._loadingOpts.removeRequest(this.loadingMessage);
          this.toastService.publishError(err);
        }
      });
  }
}
