<div
  class="base-card"
  [style.height]="height"
  [style.width]="width"
  [style.max-height]="maxHeight"
  [style.max-width]="maxWidth"
  [style.padding]="padding"
  [style.background-color]="backgroundColor"
  [style.border-color]="borderColor">
  <ng-content></ng-content>
</div>
