<div class="card-container-title">
  <div class="breadcrumb-container">
    <div class="parent" (click)="goBack()" i18n>Alerts</div>
    <img class="arrow" [src]="'assets/icons/grey/Chevron Right.svg'" alt="" />
    <div class="child">{{ pageTitle$ | async }}</div>
  </div>
  <div class="d-flex justify-content-between">
    <span class="header">{{ pageTitle$ | async }}</span>
    <lib-button-neutral
      *ngIf="(canDeleteAlert$ | async) && !(isPastAlert$ | async)"
      class="delete"
      (buttonClicked)="openDeleteModal()"
      i18n>
      Delete Alert
    </lib-button-neutral>
  </div>
</div>
<div class="card-container" [style.margin-top.rem]="8" [style.padding-bottom]="0">
  <div class="banner-preview-container" [style.margin-bottom.rem]="0" [style.margin-top.rem]="4">
    <app-banner-alert
      *ngIf="showBannerAlertPreview$ | async"
      [style.margin-bottom.rem]="2"
      [alert]="alertPreview$ | async"></app-banner-alert>
  </div>
  <div class="card-form-content-container" [style.margin-top.rem]="0">
    <div class="card-form-container">
      <app-alert-form
        *ngIf="!(isPastAlert$ | async)"
        [submitFormTrigger]="save.buttonClicked"
        (formSubmitted)="submitForm($event)"></app-alert-form>
      <app-view-past-alert *ngIf="isPastAlert$ | async"></app-view-past-alert>
    </div>
    <div class="card-form-action-container">
      <app-base-card *ngIf="(showAlertOverview$ | async) || (isLoading$ | async)">
        <div class="d-flex flex-column w-100">
          <span class="card-label mb-0" i18n *ngIf="!(isLoading$ | async); else loading">Alert Overview</span>
          <hr [style.margin]="'2rem 0'" />
          <div [ngClass]="{ 'd-flex justify-content-between': !(isLoading$ | async) }">
            <ng-container *ngIf="!(isLoading$ | async); else loading">
              <span class="info-label" i18n>Status</span>
              <app-status-pill [statusPill]="alertStatusPill$ | async"></app-status-pill>
            </ng-container>
          </div>
          <hr />
          <div [ngClass]="{ 'd-flex justify-content-between': !(isLoading$ | async) }">
            <ng-container *ngIf="!(isLoading$ | async); else loading">
              <span class="info-label" i18n>Alert Start Date</span>
              <span class="overview-data">{{ (alert$ | async)?.startDate | alertDetailsDate }}</span>
            </ng-container>
          </div>
          <hr />
          <div [ngClass]="{ 'd-flex justify-content-between': !(isLoading$ | async) }">
            <ng-container *ngIf="!(isLoading$ | async); else loading">
              <span class="info-label" i18n>Alert End Date</span>
              <span class="overview-data">{{ (alert$ | async)?.endDate | alertDetailsDate }}</span>
            </ng-container>
          </div>
          <!-- TODO: Add last edited field when API is updated -->
          <div class="active-alert" *ngIf="(alert$ | async)?.status === AlertStatusStringEnum.Active">
            <span class="active-alert-label" i18n>This is an active alert</span>
            <p class="active-alert-copy" i18n>
              Changes you save will be visible to users who are targeted by this alert.
            </p>
          </div>
        </div>
      </app-base-card>
      <app-base-card [height]="'100%'">
        <div class="d-flex flex-column">
          <span class="card-label" i18n>Preview</span>
          <div class="preview-subtext" i18n>View a live preview of what your alert will look like.</div>
        </div>
        <lib-button-primary (buttonClicked)="toggleShowAlertPreview()">
          {{ showAlertPreviewButtonText$ | async }}
        </lib-button-primary>
      </app-base-card>
      <app-card-alert
        *ngIf="(showCardAlertPreview$ | async) && !(previewLoading$ | async)"
        [alert]="alertPreview$ | async"></app-card-alert>
      <lib-loading-shimmer *ngIf="previewLoading$ | async" [width]="'100%'" [height]="'18.75rem'"></lib-loading-shimmer>
    </div>
  </div>
</div>
<div class="action-buttons" [hidden]="!(canEditAlert$ | async) || (isPastAlert$ | async)">
  <lib-button-primary #save i18n>Save Alert</lib-button-primary>
  <lib-button-neutral (buttonClicked)="goBack()" i18n>Cancel</lib-button-neutral>
</div>

<ng-template #loading>
  <lib-loading-shimmer [width]="'100%'" [height]="'1.5rem'"></lib-loading-shimmer>
</ng-template>
