import { Component } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';

@Component({
  selector: 'app-mobile-auth-navbar',
  templateUrl: './mobile-auth-navbar.component.html',
  styleUrls: ['./mobile-auth-navbar.component.scss']
})
export class MobileAuthNavbarComponent extends BaseComponent {
  constructor() {
    super();
  }

  setupBindings(): void {}

  setupViews(): void {}
}
