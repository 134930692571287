<div class="look-ahead-list" fxLayout="column">
  <ng-container *ngIf="!useProfileItems">
    <app-look-ahead-item
      *ngFor="let item of lookAheadItems; let index = index"
      [item]="item"
      [customItemTemplate]="customItemTemplate"
      [selected]="this.selectedPosition === index"
      [lookAheadProperties]="lookAheadProperties"
      [lookAheadSeparator]="lookAheadSeparator"
      (lookAheadItemClicked)="lookAheadItemClicked.emit($event)">
    </app-look-ahead-item>
  </ng-container>

  <ng-container *ngIf="useProfileItems">
    <app-profile-look-ahead-item
      *ngFor="let item of lookAheadItems; let index = index"
      [item]="item"
      [selected]="this.selectedPosition === index"
      [lookAheadProperties]="lookAheadProperties"
      [lookAheadSeparator]="lookAheadSeparator"
      (lookAheadItemClicked)="lookAheadItemClicked.emit($event)">
    </app-profile-look-ahead-item>
  </ng-container>
</div>
