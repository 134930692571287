import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector } from '@angular/core';
import { ModalUtils } from '../utils/modal-utils';
import { EulaModalComponent } from '../views/shared/modals/eula-modal/eula-modal.component';

export class ModalEula {
  static open(ngbModal: NgbModal, injector: Injector, eulaText: string): void {
    const modalRef = ngbModal.open(EulaModalComponent, ModalUtils.defaultModalOptions(injector));
    modalRef.componentInstance.eulaText = eulaText;
    modalRef.result.then(_ => {});
  }
}
