import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appMatchedDesignatedUnitRangeValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MatchedDesignatedUnitRangeValidatorDirective, multi: true }]
})
export class MatchedDesignatedUnitRangeValidatorDirective {
  static key: string = 'matchedRangePercentage';

  constructor() {}

  getErrorMsg(minPercentage: number, maxPercentage: number): string | null {
    if (!!minPercentage && !!maxPercentage) {
      if (minPercentage >= maxPercentage) return 'Minimum percentage cannot be greater than maximum percentage';
    }
    return null;
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    const minPercentageControl = control?.parent?.get('matchedMinPercentage');
    const maxPercentageControl = control?.parent?.get('matchedMaxPercentage');
    const minPercentageValue = minPercentageControl?.value;
    const maxPercentageValue = maxPercentageControl?.value;

    const minPercentage =
      typeof minPercentageValue === 'string' ? +minPercentageValue.replace('%', '') : +minPercentageValue;

    const maxPercentage =
      typeof maxPercentageValue === 'string' ? +maxPercentageValue.replace('%', '') : +maxPercentageValue;
    const errorMsg = this.getErrorMsg(minPercentage, maxPercentage);
    if (errorMsg) return { [MatchedDesignatedUnitRangeValidatorDirective.key]: errorMsg };
    return null;
  }
}
